import React from 'react';

import {useEffect} from 'react';

import TaskCard from './TaskCard';

function ManagementProjects(props) {
  useEffect(() => {}, []);

  return <TaskCard onClick={props.onClick} {...props} />;
}

export default ManagementProjects;
