import {yupResolver} from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import {Button, Grid} from '@mui/material';
import React, {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {i18n} from 'src/i18n';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import FormWrapper, {FormButtons} from 'src/view/shared/styles/FormWrapper';
import * as yup from 'yup';

const schema = yup.object().shape({
  nombre: yupFormSchemas.string(i18n('entities.habilidad.fields.nombre'), {
    required: true,
  }),
  incluirEstadisticas: yupFormSchemas.boolean(i18n('entities.habilidad.fields.incluirEstadisticas'), {}),
});

function HabilidadForm(props) {
  const [initialValues] = useState(() => {
    const record = props.record || {};

    return {
      nombre: record.nombre,
      incluirEstadisticas: record.incluirEstadisticas,
    };
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
  });

  const onSubmit = (values) => {
    props.onSubmit(props.record?.id, values);
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  const {saveLoading, modal} = props;

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Grid spacing={2} container>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputFormItem name="nombre" label={i18n('entities.habilidad.fields.nombre')} required={true} autoFocus />
            </Grid>
            {/* <Grid item lg={7} md={8} sm={12} xs={12}>
              <SwitchFormItem
                name="incluirEstadisticas"
                label={i18n('entities.habilidad.fields.incluirEstadisticas')}
              />
            </Grid> */}
          </Grid>
          <FormButtons
            style={{
              flexDirection: modal ? 'row-reverse' : undefined,
            }}>
            <Button
              variant="contained"
              color="primary"
              disabled={saveLoading}
              type="button"
              onClick={form.handleSubmit(onSubmit)}
              startIcon={<SaveIcon />}
              size="small">
              {i18n('common.save')}
            </Button>

            <Button disabled={saveLoading} onClick={onReset} type="button" startIcon={<UndoIcon />} size="small">
              {i18n('common.reset')}
            </Button>

            {props.onCancel ? (
              <Button
                disabled={saveLoading}
                onClick={() => props.onCancel()}
                type="button"
                startIcon={<CloseIcon />}
                size="small">
                {i18n('common.cancel')}
              </Button>
            ) : null}
          </FormButtons>
        </form>
      </FormProvider>
    </FormWrapper>
  );
}

export default HabilidadForm;
