import {TablePagination, useMediaQuery, useTheme} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {i18n} from 'src/i18n';
import PaginationWrapper from 'src/view/shared/table/styles/PaginationWrapper';

function Pagination(props) {
  const theme = useTheme();

  const onChangeRowsPerPage = (pageSize) => {
    props.onChange({
      current: 1,
      pageSize: pageSize || 10,
    });
  };

  const onChangePage = (event, current) => {
    const pageSize = Number(props.pagination.pageSize || 10);
    props.onChange({
      current: current + 1,
      pageSize,
    });
  };

  const {pagination} = props;
  const {current, pageSize, total} = pagination;
  const labelDisplayedRows =
    props.labelDisplayedRows || (({from, to, count}) => i18n('pagination.labelDisplayedRows', from, to, count));

  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  let rowsPerPageOptionsDefault = [10, 20, 30, 40, 100, 200];

  if (props.limitsPerPageOptions) {
    rowsPerPageOptionsDefault = props.limitsPerPageOptions;
  }

  return (
    <PaginationWrapper>
      <TablePagination
        // style={{zoom: props.noZoom ? undefined : 1.2}}
        labelDisplayedRows={labelDisplayedRows}
        labelRowsPerPage={isSmUp ? i18n('pagination.labelRowsPerPage') : ''}
        rowsPerPageOptions={rowsPerPageOptionsDefault}
        component="div"
        count={total}
        rowsPerPage={Number(pageSize)}
        page={current - 1}
        onPageChange={onChangePage}
        onRowsPerPageChange={(event) => onChangeRowsPerPage(+event.target.value)}
        sx={props.sx}
      />
    </PaginationWrapper>
  );
}

Pagination.propTypes = {
  pagination: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  labelDisplayedRows: PropTypes.func,
  limitsPerPageOptions: PropTypes.array,
  noZoom: PropTypes.any,
  sx: PropTypes.any,
};

export default Pagination;
