import {i18n} from 'src/i18n';
import {Excel} from 'src/modules/shared/excel/excel';
import ImporterSchema from 'src/modules/shared/importer/importerSchema';
import XLSX from 'xlsx';

import TerceroService from 'src/modules/tercero/terceroService';
import UbicacionService from 'src/modules/ubicacion/ubicacionService';

export default class Importer {
  schema: ImporterSchema;

  constructor(fields) {
    this.schema = new ImporterSchema(fields);
  }

  async getLocalizaciones() {
    const response = await UbicacionService.list(null, null, null, null);
    return response.rows.map((localizacion) => {
      return {
        id: localizacion.id,
        nombre: localizacion.nombre,
        clasificacion: localizacion.clasificacion,
      };
    });
  }
  async getTerceros() {
    const response = await TerceroService.list(null, null, null, null);
    return response.rows.map((tercero) => {
      return {
        id: tercero.id,
        nombre: tercero.nombre,
      };
    });
  }

  async downloadTemplate(templateFileName, options: any = null) {
    if (options) {
      if (!options?.appendSheets) options.appendSheets = [];
      if (options.withLocalizacion) {
        const hasLocalizaciones = options.appendSheets.find((sheet) => sheet.name === 'localizaciones');
        if (!hasLocalizaciones) {
          const localizaciones = await this.getLocalizaciones();
          if (localizaciones.length > 0) {
            options.appendSheets.push({
              name: 'localizaciones',
              json: localizaciones,
              header: [],
              skipHeader: false,
            });
          }
        }
      }
      if (options.withTerceros) {
        const hasTerceros = options.appendSheets.find((sheet) => sheet.name === 'terceros');
        if (!hasTerceros) {
          const terceros = await this.getTerceros();
          if (terceros.length > 0) {
            options.appendSheets.push({
              name: 'terceros',
              json: terceros,
              header: [],
              skipHeader: false,
            });
          }
        }
      }
    }
    return Excel.exportAsExcelFile([], this.schema.labels, templateFileName, options);
  }

  async castWithHeaderColumns(row) {
    return this.schema.castWithHeaderColumns(row);
  }

  async castForDisplay(row, index) {
    return this.schema.castForDisplay(row, index);
  }

  async castForImport(row) {
    return this.schema.castForImport(row);
  }

  async convertExcelFileToJson(file, config: any = {}) {
    config.skipHeader = 'skipHeader' in config ? config.skipHeader : true;

    const workbook = await this._convertExcelFileToWorkbook(file);

    const json = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
      header: 1,
      blankrows: false,
      range: config.skipHeader ? 1 : undefined,
      raw: true,
      ...config,
    });

    return json;
  }

  async _convertExcelFileToWorkbook(file) {
    try {
      const data = await this._readFile(file);
      return XLSX.read(data, {
        type: 'array',
      });
    } catch (error) {
      throw new Error(i18n('importer.errors.invalidFileUpload'));
    }
  }

  async _readFile(file) {
    if (!file) {
      return null;
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          if (!e || !e.target) {
            reject(new Error());
            return;
          }

          const data = new Uint8Array(e.target.result as any);
          resolve(data);
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = (e) => {
        reject();
      };

      reader.readAsArrayBuffer(file);
    });
  }
}
