import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import tareaEnumerators from 'src/modules/tarea/tareaEnumerators';

export default [
  {
    name: 'sucursal',
    label: i18n('entities.tarea.fields.sucursal'),
    schema: schemas.relationToOne(
      i18n('entities.tarea.fields.sucursal'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'planificadorTarea',
    label: i18n('entities.tarea.fields.planificadorTarea'),
    schema: schemas.relationToOne(
      i18n('entities.tarea.fields.planificadorTarea'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'modeloTarea',
    label: i18n('entities.tarea.fields.modeloTarea'),
    schema: schemas.relationToOne(
      i18n('entities.tarea.fields.modeloTarea'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'respuesta',
    label: i18n('entities.tarea.fields.respuesta'),
    schema: schemas.enumerator(
      i18n('entities.tarea.fields.respuesta'),
      {
        "options": tareaEnumerators.respuesta
      },
    ),
  },
  {
    name: 'comentario',
    label: i18n('entities.tarea.fields.comentario'),
    schema: schemas.string(
      i18n('entities.tarea.fields.comentario'),
      {},
    ),
  },
  {
    name: 'fotos',
    label: i18n('entities.tarea.fields.fotos'),
    schema: schemas.images(
      i18n('entities.tarea.fields.fotos'),
      {
        "max": 3
      },
    ),
  },
  {
    name: 'responsable',
    label: i18n('entities.tarea.fields.responsable'),
    schema: schemas.relationToOne(
      i18n('entities.tarea.fields.responsable'),
      {},
    ),
  },
  {
    name: 'estado',
    label: i18n('entities.tarea.fields.estado'),
    schema: schemas.enumerator(
      i18n('entities.tarea.fields.estado'),
      {
        "options": tareaEnumerators.estado
      },
    ),
  },
  {
    name: 'comienzo',
    label: i18n('entities.tarea.fields.comienzo'),
    schema: schemas.datetime(
      i18n('entities.tarea.fields.comienzo'),
      {},
    ),
  },
  {
    name: 'fin',
    label: i18n('entities.tarea.fields.fin'),
    schema: schemas.datetime(
      i18n('entities.tarea.fields.fin'),
      {},
    ),
  },
  {
    name: 'estadoActivacion',
    label: i18n('entities.tarea.fields.estadoActivacion'),
    schema: schemas.boolean(
      i18n('entities.tarea.fields.estadoActivacion'),
      {},
    ),
  },
  {
    name: 'fechaProgramacion',
    label: i18n('entities.tarea.fields.fechaProgramacion'),
    schema: schemas.datetime(
      i18n('entities.tarea.fields.fechaProgramacion'),
      {},
    ),
  },
  {
    name: 'fechaActivacion',
    label: i18n('entities.tarea.fields.fechaActivacion'),
    schema: schemas.datetime(
      i18n('entities.tarea.fields.fechaActivacion'),
      {},
    ),
  },
  {
    name: 'documentos',
    label: i18n('entities.tarea.fields.documentos'),
    schema: schemas.files(
      i18n('entities.tarea.fields.documentos'),
      {},
    ),
  },
  {
    name: 'titulo',
    label: i18n('entities.tarea.fields.titulo'),
    schema: schemas.string(
      i18n('entities.tarea.fields.titulo'),
      {},
    ),
  },
  {
    name: 'categoria',
    label: i18n('entities.tarea.fields.categoria'),
    schema: schemas.relationToOne(
      i18n('entities.tarea.fields.categoria'),
      {},
    ),
  },
  {
    name: 'dirigidoA',
    label: i18n('entities.tarea.fields.dirigidoA'),
    schema: schemas.enumerator(
      i18n('entities.tarea.fields.dirigidoA'),
      {
        "options": tareaEnumerators.dirigidoA
      },
    ),
  },
  {
    name: 'tipoVisualizacion',
    label: i18n('entities.tarea.fields.tipoVisualizacion'),
    schema: schemas.enumerator(
      i18n('entities.tarea.fields.tipoVisualizacion'),
      {
        "options": tareaEnumerators.tipoVisualizacion
      },
    ),
  },
  {
    name: 'fechaLimite',
    label: i18n('entities.tarea.fields.fechaLimite'),
    schema: schemas.datetime(
      i18n('entities.tarea.fields.fechaLimite'),
      {},
    ),
  },
  {
    name: 'enviada',
    label: i18n('entities.tarea.fields.enviada'),
    schema: schemas.boolean(
      i18n('entities.tarea.fields.enviada'),
      {},
    ),
  },
  {
    name: 'esEvaluada',
    label: i18n('entities.tarea.fields.esEvaluada'),
    schema: schemas.boolean(
      i18n('entities.tarea.fields.esEvaluada'),
      {},
    ),
  },
  {
    name: 'evaluadores',
    label: i18n('entities.tarea.fields.evaluadores'),
    schema: schemas.relationToMany(
      i18n('entities.tarea.fields.evaluadores'),
      {},
    ),
  },
  {
    name: 'metaInterfaz',
    label: i18n('entities.tarea.fields.metaInterfaz'),
    schema: schemas.string(
      i18n('entities.tarea.fields.metaInterfaz'),
      {},
    ),
  },
];