import {createSelector} from 'reselect';
import authSelectors from 'src/modules/auth/authSelectors';
import PermissionChecker from 'src/modules/auth/permissionChecker';
import Permissions from 'src/security/permissions';

const selectPermissionToRead = createSelector([authSelectors.selectCurrentTenant, authSelectors.selectCurrentUser], (currentTenant, currentUser) =>
  new PermissionChecker(currentTenant, currentUser).match(Permissions.values.activoRead),
);

const selectPermissionToEdit = createSelector([authSelectors.selectCurrentTenant, authSelectors.selectCurrentUser], (currentTenant, currentUser) =>
  new PermissionChecker(currentTenant, currentUser).match(Permissions.values.activoEdit),
);

const selectPermissionToCreate = createSelector([authSelectors.selectCurrentTenant, authSelectors.selectCurrentUser], (currentTenant, currentUser) =>
  new PermissionChecker(currentTenant, currentUser).match(Permissions.values.activoCreate),
);

const selectPermissionToImport = createSelector([authSelectors.selectCurrentTenant, authSelectors.selectCurrentUser], (currentTenant, currentUser) =>
  new PermissionChecker(currentTenant, currentUser).match(Permissions.values.activoImport),
);

const selectPermissionToDestroy = createSelector([authSelectors.selectCurrentTenant, authSelectors.selectCurrentUser], (currentTenant, currentUser) =>
  new PermissionChecker(currentTenant, currentUser).match(Permissions.values.activoDestroy),
);

const selectPermissionToConfig = createSelector([authSelectors.selectCurrentTenant, authSelectors.selectCurrentUser], (currentTenant, currentUser) =>
  new PermissionChecker(currentTenant, currentUser).match(Permissions.values.activoConfig),
);

const activoSelectors = {
  selectPermissionToRead,
  selectPermissionToEdit,
  selectPermissionToCreate,
  selectPermissionToDestroy,
  selectPermissionToImport,
  selectPermissionToConfig,
};

export default activoSelectors;
