import PropTypes from 'prop-types';
import React from 'react';

import {ViewList} from '@mui/icons-material';
import {useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import selectors from 'src/modules/planificadorTarea/planificadorTareaSelectors';
import DrawerButton from 'src/view/shared/view/DrawerButton';
import PlanificadorTareaViewPage from '../view/PlanificadorTareaViewPage';

function PlanificadorTareaListItem(props) {
  const hasPermissionToRead = useSelector(selectors.selectPermissionToRead);

  const valueAsArray = () => {
    const {value} = props;

    if (!value) {
      return [];
    }

    if (Array.isArray(value)) {
      return value;
    }

    return [value];
  };

  const displayableRecord = (record) => {
    if (!record) return null;
    return (
      <div key={record.id}>
        <DrawerButton
          drawerId={'DrawerPlanificadorTareaViewPage'}
          type={'chip'}
          entity={'planificadorTarea'}
          tooltipTitle={i18n(record.nombre)}
          buttonTitle={i18n(record.nombre)}
          buttonIcon={<ViewList />}
          width={window.innerWidth * 0.7}
          record={record}
          component={<PlanificadorTareaViewPage record={record} drawer={true} />}
        />
      </div>
    );
  };

  if (!valueAsArray().length) {
    return null;
  }

  return <>{valueAsArray().map((value) => displayableRecord(value))}</>;
}

PlanificadorTareaListItem.propTypes = {
  value: PropTypes.any,
};

export default PlanificadorTareaListItem;
