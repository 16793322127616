import {combineReducers} from 'redux';
import destroy from 'src/modules/data/destroy/dataDestroyReducers';
import form from 'src/modules/data/form/dataFormReducers';
import importerReducer from 'src/modules/data/importer/dataImporterReducers';
import list from 'src/modules/data/list/dataListReducers';
import view from 'src/modules/data/view/dataViewReducers';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
