import list from 'src/modules/planificadorTarea/list/planificadorTareaListReducers';
import form from 'src/modules/planificadorTarea/form/planificadorTareaFormReducers';
import view from 'src/modules/planificadorTarea/view/planificadorTareaViewReducers';
import destroy from 'src/modules/planificadorTarea/destroy/planificadorTareaDestroyReducers';
import importerReducer from 'src/modules/planificadorTarea/importer/planificadorTareaImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
