const turnoLaboralEnumerators = {
  dias: [
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
    'Domingo',
  ],
  turno: [
    'Mañana',
    'Tarde',
    'Noche',
  ],
};

export default turnoLaboralEnumerators;
