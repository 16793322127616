/**
 * Available only in the current session of the browser
 */
export default class AuthInvitationEmail {
  static get() {
    return sessionStorage.getItem('invitationEmail') || null;
  }

  static set(email) {
    sessionStorage.setItem('invitationEmail', email);
  }

  static clear() {
    sessionStorage.removeItem('invitationEmail');
  }
}
