import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {Button} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React from 'react';
import {getHistory} from 'src/modules/store';
const useStyles = makeStyles((theme: any) => ({
  button: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  buttonInner: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    margin: theme.spacing(0, 0.5, 0, 1),
    display: 'none',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: theme.palette.getContrastText(theme.palette.primary.main),
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  optionText: {
    margin: theme.spacing(0, 0.5, 0, 1),
  },
}));

function SimpleMenu() {
  const classes = useStyles();

  const handleClick = (event) => {
    // navigate to login
    getHistory().push('/auth/signin');
  };

  const userAvatar = null;

  return (
    <>
      <Button className={classes.button} onClick={handleClick}>
        <div className={classes.buttonInner}>
          <AccountCircleIcon />
          <div className={classes.text}>Iniciar sesión</div>
        </div>
      </Button>
    </>
  );
}

export default SimpleMenu;
