import moment from 'moment';
import {i18n} from 'src/i18n';
import Errors from 'src/modules/shared/error/errors';
import Exporter from 'src/modules/shared/exporter/exporter';
//import {PdfCalendario} from 'src/modules/shared/pdf/pdfCalendario';
import exporterFields from 'src/modules/tarea/list/tareaListExporterFields';
import selectors from 'src/modules/tarea/list/tareaListSelectors';
import TareaService from 'src/modules/tarea/tareaService';

const prefix = 'TAREA_LIST';

const tareaListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,
  SET_HAS_FILTER: `${prefix}_SET_HAS_FILTER`,
  SET_PAYLOAD: `${prefix}_SET_PAYLOAD`,

  RESETED: `${prefix}_RESETED`,
  TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
  TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
  CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  PATCH_RECORD: `${prefix}_SET_RECORD`,

  doClearAllSelected() {
    return {
      type: tareaListActions.CLEAR_ALL_SELECTED,
    };
  },

  doToggleAllSelected() {
    return {
      type: tareaListActions.TOGGLE_ALL_SELECTED,
    };
  },

  doToggleOneSelected(id) {
    return {
      type: tareaListActions.TOGGLE_ONE_SELECTED,
      payload: id,
    };
  },

  doReset:
    (emptyValues = {}) =>
    async (dispatch) => {
      dispatch({
        type: tareaListActions.RESETED,
        payload: emptyValues,
      });

      dispatch(tareaListActions.doFetchCurrentFilter());
    },

  // doExportCalendario: () => async (dispatch, getState) => {
  //   try {
  //     // if (!exporterFields || !exporterFields.length) {
  //     //   throw new Error('exporterFields is required');
  //     // }
  //     dispatch({
  //       type: tareaListActions.EXPORT_STARTED,
  //     });

  //     const filter = selectors.selectFilter(getState());
  //     console.log('%c⧭ doExportCalendario: solo ejecutar después de filtrar fecha programacion', 'color: #00bf00', {filter});
  //     const response = await TareaService.list(filter, 'fechaProgramacion_ASC', null, null);
  //     var pdf = await PdfCalendario.exportDataAsPdf(response.rows);
  //     pdf.open();

  //     dispatch({
  //       type: tareaListActions.EXPORT_SUCCESS,
  //     });
  //   } catch (error) {
  //     Errors.handle(error);
  //     dispatch({
  //       type: tareaListActions.EXPORT_ERROR,
  //     });
  //   }
  // },

  doExport: () => async (dispatch, getState) => {
    try {
      if (!exporterFields || !exporterFields.length) {
        throw new Error('exporterFields is required');
      }

      dispatch({
        type: tareaListActions.EXPORT_STARTED,
      });

      const filter = selectors.selectFilter(getState());
      filter.withChildren = undefined;
      let offset = 0;
      let response;
      let allTareas: any = [];
      do {
        response = await TareaService.list(filter, selectors.selectOrderBy(getState()), 50, offset);
        allTareas = [...allTareas, ...response.rows];
        await new Promise((resolve) => setTimeout(resolve, 500)); // wait for 0.5 seconds
        offset += 50;
      } while (response.rows.length > 0);

      const options: any = {
        customLabels: (fields) =>
          fields.filter((field) => field.name !== 'listaProcedimiento' && field.name !== 'activo').map((field) => field.label),
        customCast:
          ({fields, casted, row}) =>
          (name) => {
            const field = fields.find((field) => field.name === name);
            if (field?.name === 'activo') {
              const activo = row.activo;
              if (!activo) return;
              if (activo?.nombre && activo?.nombre !== activo?.codigo) {
                casted['Activo'] = activo.nombre;
              }
              if (activo?.codigo) {
                casted['Código'] = activo.codigo;
              }
              if (activo?.patente) {
                casted['Patente'] = activo.patente;
              }
              if (activo?.tipoActivo?.length > 0) {
                casted['Tipo Activo'] = activo.tipoActivo.map((t) => t.descripcion).join(', ');
              }
              if (activo?.marca) {
                casted['Marca'] = activo.marca;
              }
              if (activo?.modelo) {
                casted['Modelo'] = activo.modelo;
              }
              if (activo?.numeroSerial) {
                casted['Número Serial'] = activo.numeroSerial;
              }
              // if (activo?.localizacion) {
              //   casted['Localización'] = activo.localizacion.label;
              // }
              if (activo?.locations) {
                casted['Ubicación'] = activo.locations.map((l) => l.label).join(', ');
              }
            } else if (field?.name === 'listaProcedimiento') {
              const preguntas: any[] = row.listaProcedimiento
                ? row.listaProcedimiento?.filter((item) => item?.tipo === 'pregunta')
                : [];
              if (!row.listaProcedimiento) {
                const procedimiento = row.modeloTarea?.procedimiento?.formato;
                procedimiento?.forEach((seccion) => {
                  seccion.subseccions?.forEach((subseccion) => {
                    subseccion.preguntas?.forEach((pregunta) => {
                      preguntas.push(pregunta);
                    });
                  });
                });
              }
              preguntas.forEach((pregunta, index) => {
                const newKey = pregunta?.pregunta?.titulo || index;
                if (newKey in casted) return;
                casted[newKey] = '';
                // if (pregunta?.tieneComentario) {
                //   casted[newKey + ' (Comentario)'] = '';
                // }
              });
              preguntas.forEach((pregunta, index) => {
                const preguntaKey = pregunta?.pregunta?.titulo || index;
                const tipoRespuesta = pregunta?.tipoRespuesta;
                switch (tipoRespuesta) {
                  case 'time':
                    const time = pregunta['time'];
                    casted[preguntaKey] = time ? moment(time).format('HH:mm') : '';
                    break;
                  case 'multipleChoice':
                    casted[preguntaKey] = pregunta['multipleChoice'];
                    break;
                  case 'asset':
                    casted[preguntaKey] = pregunta['asset']?.label;
                    break;
                  case 'perfilEmpleado':
                    casted[preguntaKey] = pregunta['perfilEmpleado']?.label || pregunta.comentario;
                    //if (pregunta.comentario) casted[preguntaKey + ' (Comentario)'] = pregunta.comentario;
                    break;
                  case 'multiCheckbox':
                    casted[preguntaKey] = pregunta['multiCheckbox']
                      ?.filter((item) => item.value)
                      .map((item) => item.label)
                      .join(', ');
                    break;
                  case 'yesNo':
                    casted[preguntaKey] = pregunta['yesNo'];
                    break;
                  case 'number':
                    casted[preguntaKey] = pregunta['number'];
                    break;
                  case 'textField':
                    casted[preguntaKey] = pregunta['textField'];
                    break;
                  default:
                    casted[preguntaKey] = pregunta[tipoRespuesta];
                    break;
                }
              });
              //console.log('%c⧭ listaProcedimiento', 'color: #73998c', name, row[name], 'preguntas', preguntas, 'casted', casted);
            } else if (field) {
              casted[name] = field.render ? field.render(row[name]) : row[name] !== undefined ? String(row[name]) : '';
            }
          },
      };

      new Exporter(exporterFields, i18n('entities.tarea.exporterFileName'), options).transformAndExportAsExcelFile(allTareas);

      dispatch({
        type: tareaListActions.EXPORT_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: tareaListActions.EXPORT_ERROR,
      });
    }
  },

  doChangePagination: (pagination) => async (dispatch, getState) => {
    dispatch({
      type: tareaListActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(tareaListActions.doFetchCurrentFilter());
  },

  doChangeSort: (sorter) => async (dispatch, getState) => {
    dispatch({
      type: tareaListActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(tareaListActions.doFetchCurrentFilter());
  },

  doFetchCurrentFilter: () => async (dispatch, getState) => {
    const filter = selectors.selectFilter(getState());
    const rawFilter = selectors.selectRawFilter(getState());
    //console.log('%c⧭ doFetchCurrentFilter', 'color: #e5de73', {filter, rawFilter});
    dispatch(tareaListActions.doFetch(filter, rawFilter, true));
  },

  setPayload: (payload) => ({
    type: tareaListActions.SET_PAYLOAD,
    payload,
  }),

  setHasFilter:
    (filter, atLeastHast: string[] = []) =>
    async (dispatch, getState) => {
      try {
        const keysThatAreArrays = [
          'comienzoRange',
          'fechaActivacionRange',
          'fechaLimiteRange',
          'fechaProgramacionRange',
          'finRange',
        ];
        const hasFilter = !!Object.keys(filter).find((key) => {
          if (atLeastHast.length && !atLeastHast.includes(key)) return false;
          if (key === 'fechaProgramacionRange') {
            const hasBoth =
              filter['fechaProgramacionRange']?.length === 2 &&
              filter['fechaProgramacionRange'][0] &&
              filter['fechaProgramacionRange'][1];
            return hasBoth;
          }
          if (keysThatAreArrays.includes(key)) {
            return filter[key].length && filter[key][0];
          }
          return filter[key];
        });
        //console.log('%c⧭ setHasFilter', 'color: #731d1d', {hasFilter, filter});
        dispatch({
          type: tareaListActions.SET_HAS_FILTER,
          payload: {hasFilter, filter},
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: tareaListActions.FETCH_ERROR,
        });
      }
    },

  doFetch:
    (filter?, rawFilter?, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: tareaListActions.FETCH_STARTED,
          payload: {filter, rawFilter, keepPagination},
        });

        const response = await TareaService.list(
          filter,
          selectors.selectOrderBy(getState()),
          selectors.selectLimit(getState()),
          selectors.selectOffset(getState()),
        );
        // for(let row of response.rows){
        //   row.children = response.rows.map(row => { return {...row}});
        //   for(let rowb of row.children){
        //     rowb.children = row.children.map(row => { return {...row}});
        //     for(let rowc of rowb.children){
        //       rowc.children = rowb.children.map(row => { return {...row}});
        //     }
        //   }
        // }
        dispatch({
          type: tareaListActions.FETCH_SUCCESS,
          payload: {
            rows: response.rows,
            count: response.count,
          },
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: tareaListActions.FETCH_ERROR,
        });
      }
    },
};

export default tareaListActions;
