import {yupResolver} from '@hookform/resolvers/yup';
import {Add, Pause, PlayArrow, SaveOutlined} from '@mui/icons-material';

import {Box, Button, Grid, Typography} from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import moment from 'moment-timezone';
import React, {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import activoEnumerators from 'src/modules/activo/activoEnumerators';
import authSelectors from 'src/modules/auth/authSelectors';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import Storage from 'src/security/storage';
import {customButtonStyle, magicFlexBox} from 'src/theme/schemes/CustomTheme';
import ActivoAutocompleteFormItem from 'src/view/activo/autocomplete/ActivoAutocompleteFormItem';
import GestorDocumentalAutocompleteFormItem from 'src/view/gestorDocumental/autocomplete/GestorDocumentalAutocompleteFormItem';
import ModeloTareaAutocompleteFormItem from 'src/view/modeloTarea/autocomplete/ModeloTareaAutocompleteFormItem';
import PerfilEmpleadoAutocompleteFormItem from 'src/view/perfilEmpleado/autocomplete/PerfilEmpleadoAutocompleteFormItem';
import PresupuestoAutocompleteFormItem from 'src/view/presupuesto/autocomplete/PresupuestoAutocompleteFormItem';
import DatePickerFormItem from 'src/view/shared/form/items/DatePickerFormItem';
import FilesFormItem from 'src/view/shared/form/items/FilesFormItem';
import GoogleAutocompleteFormItem from 'src/view/shared/form/items/GoogleAutocompleteFormItem';
import ImagesFormItem from 'src/view/shared/form/items/ImagesFormItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import InputNumberFormItem from 'src/view/shared/form/items/InputNumberFormItem';
import SelectFormItem from 'src/view/shared/form/items/SelectFormItem';
import SwitchFormItem from 'src/view/shared/form/items/SwitchFormItem';
import TextAreaFormItem from 'src/view/shared/form/items/TextAreaFormItem';
import ConfirmModal from 'src/view/shared/modals/ConfirmModal';
import FormWrapper, {FormButtons} from 'src/view/shared/styles/FormWrapper';
import DrawerButton from 'src/view/shared/view/DrawerButton';
import TareaSendFormPage from 'src/view/tarea/form/TareaSendFormPage';
import TareaListFilter from 'src/view/tarea/list/TareaListFilter2';
import TareaListTable from 'src/view/tarea/list/TareaListTable2';
import TerceroAutocompleteFormItem from 'src/view/tercero/autocomplete/TerceroAutocompleteFormItem';
import TipoActivoAutocompleteFormItem from 'src/view/tipoActivo/autocomplete/TipoActivoAutocompleteFormItem';
import UbicacionAutocompleteFormItem from 'src/view/ubicacion/autocomplete/UbicacionAutocompleteFormItem';
import UnidadMedidaAutocompleteFormItem from 'src/view/unidadMedida/autocomplete/UnidadMedidaAutocompleteFormItem';
import UserAutocompleteFormItem from 'src/view/user/autocomplete/UserAutocompleteFormItem';
import * as yup from 'yup';

const schema = (fieldConfig) =>
  yup.object().shape({
    fotos: yupFormSchemas.images(i18n('entities.activo.fields.fotos'), {}),
    grupo: yupFormSchemas.enumerator(i18n('entities.activo.fields.grupo'), {
      required: !fieldConfig || fieldConfig.grupo,
      options: activoEnumerators.grupo,
    }),
    habilitado: yupFormSchemas.boolean(i18n('entities.activo.fields.habilitado'), {}),
    activoPrincipal: yupFormSchemas.relationToOne(i18n('entities.activo.fields.activoPrincipal'), {}),
    nombre: yupFormSchemas.string(i18n('entities.activo.fields.nombre'), {
      required: !fieldConfig || fieldConfig.nombre,
    }),
    localizacion: yupFormSchemas.relationToOne(i18n('entities.activo.fields.localizacion'), {}),
    validateWithLocationManager: yupFormSchemas.boolean(i18n('entities.activo.fields.validateWithLocationManager'), {}),
    patente: yupFormSchemas.string(i18n('entities.activo.fields.patente'), {}),
    descripcion: yupFormSchemas.string(i18n('entities.activo.fields.descripcion'), {}),
    codigo: yupFormSchemas.string(i18n('entities.activo.fields.codigo'), {
      required: !fieldConfig || fieldConfig.codigo,
    }),
    direccion: yupFormSchemas.string(i18n('entities.activo.fields.direccion'), {}),
    ciudad: yupFormSchemas.string(i18n('entities.activo.fields.ciudad'), {}),
    region: yupFormSchemas.string(i18n('entities.activo.fields.region'), {}),
    pais: yupFormSchemas.string(i18n('entities.activo.fields.pais'), {}),
    codigoArea: yupFormSchemas.string(i18n('entities.activo.fields.codigoArea'), {}),
    prioridad: yupFormSchemas.enumerator(i18n('entities.activo.fields.prioridad'), {
      options: activoEnumerators.prioridad,
    }),
    ubicacionGoogle: yupFormSchemas.string(i18n('entities.activo.fields.ubicacionGoogle'), {}),
    latitud: yupFormSchemas.decimal(i18n('entities.activo.fields.latitud'), {}),
    longitud: yupFormSchemas.decimal(i18n('entities.activo.fields.longitud'), {}),
    tipoActivo: yupFormSchemas.relationToMany(i18n('entities.activo.fields.tipoActivo'), {}),
    codigoBarra: yupFormSchemas.string(i18n('entities.activo.fields.codigoBarra'), {}),
    presupuesto: yupFormSchemas.relationToOne(i18n('entities.activo.fields.presupuesto'), {}),
    tercerosRelacionados: yupFormSchemas.relationToMany(i18n('entities.activo.fields.tercerosRelacionados'), {}),
    repuestos: yupFormSchemas.relationToMany(i18n('entities.activo.fields.repuestos'), {}),
    fabricante: yupFormSchemas.string(i18n('entities.activo.fields.fabricante'), {}),
    marca: yupFormSchemas.string(i18n('entities.activo.fields.marca'), {}),
    modelo: yupFormSchemas.string(i18n('entities.activo.fields.modelo'), {}),
    numeroSerial: yupFormSchemas.string(i18n('entities.activo.fields.numeroSerial'), {}),
    fechaCompra: yupFormSchemas.date(i18n('entities.activo.fields.fechaCompra'), {}),
    cantidadHorasDisponibilidadDia: yupFormSchemas.integer(i18n('entities.activo.fields.cantidadHorasDisponibilidadDia'), {}),
    horasUsoPromedioDiario: yupFormSchemas.integer(i18n('entities.activo.fields.horasUsoPromedioDiario'), {}),
    kilometrosPromedioDiario: yupFormSchemas.integer(i18n('entities.activo.fields.kilometrosPromedioDiario'), {}),
    visibilidadHorasUso: yupFormSchemas.boolean(i18n('entities.activo.fields.visibilidadHorasUso'), {}),
    inventario: yupFormSchemas.boolean(i18n('entities.activo.fields.inventario'), {}),
    tipoDepreciacion: yupFormSchemas.enumerator(i18n('entities.activo.fields.tipoDepreciacion'), {
      options: activoEnumerators.tipoDepreciacion,
    }),

    tipoMedicionMantenimiento: yupFormSchemas.enumerator(i18n('entities.activo.fields.tipoMedicionMantenimiento'), {
      options: activoEnumerators.tipoMedicionMantenimiento,
      required: !fieldConfig || fieldConfig.tipoMedicionMantenimiento,
    }),
    fechaInicioOperaciones: yupFormSchemas.date(i18n('entities.activo.fields.fechaInicioOperaciones'), {
      //required: !fieldConfig || fieldConfig.fechaInicioOperaciones,
    }),
    depreciacionAnual: yupFormSchemas.integer(i18n('entities.activo.fields.depreciacionAnual'), {}),
    vidaUtil: yupFormSchemas.integer(i18n('entities.activo.fields.vidaUtil'), {}),
    costoCompra: yupFormSchemas.integer(i18n('entities.activo.fields.costoCompra'), {}),
    valorSustitucion: yupFormSchemas.integer(i18n('entities.activo.fields.valorSustitucion'), {}),
    valorSalvamiento: yupFormSchemas.integer(i18n('entities.activo.fields.valorSalvamiento'), {}),
    depreciacionTotalHoy: yupFormSchemas.integer(i18n('entities.activo.fields.depreciacionTotalHoy'), {}),
    valorLibroHoy: yupFormSchemas.integer(i18n('entities.activo.fields.valorLibroHoy'), {}),
    fechaExpiracion: yupFormSchemas.date(i18n('entities.activo.fields.fechaExpiracion'), {}),
    gestionDocumental: yupFormSchemas.relationToMany(i18n('entities.activo.fields.gestionDocumental'), {}),
    unidadMedida: yupFormSchemas.relationToOne(i18n('entities.activo.fields.unidadMedida'), {}),
    numeroParte: yupFormSchemas.string(i18n('entities.activo.fields.numeroParte'), {}),
    peso: yupFormSchemas.decimal(i18n('entities.activo.fields.peso'), {}),
    tiempoEspera: yupFormSchemas.integer(i18n('entities.activo.fields.tiempoEspera'), {}),
    controladoPorSerial: yupFormSchemas.boolean(i18n('entities.activo.fields.controladoPorSerial'), {}),
    equivalencias: yupFormSchemas.relationToMany(i18n('entities.activo.fields.equivalencias'), {}),
    modeloTareaMantenimientoPreventivo: yupFormSchemas.relationToMany(
      i18n('entities.activo.fields.modeloTareaMantenimientoPreventivo'),
      {},
    ),
    ejecutoresMantenimientoPreventivo: yupFormSchemas.relationToMany(
      i18n('entities.activo.fields.ejecutoresMantenimientoPreventivo'),
      {},
    ),
    version: yupFormSchemas.decimal(i18n('entities.activo.fields.version'), {}),
    direccionWeb: yupFormSchemas.string(i18n('entities.activo.fields.direccionWeb'), {}),
    responsablesActivo: yupFormSchemas.relationToMany(i18n('entities.activo.fields.responsablesActivo'), {}),
    responsables: yupFormSchemas.relationToMany(i18n('entities.activo.fields.responsables'), {}),
    estado: yupFormSchemas.enumerator(i18n('entities.activo.fields.estado'), {
      options: activoEnumerators.estado,
    }),
    certificated: yupFormSchemas.boolean(i18n('entities.activo.fields.certificated'), {}),
    ubicacionCiclos: yupFormSchemas.relationToMany(i18n('entities.activo.fields.ubicacionCiclos'), {}),
    cantidadCiclosRecicla: yupFormSchemas.integer(i18n('entities.activo.fields.cantidadCiclosRecicla'), {}),
    documentos: yupFormSchemas.files(i18n('entities.activo.fields.documentos'), {}),
    notas: yupFormSchemas.relationToMany(i18n('entities.activo.fields.notas'), {}),
    horometro: yupFormSchemas.integer(i18n('entities.activo.fields.horometro'), {}),
    fechaHorometro: yupFormSchemas.date(i18n('entities.activo.fields.fechaHorometro'), {}),
    mantenimientoPorHorometro: yupFormSchemas.integer(i18n('entities.activo.fields.mantenimientoPorHorometro'), {}),
    mantenimientoPorKilometros: yupFormSchemas.integer(i18n('entities.activo.fields.mantenimientoPorKilometros'), {}),
    ancho: yupFormSchemas.decimal(i18n('entities.activo.fields.ancho'), {}),
    alto: yupFormSchemas.decimal(i18n('entities.activo.fields.alto'), {}),
    // campos nuevos imecom
    numLote: yupFormSchemas.integer(i18n('entities.activo.fields.numLote'), {}),
    numInspeccion: yupFormSchemas.string(i18n('entities.activo.fields.numInspeccion'), {}),
    fechaInspeccion: yupFormSchemas.date(i18n('entities.activo.fields.fechaInspeccion'), {}),
    numInspAnterior: yupFormSchemas.string(i18n('entities.activo.fields.numInspAnterior'), {}),
    fechaInspAnterior: yupFormSchemas.date(i18n('entities.activo.fields.fechaInspAnterior'), {}),
    fechaFabricacion: yupFormSchemas.integer(i18n('entities.activo.fields.fechaFabricacion'), {}),
    capacidad: yupFormSchemas.string(i18n('entities.activo.fields.capacidad'), {}),
    presionPrueba: yupFormSchemas.decimal(i18n('entities.activo.fields.presionPrueba'), {}),
    presionDiseno: yupFormSchemas.string(i18n('entities.activo.fields.presionDiseno'), {}),
    nombreDiseno: yupFormSchemas.string(i18n('entities.activo.fields.nombreDiseno'), {}),
    materialManto: yupFormSchemas.string(i18n('entities.activo.fields.materialManto'), {}),
    espesorManto: yupFormSchemas.decimal(i18n('entities.activo.fields.espesorManto'), {}),
    materialCabezales: yupFormSchemas.string(i18n('entities.activo.fields.materialCabezales'), {}),
    espesorCabezales: yupFormSchemas.decimal(i18n('entities.activo.fields.espesorCabezales'), {}),
    formaCabezales: yupFormSchemas.string(i18n('entities.activo.fields.formaCabezales'), {}),
    superficie: yupFormSchemas.string(i18n('entities.activo.fields.superficie'), {}),
    enterrado: yupFormSchemas.string(i18n('entities.activo.fields.enterrado'), {}),
    numCertAnterior: yupFormSchemas.string(i18n('entities.activo.fields.numCertAnterior'), {}),
    otorgadoPorCia: yupFormSchemas.string(i18n('entities.activo.fields.otorgadoPorCia'), {}),
    cuno: yupFormSchemas.string(i18n('entities.activo.fields.cuno'), {}),
    locations: yupFormSchemas.relationToMany(i18n('common.locations'), {}),
  });

function TabPanel(props) {
  const {children, value, index, simpleView, ...other} = props;

  return (
    <Box
      sx={
        {
          ...magicFlexBox,
          display: value !== index ? 'none' : 'flex',
        } as any
      }
      role="tabpanel"
      style={{width: '100%', marginTop: simpleView ? -30 : undefined}}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box
          sx={
            {
              ...magicFlexBox,
              overflowY: 'auto',
              flexGrow: 1,
              paddingRight: 1,
            } as any
          }
          p={0}
          pt={2}>
          {children}
        </Box>
      )}
    </Box>
  );
}

function ActivoForm(props) {
  const tenantUser = useSelector(authSelectors.selectTenantUser);
  const userLocations = tenantUser?.perfilEmpleado?.ubicacion;

  const [tabValue, setTabValue]: any = useState('tabGeneral');
  const [confirm, setConfirm] = useState(false);

  let fieldConfig = props.fieldConfig?.fields;
  //console.log('%c⧭ ActivoForm', 'color: #7f2200', {fieldConfig});
  const [initialValues] = useState(() => {
    const record = props.record || {};

    return {
      fotos: record.fotos || [],
      grupo: record.grupo,
      habilitado: record.habilitado,
      activoPrincipal: record.activoPrincipal,
      nombre: record.nombre,
      horometro: record.horometro,
      fechaHorometro: record.fechaHorometro ? moment(record.fechaHorometro, 'YYYY-MM-DD') : null,
      kilometraje: record.kilometraje,
      localizacion: record.localizacion,
      validateWithLocationManager: record.validateWithLocationManager,
      codigo: record.codigo,
      direccion: record.direccion,
      ciudad: record.ciudad,
      region: record.region,
      pais: record.pais,
      codigoArea: record.codigoArea,
      prioridad: record.prioridad,
      ubicacionGoogle: record.ubicacionGoogle,
      latitud: record.latitud,
      longitud: record.longitud,
      tipoActivo: record.tipoActivo || [],
      codigoBarra: record.codigoBarra,
      patente: record.patente,
      descripcion: record.descripcion,
      presupuesto: record.presupuesto,
      tercerosRelacionados: record.tercerosRelacionados || [],
      repuestos: record.repuestos || [],
      fabricante: record.fabricante,
      marca: record.marca,
      modelo: record.modelo,
      numeroSerial: record.numeroSerial,
      fechaCompra: record.fechaCompra ? moment(record.fechaCompra, 'YYYY-MM-DD') : null,
      horasUsoPromedioDiario: record.horasUsoPromedioDiario,
      kilometrosPromedioDiario: record.kilometrosPromedioDiario,

      visibilidadHorasUso: record.visibilidadHorasUso,
      inventario: record.inventario,
      tipoDepreciacion: record.tipoDepreciacion,
      tipoMedicionMantenimiento: record.tipoMedicionMantenimiento || 'NoAplica',
      fechaInicioOperaciones: record.fechaInicioOperaciones ? moment(record.fechaInicioOperaciones, 'YYYY-MM-DD') : null,
      depreciacionAnual: record.depreciacionAnual,
      vidaUtil: record.vidaUtil,
      costoCompra: record.costoCompra,
      valorSustitucion: record.valorSustitucion,
      valorSalvamiento: record.valorSalvamiento,
      depreciacionTotalHoy: record.depreciacionTotalHoy,
      valorLibroHoy: record.valorLibroHoy,
      fechaExpiracion: record.fechaExpiracion ? moment(record.fechaExpiracion, 'YYYY-MM-DD') : null,
      gestionDocumental: record.gestionDocumental || [],
      unidadMedida: record.unidadMedida,
      numeroParte: record.numeroParte,
      peso: record.peso,
      tiempoEspera: record.tiempoEspera,
      controladoPorSerial: record.controladoPorSerial,
      equivalencias: record.equivalencias || [],
      modeloTareaMantenimientoPreventivo: record.modeloTareaMantenimientoPreventivo || [],
      ejecutoresMantenimientoPreventivo: record.ejecutoresMantenimientoPreventivo || [],
      version: record.version,
      direccionWeb: record.direccionWeb,
      responsablesActivo: record.responsablesActivo || [],
      responsables: record.responsables || [],
      estado: record.estado,
      certificated: record.certificated ? '1' : '0',
      ubicacionCiclos: record.ubicacionCiclos || [],
      cantidadCiclosRecicla: record.cantidadCiclosRecicla,
      documentos: record.documentos || [],
      notas: record.notas || [],
      mantenimientoPorHorometro: record.mantenimientoPorHorometro,
      mantenimientoPorKilometros: record.mantenimientoPorKilometros,
      ancho: record.ancho,
      cantidadHorasDisponibilidadDia: record.cantidadHorasDisponibilidadDia,
      alto: record.alto,
      //nuevos campos imecom
      numLote: record.numLote || null,
      numInspeccion: record.numInspeccion || null,
      fechaInspeccion: record.fechaInspeccion ? moment(record.fechaInspeccion, 'YYYY-MM-DD') : null,
      numInspAnterior: record.numInspAnterior || null,
      fechaInspAnterior: record.fechaInspAnterior ? moment(record.fechaInspAnterior, 'YYYY-MM-DD') : null,
      fechaFabricacion: record.fechaFabricacion || null,
      capacidad: record.capacidad || null,
      presionPrueba: record.presionPrueba || null,
      presionDiseno: record.presionDiseno || null,
      nombreDiseno: record.nombreDiseno || null,
      materialManto: record.materialManto || null,
      espesorManto: record.espesorManto || null,
      materialCabezales: record.materialCabezales || null,
      espesorCabezales: record.espesorCabezales || null,
      formaCabezales: record.formaCabezales || null,
      superficie: record.superficie || null,
      enterrado: record.enterrado || null,
      numCertAnterior: record.numCertAnterior || null,
      otorgadoPorCia: record.otorgadoPorCia || null,
      cuno: record.cuno || null,
      locations: record.locations || userLocations || [],
    };
  });

  const form = useForm({
    resolver: yupResolver(schema(fieldConfig)),
    mode: 'all',
    defaultValues: initialValues as any,
    shouldUnregister: false,
  });
  const {formState} = form;
  const {errors} = formState;
  console.log('%c⧭', 'color: #99adcc', {errors});
  const onInvalid = (errors) => console.error(errors);

  const onSubmit = (values) => {
    console.log({values});
    setConfirm(false);
    props.onSubmit(props.record?.id, values);
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  const {saveLoading, modal} = props;

  function a11yProps(index) {
    return {
      id: `activo-tab-${index}`,
      'aria-controls': `activo-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const doConfirm = () => {
    setConfirm(true);
  };

  const doCloseConfirm = () => {
    setConfirm(false);
  };

  const roles = useSelector(authSelectors.selectRoles);

  const tareasFilter = {
    activo: props.record,
    activosSeleccionados: [props.record?.id],
    withChildren: false,
  };

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          style={
            {
              ...magicFlexBox,
            } as any
          }>
          <Box
            sx={
              {
                ...magicFlexBox,
                bgcolor: 'background.paper',
              } as any
            }>
            <Typography variant="h2" sx={{mb: 1}}>
              {form.watch('nombre')}
            </Typography>

            {/* {(form.watch(
              'modeloTareaMantenimientoPreventivo',
            ).length === 0 ||
              form.watch('mantenimientoPorHorometro') <=
                0 ||
              form.watch('mantenimientoPorKilometros') <=
                0 ||
              form.watch('horasUsoPromedioDiario') <= 0 ||
              !form.watch('fechaInicioOperaciones')) && (
              <div style={{ marginBottom: 20 }}>
                <Alert variant="filled" severity="warning">
                  <AlertTitle>
                    Mantenimiento Preventivo no configurado
                  </AlertTitle>
                </Alert>
              </div>
            )} */}

            <Tabs
              orientation="horizontal"
              variant="scrollable"
              value={tabValue}
              onChange={handleChange}
              aria-label="activo-wizard"
              textColor="secondary"
              indicatorColor="secondary"
              sx={{
                borderRight: 1,
                borderColor: 'divider',
              }}>
              {(!fieldConfig || fieldConfig.tabGeneral) && (
                <Tab label="General" value="tabGeneral" {...a11yProps('tabGeneral')} />
              )}
              {(!fieldConfig || fieldConfig.tabAdicionales) && (
                <Tab label="Adicionales" value="tabAdicionales" {...a11yProps('tabAdicionales')} />
              )}
              {(!fieldConfig || fieldConfig.tabMantenimientoPreventivo) && form.watch('grupo') === 'Equipo' && (
                <Tab
                  label="Mantenimiento Preventivo"
                  value="tabMantenimientoPreventivo"
                  {...a11yProps('tabMantenimientoPreventivo')}
                />
              )}
              {(!fieldConfig || fieldConfig.tabTareas) && form.watch('grupo') === 'Equipo' && props.record?.id && (
                <Tab label="Tareas" value="tabTareas" {...a11yProps('tabTareas')} />
              )}
              {(!fieldConfig || fieldConfig.tabResponsables) && (
                <Tab label="Responsables" value="tabResponsables" {...a11yProps('tabResponsables')} />
              )}
              {(!fieldConfig || fieldConfig.tabUbicacion) && (
                <Tab label="Ubicación" value="tabUbicacion" {...a11yProps('tabUbicacion')} />
              )}
              {(!fieldConfig || fieldConfig.tabRepuestosYSuministros) && (
                <Tab
                  label="Repuestos y Suministros"
                  value="tabRepuestosYSuministros"
                  {...a11yProps('tabRepuestosYSuministros')}
                />
              )}
              {(!fieldConfig || fieldConfig.tabEquivalencias) && (
                <Tab label="Equivalencias" value="tabEquivalencias" {...a11yProps('tabEquivalencias')} />
              )}
              {(!fieldConfig || fieldConfig.tabTercerosRelacionados) && (
                <Tab label="Terceros Relacionados" value="tabTercerosRelacionados" {...a11yProps('tabTercerosRelacionados')} />
              )}
              {/* {(!fieldConfig || fieldConfig.tabHistoriales) && (
                <Tab label="Historiales" value="tabHistoriales" {...a11yProps('tabHistoriales')} />
              )} */}
              {/* {(!fieldConfig || fieldConfig.tabAdjuntos) && (
                <Tab label="Adjuntos" value="tabAdjuntos" {...a11yProps('tabAdjuntos')} />
              )} */}
              {(!fieldConfig || fieldConfig.tabGestionDocumental) && (
                <Tab label="Documentos y Adjuntos" value="tabGestionDocumental" {...a11yProps('tabGestionDocumental')} />
              )}
              {(!fieldConfig || fieldConfig.tabFinanciero) && (
                <Tab label="Financiero" value="tabFinanciero" {...a11yProps('tabFinanciero')} />
              )}
              {(!fieldConfig || fieldConfig.tabReciclaje) && (
                <Tab label="Reciclaje" value="tabReciclaje" {...a11yProps('tabReciclaje')} />
              )}
            </Tabs>

            <TabPanel value={tabValue} index={'tabGeneral'}>
              <Grid container>
                {(!fieldConfig || fieldConfig.fechaInicioOperaciones) && (
                  <Grid item lg={12} md={8} sm={12} xs={12}>
                    {/* <h2> Fecha de Inicio Operación Stem </h2> */}

                    <DatePickerFormItem
                      //clearable
                      name="fechaInicioOperaciones"
                      disabled={props.disabled}
                      //minDate={moment().toLocaleString()}
                      label={i18n('entities.activo.fields.fechaInicioOperaciones')}
                      required={false}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.nombre) && (
                  <Grid item lg={12} md={8} sm={12} xs={12}>
                    <InputFormItem
                      name="nombre"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.nombre')}
                      required={true}
                    />
                  </Grid>
                )}

                {(!fieldConfig || fieldConfig.codigo) && (
                  <Grid item lg={props.drawer ? 12 : 4} md={8} sm={12} xs={12}>
                    <InputFormItem
                      name="codigo"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.codigo')}
                      required={true}
                    />
                  </Grid>
                )}

                {(!fieldConfig || fieldConfig.grupo) && (
                  <Grid item lg={props.drawer ? 12 : 4} md={8} sm={12} xs={12}>
                    <SelectFormItem
                      name="grupo"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.grupo')}
                      options={activoEnumerators.grupo.map((value) => ({
                        value,
                        label: i18n(`entities.activo.enumerators.grupo.${value}`),
                      }))}
                      onChange={(value) => {
                        if (value !== 'Equipo') {
                          form.setValue('tipoMedicionMantenimiento', 'N/A');
                        }
                      }}
                      required={true}
                    />
                  </Grid>
                )}

                {/* grupo equipo */}

                {form.watch('grupo') === 'Equipo' && (!fieldConfig || fieldConfig.cantidadHorasDisponibilidadDia) && (
                  <Grid item lg={12} md={8} sm={12} xs={12}>
                    <InputNumberFormItem
                      name="cantidadHorasDisponibilidadDia"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.cantidadHorasDisponibilidadDia')}
                      required={false}
                    />
                  </Grid>
                )}

                {form.watch('grupo') === 'Equipo' && (!fieldConfig || fieldConfig.tipoMedicionMantenimiento) && (
                  <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                    <SelectFormItem
                      name="tipoMedicionMantenimiento"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.tipoMedicionMantenimiento')}
                      options={activoEnumerators.tipoMedicionMantenimiento.map((value) => ({
                        value,
                        label: i18n(`entities.activo.enumerators.tipoMedicionMantenimiento.${value}`),
                      }))}
                      // onChange={(value) => {
                      //   if (value !== 'Kilometros') {
                      //     form.setValue('kilometraje', null);
                      //   }
                      //   if (value !== 'Horas'){
                      //     form.setValue('horometro', null);
                      //     form.setValue('fechaHorometro', null);
                      //   }
                      // }}
                      required={false}
                    />
                  </Grid>
                )}

                {form.watch('grupo') === 'Equipo' &&
                  form.watch('tipoMedicionMantenimiento') === 'Horas' &&
                  (!fieldConfig || fieldConfig.horometro) && (
                    <Grid item lg={12} md={8} sm={12} xs={12}>
                      <InputNumberFormItem
                        name="horometro"
                        disabled={props.disabled}
                        label={i18n('entities.activo.fields.horometro')}
                        required={true}
                      />
                    </Grid>
                  )}

                {form.watch('grupo') === 'Equipo' &&
                  form.watch('tipoMedicionMantenimiento') === 'Horas' &&
                  (!fieldConfig || fieldConfig.horometro) && (
                    <Grid item lg={12} md={8} sm={12} xs={12}>
                      <DatePickerFormItem
                        name="fechaHorometro"
                        disabled={props.disabled}
                        label={i18n('entities.activo.fields.fechaHorometro')}
                        required={true}
                      />
                    </Grid>
                  )}

                {form.watch('grupo') === 'Equipo' &&
                  form.watch('tipoMedicionMantenimiento') === 'Kilometros' &&
                  (!fieldConfig || fieldConfig.kilometraje) && (
                    <Grid item lg={12} md={8} sm={12} xs={12}>
                      <InputNumberFormItem
                        name="kilometraje"
                        disabled={props.disabled}
                        label={i18n('entities.activo.fields.kilometraje')}
                        required={true}
                      />
                    </Grid>
                  )}

                {/* fin grupo equipo */}

                {(!fieldConfig || fieldConfig.patente) && (
                  <Grid item lg={props.drawer ? 12 : 4} md={8} sm={12} xs={12}>
                    <InputFormItem
                      name="patente"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.patente')}
                      required={false}
                    />
                  </Grid>
                )}

                {(!fieldConfig || fieldConfig.descripcion) && (
                  <Grid item lg={props.drawer ? 12 : 4} md={8} sm={12} xs={12}>
                    <TextAreaFormItem
                      name="descripcion"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.descripcion')}
                      required={false}
                    />
                  </Grid>
                )}

                {(!fieldConfig || fieldConfig.locations) && (
                  <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                    <UbicacionAutocompleteFormItem
                      name="locations"
                      disabled={props.disabled}
                      label={i18n('common.locations')}
                      required={false}
                      showCreate={false} //{!props.modal}
                      mode="multiple"
                    />
                  </Grid>
                )}

                {props.disabled && (
                  <React.Fragment>
                    <Grid item lg={12} md={8} sm={12} xs={12}>
                      {(!fieldConfig || fieldConfig.tiempoInactivo) && (
                        <Grid style={{marginBottom: 10}} container direction="column">
                          <Grid item>
                            <label style={{color: 'gray', fontWeight: 600, fontSize: 20}}>
                              {i18n('entities.activo.fields.tiempoInactivo')}
                            </label>
                          </Grid>
                          <h2>{((props.record?.tiempoInactivo || 0) / 60).toFixed(1)} minutos</h2>
                        </Grid>
                      )}

                      {form.watch('tipoMedicionMantenimiento') === 'Horas' && (
                        <div>
                          {(!fieldConfig || fieldConfig.ultimoHorometroMantenimientoProgramado) && (
                            <Grid style={{marginBottom: 10}} container direction="column">
                              <Grid item>
                                <label style={{color: 'gray', fontWeight: 600, fontSize: 20}}>
                                  {i18n('entities.activo.fields.ultimoHorometroMantenimientoProgramado')}
                                </label>
                              </Grid>
                              <h2>{(props.record?.ultimoHorometroMantenimientoProgramado || 0).toFixed(0)} </h2>
                            </Grid>
                          )}

                          {(!fieldConfig || fieldConfig.ultimoHorometroMantenimientoPreventivo) && (
                            <Grid style={{marginBottom: 10}} container direction="column">
                              <Grid item>
                                <label style={{color: 'gray', fontWeight: 600, fontSize: 20}}>
                                  {i18n('entities.activo.fields.ultimoHorometroMantenimientoPreventivo')}
                                </label>
                              </Grid>
                              <h2>{(props.record?.ultimoHorometroMantenimientoPreventivo || 0).toFixed(0)} </h2>
                            </Grid>
                          )}

                          {(!fieldConfig || fieldConfig.ultimoHorometroMantenimientoCorrectivo) && (
                            <Grid style={{marginBottom: 10}} container direction="column">
                              <Grid item>
                                <label style={{color: 'gray', fontWeight: 600, fontSize: 20}}>
                                  {i18n('entities.activo.fields.ultimoHorometroMantenimientoCorrectivo')}
                                </label>
                              </Grid>
                              <h2>{(props.record?.ultimoHorometroMantenimientoCorrectivo || 0).toFixed(0)} </h2>
                            </Grid>
                          )}
                        </div>
                      )}

                      {form.watch('tipoMedicionMantenimiento') === 'Kilometros' && (
                        <div>
                          {(!fieldConfig || fieldConfig.ultimoKilometrajeMantenimientoProgramado) && (
                            <Grid style={{marginBottom: 10}} container direction="column">
                              <Grid item>
                                <label style={{color: 'gray', fontWeight: 600, fontSize: 20}}>
                                  {i18n('entities.activo.fields.ultimoKilometrajeMantenimientoProgramado')}
                                </label>
                              </Grid>
                              <h2>{(props.record?.ultimoKilometrajeMantenimientoProgramado || 0).toFixed(0)} </h2>
                            </Grid>
                          )}

                          {(!fieldConfig || fieldConfig.ultimoKilometrajeMantenimientoPreventivo) && (
                            <Grid style={{marginBottom: 10}} container direction="column">
                              <Grid item>
                                <label style={{color: 'gray', fontWeight: 600, fontSize: 20}}>
                                  {i18n('entities.activo.fields.ultimoKilometrajeMantenimientoPreventivo')}
                                </label>
                              </Grid>
                              <h2>{(props.record?.ultimoKilometrajeMantenimientoPreventivo || 0).toFixed(0)} </h2>
                            </Grid>
                          )}

                          {(!fieldConfig || fieldConfig.ultimoKilometrajeMantenimientoCorrectivo) && (
                            <Grid style={{marginBottom: 10}} container direction="column">
                              <Grid item>
                                <label style={{color: 'gray', fontWeight: 600, fontSize: 20}}>
                                  {i18n('entities.activo.fields.ultimoKilometrajeMantenimientoCorrectivo')}
                                </label>
                              </Grid>
                              <h2>{(props.record?.ultimoKilometrajeMantenimientoCorrectivo || 0).toFixed(0)} </h2>
                            </Grid>
                          )}
                        </div>
                      )}

                      {(!fieldConfig || fieldConfig.fechaUltimaInactividad) && (
                        <Grid style={{marginBottom: 10}} container direction="column">
                          <Grid item>
                            <label style={{color: 'gray', fontWeight: 600, fontSize: 20}}>
                              {i18n('entities.activo.fields.fechaUltimaInactividad')}
                            </label>
                          </Grid>
                          <h2>{moment(props.record?.fechaUltimaInactividad).format('DD/MM/YYYY HH:mm:ss')}</h2>
                        </Grid>
                      )}

                      {(!fieldConfig || fieldConfig.fechaUltimoMantenimiento) && (
                        <Grid style={{marginBottom: 10}} container direction="column">
                          <Grid item>
                            <label style={{color: 'gray', fontWeight: 600, fontSize: 20}}>
                              {i18n('entities.activo.fields.fechaUltimoMantenimiento')}
                            </label>
                          </Grid>
                          <h2>
                            {props.record?.fechaUltimoMantenimiento
                              ? moment(props.record?.fechaUltimoMantenimiento).format('DD/MM/YYYY HH:mm:ss')
                              : 'No registra mantenimiento'}
                          </h2>
                        </Grid>
                      )}

                      {(!fieldConfig || fieldConfig.fechaUltimoMantenimientoProgramado) && (
                        <Grid style={{marginBottom: 10}} container direction="column">
                          <Grid item>
                            <label style={{color: 'gray', fontWeight: 600, fontSize: 20}}>
                              {i18n('entities.activo.fields.fechaUltimoMantenimientoProgramado')}
                            </label>
                          </Grid>
                          <h2>
                            {props.record?.fechaUltimoMantenimientoProgramado
                              ? moment(props.record?.fechaUltimoMantenimientoProgramado).format('DD/MM/YYYY HH:mm:ss')
                              : 'No registra mantenimiento programado'}
                          </h2>
                        </Grid>
                      )}

                      {(!fieldConfig || fieldConfig.fechaUltimoMantenimientoPreventivo) && (
                        <Grid style={{marginBottom: 10}} container direction="column">
                          <Grid item>
                            <label style={{color: 'gray', fontWeight: 600, fontSize: 20}}>
                              {i18n('entities.activo.fields.fechaUltimoMantenimientoPreventivo')}
                            </label>
                          </Grid>
                          <h2>
                            {props.record?.fechaUltimoMantenimientoPreventivo
                              ? moment(props.record?.fechaUltimoMantenimientoPreventivo).format('DD/MM/YYYY HH:mm:ss')
                              : 'No registra mantenimiento preventivo'}
                          </h2>
                        </Grid>
                      )}

                      {(!fieldConfig || fieldConfig.fechaUltimoMantenimientoCorrectivo) && (
                        <Grid style={{marginBottom: 10}} container direction="column">
                          <Grid item>
                            <label style={{color: 'gray', fontWeight: 600, fontSize: 20}}>
                              {i18n('entities.activo.fields.fechaUltimoMantenimientoCorrectivo')}
                            </label>
                          </Grid>
                          <h2>
                            {props.record?.fechaUltimoMantenimientoCorrectivo
                              ? moment(props.record?.fechaUltimoMantenimientoCorrectivo).format('DD/MM/YYYY HH:mm:ss')
                              : 'No registra mantenimiento correctivo'}
                          </h2>
                        </Grid>
                      )}
                    </Grid>
                  </React.Fragment>
                )}
                {(!fieldConfig || fieldConfig.fotos) && (
                  <Grid item lg={props.drawer ? 12 : 4} md={8} sm={12} xs={12}>
                    <ImagesFormItem
                      name="fotos"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.fotos')}
                      required={false}
                      storage={Storage.values.activoFotos}
                      max={undefined}
                    />
                  </Grid>
                )}

                {(!fieldConfig || fieldConfig.activoPrincipal) && form.watch('grupo') !== 'Equipo' && (
                  <Grid item lg={props.drawer ? 12 : 4} md={8} sm={12} xs={12}>
                    <ActivoAutocompleteFormItem
                      name="activoPrincipal"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.activoPrincipal')}
                      required={false}
                      showCreate={!props.modal}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.localizacion) && (
                  <>
                    <Grid item lg={props.drawer ? 12 : 4} md={8} sm={12} xs={12}>
                      <UbicacionAutocompleteFormItem
                        name="localizacion"
                        disabled={props.disabled}
                        label={i18n('entities.activo.fields.localizacion')}
                        required={false}
                        showCreate={false}
                      />
                    </Grid>
                    <Grid item lg={props.drawer ? 12 : 4} md={8} sm={12} xs={12}>
                      <SwitchFormItem
                        name="validateWithLocationManager"
                        disabled={props.disabled}
                        label={i18n('entities.activo.fields.validateWithLocationManager')}
                      />
                    </Grid>
                  </>
                )}

                {(!fieldConfig || fieldConfig.costoCompra) && (
                  <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                    <InputNumberFormItem
                      name="costoCompra"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.costoCompra')}
                      placeholder={i18n('entities.activo.placeholders.costoCompra')}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.inventario) && (
                  <Grid item lg={props.drawer ? 12 : 4} md={8} sm={12} xs={12}>
                    <SwitchFormItem
                      name="inventario"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.inventario')}
                    />
                  </Grid>
                )}

                {(!fieldConfig || fieldConfig.prioridad) && (
                  <Grid item lg={props.drawer ? 12 : 4} md={8} sm={12} xs={12}>
                    <SelectFormItem
                      name="prioridad"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.prioridad')}
                      options={activoEnumerators.prioridad.map((value) => ({
                        value,
                        label: i18n(`entities.activo.enumerators.prioridad.${value}`),
                      }))}
                      required={false}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.tipoActivo) && (
                  <Grid item lg={props.drawer ? 12 : 4} md={8} sm={12} xs={12}>
                    <TipoActivoAutocompleteFormItem
                      name="tipoActivo"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.tipoActivo')}
                      required={false}
                      showCreate={!props.modal}
                      mode="multiple"
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.codigoBarra) && (
                  <Grid item lg={props.drawer ? 12 : 4} md={8} sm={12} xs={12}>
                    <InputFormItem
                      name="codigoBarra"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.codigoBarra')}
                      required={false}
                    />
                  </Grid>
                )}

                {(!fieldConfig || fieldConfig.fabricante) && (
                  <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                    <InputFormItem
                      name="fabricante"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.fabricante')}
                      required={false}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.fechaFabricacion) && (
                  <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                    <InputFormItem
                      name="fechaFabricacion"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.fechaFabricacion')}
                      required={false}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.marca) && (
                  <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                    <InputFormItem
                      name="marca"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.marca')}
                      required={false}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.modelo) && (
                  <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                    <InputFormItem
                      name="modelo"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.modelo')}
                      required={false}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.numeroSerial) && (
                  <Grid item lg={props.drawer ? 12 : 4} md={8} sm={12} xs={12}>
                    <InputFormItem
                      name="numeroSerial"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.numeroSerial')}
                      required={false}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.numInspeccion) && (
                  <Grid item lg={props.drawer ? 12 : 4} md={8} sm={12} xs={12}>
                    <InputFormItem
                      name="numInspeccion"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.numInspeccion')}
                      required={false}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.fechaInspeccion) && (
                  <Grid item lg={props.drawer ? 12 : 4} md={8} sm={12} xs={12}>
                    <DatePickerFormItem
                      name="fechaInspeccion"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.fechaInspeccion')}
                      required={false}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.numInspAnterior) && (
                  <Grid item lg={props.drawer ? 12 : 4} md={8} sm={12} xs={12}>
                    <InputFormItem
                      name="numInspAnterior"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.numInspAnterior')}
                      required={false}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.fechaInspAnterior) && (
                  <Grid item lg={props.drawer ? 12 : 4} md={8} sm={12} xs={12}>
                    <DatePickerFormItem
                      name="fechaInspAnterior"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.fechaInspAnterior')}
                      required={false}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.visibilidadHorasUso) && (
                  <Grid item lg={props.drawer ? 12 : 4} md={8} sm={12} xs={12}>
                    <SwitchFormItem
                      name="visibilidadHorasUso"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.visibilidadHorasUso')}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.fechaExpiracion) && (
                  <Grid item lg={props.drawer ? 12 : 4} md={8} sm={12} xs={12}>
                    <DatePickerFormItem
                      name="fechaExpiracion"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.fechaExpiracion')}
                      required={false}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.unidadMedida) && (
                  <Grid item lg={props.drawer ? 12 : 4} md={8} sm={12} xs={12}>
                    <UnidadMedidaAutocompleteFormItem
                      name="unidadMedida"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.unidadMedida')}
                      required={false}
                      showCreate={!props.modal}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.numeroParte) && (
                  <Grid item lg={props.drawer ? 12 : 4} md={8} sm={12} xs={12}>
                    <InputFormItem
                      name="numeroParte"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.numeroParte')}
                      required={false}
                    />
                  </Grid>
                )}

                {/* <Grid item lg={props.drawer?12:4} md={8} sm={12} xs={12}>
                  <InputNumberFormItem
                    name="tiempoEspera"
                    disabled={props.disabled}
                    label={i18n(
                      'entities.activo.fields.tiempoEspera',
                    )}
                    required={false}
                  />
                </Grid> */}
                {/* <Grid item lg={props.drawer?12:4} md={8} sm={12} xs={12}>
                  <SwitchFormItem
                    name="controladoPorSerial"
                    disabled={props.disabled}
                    label={i18n(
                      'entities.activo.fields.controladoPorSerial',
                    )}
                  />
                </Grid> */}

                {(!fieldConfig || fieldConfig.version) && (
                  <Grid item lg={props.drawer ? 12 : 4} md={8} sm={12} xs={12}>
                    <InputFormItem
                      name="version"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.version')}
                      required={false}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.direccionWeb) && (
                  <Grid item lg={props.drawer ? 12 : 4} md={8} sm={12} xs={12}>
                    <InputFormItem
                      name="direccionWeb"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.direccionWeb')}
                      required={false}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.estado) && (
                  <Grid item lg={props.drawer ? 12 : 4} md={8} sm={12} xs={12}>
                    <SelectFormItem
                      name="estado"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.estado')}
                      options={activoEnumerators.estado.map((value) => ({
                        value,
                        label: i18n(`entities.activo.enumerators.estado.${value}`),
                      }))}
                      required={false}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.certificated) && (
                  <Grid item lg={props.drawer ? 12 : 4} md={8} sm={12} xs={12}>
                    {/* <SwitchFormItem
                      name="certificated"
                      disabled={props.disabled}
                      label={i18n(
                        'entities.activo.fields.certificated',
                      )}
                    /> */}
                    <SelectFormItem
                      name="certificated"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.certificated')}
                      options={[
                        {
                          value: '1',
                          label: i18n('common.yes'),
                        },
                        {
                          value: '0',
                          label: i18n('common.no'),
                        },
                      ]}
                      required={false}
                    />
                  </Grid>
                )}
              </Grid>

              {(!fieldConfig || fieldConfig.dimensiones) && <h2> Dimensiones </h2>}

              <Grid container>
                {(!fieldConfig || fieldConfig.ancho) && (
                  <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                    <InputFormItem
                      name="ancho"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.ancho')}
                      required={false}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.alto) && (
                  <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                    <InputFormItem
                      name="alto"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.alto')}
                      required={false}
                    />
                  </Grid>
                )}

                {(!fieldConfig || fieldConfig.peso) && (
                  <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                    <InputFormItem
                      name="peso"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.peso')}
                      required={false}
                    />
                  </Grid>
                )}
              </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={'tabAdicionales'}>
              {(!fieldConfig || fieldConfig.numLote) && (
                <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                  <InputNumberFormItem
                    name="numLote"
                    disabled={props.disabled}
                    label={i18n('entities.activo.fields.numLote')}
                    required={false}
                  />
                </Grid>
              )}
              {(!fieldConfig || fieldConfig.capacidad) && (
                <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                  <InputFormItem
                    name="capacidad"
                    disabled={props.disabled}
                    label={i18n('entities.activo.fields.capacidad')}
                    required={false}
                  />
                </Grid>
              )}
              {(!fieldConfig || fieldConfig.presionPrueba) && (
                <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                  <InputNumberFormItem
                    name="presionPrueba"
                    disabled={props.disabled}
                    label={i18n('entities.activo.fields.presionPrueba')}
                    required={false}
                  />
                </Grid>
              )}
              {(!fieldConfig || fieldConfig.presionDiseno) && (
                <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                  <InputFormItem
                    name="presionDiseno"
                    disabled={props.disabled}
                    label={i18n('entities.activo.fields.presionDiseno')}
                    required={false}
                  />
                </Grid>
              )}
              {(!fieldConfig || fieldConfig.nombreDiseno) && (
                <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                  <InputFormItem
                    name="nombreDiseno"
                    disabled={props.disabled}
                    label={i18n('entities.activo.fields.nombreDiseno')}
                    required={false}
                  />
                </Grid>
              )}
              {(!fieldConfig || fieldConfig.materialManto) && (
                <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                  <InputFormItem
                    name="materialManto"
                    disabled={props.disabled}
                    label={i18n('entities.activo.fields.materialManto')}
                    required={false}
                  />
                </Grid>
              )}
              {(!fieldConfig || fieldConfig.espesorManto) && (
                <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                  <InputNumberFormItem
                    name="espesorManto"
                    disabled={props.disabled}
                    label={i18n('entities.activo.fields.espesorManto')}
                    required={false}
                  />
                </Grid>
              )}
              {(!fieldConfig || fieldConfig.materialCabezales) && (
                <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                  <InputFormItem
                    name="materialCabezales"
                    disabled={props.disabled}
                    label={i18n('entities.activo.fields.materialCabezales')}
                    required={false}
                  />
                </Grid>
              )}
              {(!fieldConfig || fieldConfig.espesorCabezales) && (
                <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                  <InputNumberFormItem
                    name="espesorCabezales"
                    disabled={props.disabled}
                    label={i18n('entities.activo.fields.espesorCabezales')}
                    required={false}
                  />
                </Grid>
              )}
              {(!fieldConfig || fieldConfig.formaCabezales) && (
                <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                  <InputFormItem
                    name="formaCabezales"
                    disabled={props.disabled}
                    label={i18n('entities.activo.fields.formaCabezales')}
                    required={false}
                  />
                </Grid>
              )}
              {(!fieldConfig || fieldConfig.superficie) && (
                <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                  <InputFormItem
                    name="superficie"
                    disabled={props.disabled}
                    label={i18n('entities.activo.fields.superficie')}
                    required={false}
                  />
                </Grid>
              )}
              {(!fieldConfig || fieldConfig.enterrado) && (
                <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                  <InputFormItem
                    name="enterrado"
                    disabled={props.disabled}
                    label={i18n('entities.activo.fields.enterrado')}
                    required={false}
                  />
                </Grid>
              )}
              {(!fieldConfig || fieldConfig.numCertAnterior) && (
                <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                  <InputFormItem
                    name="numCertAnterior"
                    disabled={props.disabled}
                    label={i18n('entities.activo.fields.numCertAnterior')}
                    required={false}
                  />
                </Grid>
              )}
              {(!fieldConfig || fieldConfig.otorgadoPorCia) && (
                <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                  <InputFormItem
                    name="otorgadoPorCia"
                    disabled={props.disabled}
                    label={i18n('entities.activo.fields.otorgadoPorCia')}
                    required={false}
                  />
                </Grid>
              )}
              {(!fieldConfig || fieldConfig.cuno) && (
                <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                  <InputFormItem
                    name="cuno"
                    disabled={props.disabled}
                    label={i18n('entities.activo.fields.cuno')}
                    required={false}
                  />
                </Grid>
              )}
            </TabPanel>
            <TabPanel value={tabValue} index={'tabMantenimientoPreventivo'}>
              {(!fieldConfig || fieldConfig.tipoDeMedicion) && <h2>Tipo de Medición </h2>}

              {(!fieldConfig || fieldConfig.tipoMedicionMantenimiento) && (
                <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                  <SelectFormItem
                    name="tipoMedicionMantenimiento"
                    disabled={props.disabled}
                    label={i18n('entities.activo.fields.tipoMedicionMantenimiento')}
                    options={activoEnumerators.tipoMedicionMantenimiento.map((value) => ({
                      value,
                      label: i18n(`entities.activo.enumerators.tipoMedicionMantenimiento.${value}`),
                    }))}
                    required={false}
                  />
                </Grid>
              )}

              {(!fieldConfig || fieldConfig.parametrosDeAnalisis) && <h2> Parámetros de Análisis </h2>}

              <Grid container>
                {(!fieldConfig || fieldConfig.mantenimientoPorHorometro) &&
                  form.watch('tipoMedicionMantenimiento') === 'Horas' && (
                    <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                      <InputNumberFormItem
                        name="mantenimientoPorHorometro"
                        disabled={props.disabled}
                        label={i18n('entities.activo.fields.mantenimientoPorHorometro')}
                        required={false}
                      />
                    </Grid>
                  )}

                {(!fieldConfig || fieldConfig.mantenimientoPorKilometros) &&
                  form.watch('tipoMedicionMantenimiento') === 'Kilometros' && (
                    <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                      <InputNumberFormItem
                        name="mantenimientoPorKilometros"
                        disabled={props.disabled}
                        label={i18n('entities.activo.fields.mantenimientoPorKilometros')}
                        required={false}
                      />
                    </Grid>
                  )}

                {(!fieldConfig || fieldConfig.horasUsoPromedioDiario) && form.watch('tipoMedicionMantenimiento') === 'Horas' && (
                  <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                    <InputNumberFormItem
                      name="horasUsoPromedioDiario"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.horasUsoPromedioDiario')}
                      required={false}
                    />
                  </Grid>
                )}

                {(!fieldConfig || fieldConfig.kilometrosPromedioDiario) &&
                  form.watch('tipoMedicionMantenimiento') === 'Kilometros' && (
                    <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                      <InputNumberFormItem
                        name="kilometrosPromedioDiario"
                        disabled={props.disabled}
                        label={i18n('entities.activo.fields.kilometrosPromedioDiario')}
                        required={false}
                      />
                    </Grid>
                  )}
              </Grid>

              {(!fieldConfig || fieldConfig.ejecucionMantenimientoPreventivo) && <h2>Ejecución Mantenimiento Preventivo</h2>}

              <Grid container>
                {(!fieldConfig || fieldConfig.ejecutoresMantenimientoPreventivo) && (
                  <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                    <UserAutocompleteFormItem
                      name="ejecutoresMantenimientoPreventivo"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.ejecutoresMantenimientoPreventivo')}
                      required={false}
                      showCreate={!props.modal}
                      mode="multiple"
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.modeloTareaMantenimientoPreventivo) && (
                  <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                    <ModeloTareaAutocompleteFormItem
                      name="modeloTareaMantenimientoPreventivo"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.modeloTareaMantenimientoPreventivo')}
                      required={false}
                      showCreate={!props.modal}
                      mode="multiple"
                    />
                  </Grid>
                )}
              </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={'tabTareas'}>
              {(!fieldConfig || fieldConfig.tabTareas) && (
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <div style={{paddingBottom: 5, maxHeight: 50}}>
                    <DrawerButton
                      drawerId={'DrawerTareaSendFormPage'}
                      width={window.innerWidth * 0.7}
                      type={'button'}
                      tooltipTitle={i18n('common.new')}
                      buttonTitle={i18n('common.new')}
                      buttonIcon={<Add />}
                      component={
                        <TareaSendFormPage
                          silent={true}
                          drawerId={'DrawerTareaSendFormPage'}
                          drawer={true}
                          filter={tareasFilter}
                        />
                      }
                    />
                  </div>
                  <TareaListFilter filter={tareasFilter} listFilterKey={'activoForm1'} />
                  <TareaListTable sendFormSilent={true} from={'ActivoForm'} />
                </Grid>
              )}
            </TabPanel>
            <TabPanel value={tabValue} index={'tabResponsables'}>
              {(!fieldConfig || fieldConfig.responsablesActivo) && (
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <PerfilEmpleadoAutocompleteFormItem
                    name="responsablesActivo"
                    disabled={props.disabled}
                    label={i18n('entities.activo.fields.responsablesActivo')}
                    required={false}
                    showCreate={!props.modal}
                    mode="multiple"
                  />
                </Grid>
              )}
            </TabPanel>
            <TabPanel value={tabValue} index={'tabUbicacion'}>
              <Grid container>
                {(!fieldConfig || fieldConfig.ubicacionGoogle) && (
                  <Grid item lg={12} md={8} sm={12} xs={12}>
                    <GoogleAutocompleteFormItem
                      name="ubicacionGoogle"
                      disabled={props.disabled}
                      label={i18n('entities.ubicacion.fields.ubicacionGoogle')}
                      required={false}
                      defaultCenter={{lat: -36.8269882, lng: -73.0497665}}
                      center={{lat: form.watch('latitud'), lng: form.watch('longitud')}}
                      zoom={14}
                      enableMap={true}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.direccion) && (
                  <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                    <InputFormItem
                      name="direccion"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.direccion')}
                      required={false}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.ciudad) && (
                  <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                    <InputFormItem
                      name="ciudad"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.ciudad')}
                      required={false}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.region) && (
                  <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                    <InputFormItem
                      name="region"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.region')}
                      required={false}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.pais) && (
                  <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                    <InputFormItem
                      name="pais"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.pais')}
                      required={false}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.codigoArea) && (
                  <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                    <InputFormItem
                      name="codigoArea"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.codigoArea')}
                      required={false}
                    />
                  </Grid>
                )}
              </Grid>
            </TabPanel>
            {/* <TabPanel value={tabValue} index={'tabRepuestosYSuministros'}>
              {(!fieldConfig || fieldConfig.repuestos) && (
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <ActivoAutocompleteFormItem
                    name="repuestos"
                    disabled={props.disabled}
                    label={i18n('entities.activo.fields.repuestos')}
                    required={false}
                    showCreate={!props.modal}
                    mode="multiple"
                  />
                </Grid>
              )}
            </TabPanel> */}
            <TabPanel value={tabValue} index={'tabEquivalencias'}>
              {(!fieldConfig || fieldConfig.equivalencias) && (
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <ActivoAutocompleteFormItem
                    name="equivalencias"
                    disabled={props.disabled}
                    label={i18n('entities.activo.fields.equivalencias')}
                    required={false}
                    showCreate={!props.modal}
                    mode="multiple"
                  />
                </Grid>
              )}
            </TabPanel>
            <TabPanel value={tabValue} index={'tabTercerosRelacionados'}>
              {(!fieldConfig || fieldConfig.tercerosRelacionados) && (
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <TerceroAutocompleteFormItem
                    name="tercerosRelacionados"
                    disabled={props.disabled}
                    label={i18n('entities.activo.fields.tercerosRelacionados')}
                    required={false}
                    showCreate={!props.modal}
                    mode="multiple"
                  />
                </Grid>
              )}
            </TabPanel>
            {/* <TabPanel value={tabValue} index={'tabHistoriales'}>
              {(!fieldConfig || fieldConfig.notas) && (
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <RegistroAutocompleteFormItem
                    name="notas"
                    disabled={props.disabled}
                    label={i18n('entities.activo.fields.notas')}
                    required={false}
                    showCreate={!props.modal}
                    mode="multiple"
                  />
                </Grid>
              )}
            </TabPanel> */}
            <TabPanel value={tabValue} index={'tabAdjuntos'}>
              {(!fieldConfig || fieldConfig.documentos) && (
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <FilesFormItem
                    name="documentos"
                    disabled={props.disabled}
                    label={i18n('entities.activo.fields.documentos')}
                    required={false}
                    storage={Storage.values.activoDocumentos}
                    max={undefined}
                    formats={undefined}
                  />
                </Grid>
              )}
            </TabPanel>
            <TabPanel value={tabValue} index={'tabGestionDocumental'}>
              {(!fieldConfig || fieldConfig.gestionDocumental) && (
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <GestorDocumentalAutocompleteFormItem
                    name="gestionDocumental"
                    label={i18n('entities.activo.fields.gestionDocumental')}
                    disabled={props.disabled}
                    required={true}
                    showCreate={!props.modal}
                    showButton={true}
                    mode="multiple"
                    disableAutocomplete
                  />
                </Grid>
              )}
            </TabPanel>
            <TabPanel value={tabValue} index={'tabFinanciero'}>
              <Grid container>
                {(!fieldConfig || fieldConfig.presupuesto) && (
                  <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                    <PresupuestoAutocompleteFormItem
                      name="presupuesto"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.presupuesto')}
                      required={false}
                      showCreate={!props.modal}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.fechaCompra) && (
                  <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                    <DatePickerFormItem
                      name="fechaCompra"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.fechaCompra')}
                      required={false}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.tipoDepreciacion) && (
                  <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                    <SelectFormItem
                      name="tipoDepreciacion"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.tipoDepreciacion')}
                      options={activoEnumerators.tipoDepreciacion.map((value) => ({
                        value,
                        label: i18n(`entities.activo.enumerators.tipoDepreciacion.${value}`),
                      }))}
                      required={false}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.depreciacionAnual) && (
                  <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                    <InputNumberFormItem
                      name="depreciacionAnual"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.depreciacionAnual')}
                      placeholder={i18n('entities.activo.placeholders.depreciacionAnual')}
                      required={false}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.vidaUtil) && (
                  <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                    <InputNumberFormItem
                      name="vidaUtil"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.vidaUtil')}
                      placeholder={i18n('entities.activo.placeholders.vidaUtil')}
                      required={false}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.valorSustitucion) && (
                  <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                    <InputNumberFormItem
                      name="valorSustitucion"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.valorSustitucion')}
                      placeholder={i18n('entities.activo.placeholders.valorSustitucion')}
                      required={false}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.valorSalvamiento) && (
                  <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                    <InputNumberFormItem
                      name="valorSalvamiento"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.valorSalvamiento')}
                      placeholder={i18n('entities.activo.placeholders.valorSalvamiento')}
                      required={false}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.depreciacionTotalHoy) && (
                  <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                    <InputNumberFormItem
                      name="depreciacionTotalHoy"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.depreciacionTotalHoy')}
                      placeholder={i18n('entities.activo.placeholders.depreciacionTotalHoy')}
                      required={false}
                    />
                  </Grid>
                )}
                {(!fieldConfig || fieldConfig.valorLibroHoy) && (
                  <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                    <InputNumberFormItem
                      name="valorLibroHoy"
                      disabled={props.disabled}
                      label={i18n('entities.activo.fields.valorLibroHoy')}
                      placeholder={i18n('entities.activo.placeholders.valorLibroHoy')}
                      required={false}
                    />
                  </Grid>
                )}
              </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={'tabReciclaje'}>
              {(!fieldConfig || fieldConfig.ubicacionCiclos) && (
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <UbicacionAutocompleteFormItem
                    name="ubicacionCiclos"
                    disabled={props.disabled}
                    label={i18n('entities.activo.fields.ubicacionCiclos')}
                    required={false}
                    showCreate={false}
                    mode="multiple"
                  />
                </Grid>
              )}
              {(!fieldConfig || fieldConfig.cantidadCiclosRecicla) && (
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <InputNumberFormItem
                    name="cantidadCiclosRecicla"
                    disabled={props.disabled}
                    label={i18n('entities.activo.fields.cantidadCiclosRecicla')}
                    required={false}
                  />
                </Grid>
              )}
            </TabPanel>
          </Box>

          {!props.disabled && (
            <FormButtons
              style={{
                flexDirection: modal ? 'row-reverse' : undefined,
              }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Button
                    style={{width: props.drawer ? '100%' : 300, ...customButtonStyle}}
                    variant="contained"
                    color="secondary"
                    disabled={saveLoading}
                    type="button"
                    onClick={form.handleSubmit(onSubmit, onInvalid)}
                    startIcon={<SaveOutlined />}
                    size="large">
                    {i18n('common.save')}
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  {props.record?.habilitado === true ? (
                    <Button
                      style={{
                        ...customButtonStyle,
                        width: '100%',
                        backgroundColor: 'red',
                      }}
                      variant="contained"
                      color="secondary"
                      disabled={saveLoading}
                      type="button"
                      onClick={() => {
                        form.setValue('habilitado', false);
                        doConfirm();
                      }}
                      startIcon={<Pause />}
                      size="large">
                      {i18n('common.stop')}
                    </Button>
                  ) : (
                    <Button
                      style={{
                        ...customButtonStyle,
                        width: '100%',
                        backgroundColor: 'green',
                      }}
                      variant="contained"
                      color="secondary"
                      disabled={saveLoading}
                      type="button"
                      onClick={() => {
                        form.setValue('habilitado', true);
                        doConfirm();
                      }}
                      startIcon={<PlayArrow />}
                      size="large">
                      {i18n('common.play')}
                    </Button>
                  )}
                </Grid>
              </Grid>

              {/* <Button
                disabled={saveLoading}
                onClick={onReset}
                type="button"
                startIcon={<UndoIcon />}
                size="small"
              >
                {i18n('common.reset')}
              </Button>

              {props.onCancel ? (
                <Button
                  disabled={saveLoading}
                  onClick={() => props.onCancel()}
                  type="button"
                  startIcon={<CloseIcon />}
                  size="small"
                >
                  {i18n('common.cancel')}
                </Button>
              ) : null} */}
            </FormButtons>
          )}
        </form>
      </FormProvider>

      {confirm && (
        <ConfirmModal
          title={i18n('common.areYouSure')}
          onConfirm={form.handleSubmit(onSubmit)}
          onClose={() => doCloseConfirm()}
          okText={i18n('common.yes')}
          cancelText={i18n('common.no')}
        />
      )}
    </FormWrapper>
  );
}

export default ActivoForm;
