import {yupResolver} from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import {Button, FormControlLabel, Grid, Switch} from '@mui/material';
import moment from 'moment-timezone';
import React, {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import authSelectors from 'src/modules/auth/authSelectors';
import gestorDocumentalEnumerators from 'src/modules/gestorDocumental/gestorDocumentalEnumerators';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import Storage from 'src/security/storage';
import CodificacionISOAutocompleteFormItem from 'src/view/codificacionISO/autocomplete/CodificacionISOAutocompleteFormItem';
import RecordatorioAutocompleteFormItem from 'src/view/recordatorio/autocomplete/RecordatorioAutocompleteFormItem';
import CheckboxFormItem from 'src/view/shared/form/items/CheckboxFormItem';
import DatePickerFormItem from 'src/view/shared/form/items/DatePickerFormItem';
import FilesFormItem from 'src/view/shared/form/items/FilesFormItem';
import ImagesFormItem from 'src/view/shared/form/items/ImagesFormItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import SelectFormItem from 'src/view/shared/form/items/SelectFormItem';
import TextAreaFormItem from 'src/view/shared/form/items/TextAreaFormItem';
import FormWrapper, {FormButtons} from 'src/view/shared/styles/FormWrapper';
import UbicacionAutocompleteFormItem from 'src/view/ubicacion/autocomplete/UbicacionAutocompleteFormItem';
import * as yup from 'yup';

const schema = yup.object().shape({
  garantia: yupFormSchemas.boolean(i18n('entities.gestorDocumental.fields.garantia'), {}),
  documentoPrincipal: yupFormSchemas.relationToOne(i18n('entities.gestorDocumental.fields.documentoPrincipal'), {}),
  nombre: yupFormSchemas.string(i18n('entities.gestorDocumental.fields.nombre'), {
    required: true,
  }),
  descripcion: yupFormSchemas.string(i18n('entities.gestorDocumental.fields.descripcion'), {}),
  tipoDocumento: yupFormSchemas.enumerator(i18n('entities.gestorDocumental.fields.tipoDocumento'), {
    required: true,
    options: gestorDocumentalEnumerators.tipoDocumento,
  }),
  version: yupFormSchemas.string(i18n('entities.gestorDocumental.fields.version'), {}),
  validoDesde: yupFormSchemas.date(i18n('entities.gestorDocumental.fields.validoDesde'), {}),
  validoHasta: yupFormSchemas.date(i18n('entities.gestorDocumental.fields.validoHasta'), {}),
  codigoISO: yupFormSchemas.relationToMany(i18n('entities.gestorDocumental.fields.codigoISO'), {}),
  autor: yupFormSchemas.string(i18n('entities.gestorDocumental.fields.autor'), {}),
  perfilesEmpleados: yupFormSchemas.relationToMany(i18n('entities.gestorDocumental.fields.perfilesEmpleados'), {}),
  activosVinculados: yupFormSchemas.relationToMany(i18n('entities.gestorDocumental.fields.activosVinculados'), {}),
  registros: yupFormSchemas.relationToMany(i18n('entities.gestorDocumental.fields.registros'), {}),
  documentosAdjuntos: yupFormSchemas.files(i18n('entities.perfilEmpleado.fields.documentosAdjuntos'), {}),
  fotosAdjuntas: yupFormSchemas.images(i18n('entities.gestorDocumental.fields.fotosAdjuntas'), {
    required: false,
  }),
  recordatorios: yupFormSchemas.relationToMany(i18n('entities.perfilEmpleado.fields.recordatorios'), {}),
  fechaRecordatorio: yupFormSchemas.date(i18n('entities.perfilEmpleado.fields.fechaRecordatorio'), {}),
  locations: yupFormSchemas.relationToMany(i18n('common.locations'), {}),
});

function GestorDocumentalForm(props) {
  const tenantUser = useSelector(authSelectors.selectTenantUser);
  const userLocations = tenantUser?.perfilEmpleado?.ubicacion;

  const [initialValues] = useState(() => {
    const record = props.record || {};

    return {
      garantia: record.garantia,
      documentoPrincipal: record.documentoPrincipal,
      nombre: record.nombre,
      descripcion: record.descripcion,
      tipoDocumento: record.tipoDocumento,
      version: record.version,
      validoDesde: record.validoDesde ? moment(record.validoDesde) : null,
      validoHasta: record.validoHasta ? moment(record.validoHasta) : null,
      codigoISO: record.codigoISO || [],
      autor: record.autor,
      perfilesEmpleados: record.perfilesEmpleados || [],
      activosVinculados: record.activosVinculados || [],
      registros: record.registros || [],
      documentosAdjuntos: record.documentosAdjuntos || [],
      fotosAdjuntas: record.fotosAdjuntas || [],
      recordatorios: record.recordatorios || [],
      fechaRecordatorio: record.fechaRecordatorio ? moment(record.fechaRecordatorio) : null,
      locations: record.locations || userLocations || [],
    };
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
    shouldUnregister: false,
  });

  const onSubmit = (values) => {
    values.fechaRecordatorio = calcularFechaRecordatorio();
    props.onSubmit(props.record?.id, values);
  };

  const calcularFechaRecordatorio = () => {
    const formValues = form.getValues();
    if (!formValues.validoHasta) return null;
    if (!formValues.recordatorios?.length) return null;

    const fechaHoy = moment();
    const fechaValidoHasta = moment(formValues.validoHasta);

    const recordatoriosMayoresAHoy = formValues.recordatorios
      .map((recordatorio) => {
        const fechaRecordatorio = fechaValidoHasta.clone().subtract(recordatorio.cantidad, recordatorio.tipo);
        return fechaRecordatorio.isSameOrAfter(fechaHoy) ? fechaRecordatorio : null;
      })
      .filter(Boolean);
    // si no hay fechas válidas, se retorna la fecha de hoy
    if (!recordatoriosMayoresAHoy.length) return fechaHoy.toISOString();
    const fechaRecordatorio = moment.min(recordatoriosMayoresAHoy);
    return fechaRecordatorio.toISOString();
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  const {saveLoading, modal} = props;

  const [advancedFields, setAdvancedFields] = useState(false);
  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflow: 'auto',
          }}>
          <Grid spacing={2} container>
            {/* <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <GestorDocumentalAutocompleteFormItem
                name="documentoPrincipal"
                label={i18n(
                  'entities.gestorDocumental.fields.documentoPrincipal',
                )}
                required={false}
                showCreate={!props.modal}
              />
            </Grid> */}
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <SelectFormItem
                name="tipoDocumento"
                disabled={props.disabled}
                label={i18n('entities.gestorDocumental.fields.tipoDocumento')}
                options={gestorDocumentalEnumerators.tipoDocumento.map((value) => ({
                  value,
                  label: i18n(`entities.gestorDocumental.enumerators.tipoDocumento.${value}`),
                }))}
                required={true}
              />
            </Grid>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <InputFormItem
                name="nombre"
                disabled={props.disabled}
                label={i18n('entities.gestorDocumental.fields.nombre')}
                required={true}
              />
            </Grid>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <TextAreaFormItem
                name="descripcion"
                disabled={props.disabled}
                label={i18n('entities.gestorDocumental.fields.descripcion')}
                required={false}
              />
            </Grid>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <FilesFormItem
                name="documentosAdjuntos"
                disabled={props.disabled}
                label={i18n('entities.tarea.fields.documentosAdjuntos')}
                required={false}
                storage={Storage.values.gestorDocumentalDocumentosAdjuntos}
                max={undefined}
                formats={undefined}
              />
            </Grid>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <ImagesFormItem
                name="fotosAdjuntas"
                disabled={props.disabled}
                label={i18n('entities.gestorDocumental.fields.fotosAdjuntas')}
                required={false}
                storage={Storage.values.gestorDocumentalFotosAdjuntas}
                max={undefined}
              />
            </Grid>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <DatePickerFormItem
                name="validoDesde"
                disabled={props.disabled}
                label={i18n('entities.gestorDocumental.fields.validoDesde')}
                required={false}
              />
            </Grid>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <DatePickerFormItem
                name="validoHasta"
                disabled={props.disabled}
                label={i18n('entities.gestorDocumental.fields.validoHasta')}
                required={false}
              />
            </Grid>
            <Grid item lg={12} md={8} sm={12} xs={12}>
              <RecordatorioAutocompleteFormItem
                noLink
                name="recordatorios"
                disabled={props.disabled}
                label={i18n('entities.gestorDocumental.fields.recordatorios')}
                required={false}
                showCreate={!props.modal}
                mode="multiple"
              />
            </Grid>
            <FormControlLabel
              labelPlacement="start"
              control={<Switch color="primary" checked={advancedFields} onChange={() => setAdvancedFields(!advancedFields)} />}
              label="Campos Avanzados"
            />
            {advancedFields && (
              <>
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <UbicacionAutocompleteFormItem
                    name="locations"
                    disabled={props.disabled}
                    label={i18n('common.locations')}
                    required={false}
                    showCreate={false}
                    mode="multiple"
                  />
                </Grid>
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <InputFormItem
                    name="version"
                    disabled={props.disabled}
                    label={i18n('entities.gestorDocumental.fields.version')}
                    required={false}
                  />
                </Grid>
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <CodificacionISOAutocompleteFormItem
                    noLink
                    name="codigoISO"
                    disabled={props.disabled}
                    label={i18n('entities.gestorDocumental.fields.codigoISO')}
                    required={false}
                    showCreate={!props.modal}
                    mode="multiple"
                  />
                </Grid>
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <InputFormItem
                    name="autor"
                    disabled={props.disabled}
                    label={i18n('entities.gestorDocumental.fields.autor')}
                    required={false}
                  />
                </Grid>
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <CheckboxFormItem
                    name="garantia"
                    disabled={props.disabled}
                    label={i18n('entities.gestorDocumental.fields.garantia')}
                  />
                </Grid>
                {/* <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <PerfilEmpleadoAutocompleteFormItem  
                name="perfilesEmpleados"
                label={i18n('entities.gestorDocumental.fields.perfilesEmpleados')}
                required={false}
                showCreate={!props.modal}
                mode="multiple"
              />
            </Grid>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <ActivoAutocompleteFormItem  
                name="activosVinculados"
                label={i18n('entities.gestorDocumental.fields.activosVinculados')}
                required={false}
                showCreate={!props.modal}
                mode="multiple"
              />
            </Grid> */}
                {/* <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <RegistroAutocompleteFormItem
                disabled={props.disabled}
                name="registros"
                label={i18n('entities.gestorDocumental.fields.registros')}
                required={false}
                showCreate={!props.modal}
                mode="multiple"
              />
            </Grid> */}
              </>
            )}
          </Grid>
          {!props.disabled && (
            <FormButtons
              style={{
                flexDirection: modal ? 'row-reverse' : undefined,
                marginTop: 64,
                placeContent: 'space-between',
                width: 350,
              }}>
              <Button
                variant="contained"
                color="primary"
                disabled={saveLoading}
                type="button"
                onClick={form.handleSubmit(onSubmit)}
                startIcon={<SaveIcon />}
                size="small">
                {i18n('common.save')}
              </Button>

              <Button disabled={saveLoading} onClick={onReset} type="button" startIcon={<UndoIcon />} size="small">
                {i18n('common.reset')}
              </Button>

              {props.onCancel ? (
                <Button
                  disabled={saveLoading}
                  onClick={() => props.onCancel()}
                  type="button"
                  startIcon={<CloseIcon />}
                  size="small">
                  {i18n('common.cancel')}
                </Button>
              ) : null}
            </FormButtons>
          )}
        </form>
      </FormProvider>
    </FormWrapper>
  );
}

export default GestorDocumentalForm;
