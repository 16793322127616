import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import {i18n} from 'src/i18n';
import solicitudEnumerators from 'src/modules/solicitud/solicitudEnumerators';
import moment from 'moment-timezone';

export default [
  {
    name: 'tipo',
    label: i18n('entities.solicitud.fields.tipo'),
    schema: schemas.enumerator(i18n('entities.solicitud.fields.tipo'), {
      options: solicitudEnumerators.tipo,
    }),
  },
  {
    name: 'titulo',
    label: i18n('entities.solicitud.fields.titulo'),
    schema: schemas.string(i18n('entities.solicitud.fields.titulo'), {
      required: true,
    }),
  },
  {
    name: 'estado',
    label: i18n('entities.solicitud.fields.estado'),
    schema: schemas.enumerator(i18n('entities.solicitud.fields.estado'), {
      options: solicitudEnumerators.estado,
    }),
  },
  {
    name: 'fechaVencimiento',
    label: i18n('entities.solicitud.fields.fechaVencimiento'),
    schema: schemas.date(i18n('entities.solicitud.fields.fechaVencimiento'), {}),
    render: value => (value && value instanceof Date ? moment(value).format('YYYY-MM-DD') : value),
  },
  {
    name: 'proveedor',
    label: i18n('entities.solicitud.fields.proveedor'),
    schema: schemas.relationToOne(i18n('entities.solicitud.fields.proveedor'), {}),
  },
  {
    name: 'descripcion',
    label: i18n('entities.solicitud.fields.descripcion'),
    schema: schemas.string(i18n('entities.solicitud.fields.descripcion'), {}),
  },
  {
    name: 'solicitante',
    label: i18n('entities.solicitud.fields.solicitante'),
    schema: schemas.relationToOne(i18n('entities.solicitud.fields.solicitante'), {}),
  },
  {
    name: 'metodoEnvio',
    label: i18n('entities.solicitud.fields.metodoEnvio'),
    schema: schemas.string(i18n('entities.solicitud.fields.metodoEnvio'), {}),
  },
  {
    name: 'condicionesEnvio',
    label: i18n('entities.solicitud.fields.condicionesEnvio'),
    schema: schemas.string(i18n('entities.solicitud.fields.condicionesEnvio'), {}),
  },
  {
    name: 'notas',
    label: i18n('entities.solicitud.fields.notas'),
    schema: schemas.string(i18n('entities.solicitud.fields.notas'), {}),
  },
  {
    name: 'correoSolicitante',
    label: i18n('entities.solicitud.fields.correoSolicitante'),
    schema: schemas.string(i18n('entities.solicitud.fields.correoSolicitante'), {}),
  },
  {
    name: 'rutSolicitante',
    label: i18n('entities.solicitud.fields.rutSolicitante'),
    schema: schemas.string(i18n('entities.solicitud.fields.rutSolicitante'), {}),
  },
  {
    name: 'nombreEmpresaSolicitante',
    label: i18n('entities.solicitud.fields.nombreEmpresaSolicitante'),
    schema: schemas.string(i18n('entities.solicitud.fields.nombreEmpresaSolicitante'), {}),
  },
  {
    name: 'direccionSolicitante',
    label: i18n('entities.solicitud.fields.direccionSolicitante'),
    schema: schemas.string(i18n('entities.solicitud.fields.direccionSolicitante'), {}),
  },
  {
    name: 'ciudadSolicitante',
    label: i18n('entities.solicitud.fields.ciudadSolicitante'),
    schema: schemas.string(i18n('entities.solicitud.fields.ciudadSolicitante'), {}),
  },
  {
    name: 'estadoSolicitante',
    label: i18n('entities.solicitud.fields.estadoSolicitante'),
    schema: schemas.string(i18n('entities.solicitud.fields.estadoSolicitante'), {}),
  },
  {
    name: 'codigoPostalSolicitante',
    label: i18n('entities.solicitud.fields.codigoPostalSolicitante'),
    schema: schemas.string(i18n('entities.solicitud.fields.codigoPostalSolicitante'), {}),
  },
  {
    name: 'telefonoSolicitante',
    label: i18n('entities.solicitud.fields.telefonoSolicitante'),
    schema: schemas.integer(i18n('entities.solicitud.fields.telefonoSolicitante'), {}),
  },
  {
    name: 'faxSolicitante',
    label: i18n('entities.solicitud.fields.faxSolicitante'),
    schema: schemas.integer(i18n('entities.solicitud.fields.faxSolicitante'), {}),
  },
  {
    name: 'nombreEnvio',
    label: i18n('entities.solicitud.fields.nombreEnvio'),
    schema: schemas.string(i18n('entities.solicitud.fields.nombreEnvio'), {}),
  },
  {
    name: 'nombreEmpresaEnvio',
    label: i18n('entities.solicitud.fields.nombreEmpresaEnvio'),
    schema: schemas.string(i18n('entities.solicitud.fields.nombreEmpresaEnvio'), {}),
  },
  {
    name: 'usarDireccionEmpresaEnvio',
    label: i18n('entities.solicitud.fields.usarDireccionEmpresaEnvio'),
    schema: schemas.boolean(i18n('entities.solicitud.fields.usarDireccionEmpresaEnvio'), {}),
  },
  {
    name: 'direccionEnvio',
    label: i18n('entities.solicitud.fields.direccionEnvio'),
    schema: schemas.string(i18n('entities.solicitud.fields.direccionEnvio'), {}),
  },
  {
    name: 'ciudadEnvio',
    label: i18n('entities.solicitud.fields.ciudadEnvio'),
    schema: schemas.string(i18n('entities.solicitud.fields.ciudadEnvio'), {}),
  },
  {
    name: 'estadoEnvio',
    label: i18n('entities.solicitud.fields.estadoEnvio'),
    schema: schemas.string(i18n('entities.solicitud.fields.estadoEnvio'), {}),
  },
  {
    name: 'codigoPostalEnvio',
    label: i18n('entities.solicitud.fields.codigoPostalEnvio'),
    schema: schemas.integer(i18n('entities.solicitud.fields.codigoPostalEnvio'), {}),
  },
  {
    name: 'telefonoEnvio',
    label: i18n('entities.solicitud.fields.telefonoEnvio'),
    schema: schemas.integer(i18n('entities.solicitud.fields.telefonoEnvio'), {}),
  },
  {
    name: 'faxEnvio',
    label: i18n('entities.solicitud.fields.faxEnvio'),
    schema: schemas.integer(i18n('entities.solicitud.fields.faxEnvio'), {}),
  },
  {
    name: 'solicitarActivos',
    label: i18n('entities.solicitud.fields.solicitarActivos'),
    schema: schemas.relationToMany(i18n('entities.solicitud.fields.solicitarActivos'), {}),
  },
  {
    name: 'cuentasContables',
    label: i18n('entities.solicitud.fields.cuentasContables'),
    schema: schemas.relationToMany(i18n('entities.solicitud.fields.cuentasContables'), {}),
  },
];
