import React, {useState} from 'react';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {i18n} from 'src/i18n';
import FormWrapper, {FormButtons} from 'src/view/shared/styles/FormWrapper';
// import activoEnumerators from 'src/modules/activo/activoEnumerators';
import ConfirmModal from 'src/view/shared/modals/ConfirmModal';
// import {useSelector} from 'react-redux';
// import authSelectors from 'src/modules/auth/authSelectors';

import {TreeItem, TreeView} from '@material-ui/lab';
import {ChevronRight, ExpandMore, SaveOutlined} from '@mui/icons-material';
import {Button, Checkbox, FormControlLabel, Grid} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {magicFlexBox} from 'src/theme/schemes/CustomTheme';

export interface RenderTree {
  id?: string;
  name: string;
  label: string;
  children?: RenderTree[];
}

const useStyles = makeStyles((theme: any) => ({
  checkbox: {
    '&.MuiCheckbox-root': {
      color: 'rgba(81, 185, 201, 0.8)',
    },
    '&.MuiCheckbox-colorSecondary': {
      '&.Mui-checked': {
        color: 'rgba(160, 81, 201, 1)',
      },
    },
  },
}));

function ActivoFormFieldConfig(props) {
  const [confirm, setConfirm] = useState(false);

  const fieldData: RenderTree[] = [
    {
      name: 'tabGeneral',
      label: 'General',
      children: [
        {name: 'fechaInicioOperaciones', label: i18n('entities.activo.fields.fechaInicioOperaciones')},
        {name: 'nombre', label: i18n('entities.activo.fields.nombre')},
        {name: 'codigo', label: i18n('entities.activo.fields.codigo')},
        {name: 'grupo', label: i18n('entities.activo.fields.grupo')},
        {name: 'cantidadHorasDisponibilidadDia', label: i18n('entities.activo.fields.cantidadHorasDisponibilidadDia')},
        {
          name: 'tipoMedicionMantenimiento',
          label: i18n('entities.activo.fields.tipoMedicionMantenimiento'),
        },
        {name: 'horometro', label: i18n('entities.activo.fields.horometro')},
        {name: 'kilometraje', label: i18n('entities.activo.fields.kilometraje')},
        {name: 'patente', label: i18n('entities.activo.fields.patente')},
        {name: 'descripcion', label: i18n('entities.activo.fields.descripcion')},
        {name: 'locations', label: i18n('common.locations')},
        {name: 'fotos', label: i18n('entities.activo.fields.fotos')},
        {name: 'activoPrincipal', label: i18n('entities.activo.fields.activoPrincipal')},
        {name: 'localizacion', label: i18n('entities.activo.fields.localizacion')},
        {name: 'costoCompra', label: i18n('entities.activo.fields.costoCompra')},
        {name: 'inventario', label: i18n('entities.activo.fields.inventario')},
        {name: 'prioridad', label: i18n('entities.activo.fields.prioridad')},
        {name: 'tipoActivo', label: i18n('entities.activo.fields.tipoActivo')},
        {name: 'codigoBarra', label: i18n('entities.activo.fields.codigoBarra')},
        {name: 'fechaFabricacion', label: i18n('entities.activo.fields.fechaFabricacion')},
        {name: 'fabricante', label: i18n('entities.activo.fields.fabricante')},
        {name: 'marca', label: i18n('entities.activo.fields.marca')},
        {name: 'modelo', label: i18n('entities.activo.fields.modelo')},
        {name: 'numeroSerial', label: i18n('entities.activo.fields.numeroSerial')},
        {name: 'numInspeccion', label: i18n('entities.activo.fields.numInspeccion')},
        {name: 'fechaInspeccion', label: i18n('entities.activo.fields.fechaInspeccion')},
        {name: 'numInspAnterior', label: i18n('entities.activo.fields.numInspAnterior')},
        {name: 'fechaInspAnterior', label: i18n('entities.activo.fields.fechaInspAnterior')},
        {name: 'visibilidadHorasUso', label: i18n('entities.activo.fields.visibilidadHorasUso')},
        {name: 'fechaExpiracion', label: i18n('entities.activo.fields.fechaExpiracion')},
        {name: 'unidadMedida', label: i18n('entities.activo.fields.unidadMedida')},
        {name: 'numeroParte', label: i18n('entities.activo.fields.numeroParte')},
        {name: 'tiempoEspera', label: i18n('entities.activo.fields.tiempoEspera')},
        {name: 'controladoPorSerial', label: i18n('entities.activo.fields.controladoPorSerial')},
        {name: 'version', label: i18n('entities.activo.fields.version')},
        {name: 'direccionWeb', label: i18n('entities.activo.fields.direccionWeb')},
        {name: 'estado', label: i18n('entities.activo.fields.estado')},
        {name: 'certificated', label: i18n('entities.activo.fields.certificated')},
        {
          name: 'dimensiones',
          label: 'Dimensiones',
          children: [
            {name: 'ancho', label: i18n('entities.activo.fields.ancho')},
            {name: 'alto', label: i18n('entities.activo.fields.alto')},
            {name: 'peso', label: i18n('entities.activo.fields.peso')},
          ],
        },
      ],
    },
    {
      name: 'tabAdicionales',
      label: 'Adicionales',
      children: [
        {name: 'numLote', label: i18n('entities.activo.fields.numLote')},
        {name: 'capacidad', label: i18n('entities.activo.fields.capacidad')},
        {name: 'presionPrueba', label: i18n('entities.activo.fields.presionPrueba')},
        {name: 'presionDiseno', label: i18n('entities.activo.fields.presionDiseno')},
        {name: 'nombreDiseno', label: i18n('entities.activo.fields.nombreDiseno')},
        {name: 'materialManto', label: i18n('entities.activo.fields.materialManto')},
        {name: 'espesorManto', label: i18n('entities.activo.fields.espesorManto')},
        {name: 'materialCabezales', label: i18n('entities.activo.fields.materialCabezales')},
        {name: 'espesorCabezales', label: i18n('entities.activo.fields.espesorCabezales')},
        {name: 'formaCabezales', label: i18n('entities.activo.fields.formaCabezales')},
        {name: 'superficie', label: i18n('entities.activo.fields.superficie')},
        {name: 'enterrado', label: i18n('entities.activo.fields.enterrado')},
        {name: 'numCertAnterior', label: i18n('entities.activo.fields.numCertAnterior')},
        {name: 'otorgadoPorCia', label: i18n('entities.activo.fields.otorgadoPorCia')},
        {name: 'cuno', label: i18n('entities.activo.fields.cuno')},
      ],
    },
    {
      name: 'tabMantenimientoPreventivo',
      label: 'Mantenimiento Preventivo',
      children: [
        {
          name: 'tipoDeMedicion',
          label: 'Tipo de Medición',
          children: [
            {
              name: 'tipoMedicionMantenimiento',
              label: i18n('entities.activo.fields.tipoMedicionMantenimiento'),
            },
          ],
        },
        {
          name: 'parametrosDeAnalisis',
          label: 'Parámetros de Análisis',
          children: [
            {name: 'mantenimientoPorHorometro', label: i18n('entities.activo.fields.mantenimientoPorHorometro')},
            {name: 'mantenimientoPorKilometros', label: i18n('entities.activo.fields.mantenimientoPorKilometros')},
            {name: 'horasUsoPromedioDiario', label: i18n('entities.activo.fields.horasUsoPromedioDiario')},
            {name: 'kilometrosPromedioDiario', label: i18n('entities.activo.fields.kilometrosPromedioDiario')},
          ],
        },
        {
          name: 'ejecucionMantenimientoPreventivo',
          label: 'Ejecución Mantenimiento Preventivo',
          children: [
            {name: 'ejecutoresMantenimientoPreventivo', label: i18n('entities.activo.fields.ejecutoresMantenimientoPreventivo')},
            {
              name: 'modeloTareaMantenimientoPreventivo',
              label: i18n('entities.activo.fields.modeloTareaMantenimientoPreventivo'),
            },
          ],
        },
      ],
    },
    {
      name: 'tabTareas',
      label: 'Tareas',
      children: [{name: 'tareas', label: 'Tareas'}],
    },
    {
      name: 'tabResponsables',
      label: 'Responsables',
      children: [
        {name: 'responsablesActivo', label: i18n('entities.activo.fields.responsablesActivo')},
        {name: 'responsables', label: i18n('entities.activo.fields.responsables')},
      ],
    },
    {
      name: 'tabUbicacion',
      label: 'Ubicación',
      children: [
        {name: 'ubicacionGoogle', label: i18n('entities.activo.fields.ubicacionGoogle')},
        {name: 'direccion', label: i18n('entities.activo.fields.direccion')},
        {name: 'ciudad', label: i18n('entities.activo.fields.ciudad')},
        {name: 'region', label: i18n('entities.activo.fields.region')},
        {name: 'pais', label: i18n('entities.activo.fields.pais')},
        {name: 'codigoArea', label: i18n('entities.activo.fields.codigoArea')},
      ],
    },
    {
      name: 'tabRepuestosYSuministros',
      label: 'Repuestos y Suministros',
      children: [
        {name: 'repuestos', label: i18n('entities.activo.fields.repuestos')},
        // {name: 'fabricante', label: i18n('entities.activo.fields.fabricante')},
        // {name: 'fechaFabricacion', label: i18n('entities.activo.fields.fechaFabricacion')},
        // {name: 'modelo', label: i18n('entities.activo.fields.modelo')},
      ],
    },
    {
      name: 'tabEquivalencias',
      label: 'Equivalencias',
      children: [{name: 'equivalencias', label: i18n('entities.activo.fields.equivalencias')}],
    },
    {
      name: 'tabTercerosRelacionados',
      label: 'Terceros Relacionados',
      children: [{name: 'tercerosRelacionados', label: i18n('entities.activo.fields.tercerosRelacionados')}],
    },
    // {
    //   name: 'tabHistoriales',
    //   label: 'Historiales',
    //   children: [{name: 'notas', label: i18n('entities.activo.fields.notas')}],
    // },
    // {
    //   name: 'tabAdjuntos',
    //   label: 'Adjuntos',
    //   children: [{name: 'documentos', label: i18n('entities.activo.fields.documentos')}],
    // },
    {
      name: 'tabGestionDocumental',
      label: 'Gestión Documental',
      children: [{name: 'gestionDocumental', label: i18n('entities.activo.fields.gestionDocumental')}],
    },
    {
      name: 'tabFinanciero',
      label: 'Financiero',
      children: [
        {name: 'presupuesto', label: i18n('entities.activo.fields.presupuesto')},
        {name: 'fechaCompra', label: i18n('entities.activo.fields.fechaCompra')},
        {name: 'tipoDepreciacion', label: i18n('entities.activo.fields.tipoDepreciacion')},
        {name: 'depreciacionAnual', label: i18n('entities.activo.fields.depreciacionAnual')},
        {name: 'vidaUtil', label: i18n('entities.activo.fields.vidaUtil')},
        {name: 'valorSustitucion', label: i18n('entities.activo.fields.valorSustitucion')},
        {name: 'valorSalvamiento', label: i18n('entities.activo.fields.valorSalvamiento')},
        {name: 'depreciacionTotalHoy', label: i18n('entities.activo.fields.depreciacionTotalHoy')},
        {name: 'valorLibroHoy', label: i18n('entities.activo.fields.valorLibroHoy')},
      ],
    },
    {
      name: 'tabReciclaje',
      label: 'Reciclaje',
      children: [
        {name: 'ubicacionCiclos', label: i18n('entities.activo.fields.ubicacionCiclos')},
        {name: 'cantidadCiclosRecicla', label: i18n('entities.activo.fields.cantidadCiclosRecicla')},
      ],
    },
  ];
  function getAllNodeNames(parentNodes: RenderTree[]) {
    let array: string[] = [];
    //returns an array of nodes ids: clicked node id and all children node ids
    function getNames(nodes?: RenderTree[]) {
      if (!nodes) return [];
      for (let node of nodes) {
        array.push(node.name);
        if (Array.isArray(node.children)) {
          array = [...array, ...getAllNodeNames(node.children)];
        }
      }
      return array;
    }
    return getNames(parentNodes);
  }
  const [initialValues] = useState(() => {
    const fieldMap = Object.fromEntries(getAllNodeNames(fieldData).map((name) => [name, true]));
    console.log('%c⧭ ActivoFormFieldConfig', 'color: #99adcc', {fieldMap, fieldConfig: props.fieldConfig?.fields});
    const fieldConfig = {
      ...fieldMap,
      ...props.fieldConfig?.fields,
    };
    //console.log('%c⧭ ActivoFormFieldConfig', 'color: #99adcc', {fieldConfig});
    return fieldConfig;
  });

  const form = useForm({defaultValues: initialValues as any});

  const values = form.getValues();

  const onSubmit = (values) => {
    setConfirm(false);
    props.onSubmit(props.record?.id, values);
  };

  // const onReset = () => {
  //   Object.keys(initialValues).forEach(key => {
  //     form.setValue(key, initialValues[key]);
  //   });
  // };

  const {saveLoading, modal} = props;

  // const doConfirm = () => {
  //   setConfirm(true);
  // };

  const doCloseConfirm = () => {
    setConfirm(false);
  };

  // const roles = useSelector(authSelectors.selectRoles);

  const RenderTreeWithCheckboxes = (node: RenderTree) => {
    const classes = useStyles();
    //const registerNode = form.register(node.name);
    return (
      <TreeItem
        key={node.name}
        nodeId={node.name}
        label={
          <FormControlLabel
            control={
              <Controller
                name={node.name}
                control={form.control}
                render={({field}) => {
                  //console.log('%c⧭ render', 'color: #00ff88', {field});
                  return (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onClick={(e) => e.stopPropagation()}
                      className={classes.checkbox}
                    />
                  );
                }}
              />
            }
            label={<>{node.label}</>}
            key={node.name}
          />
        }>
        {Array.isArray(node.children) ? node.children.map((childNode) => RenderTreeWithCheckboxes(childNode)) : null}
      </TreeItem>
    );
  };

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          style={
            {
              ...magicFlexBox,
              overflow: 'auto',
            } as any
          }>
          <TreeView defaultCollapseIcon={<ExpandMore />} defaultExpanded={['tabGeneral']} defaultExpandIcon={<ChevronRight />}>
            {fieldData.map((node) => RenderTreeWithCheckboxes(node))}
          </TreeView>
        </form>
        <FormButtons
          style={{
            flexDirection: modal ? 'row-reverse' : undefined,
          }}>
          <Grid container>
            <Grid item lg={12}>
              <Button
                style={{width: props.drawer ? '100%' : 300, borderRadius: 20, padding: 20, fontSize: 18, fontWeight: 800}}
                variant="contained"
                color="secondary"
                disabled={saveLoading}
                type="button"
                onClick={form.handleSubmit(onSubmit)}
                startIcon={<SaveOutlined />}
                size="large">
                {i18n('common.save')}
              </Button>
            </Grid>
          </Grid>
        </FormButtons>
      </FormProvider>

      {confirm && (
        <ConfirmModal
          title={i18n('common.areYouSure')}
          onConfirm={form.handleSubmit(onSubmit)}
          onClose={() => doCloseConfirm()}
          okText={i18n('common.yes')}
          cancelText={i18n('common.no')}
        />
      )}
    </FormWrapper>
  );
}
export default ActivoFormFieldConfig;
