import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.puesto.fields.id'),
  },
  {
    name: 'nombre',
    label: i18n('entities.puesto.fields.nombre'),
  },
  {
    name: 'descripcion',
    label: i18n('entities.puesto.fields.descripcion'),
  },
  {
    name: 'modeloEvaluacion',
    label: i18n('entities.puesto.fields.modeloEvaluacion'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'departamento',
    label: i18n('entities.puesto.fields.departamento'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'nivelPuesto',
    label: i18n('entities.puesto.fields.nivelPuesto'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.puesto.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.puesto.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
