import DataService from 'src/modules/data/dataService';
import Errors from 'src/modules/shared/error/errors';
import {getHistory} from 'src/modules/store';

const prefix = 'DATA_VIEW';

const dataViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (entity, data) => async (dispatch) => {
    try {
      dispatch({
        type: dataViewActions.FIND_STARTED,
      });

      const record = await DataService.find(entity, data);

      dispatch({
        type: dataViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: dataViewActions.FIND_ERROR,
      });

      getHistory().push('/data');
    }
  },
};

export default dataViewActions;
