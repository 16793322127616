import {Box, Checkbox, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';
import React from 'react';
import {i18n} from 'src/i18n';
import Spinner from 'src/view/shared/Spinner';
import TableCellCustom from 'src/view/shared/table/TableCellCustom';
import TareaNestedRow from './TareaNestedRow';

function TareaNestedTable(props) {
  const {row: childRow, level, sendFormSilent, from} = props;
  const {rows, sorter, doChangeSort, doToggleAllSelected, isAllSelected, hasRows, loading} = props.props;

  const displayRows = level === 0 ? rows : childRow.children;

  return (
    <Table
      stickyHeader
      style={
        {
          //display: 'flex',
          //flexDirection: 'column',
          //flexGrow: 1,
        }
      }>
      <TableHead>
        <TableRow>
          <TableCellCustom padding="checkbox">{/* ICON BUTTON DROPDOWN */}</TableCellCustom>
          {!['IncidentFormSeguimiento', 'ActivoForm'].includes(props.from) && (
            <TableCellCustom padding="checkbox">
              {hasRows && <Checkbox checked={Boolean(isAllSelected)} onChange={() => doToggleAllSelected()} size="small" />}
            </TableCellCustom>
          )}
          <TableCellCustom size="pad0" />
          <TableCellCustom size="pad0" />
          <TableCellCustom size="pad0" />
          <TableCellCustom size="pad0" />
          <TableCellCustom size="pad0" />

          <TableCellCustom
            onSort={doChangeSort}
            hasRows={hasRows}
            sorter={sorter}
            name={'prioridad'}
            label={i18n('entities.tarea.fields.prioridad')}
          />

          {/* <TableCellCustom label={i18n('entities.tarea.fields.titulo')} /> */}
          <TableCellCustom label={i18n('entities.tarea.fields.tituloTarea')} />
          <TableCellCustom label={i18n('entities.tarea.fields.parentTask')} />
          {/* <TableCellCustom onSort={doChangeSort} hasRows={hasRows} sorter={sorter} name={'dirigidoA'} label={i18n('entities.tarea.fields.dirigidoA')} /> */}
          <TableCellCustom
            onSort={doChangeSort}
            hasRows={hasRows}
            sorter={sorter}
            name={'estado'}
            label={i18n('entities.tarea.fields.estado')}
          />
          {/* <TableCellCustom onSort={doChangeSort} hasRows={hasRows} sorter={sorter} name={'prioridad-estado'} label={`${i18n('entities.tarea.fields.prioridad')}/${i18n('entities.tarea.fields.estado')}`} /> */}
          {/* <TableCellCustom onSort={doChangeSort} hasRows={hasRows} sorter={sorter} name={'estadoActivacion'} label={i18n('entities.tarea.fields.estadoActivacion')} /> */}
          {/* <TableCellCustom label={i18n('entities.tarea.fields.fotosAdjuntas')} />
        <TableCellCustom label={i18n('entities.tarea.fields.documentosAdjuntos')} /> */}
          {/* <TableCellCustom
          onSort={doChangeSort}
          hasRows={hasRows}
          sorter={sorter}
          name={'tipoMantenimiento'}
          label={i18n('entities.tarea.fields.tipoMantenimiento')}
        /> */}
          {/* <TableCellCustom label={i18n('entities.tarea.fields.planificadorTarea')} /> */}
          <TableCellCustom label={i18n('entities.tarea.fields.ejecutor')} />
          <TableCellCustom label={i18n('entities.tarea.fields.planificacion')} />
          <TableCellCustom>
            <span style={{textTransform: 'capitalize'}}>{i18n('entities.activo.name')}</span>
          </TableCellCustom>
          <TableCellCustom>
            <span style={{textTransform: 'capitalize'}}>{i18n('entities.tarea.fields.tercero')}</span>
          </TableCellCustom>
          <TableCellCustom
            onSort={doChangeSort}
            hasRows={hasRows}
            sorter={sorter}
            name={'fechaProgramacion'}
            label={i18n('entities.tarea.fields.fechaProgramacion')}
          />
          <TableCellCustom label={i18n('entities.tarea.fields.finEstimado')} />
          <TableCellCustom
            onSort={doChangeSort}
            hasRows={hasRows}
            sorter={sorter}
            name={'comienzo'}
            label={i18n('entities.tarea.fields.comienzo')}
          />
          <TableCellCustom
            onSort={doChangeSort}
            hasRows={hasRows}
            sorter={sorter}
            name={'fin'}
            label={i18n('entities.tarea.fields.fin')}
          />
          <TableCellCustom>
            <span style={{textTransform: 'capitalize'}}>{i18n('entities.registro.fields.duracion')}</span>
          </TableCellCustom>
          <TableCellCustom
            onSort={doChangeSort}
            hasRows={hasRows}
            sorter={sorter}
            name={'fechaLimite'}
            label={i18n('entities.tarea.fields.fechaLimite')}
          />
          <TableCellCustom
            onSort={doChangeSort}
            hasRows={hasRows}
            sorter={sorter}
            name={'expiredAt'}
            label={i18n('entities.tarea.fields.expiredAt')}
          />
          <TableCellCustom label={i18n('entities.tarea.fields.categoria')} />
          <TableCellCustom label={'Aceptador/Validador'} />
          <TableCellCustom
            onSort={doChangeSort}
            hasRows={hasRows}
            sorter={sorter}
            name={'certificacion'}
            label={i18n('entities.tarea.fields.certificacion')}
          />
          <TableCellCustom
            onSort={doChangeSort}
            hasRows={hasRows}
            sorter={sorter}
            name={'tipoVisualizacion'}
            label={i18n('entities.tarea.fields.tipoVisualizacion')}
          />
          <TableCellCustom label={i18n('common.locations')} />
          <TableCellCustom label={i18n('entities.tarea.fields.createdBy')} />

          {/* <TableCellCustom
          onSort={doChangeSort}
          hasRows={hasRows}
          sorter={sorter}
          name={'enviada'}
          label={i18n(
            'entities.tarea.fields.enviada',
          )}
        /> */}
          {/* <TableCellCustom
          onSort={doChangeSort}
          hasRows={hasRows}
          sorter={sorter}
          name={'esEvaluada'}
          label={i18n(
            'entities.tarea.fields.esEvaluada',
          )}
        /> */}
        </TableRow>
      </TableHead>
      <TableBody>
        {loading && (
          <TableRow>
            <TableCell
              colSpan={100}
              sx={{
                borderBottom: '0px solid white',
              }}>
              <Box style={{width: '80vw', justifyContent: 'normal'}}>
                <Spinner />
              </Box>
            </TableCell>
          </TableRow>
        )}
        {!loading && !hasRows && (
          <TableRow>
            <TableCell
              colSpan={100}
              sx={{
                borderBottom: '0px solid white',
              }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                {i18n('table.noData')}
              </div>
            </TableCell>
          </TableRow>
        )}
        {!loading &&
          displayRows?.map((row, index) => (
            <React.Fragment key={row.id}>
              <TareaNestedRow
                key={row.id}
                {...props}
                row={row}
                level={level}
                childIndex={index}
                childrenLength={displayRows.length}
                sendFormSilent={sendFormSilent}
                from={from}
              />
            </React.Fragment>
          ))}
      </TableBody>
    </Table>
  );
}
export default TareaNestedTable;
