const planificadorTareaCustomEnumerators = {
  dirigido_a: ['Puesto', 'Ejecutor'],
  tipo_tiempo_limite: ['días', 'meses', 'años'],
  tipo_periodicidad: ['diaria', 'semanal', 'mensual', 'anual'],
  clasificacion: ['operativa', 'mantenimiento', 'incidente'],
  visualizacion: ['Estandar', 'Checklist', 'Pasos'],
  tipo: ['Planificación', 'Sub-Planificación', 'Tarea', 'Sub-Tarea'],
};

export default planificadorTareaCustomEnumerators;
