import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/planificadorTarea/importer/planificadorTareaImporterSelectors';
import PlanificadorTareaService from 'src/modules/planificadorTarea/planificadorTareaService';
import fields from 'src/modules/planificadorTarea/importer/planificadorTareaImporterFields';
import { i18n } from 'src/i18n';

const planificadorTareaImporterActions = importerActions(
  'PLANIFICADORTAREA_IMPORTER',
  selectors,
  PlanificadorTareaService.import,
  fields,
  i18n('entities.planificadorTarea.importer.fileName'),
);

export default planificadorTareaImporterActions;