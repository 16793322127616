import {useEffect, useRef} from 'react';

import BottomBarContent from './BottomBarContent';
import ChatContent from './ChatContent';
import TopBarContent from './TopBarContent';

import {Box, Grid, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';

import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';
import {i18n} from 'src/i18n';
import actionsComunicacionInterna from 'src/modules/comunicacionInterna/form/comunicacionInternaFormActions';
import actions from 'src/modules/mensajeInterno/form/mensajeInternoFormActions';

import selectorsComunicacionInterna from 'src/modules/comunicacionInterna/form/comunicacionInternaFormSelectors';
import selectors from 'src/modules/mensajeInterno/form/mensajeInternoFormSelectors';
import {getHistory} from 'src/modules/store';
import Spinner from 'src/view/shared/Spinner';

const RootWrapper = styled(Box)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Sidebar = styled(Box)(
  ({theme}: any) => `
  width: 300px;
  background: ${theme.colors.alpha.white[100]};
  border-right: ${theme.colors.alpha.black[10]} solid 1px;
`,
);

const ChatWindow = styled(Box)(
  () => `
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`,
);

const ChatTopBar = styled(Box)(
  ({theme}: any) => `
  background: ${theme.colors.alpha.white[100]};
  border-bottom: ${theme.colors.alpha.black[10]} solid 1px;
  padding: ${theme.spacing(window.innerWidth > 768 ? 0 : 0)};
`,
);

const ChatMain = styled(Box)(
  () => `
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`,
);

const ChatBottomBar = styled(Box)(
  ({theme}) => `
  padding: ${theme.spacing(window.innerWidth > 768 ? 2 : 0)};
`,
);

function ApplicationsMessenger(props) {
  const ref: any = useRef(null);

  const [dispatched, setDispatched] = useState(false);
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const initLoading = useSelector(selectors.selectInitLoading);
  const saveLoading = useSelector(selectors.selectSaveLoading);

  const recordComunicacionInterna = useSelector(selectorsComunicacionInterna.selectRecord);

  const isEditing = Boolean(match.params.id);
  const title = isEditing ? i18n('entities.mensajeInterno.edit.title') : i18n('entities.mensajeInterno.new.title');

  useEffect(() => {
    dispatch(actions.doInit(match.params.id));
    setDispatched(true);
  }, [dispatch, match.params.id]);

  useEffect(() => {
    dispatch(actionsComunicacionInterna.doInit(props.record?.id || match.params.idComunicacion));
    setDispatched(true);
  }, [dispatch, match.params.idComunicacion, props.record?.id]);

  const doSubmit = (id, data) => {
    if (isEditing) {
      dispatch(actions.doUpdate(id, data));
    } else {
      dispatch(actions.doCreate(data));
    }
  };

  if (!recordComunicacionInterna) {
    return <Spinner />;
  }

  return (
    <Grid className={'multiway-container-message'}>
      <RootWrapper>
        <ChatWindow>
          <ChatTopBar>
            <TopBarContent
              saveLoading={saveLoading}
              initLoading={initLoading}
              recordComunicacionInterna={recordComunicacionInterna}
              isEditing={isEditing}
              onSubmit={doSubmit}
              onCancel={() => getHistory().push('/mensaje-interno')}
            />
          </ChatTopBar>
          <ChatMain>
            <ChatContent
              saveLoading={saveLoading}
              initLoading={initLoading}
              recordComunicacionInterna={recordComunicacionInterna}
              isEditing={isEditing}
              onSubmit={doSubmit}
              onCancel={() => getHistory().push('/mensaje-interno')}
            />
          </ChatMain>
          {recordComunicacionInterna?.estado === 'Cerrada' && (
            <ChatBottomBar>
              <Typography variant="h6" color="textSecondary">
                La comunicación ha sido cerrada
              </Typography>
            </ChatBottomBar>
          )}

          {recordComunicacionInterna?.estado !== 'Cerrada' && (
            <ChatBottomBar>
              <BottomBarContent
                drawer={props.drawer}
                saveLoading={saveLoading}
                initLoading={initLoading}
                recordComunicacionInterna={recordComunicacionInterna}
                isEditing={isEditing}
                onSubmit={doSubmit}
                onCancel={() => getHistory().push('/mensaje-interno')}
              />
            </ChatBottomBar>
          )}
        </ChatWindow>
      </RootWrapper>
    </Grid>
  );
}

export default ApplicationsMessenger;
