import list from 'src/modules/funcion/list/funcionListReducers';
import form from 'src/modules/funcion/form/funcionFormReducers';
import view from 'src/modules/funcion/view/funcionViewReducers';
import destroy from 'src/modules/funcion/destroy/funcionDestroyReducers';
import importerReducer from 'src/modules/funcion/importer/funcionImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
