import {yupResolver} from '@hookform/resolvers/yup';
import {Button, Grid} from '@mui/material';
import React, {useState} from 'react';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {i18n} from 'src/i18n';
import FormWrapper from 'src/view/shared/styles/FormWrapper';
import * as yup from 'yup';

import {SaveOutlined} from '@mui/icons-material';
import Box from '@mui/material/Box';
import {useSelector} from 'react-redux';
import authSelectors from 'src/modules/auth/authSelectors';
import planificacionListSelectors from 'src/modules/planificacion/list/planificacionListSelectors';
import {customButtonStyle, magicFlexBox} from 'src/theme/schemes/CustomTheme';
import PlanificacionAutocompleteFormItem from '../autocomplete/PlanificacionAutocompleteFormItem';

const schema = yup.object().shape({});

function PlanificacionFormTemplateSelection(props) {
  const tenantUser = useSelector(authSelectors.selectTenantUser);
  const userLocations = tenantUser?.perfilEmpleado?.ubicacion;

  const listFilter = useSelector(planificacionListSelectors.selectFilter);
  const listFilterRaw = useSelector(planificacionListSelectors.selectRawFilter);
  const terceroSelected = listFilterRaw.tercero;
  console.log('%c⧭ PlanificacionFormTemplateSelection', 'color: #cc7033', {listFilter, terceroSelected});

  const [initialValues] = useState(() => {
    const record = props.record || {};
    console.log('%c⧭ PlanificacionFormTemplateSelection', 'color: #cc7033', {record});

    return {
      ...record,
      tercero: record.tercero || terceroSelected,
      planificacionPadre: record.planificacionPadre,
      parentLabel: record.parentLabel,
      planificacionRaiz: record.planificacionRaiz,
      nivelAnidacion: record.nivelAnidacion || 0,
      locations: record.locations || userLocations || [],
      templates: [],
    };
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
    shouldUnregister: false,
  });

  const onSave = (values) => {
    const templates = values.templates.map((template) => template.id);
    delete values.templates;
    props.onSubmitTemplates({
      values,
      templates,
    });
  };

  const {saveLoading} = props;

  const templates = useWatch({
    control: form.control,
    name: 'templates',
  });

  const disabledButton = !templates?.length;

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSave)}
          style={
            {
              ...magicFlexBox,
            } as any
          }>
          <Box
            sx={{
              bgcolor: 'background.paper',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              flexGrow: 1,
            }}>
            <Box width="50%" minWidth="300px">
              <PlanificacionAutocompleteFormItem
                filter={{
                  isTemplate: true,
                  onlyParents: true,
                }}
                name={'templates'}
                label={i18n('common.selectTemplates')}
                mode={'multiple'}
              />
            </Box>
          </Box>
          {!props.disabled && (
            <Box
              style={{
                marginTop: 20,
                marginBottom: 15,
                display: 'flex',
                flexShrink: 0,
              }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    style={{
                      width: '100%',
                      ...customButtonStyle,
                    }}
                    variant="contained"
                    color="secondary"
                    disabled={saveLoading || disabledButton}
                    type="button"
                    onClick={form.handleSubmit(onSave)}
                    startIcon={<SaveOutlined />}
                    size="large">
                    {i18n('common.save')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </form>
      </FormProvider>
    </FormWrapper>
  );
}

export default PlanificacionFormTemplateSelection;
