import list from 'src/modules/historialInventario/list/historialInventarioListReducers';
import form from 'src/modules/historialInventario/form/historialInventarioFormReducers';
import view from 'src/modules/historialInventario/view/historialInventarioViewReducers';
import destroy from 'src/modules/historialInventario/destroy/historialInventarioDestroyReducers';
import importerReducer from 'src/modules/historialInventario/importer/historialInventarioImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
