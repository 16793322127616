import {Search} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CloseIcon from '@mui/icons-material/Close';
import {Box, Button, Tooltip, Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import authSelectors from 'src/modules/auth/authSelectors';
import Errors from 'src/modules/shared/error/errors';
import FileUploader from 'src/modules/shared/fileUpload/fileUploader';
import OpenAIService from 'src/modules/shared/openai/openAIService';
import ImageModal from 'src/view/shared/modals/ImageModal';
import Spinner from '../Spinner';
import Message from '../message';

function ImagesUploader(props) {
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [image, setImage] = useState<any>(null);
  const [imageHover, setImageHover] = useState<any>(null);
  const input = useRef<any>();

  const classes = {
    card: (theme: any) => ({
      mr: 1,
      //mb: 1,
      height: '100px',
      '&:hover div': {
        display: 'block',
      },
    }),

    image: {
      borderRadius: '5px',
      width: '100px',
      height: '100px',
      objectFit: 'cover',
    },

    imageButtons: {
      display: 'none',
      position: 'absolute',
      bottom: '0px',
      width: '100px',
      textAlign: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
    },

    AIContentTable: {
      width: '100%',
      height: '100px',
      overflow: 'hidden',
      overflowY: 'auto',
      border: '1px solid lightgray',
      borderRadius: '5px',
    },

    imageButtonIcon: (theme: any) => ({
      color: theme.palette.getContrastText(theme.palette.primary.main),
    }),
  };

  const value = () => {
    const {value} = props;

    if (!value) {
      return [];
    }

    return Array.isArray(value) ? value : [value];
  };

  const fileList = () => {
    return value().map((item) => ({
      uid: item.id || undefined,
      name: item.name,
      status: 'done',
      url: item.downloadUrl || item.publicUrl,
    }));
  };

  const handleRemove = (id) => {
    props.onChange(value().filter((item) => item.id !== id));
  };

  const handleChange = async (event) => {
    const files = event.target.files;

    if (!files || !files.length) {
      return;
    }

    const currentFiles = value();
    let newFiles: any = [];

    const uploadLimit: any = props.max ? props.max - currentFiles.length : undefined;

    try {
      setLoading(true);
      setLoadingImage(true);
      for (let i = 0; i < files.length; i++) {
        if (uploadLimit && i >= uploadLimit) {
          break;
        }
        const file = files[i];
        FileUploader.validate(file, {
          storage: props.storage,
          image: true,
        });
        if (props.onImageUpload) {
          props.onImageUpload(file);
          setLoading(false);
          setLoadingImage(false);
          if (input.current) input.current.value = '';
          return;
        }
        const newFile = await FileUploader.upload(file, {
          storage: props.storage,
          image: true,
        });
        newFiles.push(newFile);
        props.onChange([...currentFiles, ...newFiles]);
      }
    } catch (error) {
      setLoading(false);
      setLoadingImage(false);
      if (input.current) input.current.value = '';
      console.error(error);
      Errors.showMessage(error);
      return;
    }
    setLoading(false);
    setLoadingImage(false);
    if (input.current) input.current.value = '';
  };

  const doPreviewImage = (image) => {
    setImage({
      src: image.downloadUrl || image.publicUrl,
      alt: image.name,
    });
  };

  const doCloseImageModal = () => {
    setImage(null);
  };

  const {max, readonly} = props;

  //const [loadingReadImage, setLoadingReadImage] = useState<any>(null);

  const userRoles = useSelector(authSelectors.selectRoles);
  const hasAccessToIA = userRoles.includes('accesoIA');

  const readImages = async () => {
    const images = value();
    //console.log('ImagesUploader readImages value', images);
    for (let i = 0; i < images.length; i++) {
      const imageUrl = images[i]?.publicUrl;
      if (imageUrl) {
        images[i].AIContent = {loading: true};
      }
    }
    props.onChange(images);
    for (let i = 0; i < images.length; i++) {
      const imageUrl = images[i]?.publicUrl;
      if (imageUrl) {
        setLoading(true);
        //setLoadingReadImage(i);
        try {
          const imageRead = await OpenAIService.readImage({image_url: imageUrl});
          //console.log('ImagesUploader onChange imageRead', i, imageRead);
          const messageContent = imageRead?.message?.content;
          if (messageContent) {
            images[i].AIContent = JSON.parse(messageContent);
            props.onChange(images);
          }
        } catch (error) {
          const errorMessage = error?.response?.data;
          console.log('ImagesUploader AIService.readImage [error]', errorMessage);
          Message.error('Error reading image' + (errorMessage ? ': ' + errorMessage : ''));
          if (errorMessage) {
            images[i].AIContent = {error: errorMessage};
          } else {
            delete images[i].AIContent;
          }
          props.onChange(images);
        }
        setLoading(false);
        props.onReadImage && props.onReadImage();
        //setLoadingReadImage(null);
      }
    }
  };

  const uploadButton = (
    <>
      <Box sx={{mt: 1}} display="flex" flexDirection="row" alignItems="center" gap={4}>
        <Button
          component="label"
          disabled={loading}
          startIcon={<AddIcon />}
          variant="outlined"
          size="small"
          onClick={() => input.current.click()}>
          {i18n('fileUploader.upload')}
        </Button>
        {/* {loading && <CircularProgress style={{height: '24px', width: '24px'}} color="secondary" />} */}
        {props.AIContent && hasAccessToIA && (
          <Tooltip followCursor title="Leer imágenes con IA">
            <IconButton
              style={{height: '28px'}}
              disabled={loading || !value()?.length}
              size="small"
              onClick={() => {
                readImages();
              }}>
              <AutoAwesomeIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <input
        style={{display: 'none'}}
        disabled={loading || readonly}
        accept="image/*"
        type="file"
        onChange={handleChange}
        ref={input}
        multiple={max ? max > 1 : true}
      />
    </>
  );

  const images = value()?.filter(Boolean);

  return (
    <div>
      {readonly || (max && fileList().length >= max) ? null : uploadButton}

      {loadingImage || (images && images.length) ? (
        <Box style={{marginTop: '8px'}} display="flex" flexDirection={'row'} flexWrap="wrap" gap={1}>
          {images.map((item) => {
            const imgUrl = item.downloadUrl || item.publicUrl;
            const AIContent = item.AIContent;
            let imageDescriptions: any = null;
            if (AIContent) {
              imageDescriptions = AIContent.loading ? (
                <Box>
                  <Spinner />
                </Box>
              ) : (
                <table
                  style={{
                    width: '100%',
                    maxHeight: '100px',
                    height: '98px',
                    borderSpacing: 0,
                  }}>
                  {Object.keys(AIContent).map((key, index) => {
                    let content: any = AIContent[key];
                    if (Array.isArray(content)) {
                      //console.log('ImagesUploader content', content);
                      content = content.map((item, index) => {
                        return <div>{item}</div>;
                      });
                    }
                    return (
                      <tr key={key}>
                        <td style={{width: '80px', padding: '6px', borderRight: '1px solid lightgray'}}>
                          <Typography fontSize={'0.85em'}>{key}</Typography>
                        </td>
                        <td style={{padding: '6px', borderTop: index > 0 ? '1px solid lightgray' : undefined}}>
                          {
                            <Typography fontSize={'0.85em'} textAlign={'justify'}>
                              {content}
                            </Typography>
                          }
                        </td>
                      </tr>
                    );
                  })}
                </table>
              );
            }
            return (
              <Box
                display="flex"
                flexDirection={'row'}
                gap={1}
                width={images.some((item) => item.AIContent) ? '100%' : undefined}>
                <Box
                  //ts-ignore
                  sx={classes.card as any}
                  key={item.id}
                  onMouseEnter={() => setImageHover(item.id)}
                  onMouseLeave={() => setImageHover(null)}>
                  {imgUrl && (
                    <>
                      <Box display="flex">
                        <img alt={item.name} src={imgUrl} style={classes.image as any} />
                      </Box>

                      <Box style={{position: 'relative', zIndex: 1, height: '100%', bottom: '100px'} as any}>
                        <Box style={{...classes.imageButtons, display: imageHover === item.id ? 'block' : 'none'} as any}>
                          <IconButton onClick={() => doPreviewImage(item)}>
                            {/* ts-ignore */}
                            <Search sx={classes.imageButtonIcon as any} />
                          </IconButton>
                          {!readonly && (
                            <IconButton onClick={() => handleRemove(item.id)}>
                              {/* ts-ignore */}
                              <CloseIcon sx={classes.imageButtonIcon as any} />
                            </IconButton>
                          )}
                        </Box>
                      </Box>
                    </>
                  )}
                  {!imgUrl && (
                    <div>
                      <div>Imagen no encontrada.</div>
                      <div>{item.name || ''}</div>
                    </div>
                  )}
                </Box>
                {imageDescriptions && <Box style={{...classes.AIContentTable} as any}>{imageDescriptions}</Box>}
              </Box>
            );
          })}
          {loadingImage && (
            <Box display="flex" flexDirection={'row'}>
              <Box
                //ts-ignore
                sx={classes.card as any}>
                <>
                  <Box display="flex" style={{...classes.image, border: '1px solid lightgray'} as any}>
                    <Spinner />
                  </Box>
                </>
              </Box>
            </Box>
          )}
        </Box>
      ) : null}

      {image && <ImageModal src={image.src} alt={image.alt} onClose={() => doCloseImageModal()} />}
    </div>
  );
}

ImagesUploader.propTypes = {
  readonly: PropTypes.bool,
  max: PropTypes.number,
  storage: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onReadImage: PropTypes.func,
  AIContent: PropTypes.bool,
  onImageUpload: PropTypes.func,
};

export default ImagesUploader;
