import {Delete, Remove as RemoveIcon} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import MoveDownIcon from '@mui/icons-material/ArrowDownward';
import MoveUpIcon from '@mui/icons-material/ArrowUpward';
import {Box, IconButton, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow, Tooltip} from '@mui/material';
import {makeStyles} from '@mui/styles';
import clsx from 'clsx';
import _uniqBy from 'lodash/uniqBy';
import React, {useEffect, useState} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {useSelector} from 'react-redux';
import AsyncSelect from 'react-select/async';
import {i18n} from 'src/i18n';
import authSelectors from 'src/modules/auth/authSelectors';
import PermissionChecker from 'src/modules/auth/permissionChecker';
import Permissions from 'src/security/permissions';
import FormErrors from 'src/view/shared/form/formErrors';
import {
  components as materialUiComponents,
  styles as materialUiStyles,
} from 'src/view/shared/form/items/shared/reactSelectMaterialUi';
import TableCellCustom from 'src/view/shared/table/TableCellCustom';

const AutocompleteWithList = (props) => {
  const {
    disableMovingOptions,
    renderExtraButton,
    labelFn,
    label,
    name,
    hint,
    externalErrorMessage,
    required,
    disabled,
    defaultValue,
    minHeight,
    FormModal,
    modelName,
    simpleEdit,
    apiService,
    trashIcon,
  } = props;

  const currentTenant = useSelector(authSelectors.selectCurrentTenant);
  const currentUser = useSelector(authSelectors.selectCurrentUser);
  const hasPermissionToCreate = () =>
    new PermissionChecker(currentTenant, currentUser).match(Permissions.values[modelName + 'Create']);

  const AUTOCOMPLETE_SERVER_FETCH_SIZE = 50;

  const {
    control,
    setValue,
    register,
    formState: {touchedFields, isSubmitted, errors},
  } = useFormContext();

  // Sort Array by given order key
  const sortArrayByKey = (array, key = 'order') => array.sort((a, b) => a[key] - b[key]);

  const originalValue: any = useWatch({name, control, defaultValue: defaultValue || []});
  const rows = originalValue;
  // useEffect(() => {
  //   //console.log('%c⧭ originalValue changed, SET ROWS', 'color: #ff6600', originalValue);
  //   //setRows(originalValue);
  // }, [originalValue]);

  const [loading, setLoading] = useState(false);
  //const [rows, setRows] = useState<any>(originalValue);
  const hasRows = !!rows.length;

  // const fetchFn = (value?, limit?) => {
  //   return ModeloTareaService.listAutocomplete({filter: props.filter, value}, limit);
  // };

  const useStyles = makeStyles(materialUiStyles as any);

  const rowStyles = makeStyles(() => ({
    root: {
      '&:hover $clearHidden': {
        visibility: 'visible',
      },
    },
    clearHidden: {
      opacity: 0.5,
      '&:hover': {
        opacity: 1,
      },
    },
    hideButton: {
      visibility: 'hidden',
    },
  }));

  useEffect(() => {
    register(name);
    setValue(name, originalValue);
  }, []);

  const [inputValue, setInputValue] = useState('');

  const [openModal, setOpenModal] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const doCreateSuccess = (record) => {
    let insertAt = rows.length;
    if (modelName === 'subTarea') {
      insertAt = (record.posicion || 1) - 1;
      if (insertAt < 0) insertAt = 0;
    }
    rows.splice(insertAt, 0, record); // splice function mutates the original array
    handleChange(rows, {
      action: 'create',
      record: {
        id: record.id,
        index: insertAt,
      },
    });
    setOpenModal(false);
  };

  const handleChange = (value, action) => {
    setValue(name, value, {shouldValidate: true});
    props.onChange && props.onChange(value, action);
  };

  const moveUp = (index) => {
    let newIndex = index;
    if (index > 0) {
      const temp = rows[index];
      rows[index] = rows[index - 1];
      rows[index - 1] = temp;
      //setRows(rows);
      newIndex = index - 1;
    }
    handleChange(rows, {
      action: 'moveUp',
      record: {
        id: rows[index].id,
        index: newIndex,
      },
    });
  };

  const moveDown = (index) => {
    let newIndex = index;
    if (index < rows.length - 1) {
      const temp = rows[index];
      rows[index] = rows[index + 1];
      rows[index + 1] = temp;
      //setRows(rows);
      newIndex = index + 1;
    }
    handleChange(rows, {
      action: 'moveDown',
      record: {
        id: rows[index].id,
        index: newIndex,
      },
    });
  };

  const remove = (item) => {
    const newRows = rows.filter((row) => compoundKey(row) !== compoundKey(item));
    //setRows(newRows);
    handleChange(newRows, {
      action: 'remove',
      record: {
        id: item.id,
        compoundKey: compoundKey(item),
      },
    });
  };

  const compoundKey = (item, keys = props.compoundKeys || ['id']) => {
    if (!item) return null;
    return keys
      .filter((key) => key in item)
      .map((key) => item[key])
      .join('-');
  };

  const handleSelect = (value) => {
    const newRows = _uniqBy([...rows, value], (item) => compoundKey(item));
    //setRows(newRows);
    handleChange(newRows, {
      action: 'select',
      record: {
        id: value.id,
      },
    });
    setOpenMenu(false);
  };

  const handleEnterKey = (event) => {
    if (loading || loadingOptions || !simpleEdit || !inputValue || optionsLoaded?.length) return;
    switch (event.key) {
      case 'Enter':
        simpleCreate();
        event.preventDefault();
    }
  };

  const filterOptions = (item, input) => {
    if (item.data.fixed === true) return true;
    return !rows.find((row) => row.id === item.data.id) && labelFn(item.data)?.toLowerCase().includes(input.toLowerCase());
  };

  const handleBlur = () => {
    setOpenMenu(false);
  };

  const handleFocus = () => {
    //console.log('%c⧭ handleFocus', 'color: #cc0036', !loading);
    if (!loading) setOpenMenu(true);
  };

  // const handleClick = () => {
  //   handleFocus();
  // };

  const simpleCreate = async () => {
    if (!simpleEdit || !inputValue) return;
    setLoading(true);
    const recordData = {[simpleEdit]: inputValue};
    // if (modelName === 'subTarea') {
    //   recordData['posicion'] = rows.length + 1;
    // }
    const newRecord = await apiService.create(recordData);
    setLoading(false);
    handleSelect(newRecord);
    setInputValue('');
  };

  const hintOrLoading = loading ? i18n('autocomplete.loading') : hint;

  const errorMessage = FormErrors.errorMessage(name, errors, touchedFields, isSubmitted, externalErrorMessage);

  const [loadingOptions, setLoadingOptions] = useState(false);
  const [optionsLoaded, setOptionsLoaded] = useState<any>([]);
  const handleSearch = async (value) => {
    try {
      setOptionsLoaded([]);
      setLoadingOptions(true);
      const results = await apiService.listAutocomplete(value, AUTOCOMPLETE_SERVER_FETCH_SIZE);
      setOptionsLoaded(results);
      setLoadingOptions(false);
      //setOpenMenu(true);
      return results;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const marginOptions = {
    normal: {marginTop: '16px', marginBottom: '8px'},
    none: {marginTop: '0px', marginBottom: '0px'},
  };

  const controlStyles = {
    container: (provided) => ({
      ...provided,
      width: props.maxContent ? 'max-content' : '100%',
      minWidth: hasPermissionToCreate() && props.showCreate ? 'calc(100% - 40px)' : '100%',
      ...marginOptions[props.margin || 'normal'],
    }),
    control: (provided) => ({
      ...provided,
      borderColor: Boolean(errorMessage) ? 'red' : undefined,
    }),
    /** El menu portal solo afecta cuando el props menuPortalTarget={document.body} está activo */
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
      //zoom: props.menuZoom || 0.8,
    }),
  };

  if (props.fixedBase) {
    controlStyles['menuList'] = (base) => ({...base, zIndex: 9999});
  }

  const classes = useStyles();

  const renderSelect = () => {
    return (
      <div style={{display: 'flex', alignItems: 'center'}}>
        <AsyncSelect
          menuPortalTarget={props.menuPortalTarget}
          cacheOptions={props.cacheOptions || true}
          styles={controlStyles}
          classes={classes}
          inputId={name}
          TextFieldProps={{
            onChange: handleFocus,
            onClick: handleFocus,
            label,
            variant: 'outlined',
            fullWidth: true,
            error: Boolean(errorMessage),
            helperText: errorMessage || hint,
            size: 'small',
            InputLabelProps: {
              shrink: true,
            },
          }}
          components={materialUiComponents}
          defaultOptions={true}
          isMulti={false}
          inputValue={inputValue}
          menuIsOpen={!loading && !!openMenu}
          onInputChange={(newValue) => setInputValue(newValue)}
          loadOptions={handleSearch}
          filterOption={filterOptions}
          onChange={handleSelect}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onKeyDown={handleEnterKey}
          value={{}}
          isClearable={false}
          isLoading={loading}
          loadingMessage={() => i18n('autocomplete.loading')}
          noOptionsMessage={() => i18n('autocomplete.noOptions')}
        />
        {FormModal && props.showCreate && hasPermissionToCreate() ? (
          <IconButton
            style={{
              marginLeft: '0px',
              marginTop: '16px',
              marginBottom: '8px',
              flexShrink: 0,
            }}
            color="secondary"
            onClick={() => setOpenModal(true)}>
            <AddIcon />
          </IconButton>
        ) : null}
      </div>
    );
  };
  const itemById = (itemId) => rows.find((t) => t.id === itemId);

  const isString = (value) => {
    return typeof value === 'string' || value instanceof String;
  };

  const [rowIsActive, setRowActive] = useState<any>(null);

  const getActiveRow = () => {
    return props.activeRow || rowIsActive;
  };

  const sortFlag = props.sortFlag || undefined;

  // Custom sorting function
  const customSort = sortFlag
    ? (a, b) => {
        // Check if both objects have the "flag" key
        const hasFlagA = sortFlag in a;
        const hasFlagB = sortFlag in b;

        // Compare based on the presence of the "flag" key and its value
        if (hasFlagA && hasFlagB) {
          return b[sortFlag] - a[sortFlag]; // Sort by flag (true first)
        } else if (hasFlagA) {
          return -1; // "a" has flag, so it comes before "b"
        } else if (hasFlagB) {
          return 1; // "b" has flag, so it comes before "a"
        }

        // If neither has the "flag" key, maintain the original order
        return 0;
      }
    : undefined;

  const rowClasses = rowStyles();
  return (
    <div>
      {!disabled && renderSelect()}
      {disabled && label && <div>{label}</div>}
      <Box
        style={{
          display: 'block',
          width: '100%',
          minHeight: minHeight,
          overflowX: 'auto',
        }}>
        {loading && (
          <Box sx={{width: '100%'}}>
            <LinearProgress />
          </Box>
        )}
        <Table
          style={{
            borderRadius: '5px',
            border: '1px solid rgb(224, 224, 224)',
            borderCollapse: 'initial',
          }}>
          <TableHead>
            <TableRow>
              <TableCellCustom name={'order'} label={'⠀N°'} padding="checkbox" />
              <TableCellCustom name={'label'} label={label} />
              {props.extraColumns
                ? props.extraColumns.map((column) => (
                    <TableCellCustom key={column.name} name={column.name} label={column.label} />
                  ))
                : null}
              <TableCellCustom size="md" />
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {!loading && !hasRows && (
                <TableRow>
                  <TableCell
                    colSpan={100}
                    sx={{
                      borderBottom: '0px solid white',
                    }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}>
                      {i18n('table.noDataAdded')}
                    </div>
                  </TableCell>
                </TableRow>
              )}
              {hasRows &&
                rows.sort(customSort).map((row, index) => (
                  <TableRow
                    key={compoundKey(row)}
                    className={
                      rowClasses.root + ' ' + (getActiveRow() === compoundKey(row) ? 'highlightActive' : 'highlightOnHover')
                    }
                    onClick={() =>
                      props.onRowClick
                        ? props.onRowClick(row, index)
                        : setRowActive(rowIsActive === compoundKey(row) ? null : compoundKey(row))
                    }>
                    <TableCell padding="checkbox">
                      {'⠀'}
                      {index + 1}
                    </TableCell>
                    <TableCell>{labelFn ? labelFn(row) : row.label}</TableCell>
                    {props.extraColumns
                      ? props.extraColumns.map((column) => <TableCell key={column.name}>{column.render(row, index)}</TableCell>)
                      : //rows.findIndex(ogRow=>compoundKey(row) === compoundKey(row))
                        null}
                    <TableCell>
                      <Box display="flex" justifyContent="flex-end">
                        {!(disableMovingOptions || disabled) && (
                          <>
                            <Tooltip followCursor title={i18n('common.moveUp')}>
                              <IconButton onClick={() => moveUp(index)}>
                                <MoveUpIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip followCursor title={i18n('common.moveDown')}>
                              <IconButton onClick={() => moveDown(index)}>
                                <MoveDownIcon />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                        {renderExtraButton && renderExtraButton(row, index)}
                        {/* (<DrawerButton
                          drawerId={'DrawerModeloTareaViewPage'}
                          type={'icon'}
                          tooltipTitle={i18n('common.view')}
                          buttonTitle={i18n('common.view')}
                          buttonIcon={<SearchIcon />}
                          width={window.innerWidth * 0.55}
                          component={<ModeloTareaView record={row} drawer={true} />}
                        />) */}
                        {!disabled && (
                          <Tooltip followCursor enterNextDelay={400} enterDelay={600} title={i18n('common.remove2')}>
                            <IconButton
                              className={clsx(rowClasses.hideButton, rowClasses.clearHidden)}
                              color="secondary"
                              onClick={($event) => {
                                $event.stopPropagation();
                                remove(row);
                              }}>
                              {trashIcon ? <Delete fontSize="small" /> : <RemoveIcon fontSize="small" />}
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </>
          </TableBody>
        </Table>
      </Box>
      {openModal && FormModal && <FormModal onClose={() => setOpenModal(false)} onSuccess={doCreateSuccess} />}
    </div>
  );
};

export default AutocompleteWithList;
