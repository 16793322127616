import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';
import {i18n} from 'src/i18n';
import actions from 'src/modules/gestorDocumental/form/gestorDocumentalFormActions';
import selectors from 'src/modules/gestorDocumental/form/gestorDocumentalFormSelectors';
import {getHistory} from 'src/modules/store';
import GestorDocumentalForm from 'src/view/gestorDocumental/form/GestorDocumentalForm';
import ContentWrapper from 'src/view/layout/styles/ContentWrapper';
import Breadcrumb from 'src/view/shared/Breadcrumb';
import Spinner from 'src/view/shared/Spinner';
import PageTitle from 'src/view/shared/styles/PageTitle';

function GestorDocumentalFormPage(props) {
  const [dispatched, setDispatched] = useState(false);
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const initLoading = useSelector(selectors.selectInitLoading);
  const saveLoading = useSelector(selectors.selectSaveLoading);
  const record = useSelector(selectors.selectRecord);

  const isEditing = Boolean(props.record?.id || match.params.id);

  const title = isEditing ? i18n('entities.gestorDocumental.edit.title') : i18n('entities.gestorDocumental.new.title');

  useEffect(() => {
    dispatch(actions.doInit(props.record?.id || match.params.id));
    setDispatched(true);
  }, [dispatch, match.params.id]);

  const doSubmit = async (id, data) => {
    if (isEditing) {
      const updatedItem = await dispatch(actions.doUpdate(id, data));
      if (props.onUpdate) {
        props.onUpdate(updatedItem);
      }
    } else {
      const createdItem = await dispatch(actions.doCreate(data));
      if (props.onCreate && createdItem) {
        props.onCreate(createdItem);
      }
    }
  };

  return (
    <>
      {!props.drawer && (
        <Breadcrumb
          items={[[i18n('dashboard.menu'), '/'], [i18n('entities.gestorDocumental.menu'), '/gestor-documental'], [title]]}
        />
      )}

      <ContentWrapper>
        {!props.disabled && <PageTitle>{title}</PageTitle>}
        {props.disabled && <PageTitle>Ver Documento</PageTitle>}

        {initLoading && <Spinner />}

        {dispatched && !initLoading && (
          <GestorDocumentalForm
            disabled={props.disabled}
            drawer={props.drawer}
            saveLoading={saveLoading}
            initLoading={initLoading}
            record={record}
            isEditing={isEditing}
            onSubmit={doSubmit}
            onCancel={() => getHistory().push('/gestor-documental')}
          />
        )}
      </ContentWrapper>
    </>
  );
}

export default GestorDocumentalFormPage;
