import {
  Avatar,
  Chip,
  Tooltip,
} from '@mui/material';
import React from 'react';
import { getHistory } from 'src/modules/store';
import { useSelector } from 'react-redux';
import activoSelectors from 'src/modules/activo/activoSelectors';

function ChipRegistroView(props) {
  const { record, type, size } = props;

  const hasPermissionToRead = useSelector(
    activoSelectors.selectPermissionToRead,
  );

  const redirectTo = () => {
    if (hasPermissionToRead) {
      getHistory().push(`/registro/` + record.id);
    }
  };

  const fullLabel = record?.descripcion;
  // add ellipsis if label is too long
  const label = fullLabel?.length > 25 ? fullLabel?.substr(0, 25) + '...' : fullLabel;

  if (type === 'chip') {
    return (
      <Tooltip followCursor title={fullLabel}>
        <Chip
          label={label}
          onClick={() => console.log('click')}
          variant="outlined"
          color="primary"
        />
      </Tooltip>
    );
  }

  if (type === 'avatar') {
    return (
      <Tooltip followCursor title={fullLabel}>
        <Avatar
          onClick={() => console.log('click')}
          style={{ width: size, height: size }}
          src={''}
          alt={fullLabel}
        />
      </Tooltip>
    );
  }

  return <div></div>;
}

export default ChipRegistroView;
