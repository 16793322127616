import list from 'src/modules/solicitud/list/solicitudListReducers';
import form from 'src/modules/solicitud/form/solicitudFormReducers';
import view from 'src/modules/solicitud/view/solicitudViewReducers';
import destroy from 'src/modules/solicitud/destroy/solicitudDestroyReducers';
import importerReducer from 'src/modules/solicitud/importer/solicitudImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
