import list from 'src/modules/clasificacionTercero/list/clasificacionTerceroListReducers';
import form from 'src/modules/clasificacionTercero/form/clasificacionTerceroFormReducers';
import view from 'src/modules/clasificacionTercero/view/clasificacionTerceroViewReducers';
import destroy from 'src/modules/clasificacionTercero/destroy/clasificacionTerceroDestroyReducers';
import importerReducer from 'src/modules/clasificacionTercero/importer/clasificacionTerceroImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
