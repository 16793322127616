import {ArrowLeft, ArrowRight, Close} from '@mui/icons-material';
import {IconButton, InputAdornment} from '@mui/material';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import moment from 'moment-timezone';
import 'moment/locale/es';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import FormErrors from 'src/view/shared/form/formErrors';
import {DisabledText} from './shared/formItemWrappers';

export function DatePickerFormItem(props) {
  const {
    label,
    name,
    hint,
    placeholder,
    autoFocus,
    autoComplete,
    externalErrorMessage,
    required,
    showTime,
    timeOnly,
    disabled,
    minDate,
    maxDate,
    resetValueFormItemName,
    style,
    defaultValue,
    views,
    preChangeFn,
    isEditing,
    monthNavigation,
    fullWidth,
    openTo,
  } = props;

  const {
    register,
    formState: {touchedFields, isSubmitted, errors},
    setValue,
    getValues,
    control,
  } = useFormContext();

  const ogValue = getValues(name);
  const defaultValueComp = ogValue || (!isEditing ? defaultValue || null : null);
  const defaultValueDate = defaultValueComp ? moment(defaultValueComp) : null;
  const watchName: any = useWatch({name, control, defaultValue: defaultValueDate});
  //console.log('%c⧭ DatePickerFormItem', 'color: #00e600', {name, watchName});

  useEffect(() => {
    register(name);
    if (defaultValueDate) setValue(name, defaultValueDate);
  }, []);

  const errorMessage = FormErrors.errorMessage(name, errors, touchedFields, isSubmitted, externalErrorMessage);

  const DateTimePickerComponent = timeOnly ? TimePicker : showTime ? DateTimePicker : DatePicker;

  const format = props.format ? props.format : timeOnly ? 'HH:mm' : showTime ? 'DD-MM-YYYY HH:mm' : 'DD-MM-YYYY';
  const momentFormat = props.momentFormat
    ? props.momentFormat
    : timeOnly
    ? 'HH:mm'
    : showTime
    ? 'DD-MM-YYYY HH:mm'
    : 'DD-MM-YYYY';

  let otherProps: any = {};
  if (timeOnly) {
    otherProps.ampm = false;
  }

  if (disabled) {
    return <DisabledText label={label} value={watchName ? moment(watchName).format(momentFormat) : null} />;
  }

  const handleChange = (value) => {
    if (preChangeFn) {
      value = preChangeFn(value);
    }
    setValue(name, value, {shouldValidate: true});
    if (resetValueFormItemName) {
      setValue(resetValueFormItemName, value, {
        shouldValidate: true,
      });
    }
    props.onChange && props.onChange(value);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateTimePickerComponent
        readOnly={props.readOnly}
        slotProps={{
          textField: {
            fullWidth: fullWidth,
            InputLabelProps: {shrink: true},
            InputProps: {
              ...(monthNavigation
                ? {
                    startAdornment: (
                      <InputAdornment position="start" sx={{marginRight: 0}}>
                        <IconButton
                          disabled={!watchName}
                          style={{borderRadius: 50}}
                          onClick={($event) => {
                            $event.stopPropagation();
                            handleChange(moment(watchName).subtract(1, 'month'));
                          }}
                          // onMouseDown={() => handleChange(moment(watchName).subtract(1, 'month'))}
                          size="small">
                          <ArrowLeft fontSize="small" style={{color: 'gray'}} />
                        </IconButton>
                        <IconButton
                          disabled={!watchName}
                          style={{borderRadius: 50}}
                          onClick={($event) => {
                            $event.stopPropagation();
                            handleChange(moment(watchName).add(1, 'month'));
                          }}
                          // onMouseDown={() => handleChange(moment(watchName).add(1, 'month'))}
                          size="small">
                          <ArrowRight fontSize="small" style={{color: 'gray'}} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }
                : {}),
              ...(props.clearable && watchName
                ? {
                    endAdornment: (
                      <InputAdornment position="end" sx={{marginLeft: 0}}>
                        <IconButton
                          style={{borderRadius: 50}}
                          onClick={($event) => {
                            $event.stopPropagation();
                            handleChange(null);
                          }}
                          onMouseDown={() => handleChange(null)}
                          size="small">
                          <Close fontSize="small" style={{color: 'gray'}} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }
                : {}),
            },
          },
        }}
        className="MuiDatePickerFormItem"
        sx={{
          '& .MuiOutlinedInput-root': {
            padding: '8.5px 14px',
          },
          '& .MuiIconButton-root': {
            padding: '2px',
          },
          '& .MuiInputLabel-root': {
            top: '-6px',
          },
        }}
        style={style}
        views={views}
        openTo={openTo}
        minDate={minDate ? moment(minDate) : undefined}
        maxDate={maxDate ? moment(maxDate) : undefined}
        format={format}
        id={name}
        name={name}
        label={label}
        required={required}
        margin={props.margin || 'normal'}
        fullWidth
        inputVariant="outlined"
        size="small"
        onChange={handleChange}
        onBlur={(event) => {
          props.onBlur && props.onBlur(event);
        }}
        value={watchName ? moment(watchName) : null}
        placeholder={placeholder || undefined}
        autoFocus={autoFocus || undefined}
        autoComplete={autoComplete || undefined}
        error={Boolean(errorMessage)}
        helperText={errorMessage || hint}
        autoOk
        {...otherProps}
      />
    </LocalizationProvider>
  );
}

DatePickerFormItem.defaultProps = {
  required: false,
};

DatePickerFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  hint: PropTypes.string,
  autoFocus: PropTypes.bool,
  size: PropTypes.string,
  prefix: PropTypes.string,
  placeholder: PropTypes.string,
  externalErrorMessage: PropTypes.string,
  showTime: PropTypes.bool,
  disabled: PropTypes.bool,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  resetValueFormItemName: PropTypes.string,
  style: PropTypes.any,
  timeOnly: PropTypes.any,
  onChange: PropTypes.func,
  defaultValue: PropTypes.any,
  format: PropTypes.string,
  momentFormat: PropTypes.string,
  clearable: PropTypes.bool,
  views: PropTypes.array,
  openTo: PropTypes.string,
  preChangeFn: PropTypes.func,
  isEditing: PropTypes.bool,
  readOnly: PropTypes.bool,
  monthNavigation: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default DatePickerFormItem;
