import {i18n} from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.activo.fields.id'),
  },
  // {
  //   name: 'fotos',
  //   label: i18n('entities.activo.fields.fotos'),
  //   render: exporterRenders.filesOrImages(),
  // },
  // {
  //   name: 'habilitado',
  //   label: i18n('entities.activo.fields.habilitado'),
  //   render: exporterRenders.boolean(),
  // },
  // {
  //   name: 'activoPrincipal',
  //   label: i18n('entities.activo.fields.activoPrincipal'),
  //   render: exporterRenders.relationToOne(),
  // },
  {
    name: 'nombre',
    label: i18n('entities.activo.fields.nombre'),
  },
  // {
  //   name: 'localizacion',
  //   label: i18n('entities.activo.fields.localizacion'),
  //   render: exporterRenders.relationToOne(),
  // },
  {
    name: 'codigo',
    label: i18n('entities.activo.fields.codigo'),
  },
  {
    name: 'grupo',
    label: i18n('entities.activo.fields.grupo'),
  },
  // {
  //   name: 'direccion',
  //   label: i18n('entities.activo.fields.direccion'),
  // },
  // {
  //   name: 'ciudad',
  //   label: i18n('entities.activo.fields.ciudad'),
  // },
  // {
  //   name: 'region',
  //   label: i18n('entities.activo.fields.region'),
  // },
  // {
  //   name: 'pais',
  //   label: i18n('entities.activo.fields.pais'),
  // },
  // {
  //   name: 'codigoArea',
  //   label: i18n('entities.activo.fields.codigoArea'),
  // },
  // {
  //   name: 'prioridad',
  //   label: i18n('entities.activo.fields.prioridad'),
  // },
  // {
  //   name: 'ubicacionGoogle',
  //   label: i18n('entities.activo.fields.ubicacionGoogle'),
  // },
  // {
  //   name: 'latitud',
  //   label: i18n('entities.activo.fields.latitud'),
  //   render: exporterRenders.decimal(),
  // },
  // {
  //   name: 'longitud',
  //   label: i18n('entities.activo.fields.longitud'),
  //   render: exporterRenders.decimal(),
  // },
  // {
  //   name: 'tipoActivo',
  //   label: i18n('entities.activo.fields.tipoActivo'),
  //   render: exporterRenders.relationToMany(),
  // },
  // {
  //   name: 'codigoBarra',
  //   label: i18n('entities.activo.fields.codigoBarra'),
  // },
  {
    name: 'certificated',
    label: i18n('entities.activo.fields.certificated'),
  },
  {
    name: 'patente',
    label: i18n('entities.activo.fields.patente'),
  },
  // {
  //   name: 'centrosCostos',
  //   label: i18n('entities.activo.fields.centrosCostos'),
  //   render: exporterRenders.relationToMany(),
  // },
  {
    name: 'tercerosRelacionados',
    label: i18n('entities.activo.fields.tercerosRelacionados'),
    render: exporterRenders.relationToMany(),
  },
  // {
  //   name: 'repuestos',
  //   label: i18n('entities.activo.fields.repuestos'),
  //   render: exporterRenders.relationToMany(),
  // },
  {
    name: 'fabricante',
    label: i18n('entities.activo.fields.fabricante'),
  },
  {
    name: 'marca',
    label: i18n('entities.activo.fields.marca'),
  },
  {
    name: 'modelo',
    label: i18n('entities.activo.fields.modelo'),
  },
  {
    name: 'numeroSerial',
    label: i18n('entities.activo.fields.numeroSerial'),
  },
  // {
  //   name: 'fechaCompra',
  //   label: i18n('entities.activo.fields.fechaCompra'),
  // },
  // {
  //   name: 'horasUsoPromedioDiario',
  //   label: i18n('entities.activo.fields.horasUsoPromedioDiario'),
  // },
  // {
  //   name: 'visibilidadHorasUso',
  //   label: i18n('entities.activo.fields.visibilidadHorasUso'),
  //   render: exporterRenders.boolean(),
  // },
  // {
  //   name: 'tipoDepreciacion',
  //   label: i18n('entities.activo.fields.tipoDepreciacion'),
  // },
  // {
  //   name: 'fechaInicioOperaciones',
  //   label: i18n('entities.activo.fields.fechaInicioOperaciones'),
  // },
  // {
  //   name: 'depreciacionAnual',
  //   label: i18n('entities.activo.fields.depreciacionAnual'),
  // },
  // {
  //   name: 'vidaUtil',
  //   label: i18n('entities.activo.fields.vidaUtil'),
  // },
  // {
  //   name: 'costoCompra',
  //   label: i18n('entities.activo.fields.costoCompra'),
  // },
  // {
  //   name: 'valorSustitucion',
  //   label: i18n('entities.activo.fields.valorSustitucion'),
  // },
  // {
  //   name: 'valorSalvamiento',
  //   label: i18n('entities.activo.fields.valorSalvamiento'),
  // },
  // {
  //   name: 'depreciacionTotalHoy',
  //   label: i18n('entities.activo.fields.depreciacionTotalHoy'),
  // },
  // {
  //   name: 'valorLibroHoy',
  //   label: i18n('entities.activo.fields.valorLibroHoy'),
  // },
  // {
  //   name: 'fechaExpiracion',
  //   label: i18n('entities.activo.fields.fechaExpiracion'),
  // },
  {
    name: 'gestionDocumental',
    label: i18n('entities.activo.fields.gestionDocumental'),
    render: exporterRenders.relationToMany(),
  },
  // {
  //   name: 'unidadMedida',
  //   label: i18n('entities.activo.fields.unidadMedida'),
  //   render: exporterRenders.relationToOne(),
  // },
  // {
  //   name: 'numeroParte',
  //   label: i18n('entities.activo.fields.numeroParte'),
  // },
  // {
  //   name: 'peso',
  //   label: i18n('entities.activo.fields.peso'),
  //   render: exporterRenders.decimal(),
  // },
  // {
  //   name: 'tiempoEspera',
  //   label: i18n('entities.activo.fields.tiempoEspera'),
  // },
  // {
  //   name: 'controladoPorSerial',
  //   label: i18n('entities.activo.fields.controladoPorSerial'),
  //   render: exporterRenders.boolean(),
  // },
  // {
  //   name: 'equivalencias',
  //   label: i18n('entities.activo.fields.equivalencias'),
  //   render: exporterRenders.relationToMany(),
  // },
  // {
  //   name: 'version',
  //   label: i18n('entities.activo.fields.version'),
  //   render: exporterRenders.decimal(),
  // },
  // {
  //   name: 'direccionWeb',
  //   label: i18n('entities.activo.fields.direccionWeb'),
  // },
  {
    name: 'responsablesActivo',
    label: i18n('entities.activo.fields.responsablesActivo'),
    render: exporterRenders.relationToMany(),
  },
  // {
  //   name: 'responsables',
  //   label: i18n('entities.activo.fields.responsables'),
  //   render: exporterRenders.relationToMany(),
  // },
  // {
  //   name: 'estado',
  //   label: i18n('entities.activo.fields.estado'),
  // },
  // {
  //   name: 'ubicacionCiclos',
  //   label: i18n('entities.activo.fields.ubicacionCiclos'),
  //   render: exporterRenders.relationToMany(),
  // },
  // {
  //   name: 'cantidadCiclosRecicla',
  //   label: i18n('entities.activo.fields.cantidadCiclosRecicla'),
  // },
  // {
  //   name: 'documentos',
  //   label: i18n('entities.activo.fields.documentos'),
  //   render: exporterRenders.filesOrImages(),
  // },
  // {
  //   name: 'notas',
  //   label: i18n('entities.activo.fields.notas'),
  //   render: exporterRenders.relationToMany(),
  // },
  // {
  //   name: 'mantenimientoPorHorometro',
  //   label: i18n('entities.activo.fields.mantenimientoPorHorometro'),
  // },
  // {
  //   name: 'mantenimientoPorKilometros',
  //   label: i18n('entities.activo.fields.mantenimientoPorKilometros'),
  // },
  // {
  //   name: 'ancho',
  //   label: i18n('entities.activo.fields.ancho'),
  //   render: exporterRenders.decimal(),
  // },
  // {
  //   name: 'alto',
  //   label: i18n('entities.activo.fields.alto'),
  //   render: exporterRenders.decimal(),
  // },
  {
    name: 'createdAt',
    label: i18n('entities.activo.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.activo.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
