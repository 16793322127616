import {ThemeProvider as MuiThemeProvider} from '@mui/material';
//import {ThemeProvider as StylesThemeProvider} from '@mui/styles';
import React, {useState} from 'react';
import {CustomTheme} from './schemes/CustomTheme';
import {DarkSpacesTheme} from './schemes/DarkSpacesTheme';
import {GreenFieldsTheme} from './schemes/GreenFieldsTheme';
import {GreyGooseTheme} from './schemes/GreyGooseTheme';
import {LightBloomTheme} from './schemes/LightBloomTheme';
import {NebulaFighterTheme} from './schemes/NebulaFighterTheme';
import {PureLightTheme} from './schemes/PureLightTheme';
import {PurpleFlowTheme} from './schemes/PurpleFlowTheme';
//import { createMuiTheme } from '@material-ui/core';

const setThemeName = (theme: string) => {
  localStorage.setItem('appTheme', theme);
};

const themeMap: {[key: string]: any} = {
  PureLightTheme,
  LightBloomTheme,
  GreyGooseTheme,
  PurpleFlowTheme,
  NebulaFighterTheme,
  DarkSpacesTheme,
  GreenFieldsTheme,
  CustomTheme,
};

export const ThemeContext = React.createContext(setThemeName);

const ThemeProvider: React.FC = (props) => {
  // State to hold the selected theme name
  const [themeName, _setThemeName] = useState('CustomTheme');

  // Retrieve the theme object by theme name
  const theme = themeMap[themeName];
  //const theme = createMuiTheme();

  return (
    <ThemeContext.Provider value={_setThemeName}>
      {/* //<StylesThemeProvider theme={theme}> */}
      <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
      {/* //</StylesThemeProvider> */}
    </ThemeContext.Provider>
  );
};
export default ThemeProvider;
