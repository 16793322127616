import list from 'src/modules/mensajeInterno/list/mensajeInternoListReducers';
import form from 'src/modules/mensajeInterno/form/mensajeInternoFormReducers';
import view from 'src/modules/mensajeInterno/view/mensajeInternoViewReducers';
import destroy from 'src/modules/mensajeInterno/destroy/mensajeInternoDestroyReducers';
import importerReducer from 'src/modules/mensajeInterno/importer/mensajeInternoImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
