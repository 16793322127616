import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import turnoLaboralEnumerators from 'src/modules/turnoLaboral/turnoLaboralEnumerators';

export default [
  {
    name: 'descripcion',
    label: i18n('entities.turnoLaboral.fields.descripcion'),
    schema: schemas.string(
      i18n('entities.turnoLaboral.fields.descripcion'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'empleados',
    label: i18n('entities.turnoLaboral.fields.empleados'),
    schema: schemas.relationToMany(
      i18n('entities.turnoLaboral.fields.empleados'),
      {},
    ),
  },
  {
    name: 'dias',
    label: i18n('entities.turnoLaboral.fields.dias'),
    schema: schemas.stringArray(
      i18n('entities.turnoLaboral.fields.dias'),
      {},
    ),
  },
  {
    name: 'turno',
    label: i18n('entities.turnoLaboral.fields.turno'),
    schema: schemas.enumerator(
      i18n('entities.turnoLaboral.fields.turno'),
      {
        "required": true,
        "options": turnoLaboralEnumerators.turno
      },
    ),
  },
  {
    name: 'horaInicio',
    label: i18n('entities.turnoLaboral.fields.horaInicio'),
    schema: schemas.integer(
      i18n('entities.turnoLaboral.fields.horaInicio'),
      {},
    ),
  },
  {
    name: 'horaFinalizacion',
    label: i18n('entities.turnoLaboral.fields.horaFinalizacion'),
    schema: schemas.integer(
      i18n('entities.turnoLaboral.fields.horaFinalizacion'),
      {},
    ),
  },
  {
    name: 'costoHora',
    label: i18n('entities.turnoLaboral.fields.costoHora'),
    schema: schemas.integer(
      i18n('entities.turnoLaboral.fields.costoHora'),
      {},
    ),
  },
];