import { yupResolver } from '@hookform/resolvers/yup';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Box, Button, Grid, Typography } from '@mui/material';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { i18n } from 'src/i18n';
import planificadorTareaEnumerators from 'src/modules/planificadorTarea/planificadorTareaEnumerators';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import { getHistory } from 'src/modules/store';
import ActivoAutocompleteFormItem from 'src/view/activo/autocomplete/ActivoAutocompleteFormItem';
import PlanificacionAutocompleteFormItem from 'src/view/planificacion/autocomplete/PlanificacionAutocompleteFormItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import RadioFormItem from 'src/view/shared/form/items/RadioFormItem';
import Message from 'src/view/shared/message';
import FormWrapper from 'src/view/shared/styles/FormWrapper';
import * as yup from 'yup';
import PlanificadorMultiTareas from './PlanificadorMultiTareas';

import { SaveOutlined, SendOutlined } from '@mui/icons-material';

import { Alert, AlertTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import authSelectors from 'src/modules/auth/authSelectors';
import { customButtonStyle } from 'src/theme/schemes/CustomTheme';
import RegistroAutocompleteFormItem from 'src/view/registro/autocomplete/RegistroAutocompleteFormItem';
import ConfirmModal from 'src/view/shared/modals/ConfirmModal';
import UbicacionAutocompleteFormItem from 'src/view/ubicacion/autocomplete/UbicacionAutocompleteFormItem';
import UserListItem from 'src/view/user/list/UserListItem';
const schema = yup.object().shape({
  nombre: yupFormSchemas.string(i18n('entities.planificadorTarea.fields.nombre'), {
    required: true,
  }),
  programada: yupFormSchemas.boolean(i18n('entities.planificadorTarea.fields.programada'), {}),

  //Stem
  activo: yupFormSchemas.relationToOne(i18n('entities.planificadorTarea.fields.activo'), {}),
  activos: yupFormSchemas.relationToMany(i18n('entities.activo.label'), {}),

  incidentes: yupFormSchemas.relationToMany(i18n('entities.registro.label'), {}),

  clasificacion: yupFormSchemas.enumerator(i18n('entities.planificadorTarea.fields.clasificacion'), {
    required: true,
    options: planificadorTareaEnumerators.clasificacion,
  }),

  programacionTareas: yupFormSchemas.generic(i18n('entities.planificadorTarea.fields.programacionTareas')),
  planificacion: yupFormSchemas.relationToOne(i18n('entities.planificadorTarea.fields.planificacion'), {
    required: true,
  }),
  asignarHoraInicio: yupFormSchemas.boolean(i18n('entities.planificadorTarea.fields.asignarHoraInicio'), {}),
  tiempoEstimadoDefault: yupFormSchemas.integer(i18n('entities.planificadorTarea.fields.tiempoEstimadoDefault'), {}),
  locations: yupFormSchemas.relationToMany(i18n('common.locations'), {}),
});

const useStyles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

// combinar una fecha con una hora de moment
const combineDateAndTime = (date, time) => {
  if (!date || !time) return null;
  time = moment(time);
  return date.set({
    hour: time.hour(),
    minute: time.minute(),
    second: time.second(),
    millisecond: time.millisecond(),
  });
};
function PlanificadorTareaForm(props) {
  const tenantUser = useSelector(authSelectors.selectTenantUser);
  let defaultLocations = tenantUser?.perfilEmpleado?.ubicacion;
  // console.log('%c⧭ defaultLocations', 'color: #d90000', defaultLocations);

  const [confirm, setConfirm] = useState(false);

  let fromPlanificacion = props.planificacion;

  const [initialValues] = useState(() => {
    const record = props.record || {};

    let planificacionNombre = null;
    if (props.planificacion) {
      planificacionNombre = props.planificacion?.nombre;
      // si viene de la planificacion, debemos setear las ubicaciones por defecto desde la planificacion
      if (props.planificacion.locations?.length) defaultLocations = props.planificacion.locations;
    }
    let defaultRootLocation = defaultLocations?.length > 0 ? defaultLocations[0]?.ubicacionAsociada : null;
    // console.log('%c⧭ defaultRootLocation', 'color: #917399', defaultRootLocation);
    //console.log('%c⧭ PlanificadorTareaForm', 'color: #e57373', {rowsSelected: props.rowsSelected});

    const defaultTiempoEstimado = record.tiempoEstimadoDefault || 60;
    const programacionTareas =
      props.rowsSelected?.tareas.map((raw) => {
        const fechaEstimadaInicio = moment(fromPlanificacion?.fechaEstimadaInicio || new Date()).startOf('day');
        const today = moment().startOf('day');
        //const fechaEstimadaFin = fromPlanificacion?.fechaEstimadaFin;
        const horaInicio = combineDateAndTime(fechaEstimadaInicio, new Date());
        const fechaEjecucion = today.isAfter(fechaEstimadaInicio) ? today : fechaEstimadaInicio;
        return {
          tarea: {...raw},
          dirigidoA: 'Ejecutor',
          fechaEjecucion: fechaEjecucion.toDate(),
          tipoTiempoLimite: 'days',
          tiempoLimite: 0,
          // horaInicio: horaInicio.toDate(),
          // tiempoEstimado: defaultTiempoEstimado,
          // horaFin: horaInicio.add(defaultTiempoEstimado, 'minutes').toDate(),
        };
      }) ||
      record.programacionTareas ||
      [];

    //console.log('%c⧭ ', 'color: #5200cc', {programacionTareas});
    if (false && fromPlanificacion) {
      // recorrer la programacion y asignar inicio de los sucesores/predecesores
      programacionTareas.forEach((programacionTarea, index) => {
        const successors = programacionTarea.tarea?.successors || [];
        //console.log('%c⧭', 'color: #86bf60', {successors});
        successors.forEach((successor) => {
          const successorIndex = programacionTareas.findIndex((programacionTarea) => programacionTarea.tarea.id === successor);
          //console.log('%c⧭', 'color: #86bf60', {successorIndex});
          if (successorIndex > index) {
            //console.log('%c⧭ successorIndex > index', 'color: #40fff2', {programacionTarea, index, successor, successorIndex});
            programacionTareas[successorIndex].horaInicio = programacionTareas[index].horaFin;
            programacionTareas[successorIndex].tiempoEstimado = defaultTiempoEstimado;
            programacionTareas[successorIndex].horaFin = new Date(
              programacionTareas[index].horaFin.getTime() + defaultTiempoEstimado * 60000,
            );
          }
        });
      });
      // debemos volver a recorrer para el caso de predecesores que estuvieran ordenados después de su sucesor
      programacionTareas.forEach((programacionTarea, index) => {
        const predecessors = (programacionTarea.tarea?.predecessors || []).map((p) =>
          programacionTareas.find((pt) => pt.tarea.id === p),
        );
        // encontrar el predecesor con horaFin mayor
        const maxPredecessor = predecessors.reduce(
          (max, predecessor) => (predecessor.horaFin > max.horaFin ? predecessor : max),
          {horaFin: new Date(0)},
        );
        if (maxPredecessor.tarea?.id) {
          programacionTareas[index].horaInicio = maxPredecessor.horaFin;
          programacionTareas[index].tiempoEstimado = defaultTiempoEstimado;
          programacionTareas[index].horaFin = new Date(maxPredecessor.horaFin.getTime() + defaultTiempoEstimado * 60000);
        }
      });
    }

    return {
      createdBy: record.createdBy,
      firmas: record.firmas,
      estado: record.estado,
      planificacion: props.planificacion || record.planificacion || null,
      nombre: planificacionNombre || record.nombre,
      programada: record.programada,

      activos: record.activos,
      activo: record.activo,
      incidentes: record.incidentes,
      clasificacion: record.clasificacion || 'operativa',

      programacionTareas: programacionTareas,
      asignarHoraInicio: record.asignarHoraInicio,
      tiempoEstimadoDefault: defaultTiempoEstimado,
      locations: record.locations || defaultLocations || [],
      rootLocation: record.rootLocation || defaultRootLocation || null,
    };
  });

  useEffect(() => {
    if (props.disabled) {
      return;
    }

    if (props.isEditing && props.record?.estado !== 'Pendiente' && props.record?.estado !== 'Rechazada') {
      getHistory().push('/planificador-tarea');
      Message.error('La planificación ya fue enviada...');
    }
  }, []);

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
    shouldUnregister: false,
  });

  const nombre = useWatch({name: 'nombre', control: form.control, defaultValue: initialValues?.nombre});
  const estado = useWatch({name: 'estado', control: form.control, defaultValue: initialValues?.estado});
  const firmas = useWatch({name: 'firmas', control: form.control, defaultValue: initialValues?.firmas});
  const createdBy = useWatch({name: 'createdBy', control: form.control, defaultValue: initialValues?.createdBy});
  const clasificacion = useWatch({name: 'clasificacion', control: form.control, defaultValue: initialValues?.clasificacion});
  const planificacion = useWatch({name: 'planificacion', control: form.control, defaultValue: initialValues?.planificacion});

  const onSubmit = (values) => {
    setConfirm(false);

    if (!checkProgramacion(values)) {
      return;
    }

    values.estado = 'Enviada';

    if (!values['programacionTareas']) {
      values['programacionTareas'] = [];
    }

    props.onSubmit(props.record?.id, values);
  };

  const checkProgramacion = (values) => {
    let checkProgramacion = true;

    values.programacionTareas.forEach((row, index) => {
      // si dirigidoA es Ejecutor y no hay ejecutor asignado
      if (row.dirigidoA === 'Ejecutor' && !row.ejecutores?.length) {
        checkProgramacion = false;
        Message.error(`Ejecutor es requerido en la Programación Nº ${index + 1}`);
        return;
      }

      if (!row.tiempoLimite && row.tiempoLimite !== 0) {
        checkProgramacion = false;
        Message.error(`Tiempo Limite es requerido en la Programación Nº ${index + 1}`);
        return;
      }

      if (row.programar === true) {
        if (row.tipoPeriodicidad === 'weeks' && !row.daysOfWeek?.length) {
          checkProgramacion = false;
          Message.error(`Días de la semana requeridos en la Programación Nº ${index + 1}`);
          return;
        }

        if (moment(row.inicioProgramacion).isAfter(row.finProgramacion)) {
          checkProgramacion = false;
          Message.error(
            `La Fecha de Inicio de Programación no puede ser mayor a la Fecha de Fin de Programación en la Programación Nº ${
              index + 1
            }`,
          );
          return;
        }

        if (
          !row.inicioProgramacion ||
          !row.finProgramacion ||
          !row.tipoPeriodicidad ||
          !row.periodicidad ||
          row.periodicidad < 0
        ) {
          checkProgramacion = false;
          Message.error(`Todos los campos son requeridos al seleccionar "Programar" en la Programación Nº ${index + 1}`);
          return;
        }
      }
      if (row.asignarHoraInicio) {
        // if (!row.tiempoEstimado || row.tiempoEstimado < 0) {
        //   checkProgramacion = false;
        //   Message.error(`Tiempo Estimado es requerido en la Programación Nº ${index + 1}`);
        //   return;
        // }
        if (moment(row.horaInicio).isAfter(row.horaFin)) {
          checkProgramacion = false;
          Message.error(
            `La Fecha de Inicio de Programación no puede ser mayor a la Fecha de Fin de Programación en la Programación Nº ${
              index + 1
            }`,
          );
          // la hora SI puede ser menor, pero la fecha no, es decir, el tiempo estimado debe ser un numero positivo mayor a 0
          return;
        }

        if (!row.horaInicio || !row.horaFin || !row.tiempoEstimado || row.tiempoEstimado < 0) {
          checkProgramacion = false;
          Message.error(
            `Todos los campos son requeridos al seleccionar "Asignar Hora de Inicio" en la Programación Nº ${index + 1}`,
          );
          return;
        }
      }
    });

    return checkProgramacion;
  };

  const onSave = (values) => {
    console.log(
      '🚀 ~ file: PlanificadorTareaForm.tsx ~ line 239 ~ onSave ~ values.programacionTareas',
      values.programacionTareas,
    );

    if (!checkProgramacion(values)) {
      return;
    }

    setConfirm(false);

    values.estado = 'Pendiente';
    props.onSubmit(props.record?.id, values);
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  const doConfirm = () => {
    setConfirm(true);
  };

  const doCloseConfirm = () => {
    setConfirm(false);
  };

  const {saveLoading, modal} = props;
  const classes = useStyles();

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflowY: 'hidden',
          }}>
          <Typography variant="h1">{nombre}</Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              overflowY: 'hidden',
              border: '1px solid lightgray',
              borderRadius: '10px',
              my: 1,
              zIndex: 1,
            }}>
            {/* <div style={{marginBottom: 15}}>
                <QRCode value={`${config.frontendUrl.protocol}://${config.frontendUrl.host}/planificador-tarea/${props.record?.id}`} size={140} />
              </div> */}

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                p: '2px',
                overflowY: 'auto',
              }}>
              <Box
                sx={{
                  px: 2,
                  minHeight: '80vh',
                }}>
                {estado === 'Rechazada' && (
                  <Grid item lg={12} xs={12} style={{marginBottom: 20}}>
                    <Alert severity="error">
                      <AlertTitle style={{marginBottom: 10}}>
                        La Planificación ha sido rechazada por los siguientes motivos
                      </AlertTitle>
                      {firmas.map((firma) => (
                        <Grid style={{marginTop: 5}} container>
                          {firma.nota}
                        </Grid>
                      ))}
                    </Alert>
                  </Grid>
                )}
                {props.disabled && (
                  <div style={{marginBottom: 20}}>
                    <h2 style={{color: 'gray', fontWeight: 600, fontSize: 20, marginBottom: 15}}>
                      {' '}
                      {i18n('entities.planificadorTarea.fields.createdBy')}{' '}
                    </h2>
                    <UserListItem value={createdBy} />
                  </div>
                )}
                <Grid item lg={12} md={8} sm={12} xs={12}>
                  <RadioFormItem
                    name="clasificacion"
                    disabled={props.disabled}
                    label={i18n('entities.planificadorTarea.fields.clasificacion')}
                    options={planificadorTareaEnumerators.clasificacion.map((value) => ({
                      value,
                      label: i18n(`entities.planificadorTarea.enumerators.clasificacion.${value}`),
                    }))}
                    required={false}
                    onChange={(value) => {
                      console.log('%c⧭ onChange clasificacion', 'color: #ace2e6', value);
                      if (value !== 'incidente') {
                        form.setValue('incidentes', []);
                      }
                      if (value !== 'mantenimiento') {
                        form.setValue('activos', []);
                      }
                    }}
                  />
                </Grid>
                {clasificacion === 'mantenimiento' && (
                  <Grid item lg={12} md={8} sm={12} xs={12}>
                    <ActivoAutocompleteFormItem
                      name="activos"
                      disabled={props.disabled}
                      label={i18n('entities.activo.label')}
                      required={false}
                      showCreate={!props.modal}
                      mode="multiple"
                      onChange={(value) => {
                        const values = form.getValues('activos');
                        console.log('%c⧭ onChange ActivoAutocompleteFormItem', 'color: #ace2e6', {activos: values, value});
                      }}
                    />
                  </Grid>
                )}
                {clasificacion === 'incidente' && (
                  <Grid item lg={12} md={8} sm={12} xs={12}>
                    <RegistroAutocompleteFormItem
                      name="incidentes"
                      disabled={props.disabled}
                      label={i18n('entities.registro.label')}
                      required={false}
                      showCreate={!props.modal}
                      mode="multiple"
                      onChange={(value) => {
                        const values = form.getValues('incidentes');
                        console.log('%c⧭ onChange RegistroAutocompleteFormItem', 'color: #ace2e6', {incidentes: values, value});
                      }}
                    />
                  </Grid>
                )}
                <Grid item lg={12} md={8} sm={12} xs={12}>
                  <InputFormItem
                    name="nombre"
                    disabled={props.disabled}
                    label={i18n('entities.planificadorTarea.fields.nombre')}
                    required={false}
                    autoFocus
                  />
                </Grid>
                {!fromPlanificacion && (
                  <Grid item lg={12} md={8} sm={12} xs={12}>
                    <PlanificacionAutocompleteFormItem
                      name="planificacion"
                      entity="planificacion"
                      filter={{estado: ['Pendiente', 'Enviada', 'EnRevision', 'Autorizada', 'Rechazada']}}
                      disabled={props.disabled}
                      label={i18n('entities.planificadorTarea.fields.planificacion')}
                      required={true}
                      showCreate={false}
                    />
                  </Grid>
                )}
                {planificacion?.id &&
                  !!(
                    planificacion?.fechaEstimadaInicio ||
                    planificacion?.fechaEstimadaFin ||
                    planificacion?.presupuestoEstimado
                  ) && (
                    <Grid item lg={12} xs={12} sx={{my: 1}}>
                      <Alert severity="info">
                        <AlertTitle sx={{marginTop: '1px'}}>Detalles Planificación</AlertTitle>
                        <Grid sx={{mt: 2}} container direction="column" spacing={1}>
                          <Grid container direction="row" alignItems="center" spacing={1}>
                            <Grid item width={'28px'}>
                              <AccessAlarmIcon />
                            </Grid>
                            <Grid item>Inicio Estimado:</Grid>
                            <Grid item>
                              {planificacion?.fechaEstimadaInicio
                                ? moment(planificacion?.fechaEstimadaInicio).format('LL')
                                : 'No especificado'}
                            </Grid>
                          </Grid>
                          <Grid container direction="row" alignItems="center" spacing={1}>
                            <Grid item width={'28px'}>
                              <AccessAlarmIcon />
                            </Grid>
                            <Grid item>Término Estimado:</Grid>
                            <Grid item>
                              {planificacion?.fechaEstimadaFin
                                ? moment(planificacion?.fechaEstimadaFin).format('LL')
                                : 'No especificado'}
                            </Grid>
                          </Grid>
                          <Grid container direction="row" alignItems="center" spacing={1}>
                            <Grid item width={'28px'}>
                              <AttachMoneyIcon />
                            </Grid>
                            <Grid item>Presupuesto Estimado:</Grid>
                            <Grid item>
                              {new Intl.NumberFormat('es-CL', {currency: 'CLP', style: 'decimal'}).format(
                                planificacion?.presupuestoEstimado,
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Alert>
                    </Grid>
                  )}
                <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                  <UbicacionAutocompleteFormItem
                    name="locations"
                    disabled={props.disabled}
                    label={i18n('common.locations')}
                    required={false}
                    showCreate={!props.modal}
                    mode="multiple"
                  />
                </Grid>
                {/*<Grid item lg={12} md={8} sm={12} xs={12}>
                  <ModeloTareaAutocompleteFormItem
                    name="tareas"
                    disabled={true}
                    filter={{
                      planificaciones:
                        form.watch('planificacion')?.id,
                    }}
                    label={i18n(
                      'entities.planificadorTarea.fields.tareas',
                    )}
                    required={true}
                    showCreate={true}
                    mode="multiple"
                  />
                    </Grid>*/}
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexShrink: 0,
                  }}>
                  {planificacion?.id && (
                    <PlanificadorMultiTareas
                      disabled={props.disabled}
                      name="programacionTareas"
                      fromPlanificacion={props.planificacion}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          {!props.disabled && (
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <Button
                  style={{width: '100%', ...customButtonStyle}}
                  variant="contained"
                  color="primary"
                  disabled={saveLoading}
                  type="button"
                  onClick={() => doConfirm()}
                  endIcon={<SendOutlined />}
                  size="large">
                  {i18n('common.sendPlanificacion')}
                </Button>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Button
                  style={{width: '100%', ...customButtonStyle}}
                  variant="contained"
                  color="secondary"
                  disabled={saveLoading}
                  type="button"
                  onClick={form.handleSubmit(onSave)}
                  startIcon={<SaveOutlined />}
                  size="large">
                  {i18n('common.save')}
                </Button>
              </Grid>
            </Grid>
          )}
        </form>
      </FormProvider>

      {confirm && (
        <ConfirmModal
          title={i18n('common.areYouSure')}
          onConfirm={form.handleSubmit(onSubmit)}
          onClose={() => doCloseConfirm()}
          okText={i18n('common.yes')}
          cancelText={i18n('common.no')}
        />
      )}
    </FormWrapper>
  );
}

export default PlanificadorTareaForm;
