const planificacionEnumerators = {
  clasificacion: ['Programada', 'Correctiva'],
  estado: [
    'Pendiente',
    'Enviada',
    'EnRevision',
    'Autorizada',
    'Rechazada',
    'Cerrada',
  ],
};

export default planificacionEnumerators;
