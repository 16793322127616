import {i18n} from 'src/i18n';
import ActivoService from 'src/modules/activo/activoService';
import fields from 'src/modules/activo/importer/activoImporterFields';
import options from 'src/modules/activo/importer/activoImporterOptions';
import selectors from 'src/modules/activo/importer/activoImporterSelectors';
import importerActions from 'src/modules/shared/importer/importerActions';

const activoImporterActions = importerActions(
  'ACTIVO_IMPORTER',
  selectors,
  ActivoService.import,
  fields,
  i18n('entities.activo.importer.fileName'),
  1,
  options,
);

export default activoImporterActions;
