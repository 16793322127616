const solicitudEnumerators = {
  tipo: [
    'Orden de Compra',
    'Orden de Trabajo',
  ],
  estado: [
    'En revisión',
    'Rechazada',
    'Aprobada',
    'Parcialmente Aprobada',
    'Finalizada',
  ],
};

export default solicitudEnumerators;
