import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class ActivoService {
  static async test(filter?, orderBy?, limit = 10, offset = 0) {
    const params = {filter, orderBy, limit, offset};
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.get(`/tenant/${tenantId}/activo/test`, {params});
    return response.data;
  }
  static async getFieldConfig() {
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.get(`/tenant/${tenantId}/activo/field-config`);
    return response.data;
  }
  static async saveFieldConfig(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(`/tenant/${tenantId}/activo/field-config`, body);

    return response.data;
  }

  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(`/tenant/${tenantId}/activo/${id}`, body);

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.delete(`/tenant/${tenantId}/activo`, {
      params,
    });

    return response.data;
  }

  static async updateAll(ids, data) {
    const body = {
      ids,
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(`/tenant/${tenantId}/activo/update-all`, body);

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(`/tenant/${tenantId}/activo`, body);

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(`/tenant/${tenantId}/activo/import`, body);

    return response.data;
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(`/tenant/${tenantId}/activo/${id}`);

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(`/tenant/${tenantId}/activo`, {
      params,
    });

    return response.data;
  }

  static async listAutocomplete(query, limit, filter = {}) {
    const params = {
      query,
      limit,
      filter,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(`/tenant/${tenantId}/activo/autocomplete`, {
      params,
    });

    return response.data;
  }
}
