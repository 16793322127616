import React from 'react';
import Spinner from 'src/view/shared/Spinner';
import { i18n } from 'src/i18n';
import TextViewItem from 'src/view/shared/view/TextViewItem';
import ClasificacionTerceroViewItem from 'src/view/clasificacionTercero/view/ClasificacionTerceroViewItem';
import ContactoViewItem from 'src/view/contacto/view/ContactoViewItem';
import ServicioViewItem from 'src/view/servicio/view/ServicioViewItem';
import ActivoViewItem from 'src/view/activo/view/ActivoViewItem';

function TerceroView(props) {
  const renderView = () => {
    const { record } = props;

    return (
      <div>
        <TextViewItem
          label={i18n('entities.tercero.fields.nombre')}
          value={record.nombre}
        />

        <TextViewItem
          label={i18n('entities.tercero.fields.codigo')}
          value={record.codigo}
        />

        <TextViewItem
          label={i18n('entities.tercero.fields.tipo')}
          value={
            record.tipo &&
            i18n(
              `entities.tercero.enumerators.tipo.${record.tipo}`,
            )
          }
        />

        <TextViewItem
          label={i18n('entities.tercero.fields.paginaWeb')}
          value={record.paginaWeb}
        />

        <ClasificacionTerceroViewItem
          label={i18n('entities.tercero.fields.clasificacion')}
          value={record.clasificacion}
        />

        <TextViewItem
          label={i18n('entities.tercero.fields.direccion')}
          value={record.direccion}
        />

        <TextViewItem
          label={i18n('entities.tercero.fields.ciudad')}
          value={record.ciudad}
        />

        <TextViewItem
          label={i18n('entities.tercero.fields.region')}
          value={record.region}
        />

        <TextViewItem
          label={i18n('entities.tercero.fields.codigoArea')}
          value={record.codigoArea}
        />

        <TextViewItem
          label={i18n('entities.tercero.fields.pais')}
          value={record.pais}
        />

        <TextViewItem
          label={i18n('entities.tercero.fields.ubicacionGoogle')}
          value={record.ubicacionGoogle}
        />

        <TextViewItem
          label={i18n('entities.tercero.fields.latitud')}
          value={record.latitud}
        />

        <TextViewItem
          label={i18n('entities.tercero.fields.longitud')}
          value={record.longitud}
        />

        <TextViewItem
          label={i18n('entities.tercero.fields.telefonoPrincipal')}
          value={record.telefonoPrincipal}
        />

        <TextViewItem
          label={i18n('entities.tercero.fields.telefonoSecundario')}
          value={record.telefonoSecundario}
        />

        <TextViewItem
          label={i18n('entities.tercero.fields.email')}
          value={record.email}
        />

        <TextViewItem
          label={i18n('entities.tercero.fields.fax')}
          value={record.fax}
        />

        <TextViewItem
          label={i18n('entities.tercero.fields.telefonoSMS')}
          value={record.telefonoSMS}
        />

        <ContactoViewItem
          label={i18n('entities.tercero.fields.contactos')}
          value={record.contactos}
        />

        <ServicioViewItem
          label={i18n('entities.tercero.fields.servicios')}
          value={record.servicios}
        />

        <ActivoViewItem
          label={i18n('entities.tercero.fields.activosVinculados')}
          value={record.activosVinculados}
        />        
      </div>
    );
  };

  const { record, loading } = props;

  if (loading || !record) {
    return <Spinner />;
  }

  return renderView();
}

export default TerceroView;
