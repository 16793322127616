import listActions from 'src/modules/mensajeInterno/list/mensajeInternoListActions';
import MensajeInternoService from 'src/modules/mensajeInterno/mensajeInternoService';
import Errors from 'src/modules/shared/error/errors';
import {i18n} from 'src/i18n';
import {getHistory} from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'MENSAJEINTERNO_DESTROY';

const mensajeInternoDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy: id => async dispatch => {
    try {
      dispatch({
        type: mensajeInternoDestroyActions.DESTROY_STARTED,
      });

      await MensajeInternoService.destroyAll([id]);

      dispatch({
        type: mensajeInternoDestroyActions.DESTROY_SUCCESS,
      });

      Message.success(i18n('entities.mensajeInterno.destroy.success'));

      dispatch(listActions.doFetchCurrentFilter());

      //getHistory().push('/mensaje-interno');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: mensajeInternoDestroyActions.DESTROY_ERROR,
      });
    }
  },

  doDestroyAll: ids => async dispatch => {
    try {
      dispatch({
        type: mensajeInternoDestroyActions.DESTROY_ALL_STARTED,
      });

      await MensajeInternoService.destroyAll(ids);

      dispatch({
        type: mensajeInternoDestroyActions.DESTROY_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doClearAllSelected());
        dispatch(listActions.doFetchCurrentFilter());
      }

      Message.success(i18n('entities.mensajeInterno.destroyAll.success'));

      getHistory().push('/mensaje-interno');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: mensajeInternoDestroyActions.DESTROY_ALL_ERROR,
      });
    }
  },
};

export default mensajeInternoDestroyActions;
