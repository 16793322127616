import {Checkbox, FormControl, FormControlLabel, FormHelperText} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import FormErrors from 'src/view/shared/form/formErrors';

export function CheckboxFormItem(props) {
  const {
    label,
    name,
    hint,
    required,
    externalErrorMessage,
    disabled,
    defaultValue,
    size = 'small',
    style,
    labelPlacement = 'end',
    color = 'primary',
  } = props;

  const {
    register,
    formState: {touchedFields, isSubmitted, errors},
    setValue,
    control,
  } = useFormContext();

  useEffect(() => {
    register(name);
  }, []);

  const errorMessage = FormErrors.errorMessage(name, errors, touchedFields, isSubmitted, externalErrorMessage);

  const formHelperText = errorMessage || hint;

  const originalValue = useWatch({name, control});

  return (
    <FormControl required={required} error={Boolean(errorMessage)} component="fieldset" size="small">
      <FormControlLabel
        style={{
          marginLeft: 0,
          ...style,
        }}
        control={
          <Checkbox
            disabled={disabled}
            id={name}
            name={name}
            defaultValue={defaultValue}
            checked={originalValue || false}
            onChange={(e) => {
              setValue(name, Boolean(e.target.checked), {
                shouldValidate: true,
              });
              props.onChange && props.onChange(e.target.checked);
            }}
            onBlur={() => props.onBlur && props.onBlur(null)}
            color={color}
            size={size}
          />
        }
        label={label || ''}
        labelPlacement={labelPlacement}
      />
      {formHelperText && <FormHelperText style={{marginTop: 0}}>{formHelperText}</FormHelperText>}
    </FormControl>
  );
}

CheckboxFormItem.defaultProps = {};

CheckboxFormItem.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.any,
  hint: PropTypes.string,
  externalErrorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.bool,
  size: PropTypes.string,
  style: PropTypes.object,
  labelPlacement: PropTypes.string,
  color: PropTypes.string,
};

export default CheckboxFormItem;
