import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.registro.fields.id'),
  },
  {
    name: 'descripcion',
    label: i18n('entities.registro.fields.descripcion'),
  },
  {
    name: 'tipo',
    label: i18n('entities.registro.fields.tipo'),
  },
  {
    name: 'prioridad',
    label: i18n('entities.registro.fields.prioridad'),
  },
  {
    name: 'cuentasContables',
    label: i18n('entities.registro.fields.cuentasContables'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'nota',
    label: i18n('entities.registro.fields.nota'),
  },
  {
    name: 'ubicacionGoogle',
    label: i18n('entities.registro.fields.ubicacionGoogle'),
  },
  {
    name: 'latitud',
    label: i18n('entities.registro.fields.latitud'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'longitud',
    label: i18n('entities.registro.fields.longitud'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'trazabilidadesVinculadas',
    label: i18n('entities.registro.fields.trazabilidadesVinculadas'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'activosVinculados',
    label: i18n('entities.registro.fields.activosVinculados'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'documentosVinculados',
    label: i18n('entities.registro.fields.documentosVinculados'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'inventariosVinculados',
    label: i18n('entities.registro.fields.inventariosVinculados'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.registro.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.registro.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
