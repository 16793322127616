import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import { Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { i18n } from 'src/i18n';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import terceroEnumerators from 'src/modules/tercero/terceroEnumerators';
import ActivoAutocompleteFormItem from 'src/view/activo/autocomplete/ActivoAutocompleteFormItem';
import ClasificacionTerceroAutocompleteFormItem from 'src/view/clasificacionTercero/autocomplete/ClasificacionTerceroAutocompleteFormItem';
import ContactoAutocompleteFormItem from 'src/view/contacto/autocomplete/ContactoAutocompleteFormItem';
import ServicioAutocompleteFormItem from 'src/view/servicio/autocomplete/ServicioAutocompleteFormItem';
import GoogleAutocompleteFormItem from 'src/view/shared/form/items/GoogleAutocompleteFormItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import InputNumberFormItem from 'src/view/shared/form/items/InputNumberFormItem';
import SelectFormItem from 'src/view/shared/form/items/SelectFormItem';
import FormWrapper, { FormButtons } from 'src/view/shared/styles/FormWrapper';
import * as yup from 'yup';

const schema = yup.object().shape({
  nombre: yupFormSchemas.string(i18n('entities.tercero.fields.nombre'), {
    required: true,
  }),
  razonSocial: yupFormSchemas.string(i18n('entities.tercero.fields.razonSocial'), {}),
  codigo: yupFormSchemas.string(i18n('entities.tercero.fields.codigo'), {
    required: true,
  }),
  tipo: yupFormSchemas.enumerator(i18n('entities.tercero.fields.tipo'), {
    options: terceroEnumerators.tipo,
  }),
  paginaWeb: yupFormSchemas.string(i18n('entities.tercero.fields.paginaWeb'), {}),
  clasificacion: yupFormSchemas.relationToMany(i18n('entities.tercero.fields.clasificacion'), {}),
  direccion: yupFormSchemas.string(i18n('entities.tercero.fields.direccion'), {}),
  ciudad: yupFormSchemas.string(i18n('entities.tercero.fields.ciudad'), {}),
  region: yupFormSchemas.string(i18n('entities.tercero.fields.region'), {}),
  codigoArea: yupFormSchemas.string(i18n('entities.tercero.fields.codigoArea'), {}),
  pais: yupFormSchemas.string(i18n('entities.tercero.fields.pais'), {}),
  ubicacionGoogle: yupFormSchemas.string(i18n('entities.tercero.fields.ubicacionGoogle'), {}),
  latitud: yupFormSchemas.decimal(i18n('entities.tercero.fields.latitud'), {}),
  longitud: yupFormSchemas.decimal(i18n('entities.tercero.fields.longitud'), {}),
  telefonoPrincipal: yupFormSchemas.integer(i18n('entities.tercero.fields.telefonoPrincipal'), {}),
  telefonoSecundario: yupFormSchemas.integer(i18n('entities.tercero.fields.telefonoSecundario'), {}),
  email: yupFormSchemas.string(i18n('entities.tercero.fields.email'), {}),
  fax: yupFormSchemas.integer(i18n('entities.tercero.fields.fax'), {}),
  telefonoSMS: yupFormSchemas.integer(i18n('entities.tercero.fields.telefonoSMS'), {}),
  contactos: yupFormSchemas.relationToMany(i18n('entities.tercero.fields.contactos'), {}),
  servicios: yupFormSchemas.relationToMany(i18n('entities.tercero.fields.servicios'), {}),
  activosVinculados: yupFormSchemas.relationToMany(i18n('entities.tercero.fields.activosVinculados'), {}),
});

function TerceroForm(props) {
  const [initialValues] = useState(() => {
    const record = props.record || {};

    return {
      razonSocial: record.razonSocial || record.nombre,
      nombre: record.nombre,
      codigo: record.codigo,
      tipo: record.tipo,
      clasificacion: record.clasificacion || [],
      activosVinculados: record.activosVinculados || [],
      paginaWeb: record.paginaWeb,
      direccion: record.direccion,
      ciudad: record.ciudad,
      region: record.region,
      codigoArea: record.codigoArea,
      pais: record.pais,
      ubicacionGoogle: record.ubicacionGoogle,
      latitud: record.latitud,
      longitud: record.longitud,
      telefonoPrincipal: record.telefonoPrincipal,
      telefonoSecundario: record.telefonoSecundario,
      email: record.email,
      fax: record.fax,
      telefonoSMS: record.telefonoSMS,
      contactos: record.contactos || [],
      servicios: record.servicios || [],
    };
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
    shouldUnregister: false,
  });

  const onSubmit = (values) => {
    props.onSubmit(props.record?.id, values);
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  const { saveLoading, modal } = props;

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflowY: 'auto',
          }}>
          <Grid spacing={2} container>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputFormItem name="codigo" label={i18n('entities.tercero.fields.codigo')} required={true} />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputFormItem name="razonSocial" label={i18n('entities.tercero.fields.razonSocial')} required={true} autoFocus />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputFormItem name="nombre" label={i18n('entities.tercero.fields.nombre')} required={true} autoFocus />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <SelectFormItem
                name="tipo"
                label={i18n('entities.tercero.fields.tipo')}
                options={terceroEnumerators.tipo.map((value) => ({
                  value,
                  label: i18n(`entities.tercero.enumerators.tipo.${value}`),
                }))}
                required={false}
              />
            </Grid>
            {form.watch('tipo') === 'Proveedor' && (
              <Grid item lg={7} md={8} sm={12} xs={12}>
                <ServicioAutocompleteFormItem
                  name="servicios"
                  label={i18n('entities.tercero.fields.servicios')}
                  required={false}
                  showCreate={!props.modal}
                  mode="multiple"
                />
              </Grid>
            )}
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <ClasificacionTerceroAutocompleteFormItem
                name="clasificacion"
                label={i18n('entities.tercero.fields.clasificacion')}
                required={false}
                showCreate={!props.modal}
                mode="multiple"
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <ActivoAutocompleteFormItem
                name="activosVinculados"
                label={i18n('entities.tercero.fields.activosVinculados')}
                required={false}
                showCreate={!props.modal}
                mode="multiple"
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputFormItem name="paginaWeb" label={i18n('entities.tercero.fields.paginaWeb')} required={false} />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputFormItem name="direccion" label={i18n('entities.tercero.fields.direccion')} required={false} />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputFormItem name="ciudad" label={i18n('entities.tercero.fields.ciudad')} required={false} />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputFormItem name="region" label={i18n('entities.tercero.fields.region')} required={false} />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputFormItem name="codigoArea" label={i18n('entities.tercero.fields.codigoArea')} required={false} />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputFormItem name="pais" label={i18n('entities.tercero.fields.pais')} required={false} />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <GoogleAutocompleteFormItem
                name="ubicacionGoogle"
                label={i18n('entities.ubicacion.fields.ubicacionGoogle')}
                required={false}
                defaultCenter={{
                  lat: -36.8269882,
                  lng: -73.0497665,
                }}
                center={{
                  lat: form.watch('latitud'),
                  lng: form.watch('longitud'),
                }}
                zoom={14}
                enableMap={true}
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputNumberFormItem
                name="telefonoPrincipal"
                label={i18n('entities.tercero.fields.telefonoPrincipal')}
                required={false}
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputNumberFormItem
                name="telefonoSecundario"
                label={i18n('entities.tercero.fields.telefonoSecundario')}
                required={false}
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputFormItem name="email" label={i18n('entities.tercero.fields.email')} required={false} />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputNumberFormItem name="fax" label={i18n('entities.tercero.fields.fax')} required={false} />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputNumberFormItem name="telefonoSMS" label={i18n('entities.tercero.fields.telefonoSMS')} required={false} />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <ContactoAutocompleteFormItem
                name="contactos"
                label={i18n('entities.tercero.fields.contactos')}
                required={false}
                showCreate={!props.modal}
                mode="multiple"
              />
            </Grid>
          </Grid>
        </form>
        <FormButtons
          style={{
            flexDirection: modal ? 'row-reverse' : undefined,
          }}>
          <Button
            variant="contained"
            color="primary"
            disabled={saveLoading}
            type="button"
            onClick={form.handleSubmit(onSubmit)}
            startIcon={<SaveIcon />}
            size="small">
            {i18n('common.save')}
          </Button>

          <Button disabled={saveLoading} onClick={onReset} type="button" startIcon={<UndoIcon />} size="small">
            {i18n('common.reset')}
          </Button>

          {props.onCancel ? (
            <Button disabled={saveLoading} onClick={() => props.onCancel()} type="button" startIcon={<CloseIcon />} size="small">
              {i18n('common.cancel')}
            </Button>
          ) : null}
        </FormButtons>
      </FormProvider>
    </FormWrapper>
  );
}

export default TerceroForm;
