import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import AuthInvitationToken from 'src/modules/auth/authInvitationToken';
import {AuthToken} from 'src/modules/auth/authToken';
import authAxios from 'src/modules/shared/axios/authAxios';
import {tenantSubdomain} from '../tenant/tenantSubdomain';
import AuthInvitationEmail from './authInvitationEmail';

export default class AuthService {
  static async fetchReportConfig() {
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.get(`/tenant/${tenantId}/bi/getEmbedToken`);
    const reportConfig = response.data;
    return reportConfig;
  }

  static async sendEmailVerification() {
    const response = await authAxios.post('/auth/send-email-address-verification-email', {
      tenantId: tenantSubdomain.isSubdomain ? AuthCurrentTenant.get() : undefined,
    });

    return response.data;
  }

  static async sendPasswordResetEmail(email) {
    const response = await authAxios.post('/auth/send-password-reset-email', {
      email,
      tenantId: tenantSubdomain.isSubdomain ? AuthCurrentTenant.get() : undefined,
    });

    return response.data;
  }

  static async registerWithEmailAndPassword(email, password) {
    const invitationToken = AuthInvitationToken.get();

    const response = await authAxios.post('/auth/sign-up', {
      email,
      password,
      invitationToken,
      tenantId: tenantSubdomain.isSubdomain ? AuthCurrentTenant.get() : undefined,
    });

    AuthInvitationToken.clear();
    AuthInvitationEmail.clear();

    return response.data;
  }

  static async signinWithEmailAndPassword(email, password) {
    const invitationToken = AuthInvitationToken.get();

    const response = await authAxios.post('/auth/sign-in', {
      email,
      password,
      invitationToken,
      tenantId: tenantSubdomain.isSubdomain ? AuthCurrentTenant.get() : undefined,
    });

    AuthInvitationToken.clear();
    AuthInvitationEmail.clear();

    return response.data;
  }

  static async fetchMe() {
    const response = await authAxios.get('/auth/me');
    const user = response.data;
    localStorage.setItem(
      'sw_user',
      JSON.stringify({
        id: user.id,
        email: user.email,
      }),
    );
    return user;
  }

  static async isEmailConfigured() {
    const response = await authAxios.get('/auth/email-configured');
    return response.data;
  }

  static signout() {
    AuthToken.set(null, true);
  }

  static async updateProfile(data) {
    const body = {
      data,
    };

    const response = await authAxios.put('/auth/profile', body);

    return response.data;
  }

  static async changePassword(oldPassword, newPassword) {
    const body = {
      oldPassword,
      newPassword,
    };

    const response = await authAxios.put('/auth/change-password', body);

    return response.data;
  }

  static async passwordReset(token, password) {
    const response = await authAxios.put('/auth/password-reset', {
      token,
      password,
      tenantId: tenantSubdomain.isSubdomain ? AuthCurrentTenant.get() : undefined,
    });

    return response.data;
  }

  static async verifyEmail(token) {
    const response = await authAxios.put('/auth/verify-email', {
      token,
      tenantId: tenantSubdomain.isSubdomain ? AuthCurrentTenant.get() : undefined,
    });

    return response.data;
  }

  // update updateLatestSearches
  static async updateLatestSearches(entity, newSearches) {
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.put(`/tenant/${tenantId}/auth/update-latest-searches`, {
      entity,
      newSearches,
    });
    //console.log('%c⧭ updateLatestSearches result', 'color: #9c66cc', response.data);
    return response.data;
  }
}
