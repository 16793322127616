import {i18n} from 'src/i18n';
import activoEnumerators from 'src/modules/activo/activoEnumerators';
import schemas from 'src/modules/shared/yup/yupImporterSchemas';

const fields = [
  {
    name: 'nombre',
    label: i18n('entities.activo.fields.nombre'),
    schema: schemas.string(i18n('entities.activo.fields.nombre'), {
      //required: true,
    }),
  },
  {
    name: 'codigo',
    label: i18n('entities.activo.fields.codigo'),
    schema: schemas.string(i18n('entities.activo.fields.codigo'), {
      //required: true,
    }),
  },
  {
    name: 'grupo',
    label: i18n('entities.activo.fields.grupo'),
    schema: schemas.enumerator(i18n('entities.activo.fields.grupo'), {
      options: activoEnumerators.grupo,
    }),
  },
  {
    name: 'locations',
    label: i18n('common.locations'),
    schema: schemas.string(i18n('common.locations'), {}),
  },
  // todo: descripcion
  {
    name: 'localizacion',
    label: i18n('entities.activo.fields.localizacion'),
    schema: schemas.string(i18n('entities.activo.fields.localizacion'), {}),
  },
  {
    name: 'tipoActivo',
    label: i18n('entities.activo.fields.tipoActivo'),
    schema: schemas.string(i18n('entities.activo.fields.tipoActivo'), {}),
  },
  {
    name: 'costoCompra',
    label: i18n('entities.activo.fields.costoCompra'),
    schema: schemas.integer(i18n('entities.activo.fields.costoCompra'), {}),
  },
  {
    name: 'inventario',
    label: i18n('entities.activo.fields.inventario'),
    schema: schemas.boolean(i18n('entities.activo.fields.inventario'), {}).transform((val) => {
      if ([true, 'true', 'sí', 'si', 'Sí', 'Si', 'SI', 'SÍ'].includes(val)) return true;
      if ([false, 'false', 'no', 'No', 'NO'].includes(val)) return false;
      return val;
    }),
  },
  {
    name: 'prioridad',
    label: i18n('entities.activo.fields.prioridad'),
    schema: schemas.enumerator(i18n('entities.activo.fields.prioridad'), {
      options: activoEnumerators.prioridad,
    }),
  },
  {
    name: 'codigoBarra',
    label: i18n('entities.activo.fields.codigoBarra'),
    schema: schemas.string(i18n('entities.activo.fields.codigoBarra'), {}),
  },
  {
    name: 'patente',
    label: i18n('entities.activo.fields.patente'),
    schema: schemas.string(i18n('entities.activo.fields.patente'), {}),
  },
  {
    name: 'fabricante',
    label: i18n('entities.activo.fields.fabricante'),
    schema: schemas.string(i18n('entities.activo.fields.fabricante'), {}),
  },
  {
    name: 'marca',
    label: i18n('entities.activo.fields.marca'),
    schema: schemas.string(i18n('entities.activo.fields.marca'), {}),
  },
  {
    name: 'modelo',
    label: i18n('entities.activo.fields.modelo'),
    schema: schemas.string(i18n('entities.activo.fields.modelo'), {}),
  },
  {
    name: 'numeroSerial',
    label: i18n('entities.activo.fields.numeroSerial'),
    schema: schemas.string(i18n('entities.activo.fields.numeroSerial'), {}),
  },
  {
    name: 'capacidad',
    label: i18n('entities.activo.fields.capacidad'),
    schema: schemas.string(i18n('entities.activo.fields.capacidad'), {}),
  },
  {
    name: 'estado',
    label: i18n('entities.activo.fields.estado'),
    schema: schemas.enumerator(i18n('entities.activo.fields.estado'), {
      options: activoEnumerators.estado,
    }),
  },
  {
    name: 'certificated',
    label: i18n('entities.activo.fields.certificated'),
    schema: schemas.boolean(i18n('entities.activo.fields.certificated'), {
      trueValues: ['Sí', 'Si', 'SI', 'sí', 'si', 'true'],
    }),
  },
  {
    name: 'numeroParte',
    label: i18n('entities.activo.fields.numeroParte'),
    schema: schemas.string(i18n('entities.activo.fields.numeroParte'), {}),
  },
  {
    name: 'responsablesActivo',
    label: i18n('entities.activo.fields.responsablesActivo'),
    schema: schemas.string(i18n('entities.activo.fields.responsablesActivo'), {}),
  },
  // {
  //   name: 'responsables',
  //   label: i18n('entities.activo.fields.responsables'),
  //   schema: schemas.relationToMany(i18n('entities.activo.fields.responsables'), {}),
  // },
  {
    name: 'tercerosRelacionados',
    label: i18n('entities.activo.fields.tercerosRelacionados'),
    schema: schemas.string(i18n('entities.activo.fields.tercerosRelacionados'), {}),
  },
  // {
  //   name: 'tipoMedicionMantenimiento',
  //   label: i18n('entities.activo.fields.tipoMedicionMantenimiento'),
  //   schema: schemas.enumerator(i18n('entities.activo.fields.tipoMedicionMantenimiento'), {
  //     options: activoEnumerators.tipoMedicionMantenimiento,
  //   }),
  // },
  // {
  //   name: 'mantenimientoPorHorometro',
  //   label: i18n('entities.activo.fields.mantenimientoPorHorometro'),
  //   schema: schemas.integer(i18n('entities.activo.fields.mantenimientoPorHorometro'), {}),
  // },
  // {
  //   name: 'mantenimientoPorKilometros',
  //   label: i18n('entities.activo.fields.mantenimientoPorKilometros'),
  //   schema: schemas.integer(i18n('entities.activo.fields.mantenimientoPorKilometros'), {}),
  // },
  // {
  //   name: 'horasUsoPromedioDiario',
  //   label: i18n('entities.activo.fields.horasUsoPromedioDiario'),
  //   schema: schemas.integer(i18n('entities.activo.fields.horasUsoPromedioDiario'), {}),
  // },
  // {
  //   name: 'kilometrosPromedioDiario',
  //   label: i18n('entities.activo.fields.kilometrosPromedioDiario'),
  //   schema: schemas.integer(i18n('entities.activo.fields.kilometrosPromedioDiario'), {}),
  // },
  // {
  //   name: 'fechaCompra',
  //   label: i18n('entities.activo.fields.fechaCompra'),
  //   schema: schemas.date(
  //     i18n('entities.activo.fields.fechaCompra'),
  //     {},
  //   ),
  //   render: (value) =>
  //     value && value instanceof Date
  //       ? moment(value).format('YYYY-MM-DD')
  //       : value,
  // },
  // {
  //   name: 'valorSustitucion',
  //   label: i18n('entities.activo.fields.valorSustitucion'),
  //   schema: schemas.integer(
  //     i18n('entities.activo.fields.valorSustitucion'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'depreciacionTotalHoy',
  //   label: i18n(
  //     'entities.activo.fields.depreciacionTotalHoy',
  //   ),
  //   schema: schemas.integer(
  //     i18n('entities.activo.fields.depreciacionTotalHoy'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'valorLibroHoy',
  //   label: i18n('entities.activo.fields.valorLibroHoy'),
  //   schema: schemas.integer(
  //     i18n('entities.activo.fields.valorLibroHoy'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'fechaExpiracion',
  //   label: i18n('entities.activo.fields.fechaExpiracion'),
  //   schema: schemas.date(
  //     i18n('entities.activo.fields.fechaExpiracion'),
  //     {},
  //   ),
  //   render: (value) =>
  //     value && value instanceof Date
  //       ? moment(value).format('YYYY-MM-DD')
  //       : value,
  // },
  // {
  //   name: 'fotos',
  //   label: i18n('entities.activo.fields.fotos'),
  //   schema: schemas.images(
  //     i18n('entities.activo.fields.fotos'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'habilitado',
  //   label: i18n('entities.activo.fields.habilitado'),
  //   schema: schemas.boolean(
  //     i18n('entities.activo.fields.habilitado'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'activoPrincipal',
  //   label: i18n('entities.activo.fields.activoPrincipal'),
  //   schema: schemas.relationToOne(
  //     i18n('entities.activo.fields.activoPrincipal'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'direccion',
  //   label: i18n('entities.activo.fields.direccion'),
  //   schema: schemas.string(
  //     i18n('entities.activo.fields.direccion'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'ciudad',
  //   label: i18n('entities.activo.fields.ciudad'),
  //   schema: schemas.string(
  //     i18n('entities.activo.fields.ciudad'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'region',
  //   label: i18n('entities.activo.fields.region'),
  //   schema: schemas.string(
  //     i18n('entities.activo.fields.region'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'pais',
  //   label: i18n('entities.activo.fields.pais'),
  //   schema: schemas.string(
  //     i18n('entities.activo.fields.pais'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'codigoArea',
  //   label: i18n('entities.activo.fields.codigoArea'),
  //   schema: schemas.string(
  //     i18n('entities.activo.fields.codigoArea'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'ubicacionGoogle',
  //   label: i18n('entities.activo.fields.ubicacionGoogle'),
  //   schema: schemas.string(
  //     i18n('entities.activo.fields.ubicacionGoogle'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'latitud',
  //   label: i18n('entities.activo.fields.latitud'),
  //   schema: schemas.decimal(
  //     i18n('entities.activo.fields.latitud'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'longitud',
  //   label: i18n('entities.activo.fields.longitud'),
  //   schema: schemas.decimal(
  //     i18n('entities.activo.fields.longitud'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'centrosCostos',
  //   label: i18n('entities.activo.fields.centrosCostos'),
  //   schema: schemas.relationToMany(
  //     i18n('entities.activo.fields.centrosCostos'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'repuestos',
  //   label: i18n('entities.activo.fields.repuestos'),
  //   schema: schemas.relationToMany(
  //     i18n('entities.activo.fields.repuestos'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'visibilidadHorasUso',
  //   label: i18n('entities.activo.fields.visibilidadHorasUso'),
  //   schema: schemas.boolean(
  //     i18n('entities.activo.fields.visibilidadHorasUso'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'tipoDepreciacion',
  //   label: i18n('entities.activo.fields.tipoDepreciacion'),
  //   schema: schemas.enumerator(
  //     i18n('entities.activo.fields.tipoDepreciacion'),
  //     {
  //       "options": activoEnumerators.tipoDepreciacion
  //     },
  //   ),
  // },
  // {
  //   name: 'fechaInicioOperaciones',
  //   label: i18n('entities.activo.fields.fechaInicioOperaciones'),
  //   schema: schemas.date(
  //     i18n('entities.activo.fields.fechaInicioOperaciones'),
  //     {},
  //   ),
  //  render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD') : value,
  // },
  // {
  //   name: 'depreciacionAnual',
  //   label: i18n('entities.activo.fields.depreciacionAnual'),
  //   schema: schemas.integer(
  //     i18n('entities.activo.fields.depreciacionAnual'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'vidaUtil',
  //   label: i18n('entities.activo.fields.vidaUtil'),
  //   schema: schemas.integer(
  //     i18n('entities.activo.fields.vidaUtil'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'valorSalvamiento',
  //   label: i18n('entities.activo.fields.valorSalvamiento'),
  //   schema: schemas.integer(
  //     i18n('entities.activo.fields.valorSalvamiento'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'gestionDocumental',
  //   label: i18n('entities.activo.fields.gestionDocumental'),
  //   schema: schemas.relationToMany(
  //     i18n('entities.activo.fields.gestionDocumental'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'unidadMedida',
  //   label: i18n('entities.activo.fields.unidadMedida'),
  //   schema: schemas.relationToOne(
  //     i18n('entities.activo.fields.unidadMedida'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'peso',
  //   label: i18n('entities.activo.fields.peso'),
  //   schema: schemas.decimal(
  //     i18n('entities.activo.fields.peso'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'tiempoEspera',
  //   label: i18n('entities.activo.fields.tiempoEspera'),
  //   schema: schemas.integer(
  //     i18n('entities.activo.fields.tiempoEspera'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'controladoPorSerial',
  //   label: i18n('entities.activo.fields.controladoPorSerial'),
  //   schema: schemas.boolean(
  //     i18n('entities.activo.fields.controladoPorSerial'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'equivalencias',
  //   label: i18n('entities.activo.fields.equivalencias'),
  //   schema: schemas.relationToMany(
  //     i18n('entities.activo.fields.equivalencias'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'version',
  //   label: i18n('entities.activo.fields.version'),
  //   schema: schemas.decimal(
  //     i18n('entities.activo.fields.version'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'direccionWeb',
  //   label: i18n('entities.activo.fields.direccionWeb'),
  //   schema: schemas.string(
  //     i18n('entities.activo.fields.direccionWeb'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'ubicacionCiclos',
  //   label: i18n('entities.activo.fields.ubicacionCiclos'),
  //   schema: schemas.relationToMany(
  //     i18n('entities.activo.fields.ubicacionCiclos'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'cantidadCiclosRecicla',
  //   label: i18n('entities.activo.fields.cantidadCiclosRecicla'),
  //   schema: schemas.integer(
  //     i18n('entities.activo.fields.cantidadCiclosRecicla'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'documentos',
  //   label: i18n('entities.activo.fields.documentos'),
  //   schema: schemas.files(
  //     i18n('entities.activo.fields.documentos'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'notas',
  //   label: i18n('entities.activo.fields.notas'),
  //   schema: schemas.relationToMany(
  //     i18n('entities.activo.fields.notas'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'ancho',
  //   label: i18n('entities.activo.fields.ancho'),
  //   schema: schemas.decimal(
  //     i18n('entities.activo.fields.ancho'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'alto',
  //   label: i18n('entities.activo.fields.alto'),
  //   schema: schemas.decimal(
  //     i18n('entities.activo.fields.alto'),
  //     {},
  //   ),
  // },
];
export default fields;
