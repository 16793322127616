import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/centroCosto/importer/centroCostoImporterSelectors';
import CentroCostoService from 'src/modules/centroCosto/centroCostoService';
import fields from 'src/modules/centroCosto/importer/centroCostoImporterFields';
import { i18n } from 'src/i18n';

const centroCostoImporterActions = importerActions(
  'CENTROCOSTO_IMPORTER',
  selectors,
  CentroCostoService.import,
  fields,
  i18n('entities.centroCosto.importer.fileName'),
);

export default centroCostoImporterActions;