import {AppBar, Toolbar} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {i18n} from 'src/i18n';
import selectors from 'src/modules/auth/authSelectors';
import I18nSelect from 'src/view/layout/I18nSelect';
import authSelectors from '../../modules/auth/authSelectors';
import SimpleMenu from './SimpleMenu';
const useStyles = makeStyles((theme: any) => ({
  appBar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    zIndex: theme.zIndex.drawer + 1,
  },
  logo: {
    paddingLeft: theme.spacing(1),
    fontWeight: 500,
    fontSize: '1.5em',
    color: theme.palette.getContrastText(theme.palette.primary.main),
    textDecoration: 'none',
  },
  grow: {
    flex: '1 1 auto',
  },
}));

function HeaderSimple() {
  const classes = useStyles();

  // TODO: get tenant logo and name from sharedLink
  const logoUrl = useSelector(selectors.selectLogoUrl);

  const currentTenant = useSelector(authSelectors.selectCurrentTenant);

  return (
    <AppBar className={classes.appBar} position="fixed">
      <Toolbar>
        <Link className={classes.logo} to="/">
          {logoUrl ? (
            <img
              src={logoUrl}
              alt={i18n('app.title')}
              style={{verticalAlign: 'middle', maxHeight: 64, width: 'auto', maxWidth: 128, height: 'auto'}}
            />
          ) : (
            <>{currentTenant?.name}</>
          )}
        </Link>

        <div className={classes.grow} />
        <I18nSelect />

        <SimpleMenu />
      </Toolbar>
    </AppBar>
  );
}

export default HeaderSimple;
