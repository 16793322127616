

const sharedActions = {
  UPDATE_SHARED : 'UPDATE_SHARED',
  updateShared : newValues => ({
    type: sharedActions.UPDATE_SHARED,
    payload: newValues,
  })
};

export default sharedActions;