import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import authAxios from 'src/modules/shared/axios/authAxios';

export default class PlanificadorTareaCustomService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(`/tenant/${tenantId}/planificador-tarea/${id}`, body);

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.delete(`/tenant/${tenantId}/planificador-tarea`, {
      params,
    });

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(`/tenant/${tenantId}/planificador-tarea`, body);

    return response.data;
  }

  static parseDate(string) {
    if (!string) return {date: null, type: null};
    //console.log('%c⧭ parseDate', 'color: #40fff2', string);
    //sáb 14/10/23 9:00 when dates comes like this it might be tricky to parse them
    const reg = /(\d{1,2})\/(\d{1,2})\/(\d{2})(?: (\d{1,2}):(\d{2}))?/;
    const match = reg.exec(string);

    let type = 'date';
    let date;
    if (match) {
      //console.log('%c⧭ parseDate match', 'color: #40fff2', match);
      const year = match[3];
      const month = match[1] ? match[1].padStart(2, '0') : '01';
      const day = match[2] ? match[2].padStart(2, '0') : '01';
      const hour = match[4] ? match[4].padStart(2, '0') : '00';
      const minutes = match[5];

      if (hour && minutes) {
        date = new Date(`20${year}-${month}-${day}T${hour}:${minutes}:00`);
        type = 'datetime';
        //console.log('%c⧭ parseDate match', 'color: #ffa280', type, date);
      } else {
        date = new Date(`20${year}-${month}-${day}`);
      }
    } else {
      date = new Date(string);
    }
    return {
      date,
      type,
    };
  }

  static rowsToImportData(rows) {
    const rowsClone = JSON.parse(JSON.stringify(rows));
    const planificaciones: any = [];
    // subtareas belong to the previous tarea in the list, so we will need to keep track of the last tarea
    let lastPlanificacion: any = null;
    // subplanificacion belong to the previous planificacion in the list, so we will need to keep track of the last planificacion
    let planLevel: any = {};
    let currentLevel = 0;
    // tareas belong to the previous planificacion in the list, so we will need to keep track of the last planificacion
    let lastTarea: any = null;
    rowsClone.forEach((row) => {
      const {date: comienzo, type: tipo_comienzo} = this.parseDate(row.comienzo);
      const {date: fin, type: tipo_fin} = this.parseDate(row.fin);
      row.comienzo = comienzo;
      row.tipo_comienzo = tipo_comienzo;
      row.fin = fin;
      row.tipo_fin = tipo_fin;
      const tipo = row.tipo;

      let noLevel = !(row.nivel == '0' || row.nivel);
      let nivel = parseInt(noLevel ? 1 : row.nivel);
      let currentPlan = planLevel[nivel - 1];
      if (nivel < currentLevel) {
        Object.keys(planLevel).forEach((key) => {
          if (parseInt(key) > nivel) {
            planLevel[key] = null;
          }
        });
      }
      currentLevel = nivel;
      switch (tipo) {
        case 'Planificación':
          lastPlanificacion = row;
          planLevel[0] = row;
          planificaciones.push(row);
          lastTarea = null;
          if (currentLevel) {
            planLevel[currentLevel] = null;
          }
          currentLevel = 0;
          break;
        case 'Sub-Planificación':
          if (!currentPlan) break;
          //if(noLevel) nivel = currentLevel + 1; // ni idea como hacerlo sin nivel
          currentPlan.subplanificaciones = [].concat(currentPlan.subplanificaciones || []);
          currentPlan.subplanificaciones.push(row);
          planLevel[nivel] = row;
          lastTarea = null;
          break;
        case 'Tarea':
          if (!currentPlan) break;
          lastTarea = row;
          lastTarea.periodicidad = lastTarea.periodicidad; // || lastPlan.periodicidad;
          lastTarea.tipo_periodicidad = lastTarea.tipo_periodicidad; // || lastPlan.tipo_periodicidad;
          currentPlan.tareas = [].concat(currentPlan.tareas || []);
          currentPlan.tareas.push(row);
          break;
        case 'Sub-Tarea':
          if (!lastTarea) break;
          lastTarea.subtareas = [].concat(lastTarea.subtareas || []);
          lastTarea.subtareas.push(row);
          break;
        default:
          break;
      }
    });
    console.log('%c⧭ planificaciones', 'color: #607339', planificaciones);
    return planificaciones;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(`/tenant/${tenantId}/planificador-tarea/import`, body);

    return response.data;
  }

  static async customImport(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(`/tenant/${tenantId}/planificador-tarea/custom-import`, body);

    return response.data;
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(`/tenant/${tenantId}/planificador-tarea/${id}`);

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(`/tenant/${tenantId}/planificador-tarea`, {
      params,
    });

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(`/tenant/${tenantId}/planificador-tarea/autocomplete`, {
      params,
    });

    return response.data;
  }
}
