import {styled} from '@mui/styles';

const FilterWrapper = styled('div')({
  marginBottom: '12px',
  '& form': {
    width: '100%',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1.3rem',
  },
});

export const FilterButtons = styled('div')({
  paddingTop: '12px',
  textAlign: 'right',
  gap: 8,
  display: 'flex',

  // '& > *': {
  //   marginLeft: '16px',
  //   marginBottom: '4px',
  // },
});

export default FilterWrapper;
