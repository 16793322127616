import list from 'src/modules/tipoCentroCosto/list/tipoCentroCostoListReducers';
import form from 'src/modules/tipoCentroCosto/form/tipoCentroCostoFormReducers';
import view from 'src/modules/tipoCentroCosto/view/tipoCentroCostoViewReducers';
import destroy from 'src/modules/tipoCentroCosto/destroy/tipoCentroCostoDestroyReducers';
import importerReducer from 'src/modules/tipoCentroCosto/importer/tipoCentroCostoImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
