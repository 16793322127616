import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/puesto/importer/puestoImporterSelectors';
import PuestoService from 'src/modules/puesto/puestoService';
import fields from 'src/modules/puesto/importer/puestoImporterFields';
import { i18n } from 'src/i18n';

const puestoImporterActions = importerActions(
  'PUESTO_IMPORTER',
  selectors,
  PuestoService.import,
  fields,
  i18n('entities.puesto.importer.fileName'),
);

export default puestoImporterActions;