import list from 'src/modules/categoriaProducto/list/categoriaProductoListReducers';
import form from 'src/modules/categoriaProducto/form/categoriaProductoFormReducers';
import view from 'src/modules/categoriaProducto/view/categoriaProductoViewReducers';
import destroy from 'src/modules/categoriaProducto/destroy/categoriaProductoDestroyReducers';
import importerReducer from 'src/modules/categoriaProducto/importer/categoriaProductoImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
