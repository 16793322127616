import PropTypes from 'prop-types';
import React from 'react';

import {Gesture, LibraryAddCheck} from '@mui/icons-material';
import {useSelector} from 'react-redux';
import authSelectors from 'src/modules/auth/authSelectors';
import selectors from 'src/modules/tarea/tareaSelectors';
import DrawerButton from 'src/view/shared/view/DrawerButton';
import TareaFormPage from '../form/TareaFormPage';

function TareaListItem(props) {
  const hasPermissionToRead = useSelector(selectors.selectPermissionToRead);
  const roles: any = useSelector(authSelectors.selectRoles);
  const isSigningUser = roles.includes('signingUser');
  const canSign = props.canSign;
  const firma = props.firma;

  const valueAsArray = () => {
    const {value} = props;

    if (!value) {
      return [];
    }

    if (Array.isArray(value)) {
      return value;
    }

    return [value];
  };

  const displayableRecord = (record) => {
    if (hasPermissionToRead || canSign || isSigningUser) {
      return (
        <div key={record.id}>
          <DrawerButton
            drawerId={'DrawerTareaFormPage'}
            type={'chip'}
            stopPropagation={true}
            tooltipTitle={record.titulo + (record.tituloTarea ? ' ' + record.tituloTarea : '')}
            buttonTitle={record.titulo + (record.tituloTarea ? ' ' + record.tituloTarea : '')}
            width={window.innerWidth * 0.65}
            buttonIcon={canSign ? <Gesture /> : <LibraryAddCheck />}
            component={
              <TareaFormPage hideComunicacionInterna={!hasPermissionToRead} firma={firma} tarea={record} drawer={true} />
            }
          />
        </div>
      );
    }

    return (
      <div key={record.id}>
        {record.titulo} {record.tituloTarea || ''}
      </div>
    );
  };

  if (!valueAsArray().length) {
    return null;
  }

  return <>{valueAsArray().map((value) => displayableRecord(value))}</>;
}

TareaListItem.propTypes = {
  value: PropTypes.any,
  edit: PropTypes.any,
  canSign: PropTypes.bool,
  firma: PropTypes.any,
};

export default TareaListItem;
