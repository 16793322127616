import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import authSelectors from 'src/modules/auth/authSelectors';
import actions from 'src/modules/tarea/form/tareaFormActions';
import ContentWrapper from 'src/view/layout/styles/ContentWrapper';
import Breadcrumb from 'src/view/shared/Breadcrumb';
import PageTitle from 'src/view/shared/styles/PageTitle';
import TareaReportProgressForm from './TareaReportProgressForm';

function TareaReportProgressPage(props) {
  const dispatch = useDispatch();

  const currentRoles: any = useSelector(authSelectors.selectRoles);
  const progressExecutor = currentRoles.includes('ejecutorProgreso');
  const progressEditor = currentRoles.includes('editorProgreso');

  const readOnly = props.readOnly;

  const titleLabel = readOnly
    ? i18n('common.watchProgress')
    : progressEditor
    ? i18n('common.modifyProgress')
    : i18n('common.addProgress');

  const title = props.title || titleLabel;

  const onSaveSuccess = (id, values) => {
    console.log('⧭ TareaReportProgressPage [onSaveSuccess]', 'id', id, 'values', values);
    props.onSaveSuccess && props.onSaveSuccess(id, values);
    setExpanded(false);
  };

  const doSubmit = (id, values) => {
    console.log('%c⧭ TareaReportProgressPage [doSubmit]', 'color: #408059', {id, values});
    dispatch(actions.doUpdate(id, {...values, updating: props.updating}, onSaveSuccess));
  };

  const [expanded, setExpanded] = React.useState(false);

  return (
    <>
      {!props.noBreadcrumb && !props.drawer && !props.disabled && (
        <Breadcrumb items={[[i18n('dashboard.menu'), '/'], [i18n('entities.tarea.menu'), '/tarea'], [title]]} />
      )}

      {!props.expandable && (
        <ContentWrapper>
          <div style={{padding: 10}}>
            <PageTitle>{title}</PageTitle>
          </div>
          <TareaReportProgressForm {...props} onSubmit={doSubmit} />
        </ContentWrapper>
      )}

      {props.expandable && (
        <Accordion
          sx={{
            border: '1px solid lightgray',
          }}
          expanded={expanded}
          onChange={(event, expand) => {
            setExpanded(expand);
          }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant={'subtitle1'}>{title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TareaReportProgressForm {...props} onSubmit={doSubmit} />
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
}

export default TareaReportProgressPage;
