import ComunicacionInternaService from 'src/modules/comunicacionInterna/comunicacionInternaService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'COMUNICACIONINTERNA_VIEW';

const comunicacionInternaViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: comunicacionInternaViewActions.FIND_STARTED,
      });

      const record = await ComunicacionInternaService.find(id);

      dispatch({
        type: comunicacionInternaViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: comunicacionInternaViewActions.FIND_ERROR,
      });

      getHistory().push('/comunicacion-interna');
    }
  },
};

export default comunicacionInternaViewActions;
