import {Check} from '@mui/icons-material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import {FormProvider, useForm} from 'react-hook-form';
import {i18n} from 'src/i18n';
import OpenAIService from 'src/modules/shared/openai/openAIService';
import Storage from 'src/security/storage';
import ImagesFormItem from 'src/view/shared/form/items/ImagesFormItem';
import Message from 'src/view/shared/message';
import PageTitle from 'src/view/shared/styles/PageTitle';

function TareaPrePdfModal(props) {
  const {task, onCancel, onSuccess, onChange, onCloseReady} = props;
  const [loading, setLoading] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const getAllImages = () => {
    const listaProcedimiento = task?.listaProcedimiento;
    if (!listaProcedimiento) return [];
    let images: any = [];
    listaProcedimiento.forEach((part) => {
      if (part.additionalPhotos?.length > 0) {
        images.push(...part.additionalPhotos);
      }
      if (part.images?.length > 0) {
        images.push(...part.images);
      }
    });
    const ready = checkAllReady(images);
    if (ready) {
      onCloseReady && onCloseReady();
    }
    return images;
  };

  const checkReadyOne = (image) => {
    const ready = !image?.publicUrl || (image?.AIContent && !image?.AIContent.loading && !image?.AIContent.error);
    return ready;
  };

  const checkAllReady = (images) => {
    const ready = images.every((img) => checkReadyOne(img));
    setIsReady(ready);
    return ready;
  };

  const readImages = async () => {
    const images = getAllImages();
    //console.log('readImages', {images});

    for (let i = 0; i < images.length; i++) {
      if (checkReadyOne(images[i])) continue; // skip if already read or no image
      images[i].AIContent = {loading: true};
    }
    onChange(task);
    form.setValue('images', images);
    for (let i = 0; i < images.length; i++) {
      if (checkReadyOne(images[i])) continue; // skip if already read or no image
      setLoading(true);
      const imageUrl = images[i].publicUrl;
      try {
        const imageRead = await OpenAIService.readImage({image_url: imageUrl});
        const messageContent = imageRead?.message?.content;
        if (messageContent) {
          images[i].AIContent = JSON.parse(messageContent);
          onChange(task);
          form.setValue('images', images);
        }
      } catch (error) {
        const errorMessage = error?.response?.data;
        console.log('AIService.readImage [error]', errorMessage);
        Message.error('Error reading image' + (errorMessage ? ': ' + errorMessage : ''));
        if (errorMessage) {
          images[i].AIContent = {error: errorMessage};
        } else {
          delete images[i].AIContent;
        }
        onChange(task);
        form.setValue('images', images);
      }
      setLoading(false);
      props.onReadImage && props.onReadImage();
    }
    checkAllReady(images);
    console.log('readImages [end]', {images, task});
  };

  const onClose = () => {
    setLoadingPdf(false);
    setLoading(false);
    onCancel && onCancel();
  };

  const [initialValues] = useState(() => {
    return {
      images: getAllImages(),
    };
  });

  const form = useForm({
    defaultValues: initialValues,
  });

  return ReactDOM.createPortal(
    <Dialog
      open={true}
      maxWidth="md"
      fullWidth={true}
      onClose={(ev, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        onClose();
      }}>
      <DialogTitle
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          height: 'min-content',
        }}>
        <Box display="flex" flexDirection="column">
          <Box ml="5px">
            <PageTitle caption>{i18n('common.exportPdf')}</PageTitle>
          </Box>
          <Box style={{padding: '0px 0px 0px 5px'}}>
            <PageTitle sx={{mb: 0}}>
              {task?.titulo} {task?.tituloTarea}
            </PageTitle>
          </Box>
        </Box>
        <Tooltip followCursor title={i18n('common.cancel')}>
          <IconButton onClick={onClose} disabled={loading || loadingPdf}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <Box style={{width: '100%'}} pl={1} pr={2}>
          <FormProvider {...form}>
            <form>
              <Box display="flex" flexDirection="column" gap={2}>
                <Box>
                  <Typography variant="body2">{i18n('common.theFollowingImagesWillBeIncludedInThePdf')}</Typography>
                  <Typography variant="body2">{i18n('common.youCanUseAIFunctionsToIncludeADescriptionOfTheImages')}</Typography>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={loading || loadingPdf || isReady}
                    endIcon={
                      isReady ? (
                        <Check />
                      ) : loading ? (
                        <CircularProgress style={{width: 20, height: 20, color: 'white'}} />
                      ) : (
                        <AutoAwesomeIcon />
                      )
                    }
                    onClick={readImages}>
                    {isReady
                      ? i18n('common.allImagesHaveBeenProcessed')
                      : loading
                      ? i18n('common.processingImages')
                      : i18n('common.readImagesAndGenerateDescriptions')}
                  </Button>
                </Box>
                <Box>
                  <ImagesFormItem
                    disabled={true}
                    name="images"
                    label={i18n('entities.tarea.fields.fotosAdjuntas')}
                    storage={Storage.values.tareaFotosEnviadas}
                  />
                </Box>
              </Box>
            </form>
          </FormProvider>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'flex-start',
          p: 2,
          ml: 2,
        }}>
        <Button onClick={onClose} color="primary" disabled={loading || loadingPdf}>
          {i18n('common.cancel')}
        </Button>
        <Button
          onClick={() => {
            setLoadingPdf(true);
            onSuccess();
          }}
          endIcon={loadingPdf ? <CircularProgress style={{width: 20, height: 20}} /> : null}
          color="primary"
          variant="contained"
          disabled={loading || loadingPdf}>
          {i18n('common.save')}
          {' + '}
          {i18n('common.generatePdf')}
        </Button>
      </DialogActions>
    </Dialog>,
    (document as any).getElementById('modal-root'),
  );
}

export default TareaPrePdfModal;
