import {yupResolver} from '@hookform/resolvers/yup';
import {Check} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import UndoIcon from '@mui/icons-material/Undo';
import {Button, Grid} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import authSelectors from 'src/modules/auth/authSelectors';
import firmaEnumerators from 'src/modules/firma/firmaEnumerators';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import {getHistory} from 'src/modules/store';
import {customButtonStyle} from 'src/theme/schemes/CustomTheme';
import PendienteView from 'src/view/pendiente/view/PendienteView';
import PlanificadorTareaListItem from 'src/view/planificadorTarea/list/PlanificadorTareaListItem';
import InputMultiSelectorFormItem from 'src/view/shared/form/items/InputMultiSelectorFormItem';
import InputSignatureFormItem from 'src/view/shared/form/items/InputSignatureFormItem';
import TextAreaFormItem from 'src/view/shared/form/items/TextAreaFormItem';
import Message from 'src/view/shared/message';
import ConfirmModal from 'src/view/shared/modals/ConfirmModal';
import FormWrapper, {FormButtons} from 'src/view/shared/styles/FormWrapper';
import UbicacionListItem from 'src/view/ubicacion/list/UbicacionListItem';
import ChipUserView from 'src/view/user/view/ChipUserView';
import * as yup from 'yup';

const schema = yup.object().shape({
  descripcion: yupFormSchemas.string(i18n('entities.firma.fields.descripcion'), {
    required: false,
  }),
  estado: yupFormSchemas.enumerator(i18n('entities.firma.fields.estado'), {
    options: firmaEnumerators.estado,
    required: true,
  }),
  nota: yupFormSchemas.string(i18n('entities.firma.fields.nota'), {}),
  firma: yupFormSchemas.string(i18n('entities.firma.fields.firma'), {}),
  objetivo: yupFormSchemas.enumerator(i18n('entities.firma.fields.objetivo'), {
    required: true,
    options: firmaEnumerators.objetivo,
  }),
  responsable: yupFormSchemas.relationToOne(i18n('entities.firma.fields.responsable'), {
    required: true,
  }),
  firmada: yupFormSchemas.boolean(i18n('entities.firma.fields.firmada'), {}),
  tareasVinculadas: yupFormSchemas.relationToMany(i18n('entities.firma.fields.tareasVinculadas'), {}),
  planificacionesVinculadas: yupFormSchemas.relationToMany(i18n('entities.firma.fields.planificacionesVinculadas'), {}),
  trazabilidadesVinculadas: yupFormSchemas.relationToMany(i18n('entities.firma.fields.trazabilidadesVinculadas'), {}),
  historialInventariosVinculados: yupFormSchemas.relationToMany(i18n('entities.firma.fields.historialInventariosVinculados'), {}),
  locations: yupFormSchemas.relationToMany(i18n('common.locations'), {}),
});

function FirmaForm(props) {
  const tenantUser: any = useSelector(authSelectors.selectTenantUser);
  const userLocations = tenantUser?.perfilEmpleado?.ubicacion;

  const roles = tenantUser?.roles || [];
  const isSigningUser = roles.includes('signingUser');

  const [confirm, setConfirm] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const currentUser = useSelector(authSelectors.selectCurrentUser);

  const getFirmaDefault = () => {
    if (!props.disabled && currentUser?.id === props.record?.responsable?.id) {
      return currentUser?.firma;
    }
    return undefined;
  };

  const requestedSignature = props.record?.secondaryState === 'requestedSignature';

  const [initialValues] = useState(() => {
    const record = props.record || {};

    return {
      firma: record.firma || getFirmaDefault(),
      descripcion: record.descripcion,
      estado: record.estado === 'Pendiente' ? 'Aprobada' : record.estado,
      nota: record.nota,
      objetivo: record.objetivo,
      responsable: record.responsable,
      firmada: record.firmada,
      tareasVinculadas: record.tareasVinculadas || [],
      planificacionesVinculadas: record.planificacionesVinculadas || [],
      trazabilidadesVinculadas: record.trazabilidadesVinculadas || [],
      historialInventariosVinculados: record.historialInventariosVinculados || [],
      locations: record.locations || userLocations || [],
    };
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
    shouldUnregister: false,
  });

  const onSubmit = (values) => {
    setConfirm(false);

    values.firmada = true;
    props.onSubmit(props.record?.id, values);
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  const doConfirm = () => {
    setConfirm(true);
  };

  const doCloseConfirm = () => {
    setConfirm(false);
  };

  useEffect(() => {
    if (props.disabled) {
      return;
    }

    if (props.record?.estado !== 'Pendiente' && props.record?.firmada === true) {
      getHistory().push('/firma');
      Message.error('El documento ya ha sido firmado');
    }
  }, []);

  const {saveLoading, modal} = props;

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflow: 'auto',
          }}>
          <Grid spacing={2} container>
            {/* <Box p={2}>
              <ListItemButton onClick={() => setExpanded(!expanded)}>
                <ListItemIcon>
                  <Typography variant="h3">Ver información adicional</Typography>
                </ListItemIcon>
                <ListItemText />
                {expanded ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </Box> */}

            {/* {expanded && (
              <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                <div style={{marginBottom: 15}}>
                  <QRCode
                    value={`${config.frontendUrl.protocol}://${config.frontendUrl.host}/firma/${props.record?.id}`}
                    size={140}
                  />
                </div>
              </Grid>
            )} */}

            {props.isEditing && form.watch('tareasVinculadas').length > 0 && expanded && (
              <Grid item lg={12} md={8} sm={12} xs={12}>
                <PendienteView record={form.watch('tareasVinculadas')} />
              </Grid>
            )}

            {props.isEditing && form.watch('planificacionesVinculadas').length > 0 && expanded && (
              <Grid item lg={12} md={8} sm={12} xs={12}>
                <PlanificadorTareaListItem value={form.watch('planificacionesVinculadas')} />
              </Grid>
            )}

            {props.isEditing && form.watch('locations')?.filter(Boolean)?.length > 0 && expanded && (
              <Grid item lg={12} md={8} sm={12} xs={12}>
                <UbicacionListItem value={form.watch('locations')} />
              </Grid>
            )}

            {props.isEditing && (
              <Grid item lg={12}>
                <Grid item lg={12}>
                  <ChipUserView user={form.watch('responsable')} type={'chip'} />
                </Grid>
                <Grid item lg={12}>
                  <h2>{form.watch('descripcion')}</h2>
                </Grid>
              </Grid>
            )}

            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <InputMultiSelectorFormItem
                disabled={props.disabled}
                options={[
                  ...(requestedSignature
                    ? []
                    : [
                        {
                          label: 'Rechazar',
                          value: 'Rechazada',
                          color: 'red',
                        },
                      ]),
                  {
                    label: 'Aprobar',
                    value: 'Aprobada',
                    color: 'green',
                  },
                ]}
                name="estado"
              />
            </Grid>

            {
              // form.watch('estado') === 'Rechazada' &&
              <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                <TextAreaFormItem
                  disabled={props.disabled}
                  name="nota"
                  label={i18n('entities.firma.fields.nota')}
                  required={false}
                />
              </Grid>
            }

            {/* {!props.isEditing && (
              <Grid item lg={7} md={8} sm={12} xs={12}>
                <SelectFormItem
                  disabled={props.disabled}
                  name="objetivo"
                  label={i18n(
                    'entities.firma.fields.objetivo',
                  )}
                  options={firmaEnumerators.objetivo.map(
                    (value) => ({
                      value,
                      label: i18n(
                        `entities.firma.enumerators.objetivo.${value}`,
                      ),
                    }),
                  )}
                  required={true}
                />
              </Grid>
            )} */}

            {/* {!props.isEditing && (
              <Grid
                item
                lg={props.drawer ? 12 : 7}
                md={8}
                sm={12}
                xs={12}
              >
                <UserAutocompleteFormItem
                  disabled={props.disabled}
                  name="responsable"
                  label={i18n(
                    'entities.firma.fields.responsable',
                  )}
                  required={true}
                  showCreate={!props.modal}
                />
              </Grid>
            )} */}

            {/* <Grid item lg={7} md={8} sm={12} xs={12}>
              <SwitchFormItem
                name="firmada"
                label={i18n(
                  'entities.firma.fields.firmada',
                )}
              />
            </Grid> */}
            {(currentUser.id === props.record?.responsable?.id || form.watch('firma')) && (
              <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                <InputSignatureFormItem
                  disabled={props.disabled}
                  name={'firma'}
                  width={window.innerWidth > 768 ? window.innerWidth * (props.drawer ? 0.45 : 0.4) : 280}
                  height={300}
                />
              </Grid>
            )}
            {/* <Grid item lg={7} md={8} sm={12} xs={12}>
              <TareaAutocompleteFormItem
                name="tareasVinculadas"
                label={i18n(
                  'entities.firma.fields.tareasVinculadas',
                )}
                required={false}
                showCreate={!props.modal}
                mode="multiple"
              />
            </Grid> */}
            {/* 
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <PlanificacionAutocompleteFormItem  
                name="planificacionesVinculadas"
                label={i18n('entities.firma.fields.planificacionesVinculadas')}
                required={false}
                showCreate={!props.modal}
                mode="multiple"
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <TrazabilidadAutocompleteFormItem  
                name="trazabilidadesVinculadas"
                label={i18n('entities.firma.fields.trazabilidadesVinculadas')}
                required={false}
                showCreate={!props.modal}
                mode="multiple"
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <HistorialInventarioAutocompleteFormItem  
                name="historialInventariosVinculados"
                label={i18n('entities.firma.fields.historialInventariosVinculados')}
                required={false}
                showCreate={!props.modal}
                mode="multiple"
              />
            </Grid> */}
          </Grid>

          {!props.disabled && (
            <FormButtons
              style={{
                flexDirection: modal ? 'row-reverse' : undefined,
              }}>
              <Button
                style={{
                  width: 300,
                  ...customButtonStyle,
                }}
                variant="contained"
                color="primary"
                disabled={saveLoading}
                type="button"
                onClick={() => doConfirm()}
                startIcon={<Check />}
                size="large">
                {i18n('common.sign')}
              </Button>

              <Button
                style={{
                  ...customButtonStyle,
                }}
                disabled={saveLoading}
                onClick={onReset}
                type="button"
                startIcon={<UndoIcon />}
                size="small">
                {i18n('common.reset')}
              </Button>

              {props.onCancel ? (
                <Button
                  style={{
                    ...customButtonStyle,
                  }}
                  disabled={saveLoading}
                  onClick={() => props.onCancel()}
                  type="button"
                  startIcon={<CloseIcon />}
                  size="small">
                  {i18n('common.cancel')}
                </Button>
              ) : null}
            </FormButtons>
          )}

          {confirm && (
            <ConfirmModal
              title={i18n('common.areYouSure')}
              onConfirm={form.handleSubmit(onSubmit)}
              onClose={() => doCloseConfirm()}
              okText={i18n('common.yes')}
              cancelText={i18n('common.no')}
            />
          )}
        </form>
      </FormProvider>
    </FormWrapper>
  );
}

export default FirmaForm;
