import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'nota',
    label: i18n('entities.historialInventario.fields.nota'),
    schema: schemas.string(
      i18n('entities.historialInventario.fields.nota'),
      {},
    ),
  },
  {
    name: 'movimiento',
    label: i18n('entities.historialInventario.fields.movimiento'),
    schema: schemas.integer(
      i18n('entities.historialInventario.fields.movimiento'),
      {},
    ),
  },
  {
    name: 'stockInicial',
    label: i18n('entities.historialInventario.fields.stockInicial'),
    schema: schemas.integer(
      i18n('entities.historialInventario.fields.stockInicial'),
      {},
    ),
  },
  {
    name: 'stockFinal',
    label: i18n('entities.historialInventario.fields.stockFinal'),
    schema: schemas.integer(
      i18n('entities.historialInventario.fields.stockFinal'),
      {},
    ),
  },
  {
    name: 'ubicacion',
    label: i18n('entities.historialInventario.fields.ubicacion'),
    schema: schemas.relationToOne(
      i18n('entities.historialInventario.fields.ubicacion'),
      {},
    ),
  },
  {
    name: 'fotos',
    label: i18n('entities.historialInventario.fields.fotos'),
    schema: schemas.images(
      i18n('entities.historialInventario.fields.fotos'),
      {},
    ),
  },
  {
    name: 'documentos',
    label: i18n('entities.historialInventario.fields.documentos'),
    schema: schemas.files(
      i18n('entities.historialInventario.fields.documentos'),
      {},
    ),
  },
  {
    name: 'inventarios',
    label: i18n('entities.historialInventario.fields.inventarios'),
    schema: schemas.relationToMany(
      i18n('entities.historialInventario.fields.inventarios'),
      {},
    ),
  },
  {
    name: 'firmas',
    label: i18n('entities.historialInventario.fields.firmas'),
    schema: schemas.relationToMany(
      i18n('entities.historialInventario.fields.firmas'),
      {},
    ),
  },
];