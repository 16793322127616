const materialEnumerators = {
  unidadMedida: [
    'Kilos',
    'Litros',
  ],
  clasificacion: [
    'Reciclable',
    'NoReciclable',
    'ResiduosPeligrosos',
    'ResiduosNoPeligrosos',
    'Organico',
  ],
};

export default materialEnumerators;
