import {Box} from '@mui/material';
import 'moment/locale/es';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import authSelectors from 'src/modules/auth/authSelectors';
import recursoSelectors from 'src/modules/recurso/recursoSelectors';
import destroyActions from 'src/modules/tarea/destroy/tareaDestroyActions';
import destroySelectors from 'src/modules/tarea/destroy/tareaDestroySelectors';
import actions from 'src/modules/tarea/list/tareaListActions2';
import selectors from 'src/modules/tarea/list/tareaListSelectors2';
import tareaSelectors from 'src/modules/tarea/tareaSelectors';
import ConfirmModal from 'src/view/shared/modals/ConfirmModal';
import Pagination from 'src/view/shared/table/Pagination';
import TareaNestedTable from './TareaNestedTable';

function TareaListTable(props) {
  const [recordIdToDestroy, setRecordIdToDestroy] = useState(null);
  const dispatch = useDispatch();

  const findLoading = useSelector(selectors.selectLoading);

  const destroyLoading = useSelector(destroySelectors.selectLoading);

  const loading = findLoading || destroyLoading;

  const rows = useSelector(selectors.selectRows);
  const pagination = useSelector(selectors.selectPagination);

  const selectedKeys = useSelector(selectors.selectSelectedKeys);
  const hasRows = useSelector(selectors.selectHasRows);
  const sorter = useSelector(selectors.selectSorter);
  const isAllSelected = useSelector(selectors.selectIsAllSelected);
  const hasPermissionToEdit = useSelector(tareaSelectors.selectPermissionToEdit);
  const hasPermissionToDestroy = useSelector(tareaSelectors.selectPermissionToDestroy);

  const hasPermissionToCreateRecurso = useSelector(recursoSelectors.selectPermissionToCreate);

  const doOpenDestroyConfirmModal = (id) => {
    setRecordIdToDestroy(id);
  };

  const doCloseDestroyConfirmModal = () => {
    setRecordIdToDestroy(null);
  };

  const doChangeSort = (field) => {
    const order = sorter.field === field && sorter.order === 'asc' ? 'desc' : 'asc';

    dispatch(
      actions.doChangeSort({
        field,
        order,
      }),
    );
  };

  const doChangePagination = (pagination) => {
    dispatch(actions.doChangePagination(pagination));
  };

  const doDestroy = (id) => {
    doCloseDestroyConfirmModal();

    dispatch(destroyActions.doDestroy(id));
  };

  const doToggleAllSelected = () => {
    dispatch(actions.doToggleAllSelected());
  };

  const doToggleOneSelected = (id) => {
    dispatch(actions.doToggleOneSelected(id));
  };

  const [activeRows, setActiveRows] = useState<any[]>([]);

  const handleRowClick = (row) => {
    //console.log('%c⧭ handleRowClick', 'color: #ffcc00', {row});
    if (!row.parentTask) {
      if (activeRows[0]?.id === row.id) {
        setActiveRows([]);
        return;
      }
      setActiveRows([row]);
      return;
    }
    const rowIndex = activeRows.indexOf(row);
    const newActiveRows = [...activeRows];
    if (rowIndex !== -1) {
      newActiveRows.splice(rowIndex, 1);
    } else {
      newActiveRows.push(row);
    }
    setActiveRows(newActiveRows);
  };

  const currentUser = useSelector(authSelectors.selectCurrentUser);
  //Checkbox	Multiway	Recursos	Revisar (botón)	Eliminar	N° OT	"Tarea (Barra de progreso)"	Activo	Estado	Fecha de programación	Fecha de vencimiento	Tipo de visualización	Planificaciones enviadas	Ejecutor 	Tiempo presupuestado	Comienzo	Fin	Categoría	ISO
  return (
    <>
      <Box
        style={{
          display: 'block',
          width: '100%',
          overflowX: 'auto',
          maxHeight: 'calc(100vh - 220px)', //220 345px
        }}>
        <TareaNestedTable
          level={0}
          props={{
            rows,
            sorter,
            doChangeSort,
            doToggleAllSelected,
            doToggleOneSelected,
            selectedKeys,
            isAllSelected,
            hasRows,
            loading,
            handleRowClick,
            activeRows,
            hasPermissionToCreateRecurso,
            hasPermissionToEdit,
            hasPermissionToDestroy,
            doOpenDestroyConfirmModal,
          }}
          sendFormSilent={props.sendFormSilent}
          from={props.from}
        />
      </Box>

      <div style={{} /*{marginBottom: '-25px', marginTop: '-5px'}*/}>
        <Pagination onChange={doChangePagination} pagination={pagination} />
      </div>
      {recordIdToDestroy && (
        <ConfirmModal
          title={i18n('common.areYouSure')}
          onConfirm={() => doDestroy(recordIdToDestroy)}
          onClose={() => doCloseDestroyConfirmModal()}
          okText={i18n('common.yes')}
          cancelText={i18n('common.no')}
        />
      )}
    </>
  );
}

export default TareaListTable;
