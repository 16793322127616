import {Close} from '@mui/icons-material';
import {DateRangePicker} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {Box, IconButton, InputAdornment, TextField} from '@mui/material';
import esLocale from 'date-fns/locale/es';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {i18n} from 'src/i18n';
import FormErrors from 'src/view/shared/form/formErrors';

function DatePickerRangeFormItem(props) {
  const {label, name, hint, placeholder, autoFocus, autoComplete, required, showTime, externalErrorMessage, clearable} = props;

  const {
    register,
    formState: {touchedFields, isSubmitted, errors},
    setValue,
    control,
  } = useFormContext();

  const errorMessage = FormErrors.errorMessage(name, errors, touchedFields, isSubmitted, externalErrorMessage);

  const originalValue: any = useWatch({name, control, ...(props.defaultValue ? {defaultValue: props.defaultValue} : {})}) || [
    null,
    null,
  ];

  useEffect(() => {
    register(name);
  }, []);

  const handleChange = (value) => {
    // if (!showTime) value.startOf('day');
    setValue(name, value, {shouldValidate: true});
    props.onChange && props.onChange(value);
  };

  //const DateTimePickerComponent = showTime ? DateTimePicker : DatePicker;
  const format = props.format ? props.format : showTime ? 'dd-MM-yyyy HH:mm' : 'dd-MM-yyyy';
  const mask = showTime ? '__-__-____ __:__' : '__-__-____';

  //console.log('%c⧭ DatePickerRangeFormItem', 'color: #ffcc00', {originalValue});
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
      <DateRangePicker
        mask={mask}
        inputFormat={format}
        value={originalValue}
        calendars={1}
        onChange={handleChange}
        disableCloseOnSelect={false}
        renderInput={(startProps, endProps) => {
          //console.log('%c⧭ DateRangePicker renderInput', 'color: #1d3f73', {startProps, endProps});
          return (
            <React.Fragment>
              <TextField
                {...startProps}
                onBlur={(event) => {
                  props.onBlur && props.onBlur(event);
                }}
                label={`${label} ` + (placeholder?.length === 2 ? placeholder[0] : `${i18n('common.start')}`)}
                required={required}
                margin={props.margin || 'normal'}
                fullWidth
                size="small"
                placeholder={placeholder?.length === 2 ? placeholder[0] : `${label} ${i18n('common.start')}`}
                autoFocus={autoFocus || undefined}
                autoComplete={autoComplete || undefined}
                error={Boolean(errorMessage)}
                // InputLabelProps={{
                //   shrink: true,
                // }}
                variant="outlined"
                helperText={errorMessage || hint}
                InputProps={{
                  endAdornment:
                    clearable && originalValue[0] ? (
                      <InputAdornment position="end">
                        <IconButton
                          style={{borderRadius: 50}}
                          onClick={($event) => {
                            $event.stopPropagation();
                            handleChange([null, originalValue[1]]);
                          }}
                          onMouseDown={() => handleChange([null, originalValue[1]])}
                          size="small">
                          <Close fontSize="small" style={{color: 'gray'}} />
                        </IconButton>
                      </InputAdornment>
                    ) : undefined,
                }}
              />
              <Box sx={{mx: 2}}> - </Box>
              <TextField
                {...endProps}
                onBlur={(event) => {
                  props.onBlur && props.onBlur(event);
                }}
                label={`${label} ` + (placeholder?.length === 2 ? placeholder[1] : `${i18n('common.end')}`)}
                required={required}
                margin={props.margin || 'normal'}
                fullWidth
                size="small"
                placeholder={placeholder?.length === 2 ? placeholder[1] : `${label} ${i18n('common.end')}`}
                autoFocus={autoFocus || undefined}
                autoComplete={autoComplete || undefined}
                error={Boolean(errorMessage)}
                // InputLabelProps={{
                //   shrink: true,
                // }}
                variant="outlined"
                helperText={errorMessage || hint}
                InputProps={{
                  endAdornment:
                    clearable && originalValue[1] ? (
                      <InputAdornment position="end">
                        <IconButton
                          style={{borderRadius: 50}}
                          onClick={($event) => {
                            $event.stopPropagation();
                            handleChange([originalValue[0], null]);
                          }}
                          onMouseDown={() => handleChange([originalValue[0], null])}
                          size="small">
                          <Close fontSize="small" style={{color: 'gray'}} />
                        </IconButton>
                      </InputAdornment>
                    ) : undefined,
                }}
              />
            </React.Fragment>
          );
        }}
      />
    </LocalizationProvider>
  );
}

DatePickerRangeFormItem.defaultProps = {
  required: false,
  clearable: true,
};

DatePickerRangeFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  autoFocus: PropTypes.bool,
  required: PropTypes.bool,
  size: PropTypes.string,
  prefix: PropTypes.string,
  placeholder: PropTypes.any,
  externalErrorMessage: PropTypes.string,
  formItemProps: PropTypes.object,
  showTime: PropTypes.bool,
  onChange: PropTypes.func,
  format: PropTypes.string,
  defaultValue: PropTypes.array,
  clearable: PropTypes.bool,
};

export default DatePickerRangeFormItem;
