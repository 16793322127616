import React, {useEffect, useRef, useState} from 'react';

import {yupResolver} from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import {Button, Card, Divider, Grid, IconButton, Menu, TextField, alpha} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import {styled} from '@mui/material/styles';
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';
import {i18n} from 'src/i18n';
import authSelectors from 'src/modules/auth/authSelectors';
import PermissionChecker from 'src/modules/auth/permissionChecker';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import Permissions from 'src/security/permissions';
import Storage from 'src/security/storage';
import FirmaAutocompleteFormItem from 'src/view/firma/autocomplete/FirmaAutocompleteFormItem';
import InventarioAutocompleteFormItem from 'src/view/inventario/autocomplete/InventarioAutocompleteFormItem';
import PlanificadorTareaAutocompleteFormItem from 'src/view/planificadorTarea/autocomplete/PlanificadorTareaAutocompleteFormItem';
import FilesFormItem from 'src/view/shared/form/items/FilesFormItem';
import ImagesFormItem from 'src/view/shared/form/items/ImagesFormItem';
import TareaAutocompleteFormItem from 'src/view/tarea/autocomplete/TareaAutocompleteFormItem';
import UbicacionAutocompleteFormItem from 'src/view/ubicacion/autocomplete/UbicacionAutocompleteFormItem';
import ChipUserView from 'src/view/user/view/ChipUserView';
import * as yup from 'yup';

const permissions = Permissions.values;

const classes = {
  messageField: {
    '&.MuiTextField-root': {
      margin: '0px',
    },
    '& textarea::placeholder': {
      fontSize: '18px',
    },
    '& textarea': {
      fontSize: '18px',
    },
  },
};

const DividerWrapper = styled(Divider)(
  ({theme}) => `
        height: 40px !important;
        margin: 0 ${theme.spacing(2)};
        align-self: center;
`,
);

const Input = styled('input')({
  display: 'none',
});

const schema = yup.object().shape({
  comunicacionInterna: yupFormSchemas.relationToOne(i18n('entities.mensajeInterno.fields.comunicacionInterna'), {
    required: true,
  }),
  mensaje: yupFormSchemas.string(i18n('entities.mensajeInterno.fields.mensaje'), {
    required: true,
  }),
  fotosAdjunta: yupFormSchemas.images(i18n('entities.mensajeInterno.fields.fotosAdjunta'), {
    required: false,
  }),
  documentosAdjuntos: yupFormSchemas.files(i18n('entities.mensajeInterno.fields.documentosAdjuntos'), {}),
  tareasReferenciadas: yupFormSchemas.relationToMany(i18n('entities.mensajeInterno.fields.tareasReferenciadas'), {
    required: false,
  }),
  planificadoresTareasReferenciadas: yupFormSchemas.relationToMany(
    i18n('entities.mensajeInterno.fields.planificadoresTareasReferenciadas'),
    {
      required: false,
    },
  ),
  firmasReferenciadas: yupFormSchemas.relationToMany(i18n('entities.mensajeInterno.fields.firmasReferenciadas'), {
    required: false,
  }),
  ubicacionesReferenciadas: yupFormSchemas.relationToMany(i18n('entities.mensajeInterno.fields.ubicacionesReferenciadas'), {
    required: false,
  }),
  inventariosReferenciados: yupFormSchemas.relationToMany(i18n('entities.mensajeInterno.fields.inventariosReferenciados'), {
    required: false,
  }),
});

const IconButtonPrimary = styled(IconButton)(
  ({theme}: any) => `
    margin-left: ${theme.spacing(1)};
    background: ${theme.colors.alpha.trueWhite[10]};
    color: ${theme.colors.alpha.trueWhite[70]};
    padding: 0;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    transition: ${theme.transitions.create(['background', 'color'])};

    &.active,
    &:active,
    &:hover {
      background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
      color: ${theme.colors.alpha.trueWhite[100]};
    }
`,
);

function BottomBarContent(props) {
  const {t} = useTranslation();
  const match = useRouteMatch();
  const ref = useRef(null);
  const [referenceType, setReferenceType]: any = useState();

  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const user = null;

  const currentTenant = useSelector(authSelectors.selectCurrentTenant);

  const currentUser = useSelector(authSelectors.selectCurrentUser);

  const permissionChecker = new PermissionChecker(currentTenant, currentUser);

  const currentRoles = useSelector(authSelectors.selectRoles);

  const [initialValues] = useState(() => {
    const record = props.record || {};

    return {
      mensaje: record.mensaje,
      fotosAdjunta: record.fotosAdjunta || [],
      documentosAdjuntos: record.documentosAdjuntos || [],
      planificadoresTareasReferenciadas: record.planificadoresTareasReferenciadas || [],
      tareasReferenciadas: record.tareasReferenciadas || [],
      firmasReferenciadas: record.firmasReferenciadas || [],

      ubicacionesReferenciadas: record.ubicacionesReferenciadas || [],
      inventariosReferenciados: record.inventariosReferenciados || [],
    };
  });

  useEffect(() => {
    form.register('mensaje');
    form.register('fotosAdjunta');
    form.register('documentosAdjuntos');

    form.register('planificadoresTareasReferenciadas');
    form.register('tareasReferenciadas');
    form.register('firmasReferenciadas');

    form.register('comunicacionInterna');
    form.setValue('comunicacionInterna', {
      id: props.recordComunicacionInterna?.id || match.params.idComunicacion,
      label: '',
    });
  }, []);

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
    shouldUnregister: false,
  });

  const onSubmit = (values) => {
    form.setValue('mensaje', '');
    form.setValue('fotosAdjunta', null);
    form.setValue('documentosAdjuntos', null);
    form.setValue('planificadoresTareasReferenciadas', null);
    form.setValue('tareasReferenciadas', null);
    form.setValue('firmasReferenciadas', null);
    form.setValue('ubicacionesReferenciadas', null);
    form.setValue('inventariosReferenciados', null);
    setReferenceType(null);

    values.drawer = true;

    props.onSubmit(props.record?.id, values);
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  return (
    <FormProvider {...form}>
      <Grid container direction="row" alignItems="center" justifyContent="flex-start" pl={2}>
        <Grid item lg={7} xs={12}>
          {referenceType === 'Fotos' && (
            <ImagesFormItem
              name="fotosAdjunta"
              label={i18n('entities.mensajeInterno.fields.fotosAdjunta')}
              required={false}
              storage={Storage.values.mensajeInternoFotosAdjunta}
              max={3}
            />
          )}

          {referenceType === 'Documentos' && (
            <FilesFormItem
              name="documentosAdjuntos"
              label={i18n('entities.mensajeInterno.fields.documentosAdjuntos')}
              required={false}
              storage={Storage.values.mensajeInternoDocumentosAdjuntos}
              max={3}
              formats={undefined}
            />
          )}

          {referenceType === 'Tareas' && (
            <TareaAutocompleteFormItem
              name="tareasReferenciadas"
              entity="tarea"
              disabled={props.disabled}
              required={false}
              label={i18n('common.selectTasks')}
              showCreate={!props.modal}
              mode="multiple"
            />
          )}
          {referenceType === 'Planificaciones' && (
            <PlanificadorTareaAutocompleteFormItem
              name="planificadoresTareasReferenciadas"
              entity="planificadorTarea"
              disabled={props.disabled}
              required={false}
              label={i18n('common.selectPlannings')}
              showCreate={!props.modal}
              mode="multiple"
            />
          )}
          {referenceType === 'Firmas' && (
            <FirmaAutocompleteFormItem
              name="firmasReferenciadas"
              entity="firma"
              disabled={props.disabled}
              required={false}
              label={i18n('common.selectSignatures')}
              showCreate={!props.modal}
              mode="multiple"
            />
          )}

          {referenceType === 'Ubicaciones' && (
            <UbicacionAutocompleteFormItem
              name="ubicacionesReferenciadas"
              entity="ubicacion"
              disabled={props.disabled}
              required={false}
              label={i18n('common.selectLocations')}
              showCreate={!props.modal}
              mode="multiple"
            />
          )}

          {referenceType === 'Inventarios' && (
            <InventarioAutocompleteFormItem
              name="inventariosReferenciados"
              entity="inventario"
              disabled={props.disabled}
              required={false}
              label={i18n('common.selectInventories')}
              showCreate={!props.modal}
              mode="multiple"
            />
          )}
        </Grid>
      </Grid>

      <Card sx={{display: 'flex', alignItems: 'center', p: 2}}>
        <Grid container direction="row" alignItems="center">
          <Grid item xl={props.drawer ? 3 : 1} xs={4} lg={props.drawer ? 3 : 2}>
            <Grid container direction="row">
              <ChipUserView size={40} type={'avatar'} user={currentUser} />

              <PopupState variant="popover" popupId="id-popup-configurations">
                {(popupState) => [
                  <IconButtonPrimary key={0} color="secondary" style={{color: 'black'}} {...bindTrigger(popupState)}>
                    <AddIcon />
                  </IconButtonPrimary>,
                  <Menu key={1} {...bindMenu(popupState)}>
                    <MenuItem
                      onClick={() => {
                        popupState.close();
                        setReferenceType('Fotos');
                      }}>
                      {i18n('common.fotos')}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        popupState.close();
                        setReferenceType('Documentos');
                      }}>
                      {i18n('common.documentos')}
                    </MenuItem>
                    {(currentRoles.includes('admin') ||
                      currentRoles.includes('administrador') ||
                      currentRoles.includes('administradorOperaciones')) && [
                      <MenuItem
                        key={0}
                        onClick={() => {
                          popupState.close();
                          setReferenceType('Tareas');
                        }}>
                        {i18n('entities.tarea.title')}
                      </MenuItem>,
                      <MenuItem
                        key={1}
                        onClick={() => {
                          popupState.close();
                          setReferenceType('Firmas');
                        }}>
                        {i18n('entities.firma.title')}
                      </MenuItem>,
                      <MenuItem
                        key={2}
                        onClick={() => {
                          popupState.close();
                          setReferenceType('Planificaciones');
                        }}>
                        {i18n('entities.planificadorTarea.title')}
                      </MenuItem>,
                      <MenuItem
                        key={3}
                        onClick={() => {
                          popupState.close();
                          setReferenceType('Ubicaciones');
                        }}>
                        {i18n('entities.ubicacion.title')}
                      </MenuItem>,
                      <MenuItem
                        key={4}
                        onClick={() => {
                          popupState.close();
                          setReferenceType('Inventarios');
                        }}>
                        {i18n('entities.inventario.title')}
                      </MenuItem>,
                    ]}
                  </Menu>,
                ]}
              </PopupState>
            </Grid>
          </Grid>

          <Grid
            item
            xl={props.drawer ? 8 : 10}
            lg={props.drawer ? 7 : 8}
            xs={8}
            style={{
              maxHeight: '30vh',
              overflowY: 'scroll',
            }}>
            <TextField
              sx={classes.messageField}
              multiline
              hiddenLabel
              fullWidth
              value={form.watch('mensaje')}
              placeholder={t('Mensaje...')}
              onChange={(event) => {
                form.setValue('mensaje', event.target.value);
              }}
            />
          </Grid>
          {/* <Grid item xs={1}>
            <DrawerButton
              drawerId={'DrawerMensajeInternoListTable'}
              type={props.type || 'button'}
              size={props.size}
              entity={'mensajeInterno'}
              tooltipTitle={'Vista Admin'}
              buttonTitle={'Vista Admin'}
              width={window.innerWidth * 0.55}
              //record={record}
              component={<MensajeInternoListTable />}
            />
          </Grid> */}
          <Grid item xl={props.drawer ? 12 : 12} lg={props.drawer ? 12 : 12} xs={12}>
            <Grid container justifyContent={'flex-start'} alignItems="center">
              <Button
                style={{marginTop: 15}}
                disabled={!form.watch('mensaje') || form.watch('mensaje')?.length === 0}
                onClick={form.handleSubmit(onSubmit)}
                startIcon={<SendTwoToneIcon />}
                variant="contained">
                {t('Enviar')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </FormProvider>
  );
}

export default BottomBarContent;
