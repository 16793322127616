import {Avatar, Chip, Tooltip} from '@mui/material';
import React from 'react';
import config from 'src/config';

function ChipUserView(props) {
  const {user, type, size, sx} = props;

  // const goToProfit360 = () => {
  //   if (hasPermissionToRead) {
  //     getHistory().push(`/user/` + user.id);
  //   }
  // };

  if (user) {
    if (!user.avatars) {
      return (
        <Tooltip followCursor title={user.email + (user.fullName ? ' - ' + user.fullName : '')}>
          <Chip label={user.fullName || user.email} onClick={() => console.log('click')} variant="outlined" color="primary" />
        </Tooltip>
      );
    } else {
      if (type === 'chip') {
        return (
          <Tooltip followCursor title={user.email + (user.fullName ? ' - ' + user.fullName : '')}>
            <Chip
              avatar={
                <Avatar
                  src={config.backendUrl + '/file/download?privateUrl=' + user.avatars[0]?.privateUrl}
                  alt={user.fullName || user.email}
                />
              }
              label={user.fullName || user.email}
              onClick={() => console.log('click')}
              variant="outlined"
              color="primary"
            />
          </Tooltip>
        );
      } else {
        return (
          <Tooltip followCursor title={user.email + (user.fullName ? ' - ' + user.fullName : '')}>
            <Avatar
              onClick={() => console.log('click')}
              style={{width: size, height: size}}
              src={config.backendUrl + '/file/download?privateUrl=' + user?.avatars[0]?.privateUrl}
              alt={user.email + (user.fullName ? ' - ' + user.fullName : '')}
              sx={sx}
            />
          </Tooltip>
        );
      }
    }
  }

  return <div></div>;
}

export default ChipUserView;
