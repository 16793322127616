import {i18n} from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.dataLake.fields.id'),
  },
  {
    name: 'type',
    label: i18n('entities.dataLake.fields.type'),
  },
  {
    name: 'dataId',
    label: i18n('entities.dataLake.fields.dataId'),
  },
  {
    name: 'dateStart',
    label: i18n('entities.dataLake.fields.dateStart'),
  },
  {
    name: 'dateEnd',
    label: i18n('entities.dataLake.fields.dateEnd'),
  },
  {
    name: 'name',
    label: i18n('entities.dataLake.fields.name'),
  },
  {
    name: 'content',
    label: i18n('entities.dataLake.fields.content'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.dataLake.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.dataLake.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
