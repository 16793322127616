import {yupResolver} from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import {Button, Grid} from '@mui/material';
import React, {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {i18n} from 'src/i18n';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import DivisionAutocompleteFormItem from 'src/view/division/autocomplete/DivisionAutocompleteFormItem';
import PaisAutocompleteFormItem from 'src/view/pais/autocomplete/PaisAutocompleteFormItem';
import RubroAutocompleteFormItem from 'src/view/rubro/autocomplete/RubroAutocompleteFormItem';
import SegmentacionAutocompleteFormItem from 'src/view/segmentacion/autocomplete/SegmentacionAutocompleteFormItem';
import GoogleAutocompleteFormItem from 'src/view/shared/form/items/GoogleAutocompleteFormItem.js';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import InputNumberFormItem from 'src/view/shared/form/items/InputNumberFormItem';
import TextAreaFormItem from 'src/view/shared/form/items/TextAreaFormItem';
import FormWrapper, {FormButtons} from 'src/view/shared/styles/FormWrapper';
import * as yup from 'yup';

const schema = yup.object().shape({
  nombre: yupFormSchemas.string(i18n('entities.sucursal.fields.nombre'), {
    required: true,
  }),
  direccionTienda: yupFormSchemas.string(i18n('entities.sucursal.fields.direccionTienda'), {}),
  ubicacionGoogle: yupFormSchemas.string(i18n('entities.sucursal.fields.ubicacionGoogle'), {}),
  telefono: yupFormSchemas.integer(i18n('entities.sucursal.fields.telefono'), {}),
  rubro: yupFormSchemas.relationToMany(i18n('entities.sucursal.fields.rubro'), {}),
  segmentacion: yupFormSchemas.relationToOne(i18n('entities.sucursal.fields.segmentacion'), {}),
  division: yupFormSchemas.relationToOne(i18n('entities.sucursal.fields.division'), {}),
  pais: yupFormSchemas.relationToOne(i18n('entities.sucursal.fields.pais'), {}),
  latitud: yupFormSchemas.decimal(i18n('entities.sucursal.fields.latitud'), {}),
  longitud: yupFormSchemas.decimal(i18n('entities.sucursal.fields.longitud'), {}),
  codigoCiudad: yupFormSchemas.string(i18n('entities.sucursal.fields.codigoCiudad'), {}),
  codigoComuna: yupFormSchemas.string(i18n('entities.sucursal.fields.codigoComuna'), {}),
  descripcionTienda: yupFormSchemas.string(i18n('entities.sucursal.fields.descripcionTienda'), {}),
  razonSocial: yupFormSchemas.string(i18n('entities.sucursal.fields.razonSocial'), {}),
  rut: yupFormSchemas.string(i18n('entities.sucursal.fields.rut'), {}),
});

function SucursalForm(props) {
  const [initialValues] = useState(() => {
    const record = props.record || {};

    return {
      nombre: record.nombre,
      direccionTienda: record.direccionTienda,
      ubicacionGoogle: record.ubicacionGoogle,
      telefono: record.telefono,
      rubro: record.rubro || [],
      segmentacion: record.segmentacion,
      division: record.division,
      pais: record.pais,
      latitud: record.latitud,
      longitud: record.longitud,
      codigoCiudad: record.codigoCiudad,
      codigoComuna: record.codigoComuna,
      descripcionTienda: record.descripcionTienda,
      razonSocial: record.razonSocial,
      rut: record.rut,
    };
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
  });

  const onSubmit = (values) => {
    props.onSubmit(props.record?.id, values);
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  const {saveLoading, modal} = props;

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Grid spacing={2} container>
            <Grid item lg={6} md={8} sm={12} xs={12}>
              <InputFormItem name="nombre" label={i18n('entities.sucursal.fields.nombre')} required={true} autoFocus />
            </Grid>
            <Grid item lg={6} md={8} sm={12} xs={12}>
              <InputFormItem name="direccionTienda" label={i18n('entities.sucursal.fields.direccionTienda')} required={false} />
            </Grid>
            <Grid item lg={6} md={8} sm={12} xs={12}>
              <GoogleAutocompleteFormItem
                name="ubicacionGoogle"
                label={i18n('entities.sucursal.fields.ubicacionGoogle')}
                required={false}
              />
            </Grid>
            <Grid item lg={6} md={8} sm={12} xs={12}>
              <InputNumberFormItem name="telefono" label={i18n('entities.sucursal.fields.telefono')} required={false} />
            </Grid>
            <Grid item lg={6} md={8} sm={12} xs={12}>
              <RubroAutocompleteFormItem
                name="rubro"
                label={i18n('entities.sucursal.fields.rubro')}
                required={false}
                showCreate={!props.modal}
                mode="multiple"
              />
            </Grid>
            <Grid item lg={6} md={8} sm={12} xs={12}>
              <SegmentacionAutocompleteFormItem
                name="segmentacion"
                label={i18n('entities.sucursal.fields.segmentacion')}
                required={false}
                showCreate={!props.modal}
              />
            </Grid>
            <Grid item lg={6} md={8} sm={12} xs={12}>
              <DivisionAutocompleteFormItem
                name="division"
                label={i18n('entities.sucursal.fields.division')}
                required={false}
                showCreate={!props.modal}
              />
            </Grid>
            <Grid item lg={6} md={8} sm={12} xs={12}>
              <PaisAutocompleteFormItem
                name="pais"
                label={i18n('entities.sucursal.fields.pais')}
                required={false}
                showCreate={!props.modal}
              />
            </Grid>
            <Grid item lg={6} md={8} sm={12} xs={12}>
              <InputFormItem name="codigoCiudad" label={i18n('entities.sucursal.fields.codigoCiudad')} required={false} />
            </Grid>
            <Grid item lg={6} md={8} sm={12} xs={12}>
              <InputFormItem name="codigoComuna" label={i18n('entities.sucursal.fields.codigoComuna')} required={false} />
            </Grid>
            <Grid item lg={6} md={8} sm={12} xs={12}>
              <TextAreaFormItem
                name="descripcionTienda"
                label={i18n('entities.sucursal.fields.descripcionTienda')}
                required={false}
              />
            </Grid>
            <Grid item lg={6} md={8} sm={12} xs={12}>
              <InputFormItem name="razonSocial" label={i18n('entities.sucursal.fields.razonSocial')} required={false} />
            </Grid>
            <Grid item lg={6} md={8} sm={12} xs={12}>
              <InputFormItem name="rut" label={i18n('entities.sucursal.fields.rut')} required={false} />
            </Grid>
          </Grid>
          <FormButtons
            style={{
              flexDirection: modal ? 'row-reverse' : undefined,
            }}>
            <Button
              variant="contained"
              color="primary"
              disabled={saveLoading}
              type="button"
              onClick={form.handleSubmit(onSubmit)}
              startIcon={<SaveIcon />}
              size="small">
              {i18n('common.save')}
            </Button>

            <Button disabled={saveLoading} onClick={onReset} type="button" startIcon={<UndoIcon />} size="small">
              {i18n('common.reset')}
            </Button>

            {props.onCancel ? (
              <Button
                disabled={saveLoading}
                onClick={() => props.onCancel()}
                type="button"
                startIcon={<CloseIcon />}
                size="small">
                {i18n('common.cancel')}
              </Button>
            ) : null}
          </FormButtons>
        </form>
      </FormProvider>
    </FormWrapper>
  );
}

export default SucursalForm;
