import {Avatar, Chip, Tooltip} from '@mui/material';
import React from 'react';
import {useSelector} from 'react-redux';
import config from 'src/config';
import activoSelectors from 'src/modules/activo/activoSelectors';
import {getHistory} from 'src/modules/store';

function ChipActivoView(props) {
  const {record, type, size} = props;

  const hasPermissionToRead = useSelector(activoSelectors.selectPermissionToRead);

  const redirectTo = () => {
    if (hasPermissionToRead) {
      getHistory().push(`/activo/` + record.id);
    }
  };

  const photoUrl = record?.fotos ? config.backendUrl + '/file/download?privateUrl=' + record?.fotos[0]?.privateUrl : '';

  const labelWithNSerie = (record?.nombre ? record.nombre + (record.codigo ? ' | ' + record.codigo : '') : record?.label) || ' ';

  const label = (record?.nombre || record?.label)?.substr(0, 25);

  if (type === 'chip') {
    return (
      <Tooltip followCursor title={labelWithNSerie}>
        <Chip
          avatar={<Avatar style={{width: size, height: size}} src={photoUrl || ''} alt={label} />}
          label={label}
          onClick={() => console.log('click')}
          variant="outlined"
          color="primary"
        />
      </Tooltip>
    );
  }

  if (type === 'avatar') {
    return (
      <Tooltip followCursor title={labelWithNSerie}>
        <Avatar onClick={() => console.log('click')} style={{width: size, height: size}} src={photoUrl || ''} alt={label} />
      </Tooltip>
    );
  }

  return <div></div>;
}

export default ChipActivoView;
