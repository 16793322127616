import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/comunicacionInterna/importer/comunicacionInternaImporterSelectors';
import ComunicacionInternaService from 'src/modules/comunicacionInterna/comunicacionInternaService';
import fields from 'src/modules/comunicacionInterna/importer/comunicacionInternaImporterFields';
import { i18n } from 'src/i18n';

const comunicacionInternaImporterActions = importerActions(
  'COMUNICACIONINTERNA_IMPORTER',
  selectors,
  ComunicacionInternaService.import,
  fields,
  i18n('entities.comunicacionInterna.importer.fileName'),
);

export default comunicacionInternaImporterActions;