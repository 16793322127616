import statuses from 'src/modules/shared/importer/importerStatuses';

const INITIAL_PAGE_SIZE = 10;

const reducers = (actions) => {
  const initialData = {
    rows: [] as Array<any>,
    errorMessage: null,
    importing: false,
    completed: false,
    pagination: {
      current: 1,
      pageSize: INITIAL_PAGE_SIZE,
    },
    sorter: {},
    fieldConfig: null as any,
  };

  return (state = initialData, {type, payload}) => {
    if (type === actions.PATCH_STATE) {
      return {
        ...state,
        ...payload,
      };
    }

    if (type === actions.RESETED) {
      return {
        ...initialData,
        fieldConfig: state.fieldConfig,
      };
    }

    if (type === actions.PAGINATION_CHANGED) {
      return {
        ...state,
        pagination: payload || {
          current: 1,
          pageSize: INITIAL_PAGE_SIZE,
        },
      };
    }

    if (type === actions.SORTER_CHANGED) {
      return {
        ...state,
        sorter: payload.sorter || {},
        rows: payload.sortedRows || [],
      };
    }

    if (type === actions.FILE_READ_ERROR) {
      return {
        ...state,
        errorMessage: payload.message ? payload.message : payload,
      };
    }

    if (type === actions.FILE_READ_SUCCESS) {
      return {
        ...state,
        errorMessage: null,
        rows: payload,
      };
    }

    if (type === actions.IMPORT_STARTED) {
      return {
        ...state,
        importing: true,
      };
    }

    if (type === actions.IMPORT_PAUSED) {
      return {
        ...state,
        importing: false,
      };
    }

    if (type === actions.IMPORT_SUCCESS) {
      return {
        ...state,
        importing: false,
        completed: true,
      };
    }

    if (type === actions.IMPORT_ERROR) {
      return {
        ...state,
        importing: false,
      };
    }

    if (type === actions.IMPORT_MULTI_SUCCESS) {
      const items = (state.rows || []).filter((item) => payload.lines.includes(item._line));

      if (!items || !items.length) {
        return;
      }

      items.forEach((item) => {
        item._status = statuses.IMPORTED;
      });

      return {
        ...state,
        rows: [...state.rows],
      };
    }

    if (type === actions.IMPORT_BATCH_SUCCESS) {
      let items: any = [];
      if (Array.isArray(payload.line)) {
        items = (state.rows || []).filter((item) => payload.line.includes(item._line));
      } else {
        const item = (state.rows || []).find((item) => item._line === payload.line);
        if (!item) return;
        items = [item];
      }

      if (!items || !items.length) return;

      items.forEach((item) => {
        item._status = statuses.IMPORTED;
      });

      return {
        ...state,
        rows: [...state.rows],
      };
    }

    if (type === actions.IMPORT_BATCH_ERROR) {
      const item = (state.rows || []).find((item) => item._line === payload.line);

      if (!item) {
        return;
      }

      item._status = statuses.ERROR;
      item._errorMessage = payload.errorMessage;

      return {
        ...state,
        rows: [...state.rows],
      };
    }

    return state;
  };
};
export default reducers;
