import {i18n} from 'src/i18n';
import schemas from 'src/modules/shared/yup/yupImporterSchemas';

export default [
  {
    name: 'dataId',
    label: i18n('entities.data.fields.dataId'),
    schema: schemas.string(i18n('entities.data.fields.dataId'), {
      required: true,
    }),
  },
  // {
  //   name: 'dateStart',
  //   label: i18n('entities.data.fields.dateStart'),
  //   schema: schemas.date(i18n('entities.data.fields.dateStart'), {
  //     required: false,
  //   }),
  // },
  // {
  //   name: 'dateEnd',
  //   label: i18n('entities.data.fields.dateEnd'),
  //   schema: schemas.date(i18n('entities.data.fields.dateEnd'), {
  //     required: false,
  //   }),
  // },
  // {
  //   name: 'name',
  //   label: i18n('entities.data.fields.name'),
  //   schema: schemas.string(i18n('entities.data.fields.name'), {
  //     required: false,
  //   }),
  // },
  // {
  //   name: 'content',
  //   label: i18n('entities.data.fields.content'),
  //   schema: schemas.string(i18n('entities.data.fields.content'), {
  //     required: true,
  //   }),
  // },
];
