import {i18n} from 'src/i18n';
import Errors from 'src/modules/shared/error/errors';
import Exporter from 'src/modules/shared/exporter/exporter';
//import {PdfCalendario} from 'src/modules/shared/pdf/pdfCalendario';
import AbsenceService from 'src/modules/absence/absenceService';
import exporterFields from 'src/modules/absence/list/absenceListExporterFields';
import selectors from 'src/modules/absence/list/absenceListSelectors';

const prefix = 'ABSENCE_LIST';

const absenceListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,
  SET_HAS_FILTER: `${prefix}SET_HAS_FILTER`,

  RESETED: `${prefix}_RESETED`,
  TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
  TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
  CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  PATCH_RECORD: `${prefix}_SET_RECORD`,

  doClearAllSelected() {
    return {
      type: absenceListActions.CLEAR_ALL_SELECTED,
    };
  },

  doToggleAllSelected() {
    return {
      type: absenceListActions.TOGGLE_ALL_SELECTED,
    };
  },

  doToggleOneSelected(id) {
    return {
      type: absenceListActions.TOGGLE_ONE_SELECTED,
      payload: id,
    };
  },

  doReset:
    (emptyValues = {}) =>
    async (dispatch) => {
      dispatch({
        type: absenceListActions.RESETED,
        payload: emptyValues,
      });

      dispatch(absenceListActions.doFetchCurrentFilter());
    },

  doExport: () => async (dispatch, getState) => {
    try {
      if (!exporterFields || !exporterFields.length) {
        throw new Error('exporterFields is required');
      }

      dispatch({
        type: absenceListActions.EXPORT_STARTED,
      });

      const filter = selectors.selectFilter(getState());
      const response = await AbsenceService.list(filter, selectors.selectOrderBy(getState()), null, null);

      new Exporter(exporterFields, i18n('entities.absence.exporterFileName')).transformAndExportAsExcelFile(response.rows);

      dispatch({
        type: absenceListActions.EXPORT_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: absenceListActions.EXPORT_ERROR,
      });
    }
  },

  doChangePagination: (pagination) => async (dispatch, getState) => {
    dispatch({
      type: absenceListActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(absenceListActions.doFetchCurrentFilter());
  },

  doChangeSort: (sorter) => async (dispatch, getState) => {
    dispatch({
      type: absenceListActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(absenceListActions.doFetchCurrentFilter());
  },

  doFetchCurrentFilter: () => async (dispatch, getState) => {
    const filter = selectors.selectFilter(getState());
    const rawFilter = selectors.selectRawFilter(getState());
    //console.log('%c⧭ doFetchCurrentFilter', 'color: #e5de73', {filter, rawFilter});
    dispatch(absenceListActions.doFetch(filter, rawFilter, true));
  },

  setHasFilter:
    (filter, atLeastHast: string[] = []) =>
    async (dispatch, getState) => {
      try {
        const hasFilter = !!Object.keys(filter).find((key) => {
          if (atLeastHast.length && !atLeastHast.includes(key)) return false;
          if (Array.isArray(filter[key])) {
            return filter[key].length && filter[key][0];
          }
          return filter[key];
        });
        //console.log('%c⧭ setHasFilter', 'color: #731d1d', {hasFilter, filter});
        dispatch({
          type: absenceListActions.SET_HAS_FILTER,
          payload: {hasFilter, filter},
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: absenceListActions.FETCH_ERROR,
        });
      }
    },

  doFetch:
    (filter?, rawFilter?, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: absenceListActions.FETCH_STARTED,
          payload: {filter, rawFilter, keepPagination},
        });

        const response = await AbsenceService.list(
          filter,
          selectors.selectOrderBy(getState()),
          selectors.selectLimit(getState()),
          selectors.selectOffset(getState()),
        );
        // for(let row of response.rows){
        //   row.children = response.rows.map(row => { return {...row}});
        //   for(let rowb of row.children){
        //     rowb.children = row.children.map(row => { return {...row}});
        //     for(let rowc of rowb.children){
        //       rowc.children = rowb.children.map(row => { return {...row}});
        //     }
        //   }
        // }
        dispatch({
          type: absenceListActions.FETCH_SUCCESS,
          payload: {
            rows: response.rows,
            count: response.count,
          },
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: absenceListActions.FETCH_ERROR,
        });
      }
    },
};

export default absenceListActions;
