import {yupResolver} from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import {Button, Grid} from '@mui/material';
import React, {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import QRCode from 'react-qr-code';
import config from 'src/config';
import {i18n} from 'src/i18n';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import {magicFlexBox} from 'src/theme/schemes/CustomTheme';
import ActivoAutocompleteFormItem from 'src/view/activo/autocomplete/ActivoAutocompleteFormItem';
import ActivoListItem from 'src/view/activo/list/ActivoListItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import InputNumberFormItem from 'src/view/shared/form/items/InputNumberFormItem';
import ConfirmModal from 'src/view/shared/modals/ConfirmModal';
import FormWrapper, {FormButtons} from 'src/view/shared/styles/FormWrapper';
import * as yup from 'yup';

const schema = yup.object().shape({
  descripcion: yupFormSchemas.string(i18n('entities.inventario.fields.descripcion'), {
    required: true,
  }),
  activo: yupFormSchemas.relationToOne(i18n('entities.inventario.fields.activo'), {
    required: true,
  }),
  ubicacion: yupFormSchemas.relationToOne(i18n('entities.inventario.fields.ubicacion'), {
    required: false,
  }),
  stockDisponible: yupFormSchemas.integer(i18n('entities.inventario.fields.stockDisponible'), {
    required: false,
  }),
  stockCritico: yupFormSchemas.integer(i18n('entities.inventario.fields.stockCritico'), {}),
  stockMaximo: yupFormSchemas.integer(i18n('entities.inventario.fields.stockMaximo'), {}),
  notas: yupFormSchemas.relationToMany(i18n('entities.inventario.fields.notas'), {}),
});

function InventarioForm(props) {
  const [confirm, setConfirm] = useState(false);
  const [initialValues] = useState(() => {
    const record = props.record || {};

    return {
      descripcion: record.descripcion,
      activo: record.activo,
      ubicacion: record.ubicacion,
      stockDisponible: record.stockDisponible,
      stockCritico: record.stockCritico,
      stockMaximo: record.stockMaximo,
      notas: record.notas || [],
    };
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
    shouldUnregister: false,
  });

  const onSubmit = (values) => {
    setConfirm(false);
    props.onSubmit(props.record?.id, values);
  };

  const doConfirm = () => {
    setConfirm(true);
  };

  const doCloseConfirm = () => {
    setConfirm(false);
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  const {saveLoading, modal} = props;

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          style={
            {
              ...magicFlexBox,
              overflow: 'auto',
              paddingRight: 16,
              marginBottom: 8,
            } as any
          }>
          <Grid spacing={2} container>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <h1>{form.watch('descripcion')}</h1>

              <div style={{marginBottom: 15}}>
                <QRCode
                  value={`${config.frontendUrl.protocol}://${config.frontendUrl.host}/inventario/${props.record?.id}`}
                  size={140}
                />
              </div>

              {form.watch('activo') && (
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <ActivoListItem value={form.watch('activo')} />
                </Grid>
              )}
            </Grid>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <InputFormItem
                name="descripcion"
                disabled={props.disabled}
                label={i18n('entities.inventario.fields.descripcion')}
                required={true}
                autoFocus
              />
            </Grid>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <ActivoAutocompleteFormItem
                name="activo"
                disabled={props.disabled}
                label={i18n('entities.inventario.fields.activo')}
                required={true}
                showCreate={!props.modal}
              />
            </Grid>
            {/* <Grid item lg={props.drawer?12:7} md={8} sm={12} xs={12}>
              <UbicacionAutocompleteFormItem
                name="ubicacion"
                disabled={props.disabled}
                label={i18n(
                  'entities.inventario.fields.ubicacion',
                )}
                required={true}
                showCreate={!props.modal}
              />
            </Grid> */}

            {props.disabled && (
              <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                <InputNumberFormItem
                  name="stockDisponible"
                  disabled={props.disabled}
                  label={i18n('entities.inventario.fields.stockDisponible')}
                  required={true}
                />
              </Grid>
            )}

            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <InputNumberFormItem
                name="stockCritico"
                disabled={props.disabled}
                label={i18n('entities.inventario.fields.stockCritico')}
                required={false}
              />
            </Grid>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <InputNumberFormItem
                name="stockMaximo"
                disabled={props.disabled}
                label={i18n('entities.inventario.fields.stockMaximo')}
                required={false}
              />
            </Grid>
            <div style={{padding: 20}}></div>

            {/* <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <RegistroAutocompleteFormItem
                name="notas"
                disabled={props.disabled}
                label={i18n('entities.inventario.fields.notas')}
                required={false}
                showCreate={!props.modal}
                mode="multiple"
              />
            </Grid> */}
            {/* <Grid item lg={props.drawer?12:7} md={8} sm={12} xs={12}>
              <HistorialInventarioAutocompleteFormItem
                name="restock"
                disabled={props.disabled}
                label={i18n(
                  'entities.inventario.fields.restock',
                )}
                required={false}
                showCreate={!props.modal}
                mode="multiple"
              />
            </Grid> */}
          </Grid>
        </form>
        {!props.disabled && (
          <FormButtons
            style={{
              flexDirection: modal ? 'row-reverse' : undefined,
            }}>
            <Button
              variant="contained"
              color="primary"
              disabled={saveLoading}
              type="button"
              onClick={form.handleSubmit(onSubmit)}
              //onClick={() => doConfirm()}
              startIcon={<SaveIcon />}
              size="small">
              {i18n('common.save')}
            </Button>

            <Button disabled={saveLoading} onClick={onReset} type="button" startIcon={<UndoIcon />} size="small">
              {i18n('common.reset')}
            </Button>

            {props.onCancel ? (
              <Button
                disabled={saveLoading}
                onClick={() => props.onCancel()}
                type="button"
                startIcon={<CloseIcon />}
                size="small">
                {i18n('common.cancel')}
              </Button>
            ) : null}
          </FormButtons>
        )}
      </FormProvider>
      {confirm && (
        <ConfirmModal
          title={i18n('common.areYouSure')}
          onConfirm={form.handleSubmit(onSubmit)}
          onClose={() => doCloseConfirm()}
          okText={i18n('common.yes')}
          cancelText={i18n('common.no')}
        />
      )}
    </FormWrapper>
  );
}

export default InventarioForm;
