import {Box, Tab, Tabs} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';
import {i18n} from 'src/i18n';
import actions from 'src/modules/planificacion/form/planificacionFormActions';
import selectors from 'src/modules/planificacion/form/planificacionFormSelectors';
import {getHistory} from 'src/modules/store';
import {magicFlexBox} from 'src/theme/schemes/CustomTheme';
import ContentWrapper from 'src/view/layout/styles/ContentWrapper';
import PlanificacionForm from 'src/view/planificacion/form/PlanificacionForm';
import Breadcrumb from 'src/view/shared/Breadcrumb';
import Spinner from 'src/view/shared/Spinner';
import PageTitle from 'src/view/shared/styles/PageTitle';
import PlanificacionFormTemplateSelection from './PlanificacionFormTemplateSelection';

function TabPanel(props) {
  const {children, value, index, simpleView, ...other} = props;

  return (
    <Box
      sx={
        {
          ...magicFlexBox,
          display: value !== index ? 'none' : 'flex',
        } as any
      }
      role="tabpanel"
      style={{width: '100%', marginTop: simpleView ? -30 : undefined}}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box
          sx={
            {
              ...magicFlexBox,
              overflow: 'auto',
              pr: 1,
            } as any
          }
          p={0}
          pt={2}>
          {children}
        </Box>
      )}
    </Box>
  );
}
function PlanificacionFormPage(props) {
  const [dispatched, setDispatched] = useState(false);
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const initLoading = useSelector(selectors.selectInitLoading);
  const saveLoading = useSelector(selectors.selectSaveLoading);
  let record = useSelector(selectors.selectRecord);

  const isPlanningTemplates = props.isPlanningTemplates;
  const isEditing = Boolean(props.record?.id || match.params.id);
  const planificacionPadre = props.planificacionPadre;
  if (planificacionPadre && !isEditing) {
    console.log(
      '%c⧭ Es nueva y#1d5558 una planificación padre: debemos setear las variables de anidación',
      'color: #ace2e6',
      planificacionPadre,
    );
    // Es nueva y existe una planificación padre: debemos setear las variables de anidación
    record = {
      ...planificacionPadre,
      tercero: planificacionPadre.tercero,
      presupuestoEstimado: null,
      documentosAdjuntos: [],
      fotosAdjuntas: [],
      descripcion: null,
      tareas: null,
      nombre: null,
      parentLabel: planificacionPadre.label || planificacionPadre.nombre,
      planificacionPadre: planificacionPadre.id,
      planificacionRaiz: planificacionPadre.planificacionRaiz || planificacionPadre.id,
      nivelAnidacion: planificacionPadre.nivelAnidacion ? planificacionPadre.nivelAnidacion + 1 : 1,
    };
    delete record.id;
    delete record._id;
    delete record.tenant;
    delete record.createdAt;
    delete record.createdBy;
    delete record.updatedAt;
    delete record.updatedBy;
  }

  const isView = props.disabled || record?.estado === 'Cerrada';
  const title = isView
    ? i18n('entities.planificacion.view.title')
    : isEditing
    ? i18n('entities.planificacion.edit.title')
    : i18n('entities.planificacion.new.title');

  useEffect(() => {
    dispatch(actions.doInit(props.record?.id || match.params.id));
    setDispatched(true);
  }, [dispatch, match.params.id || props.params?.id]);

  const doSubmitTemplates = (data) => {
    //console.log('%c⧭ PlanificacionFormTemplateSelection', 'color: #cc7033', {data});
    dispatch(actions.doCreateFromTemplates(data, props.created));
  };

  const doSubmit = (id, data) => {
    data.label = data.nombre;
    if (data.parentLabel) data.label = data.parentLabel + ' / ' + data.nombre;
    if (isEditing) {
      dispatch(actions.doUpdate(id, data, props.edited));
    } else {
      dispatch(actions.doCreate(data, props.created));
    }
  };
  const [tabValue, setTabValue] = useState(1);

  function a11yProps(index) {
    return {
      id: `planificacion-tab-${index}`,
      'aria-controls': `planificacion-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const showTemplateTab = !isPlanningTemplates && planificacionPadre && !isEditing;

  return (
    <>
      {!props.drawer ||
        (!props.disabled && (
          <Breadcrumb items={[[i18n('dashboard.menu'), '/'], [i18n('entities.planificacion.menu'), '/planificacion'], [title]]} />
        ))}

      <ContentWrapper>
        <PageTitle caption>{title}</PageTitle>

        {initLoading && <Spinner />}

        {dispatched && !initLoading && showTemplateTab && (
          <>
            <Tabs
              orientation="horizontal"
              variant="scrollable"
              value={tabValue}
              onChange={handleChange}
              aria-label="planificacion-wizard"
              textColor="secondary"
              indicatorColor="secondary"
              sx={{
                borderRight: 1,
                borderColor: 'divider',
              }}>
              <Tab label="Crear" {...a11yProps(0)} />
              <Tab label="Desde una plantilla" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <PlanificacionForm
                isPlanningTemplates={isPlanningTemplates}
                disabled={isView}
                drawer={props.drawer}
                saveLoading={saveLoading}
                initLoading={initLoading}
                record={record}
                isEditing={isEditing}
                onSubmit={doSubmit}
                onCancel={() => getHistory().push('/planificacion')}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <PlanificacionFormTemplateSelection
                isPlanningTemplates={isPlanningTemplates}
                disabled={isView}
                drawer={props.drawer}
                saveLoading={saveLoading}
                initLoading={initLoading}
                record={record}
                isEditing={isEditing}
                onSubmitTemplates={doSubmitTemplates}
                onCancel={() => getHistory().push('/planificacion')}
              />
            </TabPanel>
          </>
        )}

        {dispatched && !initLoading && !showTemplateTab && (
          <PlanificacionForm
            isPlanningTemplates={isPlanningTemplates}
            disabled={isView}
            drawer={props.drawer}
            saveLoading={saveLoading}
            initLoading={initLoading}
            record={record}
            isEditing={isEditing}
            onSubmit={doSubmit}
            onCancel={() => getHistory().push('/planificacion')}
          />
        )}
      </ContentWrapper>
    </>
  );
}

export default PlanificacionFormPage;
