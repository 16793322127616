const empresaEnumerators = {
  tipo: [
    'Cliente',
    'Proveedor',
    'GestorReciclaje',
  ],
  estado: [
    'Normal',
    'Alerta',
    'Grave',
  ],
};

export default empresaEnumerators;
