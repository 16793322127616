import CloseIcon from '@mui/icons-material/Close';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from '@mui/material';
import moment from 'moment-timezone';
import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import {FormProvider, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import layoutActions from 'src/modules/layout/layoutActions';
import Errors from 'src/modules/shared/error/errors';
import tareaListActions from 'src/modules/tarea/list/tareaListActions';
import tareaListSelectors from 'src/modules/tarea/list/tareaListSelectors';
import TareaService from 'src/modules/tarea/tareaService';
import Message from 'src/view/shared/message';
import UserAutocompleteFormItem from 'src/view/user/autocomplete/UserAutocompleteFormItem';

function TareaReassignTasksModal(props) {
  const dispatch = useDispatch();
  const tareaRows = useSelector(tareaListSelectors.selectRows);
  const [reassignLoading, setReassignLoading] = useState(false);
  const [ejecutor, setEjecutor] = useState<any>(null);

  //console.log('%c⧭ TareaReassignTasksModal', 'color: #00bf00', {props: props, tareaRows});

  const doReassign = async () => {
    try {
      setReassignLoading(true);
      const response = await TareaService.reassignTasks(props.ids, ejecutor);
      if (response?.status === 200) {
        if (props.drawerId === 'TareaFormPage') {
          dispatch({
            type: layoutActions.DRAWER_VISIBLE,
            payload: {
              drawerId: 'TareaFormPage',
              drawerVisible: true,
              drawerChanged: moment().unix(),
              drawerUpdateComponent: 'TareaFormPage',
              recordId: props.ids[0],
            },
          });
        } else {
          dispatch(tareaListActions.doFetchCurrentFilter());
        }
        Message.success(response?.message);
        doClose();
      } else {
        Message.error(response?.message);
      }
      //console.log('%c⧭ doReassign', 'color: #733d00', {response});
    } catch (error) {
      Errors.handle(error);
    } finally {
      setReassignLoading(false);
    }
  };

  const doClose = () => {
    return props.onClose();
  };

  const recursiveFindTarea = (rows, id) => {
    let tarea: any = null;
    for (let row of rows) {
      if (row.id === id) {
        tarea = row;
      } else if (row.children) {
        tarea = recursiveFindTarea(row.children, id);
      }
      if (tarea) break;
    }
    return tarea;
  };
  const getTareaLabel = (id) => {
    const tarea = recursiveFindTarea(tareaRows, id);
    let label = tarea?.titulo || '';
    label += ' ' + tarea?.modeloTarea?.titulo || '';
    return label;
  };

  const form = useForm({});
  return ReactDOM.createPortal(
    <Dialog open={true} onClose={doClose} maxWidth="md" fullWidth={true}>
      <DialogTitle
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <h2 style={{margin: 0}}>{i18n('tarea.reassignTasksToANewExecutor')}</h2>
        <IconButton onClick={doClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{minHeight: '50vh'}}>
        <FormProvider {...form}>
          <form>
            <div style={{marginBottom: '16px'}}>
              <h3>{i18n('common.executor')}</h3>
              <p>{i18n('common.selectTheNewExecutorForTheSelectedTasks')}</p>
              <UserAutocompleteFormItem
                name="ejecutor"
                label={i18n('common.executor')}
                required={true}
                showCreate={false}
                autoFocus={true}
                onChange={(value) => setEjecutor(value?.id)}
                value={ejecutor}
                menuPortalTarget={document.body}
                //menuZoom={1}
              />
            </div>
            <div style={{backgroundColor: 'ghostwhite', borderRadius: 10, padding: 10, marginTop: 20, marginBottom: 16}}>
              <h3>{i18n('common.tasks')}</h3>
              <p>{i18n('common.theFollowingTasksWillBeReassigned')}</p>:
              <ul>
                {props.ids.map((id) => (
                  <li key={id}>{getTareaLabel(id)}</li>
                ))}
              </ul>
            </div>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button disabled={!ejecutor || reassignLoading} onClick={doReassign} color="primary" variant={'contained'}>
          {i18n('common.reassign')}
        </Button>
        <Button onClick={doClose} color="secondary">
          {i18n('common.cancel')}
        </Button>
      </DialogActions>
    </Dialog>,
    (document as any).getElementById('modal-root'),
  );
}

export default TareaReassignTasksModal;
