const firmaEnumerators = {
  estado: [
    'Pendiente',
    'Rechazada',
    'Aprobada',
  ],
  objetivo: [
    'Aceptar',
    'Validar',
    'Realizar',
  ],
};

export default firmaEnumerators;
