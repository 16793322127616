import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { i18n } from 'src/i18n';
import selectors from 'src/modules/tercero/terceroSelectors';
import DrawerButton from 'src/view/shared/view/DrawerButton';
import TerceroView from '../view/TerceroView';

function TerceroListItem(props) {
  const hasPermissionToRead = useSelector(selectors.selectPermissionToRead);

  const valueAsArray = () => {
    const { value } = props;

    if (!value) {
      return [];
    }

    if (Array.isArray(value)) {
      return value;
    }

    return [value];
  };

  const displayableRecord = (record) => {
    if (!record) return null;
    return (
      <div key={record.id}>
        <DrawerButton
          wrapText={true}
          drawerId={'DrawerTerceroViewPage'}
          type={'chip'}
          entity={'tercero'}
          tooltipTitle={i18n(record.nombre)}
          buttonTitle={i18n(record.nombre)}
          width={window.innerWidth * 0.55}
          record={record}
          component={<TerceroView record={record} drawer={true} />}
        />
      </div>
    );
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  if (!valueAsArray().length) {
    return null;
  }

  const displayedValues = isExpanded ? valueAsArray() : valueAsArray().slice(0, 2);
  return (
    <>
      {displayedValues.map((value) => displayableRecord(value))}
      {!isExpanded && valueAsArray().length > 2 && (
        <Typography
          style={{
            cursor: 'pointer',
            color: '#3f51b5',
          }}
          variant="caption"
          onClick={handleExpandClick}>
          Ver más
        </Typography>
      )}
      {isExpanded && valueAsArray().length > 2 && (
        <Typography
          style={{
            cursor: 'pointer',
            color: '#3f51b5',
          }}
          variant="caption"
          onClick={handleExpandClick}>
          Ver menos
        </Typography>
      )}
    </>
  );
}

TerceroListItem.propTypes = {
  value: PropTypes.any,
  contractorsView: PropTypes.bool,
};

export default TerceroListItem;
