import list from 'src/modules/planificadorFormulario/list/planificadorFormularioListReducers';
import form from 'src/modules/planificadorFormulario/form/planificadorFormularioFormReducers';
import view from 'src/modules/planificadorFormulario/view/planificadorFormularioViewReducers';
import destroy from 'src/modules/planificadorFormulario/destroy/planificadorFormularioDestroyReducers';
import importerReducer from 'src/modules/planificadorFormulario/importer/planificadorFormularioImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
