import moment from 'moment';
import {i18n} from 'src/i18n';
import layoutActions from 'src/modules/layout/layoutActions';
import ModeloTareaListActions from 'src/modules/modeloTarea/list/modeloTareaListActions';
import ModeloTareaService from 'src/modules/modeloTarea/modeloTareaService';
import Errors from 'src/modules/shared/error/errors';
import {getHistory} from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'MODELOTAREA_FORM';

const modeloTareaFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: modeloTareaFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await ModeloTareaService.find(id);
      }

      dispatch({
        type: modeloTareaFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: modeloTareaFormActions.INIT_ERROR,
      });

      getHistory().push('/modelo-tarea');
    }
  },

  doCreate: (values) => async (dispatch) => {
    try {
      dispatch({
        type: modeloTareaFormActions.CREATE_STARTED,
      });

      await ModeloTareaService.create(values);

      dispatch({
        type: modeloTareaFormActions.CREATE_SUCCESS,
      });

      Message.success(i18n('entities.modeloTarea.create.success'));

      dispatch(
        layoutActions.doVisibleDrawer({
          drawerId: 'DrawerModeloTareaFormPage',
          drawerVisible: false,
          drawerChanged: moment().unix(),
        }),
      );

      dispatch(ModeloTareaListActions.doFetchCurrentFilter());
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: modeloTareaFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate:
    (id, values, updated: any = null) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: modeloTareaFormActions.UPDATE_STARTED,
        });

        const updatedItem = await ModeloTareaService.update(id, values);

        dispatch({
          type: modeloTareaFormActions.UPDATE_SUCCESS,
        });

        Message.success(i18n('entities.modeloTarea.update.success'));
        if (updated) {
          updated(updatedItem);
        }
        dispatch(
          layoutActions.doVisibleDrawer({
            drawerId: 'DrawerModeloTareaFormPage',
            drawerVisible: false,
            drawerChanged: moment().unix(),
          }),
        );

        dispatch(ModeloTareaListActions.doFetchCurrentFilter());
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: modeloTareaFormActions.UPDATE_ERROR,
        });
      }
    },
};

export default modeloTareaFormActions;
