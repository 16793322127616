import actions from 'src/modules/planificacion/list/planificacionListActions';

const INITIAL_PAGE_SIZE = 10;

const initialData = {
  rows: [] as Array<any>,
  rowToExpand: null,
  count: 0,
  loading: false,
  filter: {},
  rawFilter: {},
  pagination: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },
  sorter: {field: 'createdAt', order: 'desc'},
  selectedKeys: [] as Array<any>,
  rerender: 0,
  selectedTab: 'planificacion',
};

const planificacionListReducers = (state = initialData, {type, payload}) => {
  if (type === actions.RESETED) {
    return {
      ...initialData,
    };
  }

  if (type === actions.CLEAR_SOME_SELECTED) {
    let selectedKeys = state.selectedKeys.filter((p) => !payload.includes(p.id));
    return {
      ...state,
      selectedKeys,
    };
  }
  if (type === actions.SELECTED_TAB_CHANGED) {
    const selectedTab = payload;
    //console.log('%c⧭ selectedTab', 'color: #aa00ff', selectedTab);
    return {
      ...state,
      selectedTab,
    };
  }

  if (type === actions.TOGGLE_ONE_SELECTED) {
    let selectedKeys = state.selectedKeys;

    const exists = selectedKeys.find((p) => p.id === payload.id);

    if (exists) {
      selectedKeys = selectedKeys.filter((p) => p.id !== payload.id);
    } else {
      selectedKeys = [{...payload}, ...selectedKeys];
    }

    return {
      ...state,
      selectedKeys,
    };
  }

  if (type === actions.TOGGLE_ALL_SELECTED) {
    const isAllSelected = (state.rows || []).length === (state.selectedKeys || []).length;

    return {
      ...state,
      selectedKeys: isAllSelected
        ? []
        : state.rows.map((row) => ({id: row.id, parentId: row.planificacionPadre, entity: row.entity})),
    };
  }

  if (type === actions.CLEAR_ALL_SELECTED) {
    return {
      ...state,
      selectedKeys: [],
    };
  }

  if (type === actions.PAGINATION_CHANGED) {
    return {
      ...state,
      pagination: payload || {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    };
  }

  if (type === actions.SORTER_CHANGED) {
    return {
      ...state,
      sorter: payload || {},
    };
  }

  if (type === actions.ROWS_CHANGED) {
    return {
      ...state,
      rows: payload.rows,
      rowToExpand: payload.rowToExpand,
      rerender: payload.rerender ? state.rerender + 1 : false,
    };
  }

  if (type === actions.COPY_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }
  if (type === actions.COPY_SUCCESS) {
    return {
      ...state,
      loading: false,
    };
  }
  if (type === actions.COPY_ERROR) {
    return {
      ...state,
      loading: false,
    };
  }

  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
      selectedKeys: [],
      filter: payload ? payload.filter : {},
      rawFilter: payload ? payload.rawFilter : {},
      pagination:
        payload && payload.keepPagination
          ? state.pagination
          : {
              current: 1,
              pageSize: INITIAL_PAGE_SIZE,
            },
    };
  }

  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      rows: payload.rows,
      count: payload.count,
    };
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      rows: [],
      count: 0,
    };
  }

  if (type === actions.EXPORT_STARTED) {
    return {
      ...state,
      exportLoading: true,
    };
  }

  if (type === actions.EXPORT_SUCCESS) {
    return {
      ...state,
      exportLoading: false,
    };
  }

  if (type === actions.EXPORT_ERROR) {
    return {
      ...state,
      exportLoading: false,
    };
  }

  return state;
};
export default planificacionListReducers;
