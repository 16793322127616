import {makeStyles} from '@mui/styles';
import React, {useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import Header from 'src/view/layout/Header';
import Menu from 'src/view/layout/Menu';

import 'react-tiny-fab/dist/styles.css';

import {useSelector} from 'react-redux';
import authSelectors from 'src/modules/auth/authSelectors';
import PermissionChecker from 'src/modules/auth/permissionChecker';
import Permissions from 'src/security/permissions';

import {Box} from '@mui/material';
import {styled} from '@mui/material/styles';
import {i18n} from 'src/i18n';
import Roles from 'src/security/roles';
import menus from '../menus';
import HeaderSimple from './HeaderSimple';

const permissions = Permissions.values;

const useStyles = makeStyles((theme: any) => ({
  root: {
    color: 'rgba(0, 0, 0, 0.65)',
    backgroundColor: '#f0f2f5',
    fontFamily: `'Roboto', sans-serif`,

    '& h1, h2, h3, h4, h5, h6': {
      color: 'rgba(0, 0, 0, 0.85)',
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    padding: theme.spacing(2),
    overflowX: 'hidden',
    overflowY: 'hidden',
    paddingBottom: 20,
    flexDirection: 'column',
  },
  toolbarOffset: theme.mixins.toolbar,
}));

const MainWrapper = styled(Box)(
  () => `
        flex: 1 1 auto;
        display: flex;
        height: 100vh;
`,
);

const buildRolesDescriptions = () => {
  const roles = Roles.values;
  const permissions = Permissions.values;
  const modulos_engranaje = [
    {name: 'activo', label: i18n('entities.activo.menu')},
    {name: 'ubicacion', label: i18n('entities.ubicacion.menu')},
    {name: 'categoria', label: i18n('entities.categoria.menu')},
    {name: 'user', label: i18n('user.menu')},
    {name: 'auditLog', label: i18n('auditLog.menu')},
    {name: 'habilidad', label: i18n('entities.habilidad.menu')},
  ];
  let result: any = [];
  for (const [key, value] of Object.entries(roles)) {
    const roleLabel = Roles.labelOf(value);
    const roleDescription = Roles.descriptionOf(value);
    const roleItem: any = {
      ['Rol']: roleLabel,
      ['Descripción']: roleDescription,
      ['Módulos Visibles']: [],
    };
    for (const menu of menus) {
      if (menu.subMenu) {
        for (const subMenu of menu.subMenu) {
          const label = subMenu.label;
          if (!subMenu.permissionRequired || subMenu.permissionRequired.allowedRoles.includes(value)) {
            roleItem['Módulos Visibles'].push(label);
          }
        }
      } else {
        const label = menu.label;
        if (!menu.permissionRequired || menu.permissionRequired.allowedRoles.includes(value)) {
          roleItem['Módulos Visibles'].push(label);
        }
      }
    }
    for (const modulo of modulos_engranaje) {
      if (permissions[modulo.name + 'Read'].allowedRoles.includes(value)) {
        if (!roleItem.Engranaje) {
          roleItem.Engranaje = [] as any;
        }
        const label = modulo.label;
        roleItem.Engranaje.push(label);
      }
    }
    result.push(roleItem);
  }
  console.log('Layout [roles]', result);
  // human readable print with formatting and indentation
  let humanReadableString = JSON.stringify(result, null, 2);
  // remove special characters
  //humanReadableString = humanReadableString.replace(/[,"{}[\]]/g, '');
  console.log('Layout [humanReadableString]', humanReadableString);
  // var blob = new Blob([humanReadableString], {type: 'text/plain;charset=utf-8'});
  // var blobUrl = window.URL.createObjectURL(blob);

  // var link = document.createElement('a'); // Or maybe get it from the current document
  // link.href = blobUrl;
  // link.download = 'aDefaultFileName.txt';
};

function Layout(props) {
  const classes = useStyles();
  const match = useRouteMatch();

  const [isOpen, setIsOpen] = useState(false);

  const currentTenant = useSelector(authSelectors.selectCurrentTenant);
  const currentUser = useSelector(authSelectors.selectCurrentUser);

  const permissionChecker = new PermissionChecker(currentTenant, currentUser);

  const simpleMenu = !currentTenant || !currentUser;
  const HeaderX = simpleMenu ? HeaderSimple : Header;

  //buildRolesDescriptions();
  return (
    <MainWrapper>
      <HeaderX />
      {!simpleMenu && <Menu url={match.url} />}
      <div className={classes.content}>
        <div
          style={{
            flexShrink: 0,
            display: 'flex',
          }}
          className={classes.toolbarOffset}></div>
        <div
          style={{
            width: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
          }}>
          {props.children}
        </div>
      </div>

      {/* <Fab
        mainButtonStyles={{
          backgroundColor: blue[500],
        }}
        icon={<Add />}
        alwaysShowTitle={false}
      >
        {permissionChecker.match(permissions.tareaRead) && (
          <Action
            style={{ backgroundColor: red[500] }}
            text="Tareas"
            onClick={() => getHistory().push('/tarea')}
          >
            <LibraryAddCheck />
          </Action>
        )}

        {permissionChecker.match(
          permissions.comunicacionInternaRead,
        ) && (
          <Action
            style={{ backgroundColor: red[500] }}
            text="Multi Way"
            onClick={() =>
              getHistory().push('/comunicacion-interna')
            }
          >
            <QuestionAnswerIcon />
          </Action>
        )}

        {permissionChecker.match(
          permissions.inventarioRead,
        ) && (
          <Action
            style={{ backgroundColor: red[500] }}
            text="Inventario"
            onClick={() => getHistory().push('/inventario')}
          >
            <AllInbox />
          </Action>
        )}

        {permissionChecker.match(permissions.firmaRead) && (
          <Action
            style={{ backgroundColor: red[500] }}
            text="Firmas"
            onClick={() => getHistory().push('/firma')}
          >
            <Check />
          </Action>
        )}

        {permissionChecker.match(permissions.tareaRead) && (
          <Action
            style={{ backgroundColor: red[500] }}
            text="Pendientes"
            onClick={() => getHistory().push('/pendiente')}
          >
            <PlaylistAddCheckIcon />
          </Action>
        )}

        {permissionChecker.match(
          permissions.dashboardRead,
        ) && (
          <Action
            style={{ backgroundColor: red[500] }}
            text="Dashboard"
            onClick={() =>
              getHistory().push(
                '/business-intelligence/dashboard',
              )
            }
          >
            <ShowChartIcon />
          </Action>
        )}
      </Fab> */}
    </MainWrapper>
  );
}

export default Layout;
