import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/ubicacion/importer/ubicacionImporterSelectors';
import UbicacionService from 'src/modules/ubicacion/ubicacionService';
import fields from 'src/modules/ubicacion/importer/ubicacionImporterFields';
import { i18n } from 'src/i18n';

const ubicacionImporterActions = importerActions(
  'UBICACION_IMPORTER',
  selectors,
  UbicacionService.import,
  fields,
  i18n('entities.ubicacion.importer.fileName'),
);

export default ubicacionImporterActions;