import React, {useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {useSelector} from 'react-redux';
import selectors from 'src/modules/user/userSelectors';
import UserService from 'src/modules/user/userService';
import AutocompleteInMemoryFormItem from 'src/view/shared/form/items/AutocompleteInMemoryFormItem';
import UserNewFormModal from 'src/view/user/new/UserNewFormModal';

function UserAutocompleteFormItem(props) {
  const {setValue, getValues} = useFormContext();
  const [modalVisible, setModalVisible] = useState(false);

  const hasPermissionToCreate = useSelector(selectors.selectPermissionToCreate);

  const doCreateSuccess = (record) => {
    const {name, mode} = props;

    if (mode && mode === 'multiple') {
      setValue(name, [...(getValues()[name] || []), record]);
    } else {
      setValue(name, record);
    }

    setModalVisible(false);
  };

  const fetchFn = (value, limit) => {
    if (props.filter) {
      return UserService.fetchUserAutocomplete({value, filter: props.filter}, limit);
    } else {
      return UserService.fetchUserAutocomplete(value, limit);
    }
  };

  const mapper = {
    toAutocomplete(originalValue) {
      if (!originalValue) {
        return null;
      }

      if (originalValue.fullName || originalValue.email) {
        let label = originalValue.email;

        if (originalValue.fullName) {
          label = `${originalValue.fullName} <${originalValue.email}>`;
        }

        return {
          value: originalValue.id,
          label,
          ...originalValue.record,
        };
      }

      return {
        value: originalValue.id,
        label: originalValue.label,
        ...originalValue.record,
      };
    },

    toValue(originalValue) {
      if (!originalValue) {
        return null;
      }

      return {
        id: originalValue.value,
        label: originalValue.label,
        ...originalValue,
      };
    },
  };

  return (
    <>
      <AutocompleteInMemoryFormItem
        {...props}
        fetchFn={fetchFn}
        entity="user"
        noLink
        mapper={mapper}
        onOpenModal={() => setModalVisible(true)}
        hasPermissionToCreate={hasPermissionToCreate}
      />

      {modalVisible && (
        <UserNewFormModal visible={modalVisible} onClose={() => setModalVisible(false)} onSuccess={doCreateSuccess} />
      )}
    </>
  );
}

export default UserAutocompleteFormItem;
