import actions from 'src/modules/pendiente/list/pendienteListActions';

const INITIAL_PAGE_SIZE = 10;

const initialData = {
  rows: [] as Array<any>,
  count: 0,
  loading: false,
  groupByFields: ['estado'] as Array<any>,
  groups: [] as Array<any>,
  filter: {},
  lastUpdateDateInRows: null,
  rawFilter: {},
  pagination: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },
  sorter: {},
  sort: {
    order: 'asc',
    orderBy: 'fechaProgramacion',
  },
  selectedKeys: [] as Array<any>,
  lastDownloadTime: Date.now(),
  visibleDrawerId: null,
};

export default (state = initialData, {type, payload}) => {
  if (type === actions.RESETED) {
    return {
      ...initialData,
    };
  }

  if (type === actions.SET_VALUES) {
    return {
      ...initialData,
      ...payload,
    };
  }

  if (type === actions.PATCH_VALUES) {
    return {
      ...state,
      ...payload,
    };
  }

  if (type === actions.TOGGLE_ONE_SELECTED) {
    let selectedKeys = state.selectedKeys;

    const exists = selectedKeys.includes(payload);

    if (exists) {
      selectedKeys = selectedKeys.filter((key) => key !== payload);
    } else {
      selectedKeys = [payload, ...selectedKeys];
    }

    return {
      ...state,
      selectedKeys,
    };
  }

  if (type === actions.TOGGLE_ALL_SELECTED) {
    const isAllSelected = (state.rows || []).length === (state.selectedKeys || []).length;

    return {
      ...state,
      selectedKeys: isAllSelected ? [] : state.rows.map((row) => row.id),
    };
  }

  if (type === actions.CLEAR_ALL_SELECTED) {
    return {
      ...state,
      selectedKeys: [],
    };
  }

  if (type === actions.PAGINATION_CHANGED) {
    return {
      ...state,
      pagination: payload || {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    };
  }

  if (type === actions.SORTER_CHANGED) {
    return {
      ...state,
      sorter: payload || {},
    };
  }

  if (type === actions.FETCH_STARTED) {
    // const currentRows = state.rows;
    // const payloadRows = payload.rows;
    // // add rows with { loading: true, index: index } based on start and stop index
    // // to show loading indicator on the UI
    // const startIndex = payload.startIndex;
    // const stopIndex = payload.stopIndex;
    // const filterObj = payload.filterObj;
    // const loadingRows =
    //   startIndex || stopIndex
    //     ? Array.from({length: stopIndex - startIndex}, (_, index) => {
    //         return {
    //           ...filterObj,
    //           isVirtualized: true,
    //           loading: true,
    //           index: startIndex + index,
    //         };
    //       })
    //     : [];
    // const rows = [...currentRows, ...loadingRows];
    // console.log('%c⧭ FETCH_STARTED', 'color: #ff6600', {
    //   startIndex,
    //   stopIndex,
    //   loadingRows,
    // });

    const groups = payload.groups || state.groups;
    return {
      ...state,
      groups,
      //rows,
      loading: true,
      selectedKeys: [],
      filter: payload.filter || state.filter,
      rawFilter: payload.rawFilter || state.rawFilter,
      pagination:
        payload && payload.keepPagination
          ? state.pagination
          : {
              current: 1,
              pageSize: INITIAL_PAGE_SIZE,
            },
      lastDownloadTime: payload.lastDownloadTime || state.lastDownloadTime,
    };
  }

  if (type === actions.FETCH_SUCCESS) {
    let rows = state.rows;
    if (payload.rows) {
      const lastUniqBy = (arr, iteratee) => {
        const seen = new Map();
        for (const item of arr) {
          seen.set(item[iteratee], item);
        }
        return Array.from(seen.values());
      };
      // console.log('%c⧭ rows before', 'color: #735656', rows, 'payload.rows', payload.rows);
      rows = lastUniqBy([...rows, ...payload.rows], '_id');
      // console.log('%c⧭ rows after', 'color: #735656', rows);
      if (payload.deletedItemIds) {
        rows = rows.filter((row) => !payload.deletedItemIds.includes(row._id));
      }
    }
    let groups = payload.groups || state.groups;
    // if (payload.groups) {
    //   groups = _uniqBy([...groups, ...payload.groups], (v) => state.groupByFields.map((f) => v[f]).join('-'));
    // }
    let sort = state.sort;
    if (state.groupByFields.includes('estado')) {
      if (state.groupByFields.includes('fechaProgramacion')) {
        sort = {
          order: 'asc',
          orderBy: 'fechaProgramacion',
        };
      } else {
        sort = {
          order: 'asc',
          orderBy: 'estado',
        };
      }
    }

    return {
      ...state,
      loading: false,
      rows,
      count: payload.count,
      groups: groups,
      sort: sort,
    };
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      // rows: [],
      // count: 0,
    };
  }

  if (type === actions.EXPORT_STARTED) {
    return {
      ...state,
      exportLoading: true,
    };
  }

  if (type === actions.EXPORT_SUCCESS) {
    return {
      ...state,
      exportLoading: false,
    };
  }

  if (type === actions.EXPORT_ERROR) {
    return {
      ...state,
      exportLoading: false,
    };
  }

  return state;
};
