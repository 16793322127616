import list from 'src/modules/tipoServicio/list/tipoServicioListReducers';
import form from 'src/modules/tipoServicio/form/tipoServicioFormReducers';
import view from 'src/modules/tipoServicio/view/tipoServicioViewReducers';
import destroy from 'src/modules/tipoServicio/destroy/tipoServicioDestroyReducers';
import importerReducer from 'src/modules/tipoServicio/importer/tipoServicioImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
