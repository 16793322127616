import {FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import FormErrors from 'src/view/shared/form/formErrors';
import {DisabledText} from './shared/formItemWrappers';

function RadioFormItem(props) {
  const {label, name, hint, options, externalErrorMessage, required, disabled, defaultValue} = props;

  const {
    register,
    formState: {touchedFields, isSubmitted, errors},
    setValue,
    control,
  } = useFormContext();

  useEffect(() => {
    register(name);
  }, []);

  const errorMessage = FormErrors.errorMessage(name, errors, touchedFields, isSubmitted, externalErrorMessage);

  const formHelperText = errorMessage || hint;

  const watchName: any = useWatch({name, control, defaultValue});
  if (disabled) {
    return <DisabledText label={label} value={options.find((option) => option.value === watchName)?.label} />;
  }

  return (
    <FormControl required={required} error={Boolean(errorMessage)} component="fieldset" size="small">
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        id={name}
        name={name}
        value={watchName || ''}
        onChange={(e) => {
          setValue(name, e.target.value, {
            shouldValidate: true,
          });
          props.onChange && props.onChange(e.target.value);
        }}
        onBlur={(event) => {
          props.onBlur && props.onBlur(event);
        }}
        row>
        {options.map((option) => (
          <FormControlLabel key={option.value} value={option.value} control={<Radio size="small" />} label={option.label} />
        ))}
      </RadioGroup>
      {formHelperText && <FormHelperText style={{marginTop: 0}}>{formHelperText}</FormHelperText>}
    </FormControl>
  );
}

RadioFormItem.defaultProps = {
  required: false,
};

RadioFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  required: PropTypes.bool,
  externalErrorMessage: PropTypes.string,
  onChange: PropTypes.any,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.any,
};

export default RadioFormItem;
