import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'nombre',
    label: i18n('entities.categoria.fields.nombre'),
    schema: schemas.string(
      i18n('entities.categoria.fields.nombre'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'extracto',
    label: i18n('entities.categoria.fields.extracto'),
    schema: schemas.string(
      i18n('entities.categoria.fields.extracto'),
      {},
    ),
  },
  {
    name: 'descripcion',
    label: i18n('entities.categoria.fields.descripcion'),
    schema: schemas.string(
      i18n('entities.categoria.fields.descripcion'),
      {},
    ),
  },
  {
    name: 'avatar',
    label: i18n('entities.categoria.fields.avatar'),
    schema: schemas.images(
      i18n('entities.categoria.fields.avatar'),
      {
        "max": 1
      },
    ),
  },
  {
    name: 'portada',
    label: i18n('entities.categoria.fields.portada'),
    schema: schemas.images(
      i18n('entities.categoria.fields.portada'),
      {
        "max": 1
      },
    ),
  },
  {
    name: 'incluirEstadisticas',
    label: i18n('entities.categoria.fields.incluirEstadisticas'),
    schema: schemas.boolean(
      i18n('entities.categoria.fields.incluirEstadisticas'),
      {},
    ),
  },
];