import Chip from '@mui/material/Chip';
import React from 'react';
import {i18n} from 'src/i18n';
import ColoredChip from 'src/view/shared/ColoredChip';

function ClasificacionTagView(props) {
  const {value, entity, fieldName, label, isColor, wrap} = props;

  if (value === undefined) {
    return <React.Fragment />;
  }

  if (value === true) {
    return <ColoredChip wrap={wrap} color="green" label={label || i18n('common.yes')}></ColoredChip>;
  }

  if (value === false) {
    return <ColoredChip wrap={wrap} color="red" label={label || i18n('common.no')}></ColoredChip>;
  }

  if (isColor) {
    return (
      <ColoredChip
        wrap={wrap}
        color={value}
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }

  if (value === 'Muy Baja') {
    return (
      <ColoredChip
        wrap={wrap}
        color="blue"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }

  if (value === 'Baja') {
    return (
      <ColoredChip
        wrap={wrap}
        color="green"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }

  if (value === 'Media') {
    return (
      <ColoredChip
        wrap={wrap}
        color="yellow"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }

  if (value === 'Alta') {
    // return (
    //   <Typography color="#ED7E07" fontWeight="bold" variant="caption">
    //     {label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}
    //   </Typography>
    // );
    return (
      <ColoredChip
        wrap={wrap}
        color="orange"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }

  if (value === 'Muy Alta') {
    return (
      <ColoredChip
        wrap={wrap}
        color="red"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }

  if (value === 'Aviso') {
    return (
      <ColoredChip
        wrap={wrap}
        color="blue"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }

  if (value === 'Prevención') {
    return (
      <ColoredChip
        wrap={wrap}
        color="green"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }

  if (value === 'Preparación') {
    return (
      <ColoredChip
        wrap={wrap}
        color="yellow"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }

  if (value === 'Alarma') {
    return (
      <ColoredChip
        wrap={wrap}
        color="orange"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }

  if (value === 'Afectación') {
    return (
      <ColoredChip
        wrap={wrap}
        color="red"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }
  if (value === 'Detención') {
    return (
      <ColoredChip
        wrap={wrap}
        color="red"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }

  if (value === 'operativa') {
    return (
      <ColoredChip
        wrap={wrap}
        color="red"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }

  if (value === 'mantenimiento') {
    return (
      <ColoredChip
        wrap={wrap}
        color="green"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }

  if (value === 'Pendiente') {
    return (
      <ColoredChip
        wrap={wrap}
        color="gray"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }

  if (value === 'Enviada') {
    return (
      <ColoredChip
        wrap={wrap}
        color="orange"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }

  if (value === 'EnRevision') {
    return (
      <ColoredChip
        wrap={wrap}
        color="yellow"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }

  if (value === 'Autorizada' || value === 'Aprobada') {
    return (
      <ColoredChip
        wrap={wrap}
        color="blue"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }

  if (value === 'Rechazada') {
    return (
      <ColoredChip
        wrap={wrap}
        color="orange"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }

  if (value === 'Abierta' || value === 'Pendiente') {
    return (
      <ColoredChip
        wrap={wrap}
        color="gray"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }

  if (value === 'PendienteRevision') {
    return (
      <ColoredChip
        wrap={wrap}
        color="blue"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }

  if (value === 'EnRevision') {
    return (
      <ColoredChip
        wrap={wrap}
        color="yellow"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }
  if (value === 'requestedSignature') {
    return (
      <ColoredChip
        wrap={wrap}
        color="yellow"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }
  if (value === 'signedByUser') {
    return (
      <ColoredChip
        wrap={wrap}
        color="cian"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }

  if (value === 'Cerrada') {
    return (
      <ColoredChip
        wrap={wrap}
        color="green"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }

  if (value === 'Rechazada') {
    return (
      <ColoredChip
        wrap={wrap}
        color="red"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }

  if (value === 'Preventiva') {
    return (
      <ColoredChip
        wrap={wrap}
        color="blue"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }

  if (value === 'Programada') {
    return (
      <ColoredChip
        wrap={wrap}
        color="purple"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }

  if (value === 'Correctiva') {
    return (
      <ColoredChip
        wrap={wrap}
        color="brown"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }

  if (value === 'FueraPlazo') {
    return (
      <ColoredChip
        wrap={wrap}
        color="red"
        label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)}></ColoredChip>
    );
  }

  return <Chip label={label || i18n(`entities.${entity}.enumerators.${fieldName}.${value}`)} variant="outlined" />;
}

export default ClasificacionTagView;
