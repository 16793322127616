import {routerMiddleware} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import thunkMiddleware from 'redux-thunk';
import initializers from 'src/modules/initializers';
import createRootReducer from 'src/modules/reducers';

const history = createBrowserHistory();

let store;

const setUndefinedUpTo2Levels = (obj, level = 0) => {
  if (level > 1) return;
  Object.entries(obj).forEach(([key, value]) => {
    const ignoreKeys = ['router', 'auth'];
    if (ignoreKeys.includes(key)) {
      return;
    }
    if (typeof value === 'object' && value !== null) {
      setUndefinedUpTo2Levels(value, level + 1);
    }
    if (level === 1) {
      obj[key] = undefined;
    }
  });
};
const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    // console.log('%c⧭ rootReducer RESET_STATE', 'color: #994d75', state);
    setUndefinedUpTo2Levels(state);
    // console.log('%c⧭ rootReducer setUndefinedUpTo2Levels', 'color: #994d75', state);
  }
  return createRootReducer(history)(state, action);
};
export function configureStore(preloadedState?) {
  const middlewares = [thunkMiddleware, routerMiddleware(history)].filter(Boolean);
  store = createStore(rootReducer, preloadedState, composeWithDevTools(applyMiddleware(...middlewares)));

  for (const initializer of initializers) {
    initializer(store);
  }

  return store;
}

export function getHistory() {
  return history;
}

export default function getStore() {
  return store;
}
