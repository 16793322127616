import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/solicitud/importer/solicitudImporterSelectors';
import SolicitudService from 'src/modules/solicitud/solicitudService';
import fields from 'src/modules/solicitud/importer/solicitudImporterFields';
import { i18n } from 'src/i18n';

const solicitudImporterActions = importerActions(
  'SOLICITUD_IMPORTER',
  selectors,
  SolicitudService.import,
  fields,
  i18n('entities.solicitud.importer.fileName'),
);

export default solicitudImporterActions;