import {Box} from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, {useState} from 'react';
import {i18n} from 'src/i18n';
import ContentWrapper from 'src/view/layout/styles/ContentWrapper';
import RecursoListFilter from 'src/view/recurso/list/RecursoListFilter';
import RecursoListTable from 'src/view/recurso/list/RecursoListTable';
import RecursoListToolbar from 'src/view/recurso/list/RecursoListToolbar';
import Breadcrumb from 'src/view/shared/Breadcrumb';
import PageTitle from 'src/view/shared/styles/PageTitle';
import RecursoListCard from './RecursoListCard';
import RecursoListFinanzas from './RecursoListFinanzas';

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <Box
      p={0}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box p={0}>{children}</Box>}
    </Box>
  );
}

function RecursoListPage(props) {
  const [tabValue, setTabValue] = useState(0);

  const a11yProps = (index) => {
    return {
      id: `recurso-tab-${index}`,
      'aria-controls': `recurso-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      {!props.drawer && <Breadcrumb items={[[i18n('dashboard.menu'), '/'], [i18n('entities.recurso.menu')]]} />}

      <ContentWrapper>
        {props.row && (
          <PageTitle>
            {i18n('entities.recurso.list.title')}
            {' - '}
            {props.row.titulo}
          </PageTitle>
        )}

        {!props.row && <PageTitle>{i18n('entities.recurso.list.title')}</PageTitle>}

        <RecursoListToolbar tareasVinculadas={props.row ? [props.row] : null} />
        <RecursoListFilter filter={{...props.filter}} />

        <Tabs
          orientation="horizontal"
          variant="scrollable"
          value={tabValue}
          onChange={handleChange}
          aria-label="activo-wizard"
          textColor="secondary"
          indicatorColor="secondary"
          sx={{
            borderRight: 1,
            borderColor: 'divider',
          }}>
          <Tab label={i18n('entities.recurso.menuTabla')} {...a11yProps(0)} />
          <Tab label={i18n('entities.recurso.menuFinanzas')} {...a11yProps(1)} />
        </Tabs>

        {props.drawer ? (
          <TabPanel value={tabValue} index={0}>
            <RecursoListCard />
          </TabPanel>
        ) : (
          <TabPanel value={tabValue} index={0}>
            <RecursoListTable />
          </TabPanel>
        )}

        <TabPanel value={tabValue} index={1}>
          <RecursoListFinanzas />
        </TabPanel>
      </ContentWrapper>
    </>
  );
}

export default RecursoListPage;
