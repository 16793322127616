import { i18n } from 'src/i18n';
import Errors from 'src/modules/shared/error/errors';
import Exporter from 'src/modules/shared/exporter/exporter';
//import {PdfCalendario} from 'src/modules/shared/pdf/pdfCalendario';
import IncidentService from 'src/modules/incident/incidentService';
import exporterFields from 'src/modules/incident/list/incidentListExporterFields';
import selectors from 'src/modules/incident/list/incidentListSelectors';

const prefix = 'INCIDENT_LIST';

const incidentListActions = {
  SELECTED_MODULE: `${prefix}_SELECTED_MODULE`,

  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,
  SET_HAS_FILTER: `${prefix}SET_HAS_FILTER`,

  RESETED: `${prefix}_RESETED`,
  TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
  TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
  CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  PATCH_RECORD: `${prefix}_SET_RECORD`,

  doSelectModule: (module) => async (dispatch, getState) => {
    dispatch({
      type: incidentListActions.SELECTED_MODULE,
      payload: { module },
    });
    //dispatch(incidentListActions.doFetchCurrentFilter()); // no es necesario, se hace reset desde el listFilter
  },

  doClearAllSelected() {
    return {
      type: incidentListActions.CLEAR_ALL_SELECTED,
    };
  },

  doToggleAllSelected() {
    return {
      type: incidentListActions.TOGGLE_ALL_SELECTED,
    };
  },

  doToggleOneSelected(id) {
    return {
      type: incidentListActions.TOGGLE_ONE_SELECTED,
      payload: id,
    };
  },

  doReset:
    (emptyValues = {}) =>
      async (dispatch) => {
        dispatch({
          type: incidentListActions.RESETED,
          payload: emptyValues,
        });

        dispatch(incidentListActions.doFetchCurrentFilter());
      },

  doExport: () => async (dispatch, getState) => {
    try {
      if (!exporterFields || !exporterFields.length) {
        throw new Error('exporterFields is required');
      }

      dispatch({
        type: incidentListActions.EXPORT_STARTED,
      });

      const filter = selectors.selectFilter(getState());
      const response = await IncidentService.list(filter, selectors.selectOrderBy(getState()), null, null);

      new Exporter(exporterFields, i18n('entities.incident.exporterFileName')).transformAndExportAsExcelFile(response.rows);

      dispatch({
        type: incidentListActions.EXPORT_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: incidentListActions.EXPORT_ERROR,
      });
    }
  },

  doChangePagination: (pagination) => async (dispatch, getState) => {
    dispatch({
      type: incidentListActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(incidentListActions.doFetchCurrentFilter());
  },

  doChangeSort: (sorter) => async (dispatch, getState) => {
    dispatch({
      type: incidentListActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(incidentListActions.doFetchCurrentFilter());
  },

  doFetchCurrentFilter: () => async (dispatch, getState) => {
    const filter = selectors.selectFilter(getState());
    const rawFilter = selectors.selectRawFilter(getState());
    //console.log('%c⧭ doFetchCurrentFilter', 'color: #e5de73', {filter, rawFilter});
    dispatch(incidentListActions.doFetch(filter, rawFilter, true));
  },

  setHasFilter:
    (filter, atLeastHast: string[] = []) =>
      async (dispatch, getState) => {
        try {
          const hasFilter = !!Object.keys(filter).find((key) => {
            if (atLeastHast.length && !atLeastHast.includes(key)) return false;
            if (Array.isArray(filter[key])) {
              return filter[key].length && filter[key][0];
            }
            return filter[key];
          });
          //console.log('%c⧭ setHasFilter', 'color: #731d1d', {hasFilter, filter});
          dispatch({
            type: incidentListActions.SET_HAS_FILTER,
            payload: { hasFilter, filter },
          });
        } catch (error) {
          Errors.handle(error);
          dispatch({
            type: incidentListActions.FETCH_ERROR,
          });
        }
      },

  doFetch:
    (filter?, rawFilter?, keepPagination = false) =>
      async (dispatch, getState) => {
        try {
          dispatch({
            type: incidentListActions.FETCH_STARTED,
            payload: { filter, rawFilter, keepPagination },
          });

          const response = await IncidentService.list(
            filter,
            selectors.selectOrderBy(getState()),
            selectors.selectLimit(getState()),
            selectors.selectOffset(getState()),
          );
          // for(let row of response.rows){
          //   row.children = response.rows.map(row => { return {...row}});
          //   for(let rowb of row.children){
          //     rowb.children = row.children.map(row => { return {...row}});
          //     for(let rowc of rowb.children){
          //       rowc.children = rowb.children.map(row => { return {...row}});
          //     }
          //   }
          // }
          dispatch({
            type: incidentListActions.FETCH_SUCCESS,
            payload: {
              rows: response.rows,
              count: response.count,
            },
          });
        } catch (error) {
          Errors.handle(error);

          dispatch({
            type: incidentListActions.FETCH_ERROR,
          });
        }
      },
};

export default incidentListActions;
