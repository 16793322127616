import {ConnectedRouter} from 'connected-react-router';
import './main.css';

import {CssBaseline} from '@material-ui/core';
import {StylesProvider} from '@mui/styles';
import {SnackbarProvider, useSnackbar} from 'notistack';
import React, {useEffect} from 'react';
import {Provider} from 'react-redux';
import {configureStore, getHistory} from 'src/modules/store';
import ThemeProvider from 'src/theme/ThemeProvider';
import Message from 'src/view/shared/message';
import RoutesComponent from 'src/view/shared/routes/RoutesComponent';
import 'typeface-roboto';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import esLocale from 'date-fns/locale/es';
import {loadMessages, locale} from 'devextreme/localization';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import 'moment/locale/es';
import BarcodeRead from './BarcodeRead';
import es from './devex_es.json';

loadMessages(es);
locale('es');
console.log('sets timezone to America/Santiago and locale to es');
moment.locale('es');
momentTZ.updateLocale('es', moment.localeData()['_config'] as any); // copy locale to moment-timezone
momentTZ.locale('es');
//momentTZ.tz.setDefault('America/Santiago');

const store = configureStore();

export default function App(props) {
  return (
    <Provider store={store}>
      <AppWithRedux {...props} />
    </Provider>
  );
}

//export const ThemeContext = React.createContext((themeName) => {});

function AppWithRedux(props) {
  // const settings = useSelector(authSelectors.selectCurrentSettings);

  // const colors = settingsThemeConverter.fromString(settings && settings.theme);

  // const curThemeName = localStorage.getItem('appTheme') || 'PureLightTheme';
  // const [themeName, _setThemeName] = useState(curThemeName);
  // const theme = themeCreator(themeName, colors);
  // const setThemeName = (themeName) => {
  //   localStorage.setItem('appTheme', themeName);
  //   _setThemeName(themeName);
  // };

  return (
    <StylesProvider injectFirst>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
          <SnackbarProvider maxSnack={3}>
            <>
              <CssBaseline />
              <AppWithSnackbar {...props} />
            </>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StylesProvider>
  );
}

function AppWithSnackbar(props) {
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    // this is a little hack to not have to import notistack
    // on all the components that emit messages
    Message.registerNotistakEnqueueSnackbar(enqueueSnackbar);
  }, [enqueueSnackbar]);

  return (
    <>
      <ConnectedRouter history={getHistory()}>
        <BarcodeRead />
        <RoutesComponent />
      </ConnectedRouter>
    </>
  );
}
