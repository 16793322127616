import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import servicioEnumerators from 'src/modules/servicio/servicioEnumerators';

export default [
  {
    name: 'descripcion',
    label: i18n('entities.servicio.fields.descripcion'),
    schema: schemas.string(
      i18n('entities.servicio.fields.descripcion'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'tipoServicio',
    label: i18n('entities.servicio.fields.tipoServicio'),
    schema: schemas.relationToOne(
      i18n('entities.servicio.fields.tipoServicio'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'centrosCostos',
    label: i18n('entities.servicio.fields.centrosCostos'),
    schema: schemas.relationToMany(
      i18n('entities.servicio.fields.centrosCostos'),
      {},
    ),
  },
  {
    name: 'costoHora',
    label: i18n('entities.servicio.fields.costoHora'),
    schema: schemas.integer(
      i18n('entities.servicio.fields.costoHora'),
      {},
    ),
  },
  {
    name: 'detalles',
    label: i18n('entities.servicio.fields.detalles'),
    schema: schemas.string(
      i18n('entities.servicio.fields.detalles'),
      {},
    ),
  },
  {
    name: 'cadaFrecuencia',
    label: i18n('entities.servicio.fields.cadaFrecuencia'),
    schema: schemas.integer(
      i18n('entities.servicio.fields.cadaFrecuencia'),
      {},
    ),
  },
  {
    name: 'frecuencia',
    label: i18n('entities.servicio.fields.frecuencia'),
    schema: schemas.enumerator(
      i18n('entities.servicio.fields.frecuencia'),
      {
        "options": servicioEnumerators.frecuencia
      },
    ),
  },
  {
    name: 'tercerosVinculados',
    label: i18n('entities.servicio.fields.tercerosVinculados'),
    schema: schemas.relationToMany(
      i18n('entities.servicio.fields.tercerosVinculados'),
      {},
    ),
  },
];