import React from 'react';
import Spinner from 'src/view/shared/Spinner';
import { i18n } from 'src/i18n';
import TextViewItem from 'src/view/shared/view/TextViewItem';
import CuentaContableViewItem from 'src/view/cuentaContable/view/CuentaContableViewItem';
import TrazabilidadViewItem from 'src/view/trazabilidad/view/TrazabilidadViewItem';
import ActivoViewItem from 'src/view/activo/view/ActivoViewItem';
import GestorDocumentalViewItem from 'src/view/gestorDocumental/view/GestorDocumentalViewItem';
import InventarioViewItem from 'src/view/inventario/view/InventarioViewItem';

function RegistroView(props) {
  const renderView = () => {
    const { record } = props;

    return (
      <div>
        <TextViewItem
          label={i18n('entities.registro.fields.descripcion')}
          value={record.descripcion}
        />

        <TextViewItem
          label={i18n('entities.registro.fields.tipo')}
          value={
            record.tipo &&
            i18n(
              `entities.registro.enumerators.tipo.${record.tipo}`,
            )
          }
        />

        <TextViewItem
          label={i18n('entities.registro.fields.prioridad')}
          value={
            record.prioridad &&
            i18n(
              `entities.registro.enumerators.prioridad.${record.prioridad}`,
            )
          }
        />

        <CuentaContableViewItem
          label={i18n('entities.registro.fields.cuentasContables')}
          value={record.cuentasContables}
        />

        <TextViewItem
          label={i18n('entities.registro.fields.nota')}
          value={record.nota}
        />

        <TextViewItem
          label={i18n('entities.registro.fields.ubicacionGoogle')}
          value={record.ubicacionGoogle}
        />

        <TextViewItem
          label={i18n('entities.registro.fields.latitud')}
          value={record.latitud}
        />

        <TextViewItem
          label={i18n('entities.registro.fields.longitud')}
          value={record.longitud}
        />

        <TrazabilidadViewItem
          label={i18n('entities.registro.fields.trazabilidadesVinculadas')}
          value={record.trazabilidadesVinculadas}
        />

        <ActivoViewItem
          label={i18n('entities.registro.fields.activosVinculados')}
          value={record.activosVinculados}
        />

        <GestorDocumentalViewItem
          label={i18n('entities.registro.fields.documentosVinculados')}
          value={record.documentosVinculados}
        />

        <InventarioViewItem
          label={i18n('entities.registro.fields.inventariosVinculados')}
          value={record.inventariosVinculados}
        />        
      </div>
    );
  };

  const { record, loading } = props;

  if (loading || !record) {
    return <Spinner />;
  }

  return renderView();
}

export default RegistroView;
