import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.pregunta.fields.id'),
  },
  {
    name: 'pais',
    label: i18n('entities.pregunta.fields.pais'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'titulo',
    label: i18n('entities.pregunta.fields.titulo'),
  },
  {
    name: 'descripcion',
    label: i18n('entities.pregunta.fields.descripcion'),
  },
  {
    name: 'foto',
    label: i18n('entities.pregunta.fields.foto'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'keyTraductor',
    label: i18n('entities.pregunta.fields.keyTraductor'),
  },
  {
    name: 'modificarRespuesta',
    label: i18n('entities.pregunta.fields.modificarRespuesta'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'respuestas',
    label: i18n('entities.pregunta.fields.respuestas'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.pregunta.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.pregunta.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
