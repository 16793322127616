import {yupResolver} from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import {Button, Grid} from '@mui/material';
import React, {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {i18n} from 'src/i18n';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import Storage from 'src/security/storage';
import ImagesFormItem from 'src/view/shared/form/items/ImagesFormItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import InputNumberFormItem from 'src/view/shared/form/items/InputNumberFormItem';
import FormWrapper, {FormButtons} from 'src/view/shared/styles/FormWrapper';
import * as yup from 'yup';

const schema = yup.object().shape({
  foto: yupFormSchemas.images(i18n('entities.contacto.fields.foto'), {
    required: false,
  }),
  nombre: yupFormSchemas.string(i18n('entities.contacto.fields.nombre'), {
    required: true,
  }),
  cargo: yupFormSchemas.string(i18n('entities.contacto.fields.cargo'), {}),
  telefonoPrincipal: yupFormSchemas.integer(i18n('entities.contacto.fields.telefonoPrincipal'), {}),
  telefonoSecundario: yupFormSchemas.integer(i18n('entities.contacto.fields.telefonoSecundario'), {}),
  movil: yupFormSchemas.integer(i18n('entities.contacto.fields.movil'), {}),
  email: yupFormSchemas.string(i18n('entities.contacto.fields.email'), {}),
  skype: yupFormSchemas.string(i18n('entities.contacto.fields.skype'), {}),
  linkedin: yupFormSchemas.string(i18n('entities.contacto.fields.linkedin'), {}),
  terceros: yupFormSchemas.relationToMany(i18n('entities.contacto.fields.terceros'), {}),
});

function ContactoForm(props) {
  const [initialValues] = useState(() => {
    const record = props.record || {};

    return {
      foto: record.foto || [],
      nombre: record.nombre,
      cargo: record.cargo,
      telefonoPrincipal: record.telefonoPrincipal,
      telefonoSecundario: record.telefonoSecundario,
      movil: record.movil,
      email: record.email,
      skype: record.skype,
      linkedin: record.linkedin,
      terceros: record.terceros || [],
    };
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
    shouldUnregister: false,
  });

  const onSubmit = (values) => {
    props.onSubmit(props.record?.id, values);
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  const {saveLoading, modal} = props;

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Grid spacing={2} container>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <ImagesFormItem
                name="foto"
                label={i18n('entities.contacto.fields.foto')}
                required={false}
                storage={Storage.values.contactoFoto}
                max={undefined}
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputFormItem name="nombre" label={i18n('entities.contacto.fields.nombre')} required={true} />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputFormItem name="cargo" label={i18n('entities.contacto.fields.cargo')} required={false} />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputNumberFormItem
                name="telefonoPrincipal"
                label={i18n('entities.contacto.fields.telefonoPrincipal')}
                required={false}
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputNumberFormItem
                name="telefonoSecundario"
                label={i18n('entities.contacto.fields.telefonoSecundario')}
                required={false}
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputNumberFormItem name="movil" label={i18n('entities.contacto.fields.movil')} required={false} />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputFormItem name="email" label={i18n('entities.contacto.fields.email')} required={false} />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputFormItem name="skype" label={i18n('entities.contacto.fields.skype')} required={false} />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputFormItem name="linkedin" label={i18n('entities.contacto.fields.linkedin')} required={false} />
            </Grid>
            {/* <Grid item lg={7} md={8} sm={12} xs={12}>
              <TerceroAutocompleteFormItem  
                name="terceros"
                label={i18n('entities.contacto.fields.terceros')}
                required={false}
                showCreate={!props.modal}
                mode="multiple"
              />
            </Grid> */}
          </Grid>
          <FormButtons
            style={{
              flexDirection: modal ? 'row-reverse' : undefined,
            }}>
            <Button
              variant="contained"
              color="primary"
              disabled={saveLoading}
              type="button"
              onClick={form.handleSubmit(onSubmit)}
              startIcon={<SaveIcon />}
              size="small">
              {i18n('common.save')}
            </Button>

            <Button disabled={saveLoading} onClick={onReset} type="button" startIcon={<UndoIcon />} size="small">
              {i18n('common.reset')}
            </Button>

            {props.onCancel ? (
              <Button
                disabled={saveLoading}
                onClick={() => props.onCancel()}
                type="button"
                startIcon={<CloseIcon />}
                size="small">
                {i18n('common.cancel')}
              </Button>
            ) : null}
          </FormButtons>
        </form>
      </FormProvider>
    </FormWrapper>
  );
}

export default ContactoForm;
