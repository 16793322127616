import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import firmaEnumerators from 'src/modules/firma/firmaEnumerators';

export default [
  {
    name: 'descripcion',
    label: i18n('entities.firma.fields.descripcion'),
    schema: schemas.string(
      i18n('entities.firma.fields.descripcion'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'estado',
    label: i18n('entities.firma.fields.estado'),
    schema: schemas.enumerator(
      i18n('entities.firma.fields.estado'),
      {
        "options": firmaEnumerators.estado
      },
    ),
  },
  {
    name: 'nota',
    label: i18n('entities.firma.fields.nota'),
    schema: schemas.string(
      i18n('entities.firma.fields.nota'),
      {},
    ),
  },
  {
    name: 'objetivo',
    label: i18n('entities.firma.fields.objetivo'),
    schema: schemas.enumerator(
      i18n('entities.firma.fields.objetivo'),
      {
        "required": true,
        "options": firmaEnumerators.objetivo
      },
    ),
  },
  {
    name: 'responsable',
    label: i18n('entities.firma.fields.responsable'),
    schema: schemas.relationToOne(
      i18n('entities.firma.fields.responsable'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'firmada',
    label: i18n('entities.firma.fields.firmada'),
    schema: schemas.boolean(
      i18n('entities.firma.fields.firmada'),
      {},
    ),
  },
  {
    name: 'tareasVinculadas',
    label: i18n('entities.firma.fields.tareasVinculadas'),
    schema: schemas.relationToMany(
      i18n('entities.firma.fields.tareasVinculadas'),
      {},
    ),
  },
  {
    name: 'planificacionesVinculadas',
    label: i18n('entities.firma.fields.planificacionesVinculadas'),
    schema: schemas.relationToMany(
      i18n('entities.firma.fields.planificacionesVinculadas'),
      {},
    ),
  },
  {
    name: 'trazabilidadesVinculadas',
    label: i18n('entities.firma.fields.trazabilidadesVinculadas'),
    schema: schemas.relationToMany(
      i18n('entities.firma.fields.trazabilidadesVinculadas'),
      {},
    ),
  },
  {
    name: 'historialInventariosVinculados',
    label: i18n('entities.firma.fields.historialInventariosVinculados'),
    schema: schemas.relationToMany(
      i18n('entities.firma.fields.historialInventariosVinculados'),
      {},
    ),
  },
];