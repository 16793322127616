import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/planificacion/importer/planificacionImporterSelectors';
import PlanificacionService from 'src/modules/planificacion/planificacionService';
import fields from 'src/modules/planificacion/importer/planificacionImporterFields';
import { i18n } from 'src/i18n';

const planificacionImporterActions = importerActions(
  'PLANIFICACION_IMPORTER',
  selectors,
  PlanificacionService.import,
  fields,
  i18n('entities.planificacion.importer.fileName'),
);

export default planificacionImporterActions;