import React, {useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {useSelector} from 'react-redux';
import selectors from 'src/modules/tercero/terceroSelectors';
import TerceroService from 'src/modules/tercero/terceroService';
import AutocompleteInMemoryFormItem from 'src/view/shared/form/items/AutocompleteInMemoryFormItem';
import TerceroFormModal from 'src/view/tercero/form/TerceroFormModal';

function TerceroAutocompleteFormItem(props) {
  const {setValue, getValues} = useFormContext();

  const [modalVisible, setModalVisible] = useState(false);

  const hasPermissionToCreate = useSelector(selectors.selectPermissionToCreate);

  const doCloseModal = () => {
    setModalVisible(false);
  };

  const doOpenModal = () => {
    setModalVisible(true);
  };

  const doCreateSuccess = (record) => {
    const {name, mode} = props;

    if (mode && mode === 'multiple') {
      setValue(name, [...(getValues()[name] || []), record], {shouldValidate: true, shouldDirty: true});
    } else {
      setValue(name, record, {shouldValidate: true, shouldDirty: true});
    }

    doCloseModal();
  };

  const fetchFn = (value, limit) => {
    if (props.filter) {
      return TerceroService.listAutocomplete({value, filter: props.filter}, limit);
    } else {
      return TerceroService.listAutocomplete(value, limit);
    }
  };

  const mapper = {
    toAutocomplete(originalValue) {
      if (!originalValue) {
        return null;
      }

      const value = originalValue.id;
      let label = originalValue.label;

      if (originalValue.nombre) {
        label = originalValue.nombre;
      }

      return {
        key: value,
        value,
        label,
      };
    },

    toValue(originalValue) {
      if (!originalValue) {
        return null;
      }

      return {
        id: originalValue.value,
        label: originalValue.label,
      };
    },
  };

  return (
    <>
      <AutocompleteInMemoryFormItem
        {...props}
        fetchFn={fetchFn}
        entity="tercero"
        mapper={mapper}
        onOpenModal={doOpenModal}
        hasPermissionToCreate={hasPermissionToCreate}
      />

      {modalVisible && <TerceroFormModal onClose={doCloseModal} onSuccess={doCreateSuccess} />}
    </>
  );
}

export default TerceroAutocompleteFormItem;
