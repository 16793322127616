import {TableCell, TableSortLabel} from '@mui/material';
import React from 'react';

function TableCellCustom(props) {
  const {padding, customStyle, sorter, onSort, name, label, hasRows, children, size = 'auto', align = 'left', ...rest} = props;

  const style =
    {
      auto: {},
      sm: {
        width: '80px',
      },
      md: {
        width: '180px',
      },
      custom110: {
        minWidth: '110px',
      },
      pad0: {
        padding: '0 !important',
      },
    }[size] || {};

  if (!hasRows || !onSort) {
    return (
      <TableCell padding={padding} align={align} sx={{...style, ...customStyle}} {...rest}>
        <span>{children || label || ''}</span>
      </TableCell>
    );
  }

  return (
    <TableCell
      padding={padding}
      key={name}
      sx={{...style, ...customStyle, '& .MuiButtonBase-root': {padding: '0 !important'}}}
      sortDirection={sorter.field === name ? sorter.order : false}
      align={align}>
      <TableSortLabel active={sorter.field === name} direction={sorter.order} onClick={() => onSort(name)}>
        {children || label || ''}
      </TableSortLabel>
    </TableCell>
  );
}

export default TableCellCustom;
