import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/subTarea/importer/subTareaImporterSelectors';
import SubTareaService from 'src/modules/subTarea/subTareaService';
import fields from 'src/modules/subTarea/importer/subTareaImporterFields';
import { i18n } from 'src/i18n';

const subTareaImporterActions = importerActions(
  'SUBTAREA_IMPORTER',
  selectors,
  SubTareaService.import,
  fields,
  i18n('entities.subTarea.importer.fileName'),
);

export default subTareaImporterActions;