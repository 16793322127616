import moment from 'moment';
import {i18n} from 'src/i18n';
import DataService from 'src/modules/data/dataService';
import layoutActions from 'src/modules/layout/layoutActions';
import Errors from 'src/modules/shared/error/errors';
import {getHistory} from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'DATA_FORM';

const timer = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const dataFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit:
    (entity, data = {}) =>
    async (dispatch) => {
      try {
        dispatch({
          type: dataFormActions.INIT_STARTED,
        });

        let record: any = data;
        //console.log('%c⧭', 'color: #997326', record);

        const isEdit = Boolean(record?.dataId);

        if (isEdit) {
          record = await DataService.find(entity, data);
        }

        dispatch({
          type: dataFormActions.INIT_SUCCESS,
          payload: record,
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: dataFormActions.INIT_ERROR,
        });

        getHistory().push('/data');
      }
    },

  setRecord: (record) => async (dispatch) => {
    // fake timer to simulate server call
    dispatch({
      type: dataFormActions.INIT_STARTED,
    });

    await timer(500);

    dispatch({
      type: dataFormActions.INIT_SUCCESS,
      payload: record,
    });
  },

  doCreate: (entity, data) => async (dispatch) => {
    try {
      dispatch({
        type: dataFormActions.CREATE_STARTED,
      });

      await DataService.upsertOne(entity, data, 'create');

      dispatch({
        type: dataFormActions.CREATE_SUCCESS,
      });

      Message.success(i18n(`entities.data.${entity}.save-success`));

      dispatch(
        layoutActions.doVisibleDrawer({
          drawerId: 'DrawerDataFormPage',
          drawerVisible: false,
          drawerChanged: moment().unix(),
          drawerUpdateComponent: 'DataListFilter',
        }),
      );
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: dataFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (entity, data) => async (dispatch, getState) => {
    try {
      dispatch({
        type: dataFormActions.UPDATE_STARTED,
      });

      await DataService.upsertOne(entity, data, 'update');

      dispatch({
        type: dataFormActions.UPDATE_SUCCESS,
      });

      Message.success(i18n(`entities.data.${entity}.save-success`));

      dispatch(
        layoutActions.doVisibleDrawer({
          drawerId: 'DrawerDataFormPage',
          drawerVisible: false,
          drawerChanged: moment().unix(),
          drawerUpdateComponent: 'DataListFilter',
        }),
      );
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: dataFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default dataFormActions;
