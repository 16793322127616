import list from 'src/modules/registro/list/registroListReducers';
import form from 'src/modules/registro/form/registroFormReducers';
import view from 'src/modules/registro/view/registroViewReducers';
import destroy from 'src/modules/registro/destroy/registroDestroyReducers';
import importerReducer from 'src/modules/registro/importer/registroImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
