import list from 'src/modules/centroCosto/list/centroCostoListReducers';
import form from 'src/modules/centroCosto/form/centroCostoFormReducers';
import view from 'src/modules/centroCosto/view/centroCostoViewReducers';
import destroy from 'src/modules/centroCosto/destroy/centroCostoDestroyReducers';
import importerReducer from 'src/modules/centroCosto/importer/centroCostoImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
