import PropTypes from 'prop-types';
import React from 'react';

import {useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import selectors from 'src/modules/planificacion/planificacionSelectors';
import DrawerButton from 'src/view/shared/view/DrawerButton';
import PlanificacionView from '../view/PlanificacionView';

function PlanificacionListItem(props) {
  const hasPermissionToRead = useSelector(selectors.selectPermissionToRead);

  const valueAsArray = () => {
    const {value} = props;

    if (!value) {
      return [];
    }

    if (Array.isArray(value)) {
      return value;
    }

    return [value];
  };

  const displayableRecord = (record) => {
    return (
      <div key={record.id}>
        <DrawerButton
          drawerId={'DrawerPlanificacionViewPage'}
          type={'chip'}
          entity={'planificacion'}
          tooltipTitle={i18n(record.nombre)}
          buttonTitle={i18n(record.nombre)}
          width={window.innerWidth * 0.55}
          record={record}
          component={<PlanificacionView record={record} drawer={true} />}
        />
      </div>
    );
  };

  if (!valueAsArray().length) {
    return null;
  }

  return <>{valueAsArray().map((value) => displayableRecord(value))}</>;
}

PlanificacionListItem.propTypes = {
  value: PropTypes.any,
};

export default PlanificacionListItem;
