import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'foto',
    label: i18n('entities.producto.fields.foto'),
    schema: schemas.images(
      i18n('entities.producto.fields.foto'),
      {},
    ),
  },
  {
    name: 'productoCompleto',
    label: i18n('entities.producto.fields.productoCompleto'),
    schema: schemas.string(
      i18n('entities.producto.fields.productoCompleto'),
      {},
    ),
  },
  {
    name: 'codBarra',
    label: i18n('entities.producto.fields.codBarra'),
    schema: schemas.string(
      i18n('entities.producto.fields.codBarra'),
      {},
    ),
  },
  {
    name: 'codGrupo',
    label: i18n('entities.producto.fields.codGrupo'),
    schema: schemas.string(
      i18n('entities.producto.fields.codGrupo'),
      {},
    ),
  },
  {
    name: 'codProd',
    label: i18n('entities.producto.fields.codProd'),
    schema: schemas.string(
      i18n('entities.producto.fields.codProd'),
      {},
    ),
  },
  {
    name: 'codRapido',
    label: i18n('entities.producto.fields.codRapido'),
    schema: schemas.string(
      i18n('entities.producto.fields.codRapido'),
      {},
    ),
  },
  {
    name: 'codSubGr',
    label: i18n('entities.producto.fields.codSubGr'),
    schema: schemas.string(
      i18n('entities.producto.fields.codSubGr'),
      {},
    ),
  },
  {
    name: 'codUMed',
    label: i18n('entities.producto.fields.codUMed'),
    schema: schemas.string(
      i18n('entities.producto.fields.codUMed'),
      {},
    ),
  },
  {
    name: 'desProd',
    label: i18n('entities.producto.fields.desProd'),
    schema: schemas.string(
      i18n('entities.producto.fields.desProd'),
      {},
    ),
  },
  {
    name: 'desProd2',
    label: i18n('entities.producto.fields.desProd2'),
    schema: schemas.string(
      i18n('entities.producto.fields.desProd2'),
      {},
    ),
  },
  {
    name: 'precioBol',
    label: i18n('entities.producto.fields.precioBol'),
    schema: schemas.string(
      i18n('entities.producto.fields.precioBol'),
      {},
    ),
  },
  {
    name: 'precioVta',
    label: i18n('entities.producto.fields.precioVta'),
    schema: schemas.string(
      i18n('entities.producto.fields.precioVta'),
      {},
    ),
  },
  {
    name: 'ean13',
    label: i18n('entities.producto.fields.ean13'),
    schema: schemas.string(
      i18n('entities.producto.fields.ean13'),
      {},
    ),
  },
  {
    name: 'sku',
    label: i18n('entities.producto.fields.sku'),
    schema: schemas.string(
      i18n('entities.producto.fields.sku'),
      {},
    ),
  },
  {
    name: 'imgUrl',
    label: i18n('entities.producto.fields.imgUrl'),
    schema: schemas.string(
      i18n('entities.producto.fields.imgUrl'),
      {},
    ),
  },
  {
    name: 'categorias',
    label: i18n('entities.producto.fields.categorias'),
    schema: schemas.relationToMany(
      i18n('entities.producto.fields.categorias'),
      {},
    ),
  },
];