import {i18n} from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.perfilEmpleado.fields.id'),
  },
  {
    name: 'usuario',
    label: i18n('entities.perfilEmpleado.fields.usuario'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'nombre',
    label: i18n('entities.perfilEmpleado.fields.nombre'),
  },
  {
    name: 'apellidos',
    label: i18n('entities.perfilEmpleado.fields.apellidos'),
  },
  {
    name: 'registrationNumber',
    label: i18n('entities.perfilEmpleado.fields.registrationNumber'),
  },
  {
    name: 'fechaNacimiento',
    label: i18n('entities.perfilEmpleado.fields.fechaNacimiento'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'nationality',
    label: i18n('entities.perfilEmpleado.fields.nationality'),
    render: exporterRenders.enumerator('entities.perfilEmpleado.enumerators.nationality', 'Chilean'),
  },
  {
    name: 'documentosAdjuntos',
    label: i18n('entities.perfilEmpleado.fields.documentosAdjuntos'),
    render: exporterRenders.relationToManyMap((item) => item.nombre),
  },
  {
    name: 'ubicacion',
    label: i18n('common.locations'),
    render: exporterRenders.relationToManyMap((item) => item.nombre),
  },
  {
    name: 'thirdPartyProviders',
    label: i18n('entities.perfilEmpleado.fields.thirdPartyProviders'),
    render: exporterRenders.relationToManyMap((item) => item.nombre),
  },
  {
    name: 'createdAt',
    label: i18n('entities.perfilEmpleado.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.perfilEmpleado.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
