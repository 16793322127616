import {useEffect, useRef} from 'react';

const BarcodeRead = () => {
  let barcodeBuffer = '';
  let isScanning = false;
  const timer = useRef<any>(null);

  const processBarcode = (event) => {
    if (!isScanning) return;
    //console.log('Key pressed', event.key);
    if (event.key === 'Enter') {
      // Finish the scan if the user presses the Enter key
      if (barcodeBuffer.length < 5) return;
      //console.log('processBarcode', barcodeBuffer);
      // Create a new event
      const barCodeReadEvent = new CustomEvent('barcoderead', {detail: barcodeBuffer});
      // Dispatch the barcoderead event
      window.dispatchEvent(barCodeReadEvent);
      // Clear Scan
      clearScan();
      return;
    }
    // Append the pressed key to the barcode buffer
    barcodeBuffer = barcodeBuffer + event.key;
  };

  const clearScan = () => {
    clearTimeout(timer.current);
    timer.current = null;
    barcodeBuffer = '';
    isScanning = false;
  };

  const checkNumberOrLetter = (event) => {
    //console.log('%c⧭ checkNumberOrLetter', 'color: #408059', event.keyCode);
    if (event.keyCode >= 48 && event.keyCode <= 57) {
      // Number
      return true;
    } else if (event.keyCode >= 65 && event.keyCode <= 90) {
      // Letter
      return true;
    }
    //console.log('%c⧭ checkNumberOrLetter', 'color: #408059', 'false', event.keyCode, event.key);
    return false;
  };

  const handleKeyPress = (event) => {
    processBarcode(event);
    //console.log('%c⧭ handleKeyPress', 'color: #408059', {isScanning: isScanning, keyCode: event.keyCode});
    if (!isScanning && checkNumberOrLetter(event)) {
      // Set the scanning flag to prevent starting another scan until this scan is complete
      isScanning = true;
      barcodeBuffer = event.key;
      // Set a timer during which the barcode scanner will look for additional keypress events
      timer.current = setTimeout(() => {
        clearScan();
      }, 500); // Adjust the timeout based on your needs
    }
  };

  // Barcode read event handler
  const barCodeReadHandler = (event) => {
    console.log('barcoderead EventListener', event.detail);
  };

  useEffect(() => {
    // Attach the keypress event listener
    window.addEventListener('keypress', handleKeyPress);
    // Add the barcoderead event listener
    window.addEventListener('barcoderead', barCodeReadHandler);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
      window.removeEventListener('barcoderead', barCodeReadHandler);
      clearTimeout(timer.current);
    };
  }, []);

  return null;
};

export default BarcodeRead;
