import {Box, Grid, TextField, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import FormErrors from 'src/view/shared/form/formErrors';

function TextAreaFormItem(props) {
  const {
    label,
    name,
    hint,
    placeholder,
    autoFocus,
    autoComplete,
    externalErrorMessage,
    required,
    defaultValue,
    rows,
    disabled,
    onChange,
    onBlur,
  } = props;

  const {getFieldState, register, formState, control} = useFormContext();
  const {touchedFields, isSubmitted, errors} = formState;
  const {isTouched, error} = getFieldState(name, formState);

  const watchName: any = useWatch({name, control, defaultValue});

  const message = error?.message;
  const errorMessage =
    isTouched && message ? message : FormErrors.errorMessage(name, errors, touchedFields, isSubmitted, externalErrorMessage);

  // console.log('%c⧭', 'color: #514080', {watchName});
  if (disabled) {
    return (
      <Grid mb={1} container direction="column">
        {label && <Typography variant="h4">{label}</Typography>}
        <Box>
          {watchName ? (
            <Typography
              component="pre"
              variant="subtitle2"
              sx={{
                fontWeight: 500,
                fontSize: '1.15em',
                fontFamily: 'inherit',
                whiteSpace: 'pre-wrap',
              }}>
              {watchName}
            </Typography>
          ) : (
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 500,
                fontSize: '1.05em',
              }}>
              Sin Respuesta
            </Typography>
          )}
        </Box>
      </Grid>
    );
  }

  const inputRef: any = register(name, {required, onChange: (event) => onChange && onChange(event.target.value), onBlur});

  return (
    <TextField
      {...inputRef}
      inputRef={inputRef.ref}
      disabled={disabled}
      defaultValue={defaultValue}
      id={name}
      name={name}
      label={label}
      required={required}
      margin={props.margin || 'normal'}
      fullWidth
      variant="outlined"
      size="small"
      style={props.style}
      placeholder={placeholder || undefined}
      autoFocus={autoFocus || undefined}
      autoComplete={autoComplete || undefined}
      InputLabelProps={{
        shrink: true,
      }}
      multiline
      rows={rows}
      error={Boolean(errorMessage)}
      helperText={errorMessage || hint}
    />
  );
}

TextAreaFormItem.defaultProps = {
  type: 'text',
  required: false,
};

TextAreaFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  hint: PropTypes.string,
  autoFocus: PropTypes.bool,
  prefix: PropTypes.string,
  placeholder: PropTypes.string,
  externalErrorMessage: PropTypes.string,
  defaultValue: PropTypes.any,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  noAnswerText: PropTypes.string,
  onChange: PropTypes.func,
};

export default TextAreaFormItem;
