import {Avatar, Grid} from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {makeStyles} from '@mui/styles';
import moment from 'moment-timezone';
import React, {useEffect, useState} from 'react';
import {Radar} from 'react-chartjs-2';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';
import HabilidadService from 'src/modules/habilidad/habilidadService';
import TareaService from 'src/modules/tarea/tareaService';
import actions from 'src/modules/user/view/userViewActions';
import selectors from 'src/modules/user/view/userViewSelectors';
import ContentWrapper from 'src/view/layout/styles/ContentWrapper';
import Spinner from 'src/view/shared/Spinner';

function UserViewPage(props) {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const loading = useSelector(selectors.selectLoading);
  const user = useSelector(selectors.selectUser);
  const [data, setData] = useState<any>([]);
  const [information, setInformation] = useState<any>({});

  useEffect(() => {
    if (user) {
      initializer(user);
    }
  }, [user]);

  const initializer = async (user) => {
    const habilidades = await HabilidadService.list(
      {
        incluirEstadisticas: true,
      },
      'createdAt_DESC',
      0,
      0,
    );

    const tareas = await TareaService.list(
      {
        ejecutor: user.id,
        fechaProgramacionRange: [null, moment().format('YYYY-MM-DD') + ' 23:59:59'],
      },
      'createdAt_DESC',
      0,
      0,
    );

    let rowsTareas = tareas.rows.filter((item) => item.estado === 'Cerrada');

    let dataHabilidades: any;
    dataHabilidades = [];

    habilidades.rows.map((h, index) => {
      let sumHabilidad = 0;
      let countHabilidad = 0;

      rowsTareas.map((item) => {
        if (item.modeloTarea.habilidades && item.modeloTarea.habilidades.includes(h.id)) {
          sumHabilidad = sumHabilidad + (item.modeloTarea.puntaje || 1);
          countHabilidad++;
        }
      });

      dataHabilidades[index] = (sumHabilidad * 100) / countHabilidad ? (sumHabilidad * 100) / countHabilidad : 0;
    });

    const data = {
      labels: habilidades.rows.map((item) => item.nombre),
      datasets: [
        {
          label: 'Puntaje',
          data: dataHabilidades,
          backgroundColor: '#FC733A',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
        },
      ],
    };

    let tareasPendientes = 0;

    tareas.rows
      .filter(
        (item) =>
          (item.estado === 'Abierta' ||
            item.estado === 'Rechazada' ||
            item.estado === 'PendienteRevision' ||
            item.estado === 'EnRevision') &&
          moment(item.fechaLimite).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD'),
      )
      .map((item) => tareasPendientes++);

    let tareasFueraPlazo = 0;

    tareas.rows
      .filter(
        (item) =>
          (item.estado === 'Abierta' ||
            item.estado === 'Rechazada' ||
            item.estado === 'PendienteRevision' ||
            item.estado === 'EnRevision') &&
          moment(item.fechaLimite).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD'),
      )
      .map((item) => tareasFueraPlazo++);

    let tareasRealizadas = 0;

    tareas.rows.filter((item) => item.estado === 'Cerrada').map((item) => tareasRealizadas++);

    setInformation({
      tareasPendientes,
      tareasFueraPlazo,
      tareasRealizadas,
    });

    setData(data);
  };

  const options = {
    scale: {
      ticks: {beginAtZero: true},
      pointLabels: {
        fontSize: 18,
        fontWeight: 800,
      },
    },
    legend: {
      position: 'right',
      labels: {
        fontColor: 'black',
        fontWeight: '800',
        fontSize: 18,
      },
    },
  };

  useEffect(() => {
    dispatch(actions.doFind(props.record?.id || match.params.id));
  }, [dispatch, match.params.id]);

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

  function createData(name, pendientes, fueraPlazo, realizadas) {
    return {name, pendientes, fueraPlazo, realizadas};
  }

  const rows = [createData('Tareas', information.tareasPendientes, information.tareasFueraPlazo, information.tareasRealizadas)];

  const classes = useStyles();

  return (
    <>
      <ContentWrapper>
        {loading && <Spinner />}

        {user && (
          <React.Fragment>
            <Grid spacing={4} container direction="row">
              <Grid item lg={props.drawer ? 12 : 6} xs={12}>
                <Grid direction="column" container alignItems="center">
                  <Avatar
                    style={{width: 180, height: 180}}
                    src={user.avatars && user.avatars.length && user.avatars[0].downloadUrl}
                  />
                  <h1 style={{marginBottom: 0}}>{user.fullName}</h1>
                  <h2>{user.puesto?.nombre}</h2>

                  <TableContainer style={{marginTop: 20}} component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>OTs</TableCell>
                          <TableCell align="right">Pendientes</TableCell>
                          <TableCell align="right">Fuera de Plazo</TableCell>
                          <TableCell align="right">Realizadas</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell align="right">{row.pendientes}</TableCell>
                            <TableCell align="right">{row.fueraPlazo}</TableCell>
                            <TableCell align="right">{row.realizadas}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              {data.length !== 0 && (
                <Grid item lg={props.drawer ? 12 : 6} xs={12}>
                  <Radar data={data} options={options} />
                </Grid>
              )}
            </Grid>
          </React.Fragment>
        )}
      </ContentWrapper>
    </>
  );
}

export default UserViewPage;
