import { createSelector } from 'reselect';

const selectRaw = (state) => state.tarea.destroy;

const selectLoading = createSelector([selectRaw], (raw) =>
  Boolean(raw.loading),
);

const tareaDestroySelectors = {
  selectLoading,
};

export default tareaDestroySelectors;
