import list from 'src/modules/puesto/list/puestoListReducers';
import form from 'src/modules/puesto/form/puestoFormReducers';
import view from 'src/modules/puesto/view/puestoViewReducers';
import destroy from 'src/modules/puesto/destroy/puestoDestroyReducers';
import importerReducer from 'src/modules/puesto/importer/puestoImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
