import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.turnoLaboral.fields.id'),
  },
  {
    name: 'descripcion',
    label: i18n('entities.turnoLaboral.fields.descripcion'),
  },
  {
    name: 'empleados',
    label: i18n('entities.turnoLaboral.fields.empleados'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'dias',
    label: i18n('entities.turnoLaboral.fields.dias'),
    render: exporterRenders.stringArray(),
  },
  {
    name: 'turno',
    label: i18n('entities.turnoLaboral.fields.turno'),
  },
  {
    name: 'horaInicio',
    label: i18n('entities.turnoLaboral.fields.horaInicio'),
  },
  {
    name: 'horaFinalizacion',
    label: i18n('entities.turnoLaboral.fields.horaFinalizacion'),
  },
  {
    name: 'costoHora',
    label: i18n('entities.turnoLaboral.fields.costoHora'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.turnoLaboral.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.turnoLaboral.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
