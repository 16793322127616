import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.centroCosto.fields.id'),
  },
  {
    name: 'descripcion',
    label: i18n('entities.centroCosto.fields.descripcion'),
  },
  {
    name: 'tipoCentroCosto',
    label: i18n('entities.centroCosto.fields.tipoCentroCosto'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'presupuestosAsociados',
    label: i18n('entities.centroCosto.fields.presupuestosAsociados'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'habilitado',
    label: i18n('entities.centroCosto.fields.habilitado'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'perfilesEmpleadosVinculados',
    label: i18n('entities.centroCosto.fields.perfilesEmpleadosVinculados'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'activosVinculados',
    label: i18n('entities.centroCosto.fields.activosVinculados'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'serviciosVinculados',
    label: i18n('entities.centroCosto.fields.serviciosVinculados'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'ubicacionesVinculados',
    label: i18n('entities.centroCosto.fields.ubicacionesVinculados'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'trazabilidadesVinculadas',
    label: i18n('entities.centroCosto.fields.trazabilidadesVinculadas'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.centroCosto.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.centroCosto.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
