import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import ubicacionEnumerators from 'src/modules/ubicacion/ubicacionEnumerators';

export default [
  {
    name: 'foto',
    label: i18n('entities.ubicacion.fields.foto'),
    schema: schemas.images(
      i18n('entities.ubicacion.fields.foto'),
      {
        "max": 3
      },
    ),
  },
  {
    name: 'nombre',
    label: i18n('entities.ubicacion.fields.nombre'),
    schema: schemas.string(
      i18n('entities.ubicacion.fields.nombre'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'clasificacion',
    label: i18n('entities.ubicacion.fields.clasificacion'),
    schema: schemas.enumerator(
      i18n('entities.ubicacion.fields.clasificacion'),
      {
        "required": true,
        "options": ubicacionEnumerators.clasificacion
      },
    ),
  },
  {
    name: 'ubicacionAsociada',
    label: i18n('entities.ubicacion.fields.ubicacionAsociada'),
    schema: schemas.relationToOne(
      i18n('entities.ubicacion.fields.ubicacionAsociada'),
      {},
    ),
  },
  {
    name: 'ubicacionGoogle',
    label: i18n('entities.ubicacion.fields.ubicacionGoogle'),
    schema: schemas.string(
      i18n('entities.ubicacion.fields.ubicacionGoogle'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'latitud',
    label: i18n('entities.ubicacion.fields.latitud'),
    schema: schemas.decimal(
      i18n('entities.ubicacion.fields.latitud'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'longitud',
    label: i18n('entities.ubicacion.fields.longitud'),
    schema: schemas.decimal(
      i18n('entities.ubicacion.fields.longitud'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'responsables',
    label: i18n('entities.ubicacion.fields.responsables'),
    schema: schemas.relationToOne(
      i18n('entities.ubicacion.fields.responsables'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'capacidad',
    label: i18n('entities.ubicacion.fields.capacidad'),
    schema: schemas.decimal(
      i18n('entities.ubicacion.fields.capacidad'),
      {},
    ),
  },
  {
    name: 'documentos',
    label: i18n('entities.ubicacion.fields.documentos'),
    schema: schemas.files(
      i18n('entities.ubicacion.fields.documentos'),
      {},
    ),
  },
  {
    name: 'estado',
    label: i18n('entities.ubicacion.fields.estado'),
    schema: schemas.enumerator(
      i18n('entities.ubicacion.fields.estado'),
      {
        "options": ubicacionEnumerators.estado
      },
    ),
  },
  {
    name: 'correoContacto',
    label: i18n('entities.ubicacion.fields.correoContacto'),
    schema: schemas.string(
      i18n('entities.ubicacion.fields.correoContacto'),
      {},
    ),
  },
  {
    name: 'telefonoContacto',
    label: i18n('entities.ubicacion.fields.telefonoContacto'),
    schema: schemas.integer(
      i18n('entities.ubicacion.fields.telefonoContacto'),
      {},
    ),
  },
  {
    name: 'propiedad',
    label: i18n('entities.ubicacion.fields.propiedad'),
    schema: schemas.boolean(
      i18n('entities.ubicacion.fields.propiedad'),
      {},
    ),
  },
  {
    name: 'empresaExterna',
    label: i18n('entities.ubicacion.fields.empresaExterna'),
    schema: schemas.relationToOne(
      i18n('entities.ubicacion.fields.empresaExterna'),
      {},
    ),
  },
  {
    name: 'visibilidad',
    label: i18n('entities.ubicacion.fields.visibilidad'),
    schema: schemas.enumerator(
      i18n('entities.ubicacion.fields.visibilidad'),
      {
        "options": ubicacionEnumerators.visibilidad
      },
    ),
  },
];