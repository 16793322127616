import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/categoria/importer/categoriaImporterSelectors';
import CategoriaService from 'src/modules/categoria/categoriaService';
import fields from 'src/modules/categoria/importer/categoriaImporterFields';
import { i18n } from 'src/i18n';

const categoriaImporterActions = importerActions(
  'CATEGORIA_IMPORTER',
  selectors,
  CategoriaService.import,
  fields,
  i18n('entities.categoria.importer.fileName'),
);

export default categoriaImporterActions;