//import {GridSearchIcon} from '@material-ui/data-grid';
import {Add, Close, Edit, Search as GridSearchIcon} from '@mui/icons-material';
import {Button, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';
//import {GridSearchIcon} from '@mui/x-data-grid';
import moment from 'moment-timezone';
import React, {useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import {default as gestorDocumentalSelectors, default as selectors} from 'src/modules/gestorDocumental/gestorDocumentalSelectors';
import GestorDocumentalService from 'src/modules/gestorDocumental/gestorDocumentalService';
import layoutActions from 'src/modules/layout/layoutActions';
import GestorDocumentalFormModal from 'src/view/gestorDocumental/form/GestorDocumentalFormModal';
import AutocompleteInMemoryFormItem from 'src/view/shared/form/items/AutocompleteInMemoryFormItem';
import ConfirmModal from 'src/view/shared/modals/ConfirmModal';
import TableCellCustom from 'src/view/shared/table/TableCellCustom';
import TableWrapper from 'src/view/shared/table/styles/TableWrapper';
import DrawerButton from 'src/view/shared/view/DrawerButton';
import FilesViewItem from 'src/view/shared/view/FilesViewItem';
import ImagesViewItem from 'src/view/shared/view/ImagesViewItem';
import GestorDocumentalForm from '../form/GestorDocumentalForm';
import GestorDocumentalFormPage from '../form/GestorDocumentalFormPage';
import GestorDocumentalListPage from '../list/GestorDocumentalListPage';

function GestorDocumentalAutocompleteFormItem(props) {
  const {setValue, getValues, watch} = useFormContext();
  const dispatch = useDispatch();

  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisibleList, setModalVisibleList] = useState(false);

  const hasPermissionToCreate = useSelector(selectors.selectPermissionToCreate);

  const doCloseModal = () => {
    setModalVisible(false);
  };

  const doOpenModal = () => {
    setModalVisible(true);
  };

  const doCloseModalList = () => {
    setModalVisibleList(false);
  };

  const doOpenModalList = () => {
    setModalVisibleList(true);
  };

  const [recordIdToDestroy, setRecordIdToDestroy] = useState(null);
  const hasPermissionToEdit = useSelector(gestorDocumentalSelectors.selectPermissionToEdit);
  const hasPermissionToDestroy = useSelector(gestorDocumentalSelectors.selectPermissionToDestroy);

  const doOpenDestroyConfirmModal = (id) => {
    setRecordIdToDestroy(id);
  };

  const doCloseDestroyConfirmModal = () => {
    setRecordIdToDestroy(null);
  };

  const doCreateSuccess = (record) => {
    const {name, mode} = props;

    if (mode && mode === 'multiple') {
      setValue(name, [...(getValues()[name] || []), record], {shouldValidate: true, shouldDirty: true});
    } else {
      setValue(name, record, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }

    doCloseModal();
  };

  const fetchFn = (value, limit) => {
    return GestorDocumentalService.listAutocomplete(value, limit);
  };

  const mapper = {
    toAutocomplete(originalValue) {
      if (!originalValue) {
        return null;
      }

      const value = originalValue.id;
      let label = originalValue.label;

      if (originalValue.nombre) {
        label = originalValue.nombre;
      }

      return {
        key: value,
        value,
        label,
      };
    },

    toValue(originalValue) {
      if (!originalValue) {
        return null;
      }

      return {
        id: originalValue.value,
        label: originalValue.label,
      };
    },
  };

  const doCreateSuccessList = (record) => {
    const {name, mode} = props;

    if (mode && mode === 'multiple') {
      setValue(name, [...(getValues()[name] || []), record]);
    } else {
      setValue(name, record);
    }

    doCloseModalList();

    dispatch(
      layoutActions.doVisibleDrawer({
        drawerId: 'DrawerGestorDocumentalListPage',
        drawerVisible: false,
        drawerChanged: moment().unix(),
        drawerUpdateComponent: null,
      }),
    );
  };

  const onDocumentEdited = (value) => {
    if (props.mode === 'multiple') {
      const documents = watch(props.name) || [];
      // search document by id
      const document = documents.find((doc) => doc.id === value.id);
      // update document
      if (document) {
        const index = documents.indexOf(document);
        documents[index] = value;
      }
      // update value
      setValue(props.name, documents);
    } else {
      //const document = watch(props.name);
    }
  };

  const onDocumentCreated = (value) => {
    if (props.mode === 'multiple') {
      const documents = watch(props.name) || [];
      documents.push(value);
      setValue(props.name, documents);
    } else {
      //setValue(props.name, value);
    }
  };

  const disabled = props.disabled;

  return (
    <>
      {!props.disableAutocomplete && (
        <AutocompleteInMemoryFormItem
          {...props}
          fetchFn={fetchFn}
          mapper={mapper}
          onOpenModal={doOpenModal}
          hasPermissionToCreate={hasPermissionToCreate}
        />
      )}

      {!props.showButton && (
        <GestorDocumentalForm
          visible={modalVisible}
          onCancel={doCloseModal}
          onSuccess={doCreateSuccess}
          defaultprops={props.defaultprops}
        />
      )}

      {props.showButton && (
        <>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Grid container direction="row" spacing={1}>
                <Grid item>
                  {!disabled && hasPermissionToCreate && (
                    <DrawerButton
                      drawerId={'DrawerGestorDocumentalFormPage'}
                      type={'button'}
                      width={window.innerWidth * 0.55}
                      tooltipTitle={i18n('common.new')}
                      buttonTitle={i18n('common.new')}
                      buttonIcon={<Add />}
                      component={<GestorDocumentalFormPage onCreate={onDocumentCreated} drawer={true} />}
                    />
                  )}
                </Grid>
                <Grid item>
                  {!disabled && (
                    <DrawerButton
                      drawerId={'DrawerGestorDocumentalListPage'}
                      type={'button'}
                      width={window.innerWidth * 0.55}
                      tooltipTitle={i18n('common.searchInDocumentManager')}
                      buttonTitle={i18n('common.searchInDocumentManager')}
                      buttonIcon={<GridSearchIcon />}
                      component={
                        <GestorDocumentalListPage
                          modal={true}
                          mode={props.mode}
                          visible={modalVisibleList}
                          onCancel={doCloseModalList}
                          onSuccess={doCreateSuccessList}
                          drawer={true}
                        />
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {watch(props.name) && props.mode === 'multiple' && (
                <TableWrapper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCellCustom label={i18n('common.documents')} />
                        <TableCellCustom label={i18n('entities.gestorDocumental.fields.validoHasta')} />
                        <TableCellCustom size="md" />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {watch(props.name).map((row, index) => (
                        <TableRow>
                          <TableCell>
                            <strong>{row.nombre}</strong>
                            {row.documentosAdjuntos?.length > 0 && <FilesViewItem value={row.documentosAdjuntos} />}
                            {row.fotosAdjuntas?.length > 0 && <ImagesViewItem value={row.fotosAdjuntas} />}
                          </TableCell>
                          <TableCell>{row.validoHasta ? moment(row.validoHasta).format('DD/MM/YYYY') : 'No definido'}</TableCell>
                          <TableCell>
                            {!disabled && hasPermissionToEdit && (
                              <DrawerButton
                                drawerId={'DrawerGestorDocumentalFormPage'}
                                type={'icon'}
                                tooltipTitle={i18n('common.edit')}
                                buttonTitle={i18n('common.edit')}
                                buttonIcon={<Edit />}
                                width={window.innerWidth * 0.55}
                                component={
                                  <GestorDocumentalFormPage
                                    onUpdate={onDocumentEdited}
                                    record={row}
                                    disabled={false}
                                    drawer={true}
                                  />
                                }
                              />
                            )}
                            {!disabled && hasPermissionToDestroy && (
                              <IconButton
                                size="small"
                                color="secondary"
                                type="button"
                                onClick={() => {
                                  doOpenDestroyConfirmModal(index);
                                }}>
                                <Close />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableWrapper>
              )}
              {watch(props.name) && props.mode !== 'multiple' && (
                <div>
                  <Grid container justifyContent="flex-start" alignItems="center" style={{marginBottom: 10}}>
                    <Button variant="contained" color="secondary" type="button" startIcon={<Close />} size="small">
                      {i18n('common.destroy')}
                    </Button>
                  </Grid>
                  <Grid container justifyContent="flex-start" alignItems="center">
                    <h4 style={{fontWeight: 'bold'}}>{/* {watch(props.name)?.nombre} */}</h4>
                  </Grid>
                  <Grid></Grid>
                  <label style={{fontSize: 12}}>{/* {watch(props.name)?.creditos} */}</label>
                </div>
              )}
            </Grid>
          </Grid>

          {modalVisible && <GestorDocumentalFormModal onClose={doCloseModal} onSuccess={doCreateSuccess} />}

          {recordIdToDestroy !== null && (
            <ConfirmModal
              title={i18n('common.areYouSure')}
              onConfirm={() => {
                setValue(
                  props.name,
                  watch(props.name)?.filter((raw, i) => i !== recordIdToDestroy),
                );
                doCloseDestroyConfirmModal();
              }}
              onClose={() => doCloseDestroyConfirmModal()}
              okText={i18n('common.yes')}
              cancelText={i18n('common.no')}
            />
          )}
        </>
      )}
    </>
  );
}

export default GestorDocumentalAutocompleteFormItem;
