import { useState, useCallback, useEffect } from 'react';

import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/Footer';
import PageTitleWrapper from 'src/components/PageTitleWrapper';

import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import useRefMounted from 'src/hooks/useRefMounted';

import Body from './body';
import Header from './header';

function PlanificadorTareaDocumentView(props) {
  const isMountedRef = useRefMounted();
  const [invoice, setInvoice] = useState(null);
  const { invoiceId } = useParams();
  const { record } = props;

  useEffect(() => {}, []);

  return (
    <>
      <Grid
        sx={{ px: 4 }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <Body record={record} />
        </Grid>
      </Grid>
    </>
  );
}

export default PlanificadorTareaDocumentView;
