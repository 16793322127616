import {Grid, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import StandaloneSearchBox from 'react-google-maps/lib/components/places/StandaloneSearchBox';
import {useFormContext} from 'react-hook-form';
import {compose, withProps} from 'recompose';
import FormErrors from 'src/view/shared/form/formErrors';

const {withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow} = require('react-google-maps');

export const InputFormItem = (props) => {
  const {
    label,
    name,
    hint,
    type,
    placeholder,
    autoFocus,
    autoComplete,
    required,
    externalErrorMessage,
    disabled,
    endAdornment,
    defaultCenter,
    zoom,
    center,
    enableMap,
  } = props;

  const refs = useRef();

  const {
    setValue,
    control,
    watch,
    register,

    formState: {touchedFields, isSubmitted, errors},
  } = useFormContext();

  useEffect(() => {
    register(name);
    register('latitud');
    register('longitud');
    register('geocode');
  }, []);

  const errorMessage = FormErrors.errorMessage(name, errors, touchedFields, isSubmitted, externalErrorMessage);

  const handlePlacesChanged = async () => {
    const places = refs.current.getPlaces();

    setValue('latitud', places && places[0] && places[0].geometry.location.lat() && places[0].geometry.location.lat());
    setValue('longitud', places && places[0] && places[0].geometry.location.lng() && places[0].geometry.location.lng());
    setValue(name, places && places[0] && places[0].formatted_address);
  };

  const onZoomChanged = () => {};

  const onMapMounted = () => {};

  const onToggleOpen = () => {};

  const onDragEnd = (event) => {
    setValue('latitud', event.latLng.lat());
    setValue('longitud', event.latLng.lng());
  };

  const onCenterChanged = (event) => {
    console.log(event);
  };

  const GoogleMapComponent = compose(
    withProps({
      loadingElement: <div style={{height: `100%`}} />,
      containerElement: <div style={{height: `400px`}} />,
      mapElement: <div style={{height: `100%`}} />,
    }),
    withGoogleMap,
  )((props) => <GoogleMap {...props} />);

  if (disabled) {
    return (
      <Grid container direction="column">
        {label && (
          <Grid item>
            <label
              style={{
                color: 'gray',
                fontWeight: 600,
                fontSize: 20,
              }}>
              {label}
            </label>
          </Grid>
        )}
        <Grid item>
          <h3>{watch(name)}</h3>
        </Grid>
        <GoogleMapComponent
          defaultCenter={defaultCenter}
          center={center}
          defaultZoom={zoom || 15}
          ref={onMapMounted}
          onZoomChanged={onZoomChanged}
          onCenterChanged={onCenterChanged}>
          <Marker
            position={{
              lat: watch('latitud'),
              lng: watch('longitud'),
            }}
            defaultPosition={defaultCenter}
            onClick={onToggleOpen}
            draggable={false}
            onDragEnd={onDragEnd}>
            {/*
                        <InfoWindow onCloseClick={onToggleOpen}>
                            <div>
                                <FaAnchor />
                                {" "} Controlled zoom: {zoom}
                            </div>
                        </InfoWindow>
                        */}
          </Marker>
        </GoogleMapComponent>
      </Grid>
    );
  }

  const inputRef = register(name, {
    onChange: (event) => {
      setValue(name, event.target.value, {shouldValidate: true});
      props.onChange && props.onChange(event.target.value);
    },
    onBlur: (event) => {
      props.onBlur && props.onBlur(event);
    },
  });
  return (
    <React.Fragment>
      <StandaloneSearchBox onPlacesChanged={handlePlacesChanged} ref={refs}>
        <TextField
          {...inputRef}
          inputRef={inputRef.ref}
          id={name}
          name={name}
          type={type}
          label={label}
          required={required}
          margin={props.margin || 'normal'}
          fullWidth
          variant="outlined"
          size="small"
          placeholder={placeholder || 'Ejemplos de busqueda “Concepción,Chile / Lugar Objetivo” '}
          autoFocus={autoFocus || undefined}
          autoComplete={autoComplete || undefined}
          InputLabelProps={{
            shrink: true,
          }}
          error={Boolean(errorMessage)}
          helperText={errorMessage || hint}
          InputProps={{endAdornment}}
          inputProps={{
            name,
          }}
          disabled={disabled}
        />
      </StandaloneSearchBox>
      {enableMap === true ? (
        <GoogleMapComponent
          defaultCenter={defaultCenter}
          center={center}
          zoom={zoom || 15}
          defaultZoom={zoom || 15}
          ref={onMapMounted}
          onZoomChanged={onZoomChanged}
          onCenterChanged={onCenterChanged}>
          <Marker
            position={{
              lat: watch('latitud'),
              lng: watch('longitud'),
            }}
            defaultPosition={defaultCenter}
            onClick={onToggleOpen}
            draggable={true}
            onDragEnd={onDragEnd}>
            {/*
                        <InfoWindow onCloseClick={onToggleOpen}>
                            <div>
                                <FaAnchor />
                                {" "} Controlled zoom: {zoom}
                            </div>
                        </InfoWindow>
                        */}
          </Marker>
        </GoogleMapComponent>
      ) : null}
    </React.Fragment>
  );
};

InputFormItem.defaultProps = {
  layout: null,
  type: 'text',
  required: false,
};

InputFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  prefix: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  externalErrorMessage: PropTypes.string,
  onChange: PropTypes.func,
  endAdornment: PropTypes.any,
  defaultCenter: PropTypes.object,
  zoom: PropTypes.number,
  center: PropTypes.object,
  enableMap: PropTypes.bool,
};

export default InputFormItem;
