const tareaEnumerators = {
  respuesta: ['Si', 'No'],
  estado: [
    'Abierta',
    'Cerrada',
    'Rechazada',
    'FueraPlazo',
    'PendienteRevision',
    'EnRevision',
    'requestedSignature',
    'signedByUser',
    'notRequested',
  ],
  dirigidoA: ['Puesto', 'Ejecutor'],
  tipoVisualizacion: ['Ciclico1', 'Pasos', 'Estandar', 'Checklist', 'Procedimiento'],
  tipoMantenimiento: ['Preventiva', 'Programada', 'Correctiva', 'Predictiva'],
  tipoTarea: ['Estandar', 'Personalizada', 'Modelo', 'Operativa', 'Mantenimiento', 'Incidente'],
  clasificacion: ['operativa', 'mantenimiento', 'incidente'],
  prioridad: ['Baja', 'Media', 'Alta'],
};

export default tareaEnumerators;
