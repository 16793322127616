import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import LinkIcon from '@mui/icons-material/Link';
import {Box, Button, Link as MaterialLink} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import {i18n} from 'src/i18n';
import Errors from 'src/modules/shared/error/errors';
import FileUploader from 'src/modules/shared/fileUpload/fileUploader';

function FilesUploader(props) {
  const [loading, setLoading] = useState(false);
  const input = useRef<any>();
  const classes = {
    uploadButton: {
      mt: 1,
    },

    linkIcon: {
      mr: 1,
    },

    link: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    clearLink: {
      ml: 1,
    },
  };

  const value = () => {
    const {value} = props;

    if (!value) {
      return [];
    }

    return Array.isArray(value) ? value.filter(Boolean) : [value];
  };

  const fileList = () => {
    return value().map((item) => ({
      uid: item.id || undefined,
      name: item.name,
      status: 'done',
      url: item.downloadUrl || item.publicUrl,
    }));
  };

  const handleRemove = (id) => {
    props.onChange(value().filter((item) => item.id !== id));
  };

  const handleChange = async (event) => {
    try {
      const files = event.target.files;

      if (!files || !files.length) {
        return;
      }

      let file = files[0];

      FileUploader.validate(file, {
        storage: props.storage,
        formats: props.formats,
      });

      setLoading(true);

      file = await FileUploader.upload(file, {
        storage: props.storage,
        formats: props.formats,
      });

      input.current.value = '';

      setLoading(false);
      props.onChange([...value(), file]);
    } catch (error) {
      input.current.value = '';
      console.error(error);
      setLoading(false);
      Errors.showMessage(error);
    }
  };

  const formats = () => {
    const {schema} = props;

    if (schema && schema.formats) {
      return schema.formats.map((format) => `.${format}`).join(',');
    }

    return undefined;
  };

  const {max, readonly} = props;

  const uploadButton = (
    <>
      <Button
        sx={classes.uploadButton}
        component="label"
        disabled={loading}
        startIcon={<AddIcon />}
        variant="outlined"
        size="small"
        onClick={() => input.current.click()}>
        {i18n('fileUploader.upload')}
      </Button>
      <input
        style={{display: 'none'}}
        disabled={loading || readonly}
        accept={formats()}
        type="file"
        onChange={handleChange}
        ref={input}
      />
    </>
  );

  return (
    <div>
      {readonly || (max && fileList().length >= max) ? null : uploadButton}

      {value() && value().length ? (
        <div>
          {value().map((item) => {
            return (
              <Box display="flex" alignItems="center" key={item.id}>
                <MaterialLink
                  sx={classes.link}
                  href={item.downloadUrl || item.publicUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  download>
                  <LinkIcon sx={classes.linkIcon} color="primary" />

                  {item.name}
                </MaterialLink>

                {!readonly && (
                  <MaterialLink
                    href="#"
                    component="button"
                    color="secondary"
                    onClick={() => handleRemove(item.id)}
                    sx={classes.clearLink}>
                    <ClearIcon fontSize="small" />
                  </MaterialLink>
                )}
              </Box>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}

FilesUploader.propTypes = {
  readonly: PropTypes.bool,
  max: PropTypes.number,
  formats: PropTypes.arrayOf(PropTypes.string),
  storage: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default FilesUploader;
