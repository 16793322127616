import {Box, Grid, Typography} from '@mui/material';
import * as React from 'react';

export const LabelGrid = (props) => {
  const {label} = props;
  return (
    <Grid mb={1} container direction="column">
      {label && <Typography variant="h4">{label}</Typography>}
      {props.children}
    </Grid>
  );
};

export const LabelTypography = (props) => {
  return (
    <Typography
      variant={props.variant || 'subtitle2'}
      sx={{
        fontWeight: 500,
        fontSize: props.small ? '1.05em' : '1.15em',
        ...props.sx,
      }}>
      {props.children}
    </Typography>
  );
};

export const DisabledText = (props) => {
  const {label, value, noAnswerText = 'Sin Respuesta'} = props;
  return (
    <LabelGrid label={label}>
      <Box>
        {value || value === '0' || value === 0 ? (
          <LabelTypography>{value}</LabelTypography>
        ) : (
          <LabelTypography small>{noAnswerText}</LabelTypography>
        )}
      </Box>
    </LabelGrid>
  );
};

export const DisabledItem = (props) => {
  const {label, item, noAnswerText = 'Sin Respuesta', show = true} = props;
  return (
    <LabelGrid label={label}>
      {show ? (
        item
      ) : (
        <Box>
          <LabelTypography small>{noAnswerText}</LabelTypography>
        </Box>
      )}
    </LabelGrid>
  );
};
