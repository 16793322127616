import {yupResolver} from '@hookform/resolvers/yup';
import {ExpandMore, Search, Undo, Update} from '@mui/icons-material';
import {AccordionDetails, AccordionSummary, Button, Grid, IconButton} from '@mui/material';

import _debounce from 'lodash/debounce';
import React, {useCallback, useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import layoutSelectors from 'src/modules/layout/layoutSelectors';
import filterRenders from 'src/modules/shared/filter/filterRenders';
import yupFilterSchemas from 'src/modules/shared/yup/yupFilterSchemas';
import actions from 'src/modules/tarea/list/tareaListActions2';
import selectors from 'src/modules/tarea/list/tareaListSelectors2';
import tareaEnumerators from 'src/modules/tarea/tareaEnumerators';
import ActivoAutocompleteFormItem from 'src/view/activo/autocomplete/ActivoAutocompleteFormItem';
import CategoriaAutocompleteFormItem from 'src/view/categoria/autocomplete/CategoriaAutocompleteFormItem';
import ModeloTareaAutocompleteFormItem from 'src/view/modeloTarea/autocomplete/ModeloTareaAutocompleteFormItem';
import PlanificacionAutocompleteFormItem from 'src/view/planificacion/autocomplete/PlanificacionAutocompleteFormItem';
import FilterAccordion from 'src/view/shared/filter/FilterAccordion';
import FilterPreview from 'src/view/shared/filter/FilterPreview';
import DatePickerRangeFormItem from 'src/view/shared/form/items/DatePickerRangeFormItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import SelectFormItem from 'src/view/shared/form/items/SelectFormItem';
import FilterWrapper, {FilterButtons} from 'src/view/shared/styles/FilterWrapper';
import UbicacionAutocompleteFormItem from 'src/view/ubicacion/autocomplete/UbicacionAutocompleteFormItem';
import UserAutocompleteFormItem from 'src/view/user/autocomplete/UserAutocompleteFormItem';
import * as yup from 'yup';

const schema = yup.object().shape({
  numeroTarea: yupFilterSchemas.integer(i18n('entities.tarea.fields.numeroTarea')),
  activo: yupFilterSchemas.relationToOne(i18n('entities.tarea.fields.activo')),
  sucursal: yupFilterSchemas.relationToOne(i18n('entities.tarea.fields.sucursal')),
  planificacion: yupFilterSchemas.relationToMany(i18n('entities.tarea.fields.planificacion')),
  modeloTarea: yupFilterSchemas.relationToOne(i18n('entities.tarea.fields.modeloTarea')),
  respuesta: yupFilterSchemas.enumerator(i18n('entities.tarea.fields.respuesta')),
  comentario: yupFilterSchemas.string(i18n('entities.tarea.fields.comentario')),
  ejecutor: yupFilterSchemas.relationToOne(i18n('entities.tarea.fields.ejecutor')),
  estado: yupFilterSchemas.enumerator(i18n('entities.tarea.fields.estado')),
  comienzoRange: yupFilterSchemas.datetimeRange(i18n('entities.tarea.fields.comienzoRange')),
  finRange: yupFilterSchemas.datetimeRange(i18n('entities.tarea.fields.finRange')),
  estadoActivacion: yupFilterSchemas.boolean(i18n('entities.tarea.fields.estadoActivacion')),
  fechaProgramacionRange: yupFilterSchemas.datetimeRange(i18n('entities.tarea.fields.fechaProgramacionRange')),
  fechaActivacionRange: yupFilterSchemas.datetimeRange(i18n('entities.tarea.fields.fechaActivacionRange')),
  titulo: yupFilterSchemas.string(i18n('entities.tarea.fields.titulo')),
  categoria: yupFilterSchemas.relationToOne(i18n('entities.tarea.fields.categoria')),
  dirigidoA: yupFilterSchemas.enumerator(i18n('entities.tarea.fields.dirigidoA')),
  tipoVisualizacion: yupFilterSchemas.enumerator(i18n('entities.tarea.fields.tipoVisualizacion')),
  fechaLimiteRange: yupFilterSchemas.datetimeRange(i18n('entities.tarea.fields.fechaLimiteRange')),
  enviada: yupFilterSchemas.boolean(i18n('entities.tarea.fields.enviada')),
  esEvaluada: yupFilterSchemas.boolean(i18n('entities.tarea.fields.esEvaluada')),
  metaInterfaz: yupFilterSchemas.string(i18n('entities.tarea.fields.metaInterfaz')),
  incidenteDesc: yupFilterSchemas.string(i18n('entities.tarea.fields.incidente')),
  esPlanificada: yupFilterSchemas.string(i18n('entities.tarea.fields.esPlanificada')),
  locations: yupFilterSchemas.relationToMany(i18n('common.locations')),
});

const emptyValues = {
  sucursal: null,
  planificacion: null,
  modeloTarea: null,
  respuesta: null,
  comentario: null,
  ejecutor: null,
  estado: null,
  comienzoRange: [null, null],
  finRange: [null, null],
  estadoActivacion: null,
  fechaProgramacionRange: [null, null],
  fechaActivacionRange: [null, null],
  titulo: null,
  numeroTarea: null,
  categoria: null,
  dirigidoA: null,
  tipoVisualizacion: null,
  fechaLimiteRange: [null, null],
  enviada: null,
  esEvaluada: null,
  metaInterfaz: null,
  incidenteDesc: null,
  esPlanificada: null,
  withChildren: true,
  locations: null,
};

const previewRenders = {
  sucursal: {
    label: i18n('entities.tarea.fields.sucursal'),
    render: filterRenders.relationToOne(),
  },
  activo: {
    label: i18n('entities.tarea.fields.activo'),
    render: filterRenders.relationToOne(),
  },
  planificacion: {
    label: i18n('entities.tarea.fields.planificacion'),
    render: filterRenders.relationToMany(),
  },
  modeloTarea: {
    label: i18n('entities.tarea.fields.modeloTarea'),
    render: filterRenders.relationToOne(),
  },
  respuesta: {
    label: i18n('entities.tarea.fields.respuesta'),
    render: filterRenders.enumerator('entities.tarea.enumerators.respuesta'),
  },
  comentario: {
    label: i18n('entities.tarea.fields.comentario'),
    render: filterRenders.generic(),
  },
  ejecutor: {
    label: i18n('entities.tarea.fields.ejecutor'),
    render: filterRenders.relationToOne(),
  },
  estado: {
    label: i18n('entities.tarea.fields.estado'),
    render: filterRenders.enumerator('entities.tarea.enumerators.estado'),
  },
  comienzoRange: {
    label: i18n('entities.tarea.fields.comienzoRange'),
    render: filterRenders.datetimeRange(),
  },
  finRange: {
    label: i18n('entities.tarea.fields.finRange'),
    render: filterRenders.datetimeRange(),
  },
  estadoActivacion: {
    label: i18n('entities.tarea.fields.estadoActivacion'),
    render: filterRenders.boolean(),
  },
  fechaProgramacionRange: {
    label: i18n('entities.tarea.fields.fechaProgramacionRange'),
    render: filterRenders.datetimeRange(),
  },
  fechaActivacionRange: {
    label: i18n('entities.tarea.fields.fechaActivacionRange'),
    render: filterRenders.datetimeRange(),
  },
  numeroTarea: {
    label: i18n('entities.tarea.fields.numeroTarea'),
    render: filterRenders.generic(),
  },
  titulo: {
    label: i18n('entities.tarea.fields.titulo'),
    render: filterRenders.generic(),
  },
  categoria: {
    label: i18n('entities.tarea.fields.categoria'),
    render: filterRenders.relationToOne(),
  },
  dirigidoA: {
    label: i18n('entities.tarea.fields.dirigidoA'),
    render: filterRenders.enumerator('entities.tarea.enumerators.dirigidoA'),
  },
  tipoVisualizacion: {
    label: i18n('entities.tarea.fields.tipoVisualizacion'),
    render: filterRenders.enumerator('entities.tarea.enumerators.tipoVisualizacion'),
  },
  fechaLimiteRange: {
    label: i18n('entities.tarea.fields.fechaLimiteRange'),
    render: filterRenders.datetimeRange(),
  },
  enviada: {
    label: i18n('entities.tarea.fields.enviada'),
    render: filterRenders.boolean(),
  },
  esEvaluada: {
    label: i18n('entities.tarea.fields.esEvaluada'),
    render: filterRenders.boolean(),
  },
  metaInterfaz: {
    label: i18n('entities.tarea.fields.metaInterfaz'),
    render: filterRenders.generic(),
  },
  incidenteDesc: {
    label: i18n('entities.tarea.fields.incidente'),
    render: filterRenders.generic(),
  },
  esPlanificada: {
    label: i18n('entities.tarea.fields.esPlanificada'),
    render: filterRenders.enumerator('entities.tarea.enumerators.esPlanificada'),
  },
  prioridad: {
    label: i18n('entities.tarea.fields.prioridad'),
    render: filterRenders.generic(),
  },
  locations: {
    label: i18n('common.locations'),
    render: filterRenders.relationToMany(),
  },
};

function TareaListFilter(props) {
  const rawFilter = useSelector(selectors.selectRawFilter);
  const layout: any = useSelector(layoutSelectors.selectRaw);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);

  const [initialValues] = useState(() => {
    return {
      ...emptyValues,
      ...rawFilter,
      withChildren: true,
      ...props.filter,
    };
  });

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
    mode: 'all',
  });

  // const fechaProgramacionRange = useWatch({name: 'fechaProgramacionRange', control: form.control});
  // const numeroTarea = useWatch({name: 'numeroTarea', control: form.control});
  // podría ser un watch al form completo?
  // Mejor un metodo en los onChange de los input
  const handleActualizarFiltro = () => {
    const filter = form.getValues();
    //console.log('%c⧭ actualizarFiltro', 'color: #e57373', {filter});
    form.handleSubmit((valuesFormated) => {
      //console.log('%c⧭ handleSubmit', 'color: #1d5673', {valuesFormated});
      dispatch(actions.setHasFilter(valuesFormated, ['fechaProgramacionRange']));
    })(filter);
  };
  // eslint-disable-next-line
  const debounceActualizarFiltro = useCallback(_debounce(handleActualizarFiltro, 600), []); // para que no se quede pegado
  const actualizarFiltro = () => {
    debounceActualizarFiltro();
  };
  //console.log('tarealistfilter rendered');

  useEffect(() => {
    if (layout.drawerUpdateComponent === 'TareaListPage') {
      form.handleSubmit(onSubmit)();
    }
  }, [layout.drawerChanged]);

  const propListFilterKey = props.listFilterKey || 0;
  const selectListFilterKey = useSelector(selectors.selectListFilterKey);

  useEffect(() => {
    //console.log('%c⧭ (propListFilterKey != selectListFilterKey)', 'color: #514080', {propListFilterKey, selectListFilterKey});
    if (propListFilterKey != selectListFilterKey) {
      onReset();
      dispatch(
        actions.setPayload({
          listFilterKey: propListFilterKey,
        }),
      );
    } else {
      dispatch(actions.doFetch(schema.cast(initialValues), rawFilter));
    }
    // eslint-disable-next-line
  }, [dispatch]);

  const onSubmit = (values) => {
    const rawValues = form.getValues();
    dispatch(actions.doFetch(values, rawValues));
    setExpanded(false);
  };

  const onReset = () => {
    const resetValues = props.filter ? initialValues : emptyValues;
    form.reset(resetValues);
    dispatch(actions.doReset(resetValues));
    setExpanded(false);
  };

  const loading = useSelector(selectors.selectLoading);

  return (
    <FilterWrapper>
      <FilterAccordion expanded={expanded} onChange={(event, isExpanded) => setExpanded(isExpanded)}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <IconButton
            disabled={loading}
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              form.handleSubmit(onSubmit)();
            }}
            style={{
              marginRight: 20,
            }}>
            <Update />
          </IconButton>
          <FilterPreview values={rawFilter} renders={previewRenders} expanded={expanded} />
        </AccordionSummary>
        <AccordionDetails>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <Grid container columnSpacing={2}>
                {/* <Grid item lg={6} xs={12}>
                  <SelectFormItem
                    name="tipoVisualizacion"
                    label={i18n('entities.tarea.fields.tipoVisualizacion')}
                    options={tareaEnumerators.tipoVisualizacion.map(
                      (value) => ({
                        value,
                        label: i18n(
                          `entities.tarea.enumerators.tipoVisualizacion.${value}`,
                        ),
                      }),
                    )}
                  />
                </Grid> */}
                <Grid item lg={6} xs={12}>
                  <SelectFormItem
                    name="prioridad"
                    label={i18n('entities.tarea.fields.prioridad')}
                    options={tareaEnumerators.prioridad.map((value) => ({
                      value,
                      label: i18n(`entities.tarea.enumerators.prioridad.${value}`),
                    }))}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <InputFormItem
                    onChange={(e) => {
                      actualizarFiltro();
                    }}
                    name="numeroTarea"
                    label={i18n('entities.tarea.fields.numeroTarea')}
                    required={false}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <PlanificacionAutocompleteFormItem
                    //filter={{onlyParents: true}}
                    onChange={(e) => {
                      actualizarFiltro();
                    }}
                    name="planificacion"
                    label={i18n('entities.tarea.fields.planificacion')}
                    mode="multiple"
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <ActivoAutocompleteFormItem
                    onChange={(e) => {
                      actualizarFiltro();
                    }}
                    name="activo"
                    label={i18n('entities.tarea.fields.activo')}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <ModeloTareaAutocompleteFormItem
                    onChange={(e) => {
                      actualizarFiltro();
                    }}
                    name="modeloTarea"
                    label={i18n('entities.tarea.fields.modeloTarea')}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <UserAutocompleteFormItem
                    onChange={(e) => {
                      actualizarFiltro();
                    }}
                    name="ejecutor"
                    label={i18n('entities.tarea.fields.ejecutor')}
                  />
                </Grid>
                {/* <Grid item lg={6} xs={12}>
                  <SelectFormItem
                    name="respuesta"
                    label={i18n(
                      'entities.tarea.fields.respuesta',
                    )}
                    options={tareaEnumerators.respuesta.map(
                      (value) => ({
                        value,
                        label: i18n(
                          `entities.tarea.enumerators.respuesta.${value}`,
                        ),
                      }),
                    )}
                  />
                </Grid> */}

                <Grid item sm={3} xs={12}>
                  <SelectFormItem
                    onChange={(e) => {
                      actualizarFiltro();
                    }}
                    name="estado"
                    label={i18n('entities.tarea.fields.estado')}
                    options={tareaEnumerators.estado.map((value) => ({
                      value,
                      label: i18n(`entities.tarea.enumerators.estado.${value}`),
                    }))}
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <SelectFormItem
                    name="esPlanificada"
                    label={i18n('entities.tarea.fields.esPlanificada')}
                    options={[
                      {
                        value: 'si',
                        label: i18n('common.yes'),
                      },
                      {
                        value: 'no',
                        label: i18n('common.no'),
                      },
                    ]}
                  />
                </Grid>
                {/* <Grid item lg={6} xs={12}>
                  <SelectFormItem
                    name="estadoActivacion"
                    label={i18n(
                      'entities.tarea.fields.estadoActivacion',
                    )}
                    options={[
                      {
                        value: true,
                        label: i18n('common.yes'),
                      },
                      {
                        value: false,
                        label: i18n('common.no'),
                      },
                    ]}
                  />
                </Grid> */}
                {/* <Grid item lg={6} xs={12}>
                  <DatePickerRangeFormItem
                    name="fechaActivacionRange"
                    label={i18n(
                      'entities.tarea.fields.fechaActivacionRange',
                    )}
                    showTime
                  />
                </Grid> */}
                <Grid item lg={6} xs={12}>
                  <CategoriaAutocompleteFormItem
                    onChange={(e) => {
                      actualizarFiltro();
                    }}
                    name="categoria"
                    label={i18n('entities.tarea.fields.categoria')}
                  />
                </Grid>
                {/* <Grid item lg={6} xs={12}>
                  <SelectFormItem
                    name="dirigidoA"
                    label={i18n(
                      'entities.tarea.fields.dirigidoA',
                    )}
                    options={tareaEnumerators.dirigidoA.map(
                      (value) => ({
                        value,
                        label: i18n(
                          `entities.tarea.enumerators.dirigidoA.${value}`,
                        ),
                      }),
                    )}
                  />
                </Grid> */}
                <Grid item lg={6} xs={12}>
                  <UbicacionAutocompleteFormItem name="locations" label={i18n('common.locations')} mode="multiple" />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <InputFormItem
                    onChange={(e) => {
                      actualizarFiltro();
                    }}
                    name="incidenteDesc"
                    label={i18n('entities.tarea.fields.incidente')}
                    required={false}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <DatePickerRangeFormItem
                    onChange={(e) => {
                      actualizarFiltro();
                    }}
                    name="fechaProgramacionRange"
                    label={i18n('entities.tarea.fields.fechaProgramacionRange')}
                  />
                </Grid>
                {/* <Grid item lg={6} xs={12}>
                  <SelectFormItem
                    name="enviada"
                    label={i18n(
                      'entities.tarea.fields.enviada',
                    )}
                    options={[
                      {
                        value: true,
                        label: i18n('common.yes'),
                      },
                      {
                        value: false,
                        label: i18n('common.no'),
                      },
                    ]}
                  />
                </Grid> */}
                {/* <Grid item lg={6} xs={12}>
                  <SelectFormItem
                    name="esEvaluada"
                    label={i18n(
                      'entities.tarea.fields.esEvaluada',
                    )}
                    options={[
                      {
                        value: true,
                        label: i18n('common.yes'),
                      },
                      {
                        value: false,
                        label: i18n('common.no'),
                      },
                    ]}
                  />
                </Grid> */}
              </Grid>

              <FilterButtons>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={form.handleSubmit(onSubmit)}
                  disabled={props.loading}
                  startIcon={<Search />}
                  size="small">
                  {i18n('common.search')}
                </Button>

                <Button type="button" onClick={onReset} disabled={props.loading} startIcon={<Undo />} size="small">
                  {i18n('common.reset')}
                </Button>
              </FilterButtons>
            </form>
          </FormProvider>
        </AccordionDetails>
      </FilterAccordion>
    </FilterWrapper>
  );
}

export default TareaListFilter;
