import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/turnoLaboral/importer/turnoLaboralImporterSelectors';
import TurnoLaboralService from 'src/modules/turnoLaboral/turnoLaboralService';
import fields from 'src/modules/turnoLaboral/importer/turnoLaboralImporterFields';
import { i18n } from 'src/i18n';

const turnoLaboralImporterActions = importerActions(
  'TURNOLABORAL_IMPORTER',
  selectors,
  TurnoLaboralService.import,
  fields,
  i18n('entities.turnoLaboral.importer.fileName'),
);

export default turnoLaboralImporterActions;