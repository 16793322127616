let notistakEnqueueSnackbar;

export default class Message {
  static registerNotistakEnqueueSnackbar(instance) {
    notistakEnqueueSnackbar = instance;
  }

  static success(arg, autoHideDuration: any = undefined) {
    notistakEnqueueSnackbar(arg, {autoHideDuration});
  }

  static error(arg, autoHideDuration: any = undefined) {
    notistakEnqueueSnackbar(arg, {variant: 'error', autoHideDuration});
  }
}
