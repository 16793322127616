import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/nivelPuesto/importer/nivelPuestoImporterSelectors';
import NivelPuestoService from 'src/modules/nivelPuesto/nivelPuestoService';
import fields from 'src/modules/nivelPuesto/importer/nivelPuestoImporterFields';
import { i18n } from 'src/i18n';

const nivelPuestoImporterActions = importerActions(
  'NIVELPUESTO_IMPORTER',
  selectors,
  NivelPuestoService.import,
  fields,
  i18n('entities.nivelPuesto.importer.fileName'),
);

export default nivelPuestoImporterActions;