import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.clasificacionTercero.fields.id'),
  },
  {
    name: 'descripcion',
    label: i18n('entities.clasificacionTercero.fields.descripcion'),
  },
  {
    name: 'habilitado',
    label: i18n('entities.clasificacionTercero.fields.habilitado'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'tercerosVinculados',
    label: i18n('entities.clasificacionTercero.fields.tercerosVinculados'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.clasificacionTercero.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.clasificacionTercero.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
