import {yupResolver} from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import {Button, Grid} from '@mui/material';
import moment from 'moment-timezone';
import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import authSelectors from 'src/modules/auth/authSelectors';
import registroEnumerators from 'src/modules/registro/registroEnumerators';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import {magicFlexBox} from 'src/theme/schemes/CustomTheme';
import ActivoAutocompleteFormItem from 'src/view/activo/autocomplete/ActivoAutocompleteFormItem';
import DescRegistroAutocompleteFormItem from 'src/view/descRegistro/autocomplete/DescRegistroAutocompleteFormItem';
import PlanificacionAutocompleteFormItem from 'src/view/planificacion/autocomplete/PlanificacionAutocompleteFormItem';
import DatePickerFormItem from 'src/view/shared/form/items/DatePickerFormItem';
import InputNumberFormItem from 'src/view/shared/form/items/InputNumberFormItem';
import SelectFormItem from 'src/view/shared/form/items/SelectFormItem';
import Message from 'src/view/shared/message';
import FormWrapper, {FormButtons} from 'src/view/shared/styles/FormWrapper';
import TareaAutocompleteFormItem from 'src/view/tarea/autocomplete/TareaAutocompleteFormItem';
import UbicacionAutocompleteFormItem from 'src/view/ubicacion/autocomplete/UbicacionAutocompleteFormItem';
import UserAutocompleteFormItem from 'src/view/user/autocomplete/UserAutocompleteFormItem';
import * as yup from 'yup';

const schema = yup.object().shape({
  descRegistro: yupFormSchemas.relationToOne(i18n('entities.registro.fields.descripcion'), {}),
  categoria: yupFormSchemas.enumerator(i18n('entities.registro.fields.categoria'), {
    options: registroEnumerators.categoria,
  }),
  descripcion: yupFormSchemas.string(i18n('entities.registro.fields.descripcion'), {
    required: true,
  }),
  comentario: yupFormSchemas.string(i18n('entities.registro.fields.comentario'), {}),
  tipo: yupFormSchemas.enumerator(i18n('entities.registro.fields.tipo'), {
    options: registroEnumerators.tipo,
  }),
  prioridad: yupFormSchemas.enumerator(i18n('entities.registro.fields.prioridad'), {
    options: registroEnumerators.prioridad,
  }),
  fechaInicio: yupFormSchemas.datetime(i18n('entities.registro.fields.fechaInicio'), {}),
  duracion: yupFormSchemas.integer(i18n('entities.registro.fields.duracion'), {}),
  fechaFin: yupFormSchemas.datetime(i18n('entities.registro.fields.fechaFin'), {}),
  cuentasContables: yupFormSchemas.relationToMany(i18n('entities.registro.fields.cuentasContables'), {}),
  nota: yupFormSchemas.string(i18n('entities.registro.fields.nota'), {}),
  ubicacionGoogle: yupFormSchemas.string(i18n('entities.registro.fields.ubicacionGoogle'), {}),
  latitud: yupFormSchemas.decimal(i18n('entities.registro.fields.latitud'), {}),
  longitud: yupFormSchemas.decimal(i18n('entities.registro.fields.longitud'), {}),
  trazabilidadesVinculadas: yupFormSchemas.relationToMany(i18n('entities.registro.fields.trazabilidadesVinculadas'), {}),
  activosVinculados: yupFormSchemas.relationToMany(i18n('entities.registro.fields.activosVinculados'), {}),
  documentosVinculados: yupFormSchemas.relationToMany(i18n('entities.registro.fields.documentosVinculados'), {}),
  inventariosVinculados: yupFormSchemas.relationToMany(i18n('entities.registro.fields.inventariosVinculados'), {}),
  tareasVinculadas: yupFormSchemas.relationToMany(i18n('entities.registro.fields.tareasVinculadas'), {}),
  planificacionesVinculadas: yupFormSchemas.relationToMany(i18n('entities.registro.fields.planificacionesVinculadas'), {}),
  usuariosVinculados: yupFormSchemas.relationToMany(i18n('entities.registro.fields.usuariosVinculados'), {}),
  locations: yupFormSchemas.relationToMany(i18n('common.locations'), {}),
});

function RegistroForm(props) {
  const tenantUser = useSelector(authSelectors.selectTenantUser);
  const userLocations = tenantUser?.perfilEmpleado?.ubicacion;

  const categoriaSeleccionada = props.categoriaSeleccionada;
  const [initialValues] = useState(() => {
    const record = props.record || {};

    return {
      descRegistro: record.descRegistro,
      categoria: record.categoria || categoriaSeleccionada,
      descripcion: record.descripcion,
      tipo: record.tipo,
      prioridad: record.prioridad,
      fechaInicio: record.fechaInicio ? moment(record.fechaInicio, 'YYYY-MM-DD HH:mm') : moment(),
      duracion: record.duracion,
      fechaFin: record.fechaFin ? moment(record.fechaFin, 'YYYY-MM-DD HH:mm') : null,
      cuentasContables: record.cuentasContables || [],
      nota: record.nota,
      ubicacionGoogle: record.ubicacionGoogle,
      latitud: record.latitud,
      longitud: record.longitud,
      trazabilidadesVinculadas: record.trazabilidadesVinculadas || [],
      activosVinculados: record.activosVinculados?.filter((a) => a) || [], // hotfix por error al guardar activo
      documentosVinculados: record.documentosVinculados || [],
      inventariosVinculados: record.inventariosVinculados || [],
      tareasVinculadas: record.tareasVinculadas || [],
      planificacionesVinculadas: record.planificacionesVinculadas || [],
      usuariosVinculados: record.usuariosVinculados || [],
      locations: record.locations || userLocations || [],
    };
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
    shouldUnregister: false,
  });
  const {getValues, setValue} = form;
  const categoria = categoriaSeleccionada || form.getValues('categoria');

  useEffect(() => {
    let descRegistro = form.getValues('descRegistro');
    if ((!descRegistro || descRegistro.categoria !== categoria) && categoria !== 'General') form.setValue('descRegistro', null);
    if (
      ![
        undefined,
        null,
        i18n('entities.registro.enumerators.categoria.General'),
        i18n('entities.registro.enumerators.categoria.Activo'),
      ].includes(categoria)
    ) {
      form.setValue('activosVinculados', []);
    }
    if (
      ![
        undefined,
        null,
        i18n('entities.registro.enumerators.categoria.General'),
        i18n('entities.registro.enumerators.categoria.Tarea'),
      ].includes(categoria)
    ) {
      form.setValue('tareasVinculadas', []);
      form.setValue('planificacionesVinculadas', []);
    }
  }, [categoria]);

  const onSubmit = (e) => {
    //console.log('%c⧭ onSubmit event', 'color: #86bf60', e);
    form.handleSubmit(
      (values) => {
        //console.log('%c⧭ onSubmit OK', 'color: #86bf60', {values});
        props.onSubmit(props.record?.id, values);
      },
      (error) => {
        console.log('%c⧭ onSubmit ERROR', 'color: #bfaf60', {error});
        Message.error(error.descripcion?.message || 'Error', 5000);
      },
    )();
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  const {saveLoading, modal} = props;

  const getVal = (fieldName, defaultValue = undefined) => {
    const value = getValues(fieldName);
    return value ?? defaultValue;
  };
  const setVal = (fieldName, value) => {
    setValue(fieldName, value ?? null);
  };

  const isPositiveOrZero = (value: any) => {
    return value !== null && value !== undefined && value !== '' && value >= 0;
  };
  const calcDuracion = (fechaInicio, fechaFin) => {
    if (!fechaInicio || !fechaFin) return null;
    let newDuracion = moment(fechaFin).diff(moment(fechaInicio), 'minutes', true);
    // debe ser un entero y positivo
    newDuracion = Math.round(newDuracion);
    if (newDuracion < 0) newDuracion = 0;
    return newDuracion;
  };
  const calcFechaFin = (fechaInicio, duracion) => {
    if (!fechaInicio || !isPositiveOrZero(duracion)) return null;
    //console.log('%c⧭ calcFechaFin', 'color: #aa00ff', {duracion});
    return moment(fechaInicio).add(duracion, 'minutes');
  };
  const onChangeDuracion = (value) => {
    //console.log('%c⧭ onChangeDuracion', 'color: #00a3cc', value);
    if (value < 0) {
      value = 0;
      setVal('duracion', value);
    }
    const fechaInicio = getVal('fechaInicio');
    if (fechaInicio) {
      const fechaFin = calcFechaFin(fechaInicio, value);
      //console.log('%c⧭ fechaFin', 'color: #00e600', fechaFin?.format('YYYY-MM-DD HH:mm'));
      setVal('fechaFin', fechaFin?.toDate());
    }
  };
  const onChangeFechaInicio = (value) => {
    const duracion = getVal('duracion');
    if (duracion) {
      const fechaFin = calcFechaFin(value, duracion);
      setVal('fechaFin', fechaFin?.toDate());
    }
  };
  const onChangeFechaFin = (value) => {
    const fechaInicio = getVal('fechaInicio');
    if (fechaInicio) {
      const duracion = calcDuracion(fechaInicio, value);
      setVal('duracion', duracion);
    }
  };

  const tenantId = AuthCurrentTenant.get();
  const isPuertoCoronel = tenantId === '63beb09e3a93ab23954c5b04';
  const tabGeneral = isPuertoCoronel ? 'General' : i18n('entities.registro.enumerators.categoria.General');
  const tabActivo = isPuertoCoronel ? 'Calidad' : i18n('entities.registro.enumerators.categoria.Activo');
  const tabTarea = isPuertoCoronel ? 'Medio Ambiente' : i18n('entities.registro.enumerators.categoria.Tarea');
  const tabUsuario = isPuertoCoronel ? 'SSO' : i18n('entities.registro.enumerators.categoria.Usuario');
  let categoriaLabel = {
    General: tabGeneral,
    Activo: tabActivo,
    Tarea: tabTarea,
    Usuario: tabUsuario,
  };
  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form
          style={
            {
              ...magicFlexBox,
              overflow: 'auto',
              marginBottom: 8,
            } as any
          }>
          <Grid spacing={2} container>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              {!categoriaSeleccionada && (
                <SelectFormItem
                  name="categoria"
                  label={i18n('entities.registro.fields.categoria')}
                  options={registroEnumerators.categoria.map((value) => ({
                    value,
                    label: categoriaLabel[value], //i18n(`entities.registro.enumerators.categoria.${value}`),
                  }))}
                  required={false}
                />
              )}
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <DescRegistroAutocompleteFormItem
                defaultValue={initialValues.descRegistro}
                categoriaSeleccionada={categoria}
                name="descRegistro"
                onChange={(value) => {
                  // if (!formValue) form.setValue('descRegistro', value);
                  //if (!value) return;
                  //if (value.descripcion)
                  form.setValue('descripcion', value?.descripcion);
                  if (value?.tipo) form.setValue('tipo', value.tipo);
                  if (value?.prioridad) form.setValue('prioridad', value.prioridad);
                  if (value?.afectaDisponibilidad) form.setValue('afectaDisponibilidad', value.afectaDisponibilidad);
                }}
                label={i18n('entities.registro.fields.descripcion')}
                required={true}
                showCreate={true}
              />
              {/* <InputFormItem name="descripcion" label={i18n('entities.registro.fields.descripcion')} required={true} autoFocus /> */}
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <Grid container>
                <Grid item sm={5} xs={12}>
                  <DatePickerFormItem
                    defaultValue={initialValues.fechaInicio}
                    name="fechaInicio"
                    label={i18n('entities.registro.fields.fechaInicio')}
                    required={false}
                    showTime
                    onChange={(value) => onChangeFechaInicio(value)}
                  />
                </Grid>
                <Grid item sm={2}></Grid>
                <Grid item sm={5} xs={12}>
                  <InputNumberFormItem
                    defaultValue={initialValues.duracion}
                    disableFormat
                    name="duracion"
                    label={i18n('entities.registro.fields.duracion') + ' (minutos)'}
                    required={false}
                    onChange={(value) => onChangeDuracion(value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <DatePickerFormItem
                defaultValue={initialValues.fechaFin}
                name="fechaFin"
                label={i18n('entities.registro.fields.fechaFin')}
                required={false}
                showTime
                onChange={(value) => onChangeFechaFin(value)}
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <SelectFormItem
                name="tipo"
                label={i18n('entities.registro.fields.tipo')}
                options={registroEnumerators.tipo.map((value) => ({
                  value,
                  label: i18n(`entities.registro.enumerators.tipo.${value}`),
                }))}
                required={false}
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <SelectFormItem
                name="prioridad"
                label={i18n('entities.registro.fields.prioridad')}
                options={registroEnumerators.prioridad.map((value) => ({
                  value,
                  label: i18n(`entities.registro.enumerators.prioridad.${value}`),
                }))}
                required={false}
              />
            </Grid>
            {/* <Grid item lg={7} md={8} sm={12} xs={12}>
              <CuentaContableAutocompleteFormItem
                name="cuentasContables"
                label={i18n(
                  'entities.registro.fields.cuentasContables',
                )}
                required={false}
                showCreate={!props.modal}
                mode="multiple"
              />
            </Grid> */}
            {/* <Grid item lg={7} md={8} sm={12} xs={12}>
              <TextAreaFormItem name="nota" label={i18n('entities.registro.fields.nota')} required={false} />
            </Grid> */}
            {/* <Grid item lg={7} md={8} sm={12} xs={12}>
              <GoogleAutocompleteFormItem
                name="ubicacionGoogle"
                label={i18n('entities.ubicacion.fields.ubicacionGoogle')}
                required={false}
                defaultCenter={{
                  lat: -36.8269882,
                  lng: -73.0497665,
                }}
                center={{
                  lat: form.watch('latitud'),
                  lng: form.watch('longitud'),
                }}
                zoom={14}
                enableMap={true}
              />
            </Grid> */}
            {/* <Grid item lg={7} md={8} sm={12} xs={12}>
              <TrazabilidadAutocompleteFormItem  
                name="trazabilidadesVinculadas"
                label={i18n('entities.registro.fields.trazabilidadesVinculadas')}
                required={false}
                showCreate={!props.modal}
                mode="multiple"
              />
            </Grid> */}
            {[
              undefined,
              null,
              i18n('entities.registro.enumerators.categoria.General'),
              i18n('entities.registro.enumerators.categoria.Activo'),
            ].includes(categoria) && (
              <Grid item lg={7} md={8} sm={12} xs={12}>
                <ActivoAutocompleteFormItem
                  filter={{grupo: 'Equipo'}}
                  name="activosVinculados"
                  // onChange={value => {
                  //   console.log('%c⧭ activosVinculados on change', 'color: #9c66cc', {value});
                  // }}
                  label={i18n('entities.registro.fields.activosVinculados')}
                  required={false}
                  showCreate={!props.modal}
                  mode="multiple"
                />
              </Grid>
            )}
            {[
              undefined,
              null,
              i18n('entities.registro.enumerators.categoria.General'),
              i18n('entities.registro.enumerators.categoria.Tarea'),
            ].includes(categoria) && (
              <>
                <Grid item lg={7} md={8} sm={12} xs={12}>
                  <TareaAutocompleteFormItem
                    name="tareasVinculadas"
                    label={i18n('entities.registro.fields.tareasVinculadas')}
                    required={false}
                    showCreate={!props.modal}
                    mode="multiple"
                  />
                </Grid>
                <Grid item lg={7} md={8} sm={12} xs={12}>
                  <PlanificacionAutocompleteFormItem
                    name="planificacionesVinculadas"
                    label={i18n('entities.registro.fields.planificacionesVinculadas')}
                    required={false}
                    showCreate={!props.modal}
                    mode="multiple"
                  />
                </Grid>
              </>
            )}
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <UserAutocompleteFormItem
                name="usuariosVinculados"
                label={i18n('entities.registro.fields.usuariosVinculados')}
                required={false}
                showCreate={!props.modal}
                mode="multiple"
              />
            </Grid>
            {/* <Grid item lg={7} md={8} sm={12} xs={12}>
              <GestorDocumentalAutocompleteFormItem  
                name="documentosVinculados"
                label={i18n('entities.registro.fields.documentosVinculados')}
                required={false}
                showCreate={!props.modal}
                mode="multiple"
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InventarioAutocompleteFormItem  
                name="inventariosVinculados"
                label={i18n('entities.registro.fields.inventariosVinculados')}
                required={false}
                showCreate={!props.modal}
                mode="multiple"
              />
            </Grid> */}

            <Grid item lg={7} md={8} sm={12} xs={12}>
              <UbicacionAutocompleteFormItem
                name="locations"
                disabled={props.disabled}
                label={i18n('common.locations')}
                required={false}
                showCreate={false}
                mode="multiple"
              />
            </Grid>
          </Grid>
        </form>
        <FormButtons
          style={{
            flexDirection: modal ? 'row-reverse' : undefined,
          }}>
          <Button
            variant="contained"
            color="primary"
            disabled={saveLoading}
            onClick={onSubmit}
            type="button"
            startIcon={<SaveIcon />}
            size="small">
            {i18n('common.save')}
          </Button>

          <Button disabled={saveLoading} onClick={onReset} type="button" startIcon={<UndoIcon />} size="small">
            {i18n('common.reset')}
          </Button>

          {props.onCancel ? (
            <Button disabled={saveLoading} onClick={() => props.onCancel()} type="button" startIcon={<CloseIcon />} size="small">
              {i18n('common.cancel')}
            </Button>
          ) : null}
        </FormButtons>
      </FormProvider>
    </FormWrapper>
  );
}

export default RegistroForm;
