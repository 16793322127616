import {i18n} from 'src/i18n';
import DataService from 'src/modules/data/dataService';
import customDataImporterActions from 'src/modules/data/importer/customDataImporterActions';
import fields from 'src/modules/data/importer/dataImporterFields';
import selectors from 'src/modules/data/importer/dataImporterSelectors';

const dataImporterActions = customDataImporterActions(
  'DATA_IMPORTER',
  selectors,
  DataService.import,
  fields,
  i18n('entities.data.importer.fileName'),
  500,
);

export default dataImporterActions;
