import {i18n} from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.planificacion.exporterFields.id'),
  },
  {
    name: 'nivel',
    label: i18n('entities.planificacion.exporterFields.nivel'),
  },
  {
    name: 'title',
    label: i18n('entities.planificacion.exporterFields.title'),
  },
  {
    name: 'duracionProgramada',
    label: i18n('entities.planificacion.exporterFields.duracionProgramada'),
  },
  {
    name: 'duracionReal',
    label: i18n('entities.planificacion.exporterFields.duracionReal'),
  },
  {
    name: 'inicioProgramado',
    label: i18n('entities.planificacion.exporterFields.inicioProgramado'),
    //render: exporterRenders.datetime(),
  },
  {
    name: 'terminoProgramado',
    label: i18n('entities.planificacion.exporterFields.terminoProgramado'),
    //render: exporterRenders.datetime(),
  },
  {
    name: 'inicioReal',
    label: i18n('entities.planificacion.exporterFields.inicioReal'),
    //render: exporterRenders.datetime(),
  },
  {
    name: 'terminoReal',
    label: i18n('entities.planificacion.exporterFields.terminoReal'),
    //render: exporterRenders.datetime(),
  },
  {
    name: 'estado',
    label: i18n('entities.planificacion.exporterFields.estado'),
  },
  {
    name: 'progresoReal',
    label: i18n('entities.planificacion.exporterFields.progresoReal'),
  },
  // {
  //   name: 'gap',
  //   label: i18n('entities.planificacion.exporterFields.gap'),
  // },
  {
    name: 'tipo',
    label: i18n('entities.planificacion.exporterFields.tipo'),
  },
  {
    name: 'responsable',
    label: i18n('entities.planificacion.exporterFields.responsable'),
  },
  {
    name: 'prioridad',
    label: i18n('entities.planificacion.exporterFields.prioridad'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.planificacion.exporterFields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.planificacion.exporterFields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];

// export default [
//   {
//     name: 'id',
//     label: i18n('entities.planificacion.fields.id'),
//   },
//   {
//     name: 'activo',
//     label: i18n('entities.planificacion.fields.activo'),
//     render: exporterRenders.relationToOne(),
//   },
//   {
//     name: 'nombre',
//     label: i18n('entities.planificacion.fields.nombre'),
//   },
//   {
//     name: 'descripcion',
//     label: i18n(
//       'entities.planificacion.fields.descripcion',
//     ),
//   },
//   {
//     name: 'fechaEstimadaFinalizacion',
//     label: i18n(
//       'entities.planificacion.fields.fechaEstimadaFinalizacion',
//     ),
//     render: exporterRenders.datetime(),
//   },
//   {
//     name: 'ubicacionLlegada',
//     label: i18n(
//       'entities.planificacion.fields.ubicacionLlegada',
//     ),
//     render: exporterRenders.relationToOne(),
//   },
//   {
//     name: 'responsables',
//     label: i18n(
//       'entities.planificacion.fields.responsables',
//     ),
//     render: exporterRenders.relationToMany(),
//   },
//   {
//     name: 'ubicacionGoogle',
//     label: i18n(
//       'entities.planificacion.fields.ubicacionGoogle',
//     ),
//   },
//   {
//     name: 'latitud',
//     label: i18n('entities.planificacion.fields.latitud'),
//     render: exporterRenders.decimal(),
//   },
//   {
//     name: 'longitud',
//     label: i18n('entities.planificacion.fields.longitud'),
//     render: exporterRenders.decimal(),
//   },
//   {
//     name: 'tipo',
//     label: i18n('entities.planificacion.fields.tipo'),
//   },
//   {
//     name: 'createdAt',
//     label: i18n('entities.planificacion.fields.createdAt'),
//     render: exporterRenders.datetime(),
//   },
//   {
//     name: 'updatedAt',
//     label: i18n('entities.planificacion.fields.updatedAt'),
//     render: exporterRenders.datetime(),
//   },
// ];
