import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.firma.fields.id'),
  },
  {
    name: 'descripcion',
    label: i18n('entities.firma.fields.descripcion'),
  },
  {
    name: 'estado',
    label: i18n('entities.firma.fields.estado'),
  },
  {
    name: 'nota',
    label: i18n('entities.firma.fields.nota'),
  },
  {
    name: 'objetivo',
    label: i18n('entities.firma.fields.objetivo'),
  },
  {
    name: 'responsable',
    label: i18n('entities.firma.fields.responsable'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'firmada',
    label: i18n('entities.firma.fields.firmada'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'tareasVinculadas',
    label: i18n('entities.firma.fields.tareasVinculadas'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'planificacionesVinculadas',
    label: i18n('entities.firma.fields.planificacionesVinculadas'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'trazabilidadesVinculadas',
    label: i18n('entities.firma.fields.trazabilidadesVinculadas'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'historialInventariosVinculados',
    label: i18n('entities.firma.fields.historialInventariosVinculados'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.firma.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.firma.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
