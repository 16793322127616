import {Box, Button} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import SignatureCanvas from 'react-signature-canvas';
import {DisabledItem} from './shared/formItemWrappers';

function InputSignatureFormItem(props) {
  const signatureRef = useRef<any>();
  const getSignaturePad = () => {
    return signatureRef?.current?.getSignaturePad();
  };

  let {name, width, height, disabled, label, onChange} = props;
  width = window.innerWidth > 768 ? width * 0.7 : 250;
  height = width * 0.4;

  const {
    register,
    control,
    setValue,
    formState: {touchedFields, isSubmitted, errors},
  } = useFormContext();

  const watchValue: any = useWatch({name, control});

  useEffect(() => {
    register(name);
  }, []);

  useEffect(() => {
    signatureChanged(watchValue);
  }, [watchValue]);

  const signatureChanged = (value) => {
    const signaturePad = getSignaturePad();
    if (signaturePad) {
      if (getSignature() !== value) {
        signaturePad.clear();
        signaturePad.fromDataURL(value, {width, height});
      }
    }
  };

  const clearCanvas = () => {
    const signaturePad = getSignaturePad();
    signaturePad.clear();
    setSignature();
  };

  const getSignature = () => {
    const signaturePad = getSignaturePad();
    return signaturePad?.toDataURL('image/png', 1);
  };

  const setSignature = () => {
    const newValue = getSignature();
    setValue(name, newValue);
    onChange && onChange(newValue);
  };

  if (disabled) {
    return (
      <DisabledItem
        label={label}
        item={
          <Box sx={{border: '8px solid gray', borderRadius: '5px', width: 'min-content', mb: 1}}>
            {watchValue && <img alt={'signature'} style={{width, height}} src={watchValue} />}
          </Box>
        }
      />
    );
  }
  //return <CanvasExample />;
  //return <SignaturePadExample />;
  // return (
  //   <div style={{width, height, position: 'relative'}}>
  //     <div className="canvasWrapper500">
  //       <SignatureCanvas
  //         clearOnResize={false}
  //         ref={signatureRef}
  //         throttle={16}
  //         onEnd={() => {
  //           setValue(name, signatureRef?.current?.getSignaturePad().toDataURL('image/png', 1));
  //         }}
  //         penColor="black"
  //         canvasProps={{
  //           padding: 0,
  //           margin: 0,
  //           className: 'inputSigCanvas',
  //         }}
  //       />
  //     </div>
  //   </div>
  // );
  return (
    <Box display="flex" flexDirection="column" sx={{border: '8px solid gray', borderRadius: '5px', width: 'min-content', mb: 1}}>
      <Box>
        <Button onClick={clearCanvas}>Limpiar</Button>
      </Box>
      <Box
        sx={{
          width,
          height,
        }}>
        {!disabled ? (
          <SignatureCanvas
            clearOnResize={false}
            ref={signatureRef}
            minDistance={5}
            minWidth={0.5}
            maxWidth={2.5}
            throttle={16}
            onEnd={() => {
              console.log('on end');
              setSignature();
            }}
            penColor="black"
            //style={{width, height}}
            canvasProps={{
              padding: 0,
              margin: 0,
              width,
              height,
              // className: 'inputSigCanvas',
            }}
          />
        ) : (
          <img alt={'signature'} style={{width, height}} src={watchValue} />
        )}
      </Box>
    </Box>
  );
}

InputSignatureFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  firma: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.any,
};

export default InputSignatureFormItem;
