import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.inventario.fields.id'),
  },
  {
    name: 'descripcion',
    label: i18n('entities.inventario.fields.descripcion'),
  },
  {
    name: 'activo',
    label: i18n('entities.inventario.fields.activo'),
    render: exporterRenders.relationToOne(),
  },
  // {
  //   name: 'ubicacion',
  //   label: i18n('entities.inventario.fields.ubicacion'),
  //   render: exporterRenders.relationToOne(),
  // },
  // {
  //   name: 'stockDisponible',
  //   label: i18n(
  //     'entities.inventario.fields.stockDisponible',
  //   ),
  // },
  {
    name: 'stockCritico',
    label: i18n('entities.inventario.fields.stockCritico'),
  },
  {
    name: 'stockMaximo',
    label: i18n('entities.inventario.fields.stockMaximo'),
  },
];
