import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.planificadorFormulario.fields.id'),
  },
  {
    name: 'titulo',
    label: i18n('entities.planificadorFormulario.fields.titulo'),
  },
  {
    name: 'tipoFormulario',
    label: i18n('entities.planificadorFormulario.fields.tipoFormulario'),
  },
  {
    name: 'dirigidoA',
    label: i18n('entities.planificadorFormulario.fields.dirigidoA'),
  },
  {
    name: 'evaluadores',
    label: i18n('entities.planificadorFormulario.fields.evaluadores'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'sucursales',
    label: i18n('entities.planificadorFormulario.fields.sucursales'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'puestos',
    label: i18n('entities.planificadorFormulario.fields.puestos'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'evaluados',
    label: i18n('entities.planificadorFormulario.fields.evaluados'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'modeloFormulario',
    label: i18n('entities.planificadorFormulario.fields.modeloFormulario'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'programado',
    label: i18n('entities.planificadorFormulario.fields.programado'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'programar',
    label: i18n('entities.planificadorFormulario.fields.programar'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'inicioProgramacion',
    label: i18n('entities.planificadorFormulario.fields.inicioProgramacion'),
  },
  {
    name: 'finProgramacion',
    label: i18n('entities.planificadorFormulario.fields.finProgramacion'),
  },
  {
    name: 'tipoPeriodicidad',
    label: i18n('entities.planificadorFormulario.fields.tipoPeriodicidad'),
  },
  {
    name: 'periodicidad',
    label: i18n('entities.planificadorFormulario.fields.periodicidad'),
  },
  {
    name: 'encuestados',
    label: i18n('entities.planificadorFormulario.fields.encuestados'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'activarTiempoLimite',
    label: i18n('entities.planificadorFormulario.fields.activarTiempoLimite'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'tipoTiempoLimite',
    label: i18n('entities.planificadorFormulario.fields.tipoTiempoLimite'),
  },
  {
    name: 'tiempoLimite',
    label: i18n('entities.planificadorFormulario.fields.tiempoLimite'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.planificadorFormulario.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.planificadorFormulario.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
