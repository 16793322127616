import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/tipoActivo/importer/tipoActivoImporterSelectors';
import TipoActivoService from 'src/modules/tipoActivo/tipoActivoService';
import fields from 'src/modules/tipoActivo/importer/tipoActivoImporterFields';
import { i18n } from 'src/i18n';

const tipoActivoImporterActions = importerActions(
  'TIPOACTIVO_IMPORTER',
  selectors,
  TipoActivoService.import,
  fields,
  i18n('entities.tipoActivo.importer.fileName'),
);

export default tipoActivoImporterActions;