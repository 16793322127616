import {uniqBy} from 'lodash';
import {i18n} from 'src/i18n';
import exporterFields from 'src/modules/pendiente/list/pendienteListExporterFields';
import selectors from 'src/modules/pendiente/list/pendienteListSelectors';
import PendienteService from 'src/modules/pendiente/pendienteService';
import Errors from 'src/modules/shared/error/errors';
import Exporter from 'src/modules/shared/exporter/exporter';
import TareaService from 'src/modules/tarea/tareaService';

const prefix = 'PENDIENTE_LIST';

const pendienteListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,
  TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
  TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
  CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  PATCH_VALUES: `${prefix}_PATCH_VALUES`,
  SET_VALUES: `${prefix}_SET_VALUES`,

  patchValues(values) {
    return {
      type: pendienteListActions.PATCH_VALUES,
      payload: values,
    };
  },

  setValues(values) {
    return {
      type: pendienteListActions.SET_VALUES,
      payload: values,
    };
  },

  doClearAllSelected() {
    return {
      type: pendienteListActions.CLEAR_ALL_SELECTED,
    };
  },

  doToggleAllSelected() {
    return {
      type: pendienteListActions.TOGGLE_ALL_SELECTED,
    };
  },

  doToggleOneSelected(id) {
    return {
      type: pendienteListActions.TOGGLE_ONE_SELECTED,
      payload: id,
    };
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: pendienteListActions.RESETED,
    });

    dispatch(pendienteListActions.doFetch());
  },

  doExport: () => async (dispatch, getState) => {
    try {
      if (!exporterFields || !exporterFields.length) {
        throw new Error('exporterFields is required');
      }

      dispatch({
        type: pendienteListActions.EXPORT_STARTED,
      });

      const filter = selectors.selectFilter(getState());
      const response = await PendienteService.list(filter, selectors.selectOrderBy(getState()), null, null);

      new Exporter(exporterFields, i18n('entities.pendiente.exporterFileName')).transformAndExportAsExcelFile(response.rows);

      dispatch({
        type: pendienteListActions.EXPORT_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: pendienteListActions.EXPORT_ERROR,
      });
    }
  },

  doChangePagination: (pagination) => async (dispatch, getState) => {
    dispatch({
      type: pendienteListActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(pendienteListActions.doFetchCurrentFilter());
  },

  doChangeSort: (sorter) => async (dispatch, getState) => {
    dispatch({
      type: pendienteListActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(pendienteListActions.doFetchCurrentFilter());
  },

  fetchUpdatedItems: () => async (dispatch, getState) => {
    const loading = selectors.selectLoading(getState());
    if (loading) {
      return;
    }
    const lastDownloadTime = selectors.selectLastDownloadTime(getState());
    const downloadedIds = selectors.selectDownloadedIds(getState());
    const groupByFields = selectors.selectGroupByFields(getState());
    const filter = selectors.selectFilter(getState());

    // console.log('%c⧭ fetchUpdatedItems', 'color: #86bf60', {
    //   lastDownloadTime,
    //   downloadedIds,
    // });

    try {
      dispatch({
        type: pendienteListActions.FETCH_STARTED,
        payload: {
          lastDownloadTime: Date.now(),
        },
      });

      const response = await TareaService.fetchUpdatedItems({
        filter,
        groupByFields,
        lastDownloadTime,
        downloadedIds,
      });

      // console.log('%c⧭ response', 'color: #99adcc', response);

      if (!response) {
        dispatch({
          type: pendienteListActions.FETCH_SUCCESS,
          payload: {
            rows: [],
            deletedItemIds: [],
          },
        });
        return;
      }
      const {updatedItems, deletedItemIds, updatedGroups} = response;
      let payload: any = {
        rows: updatedItems,
        deletedItemIds,
      };
      // console.log('%c⧭ payload', 'color: #f279ca', payload);
      if (updatedGroups?.length > 0) {
        payload = {
          ...payload,
          groups: updatedGroups,
        };
      }
      dispatch({
        type: pendienteListActions.FETCH_SUCCESS,
        payload,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: pendienteListActions.FETCH_ERROR,
      });
    }
  },
  loadMoreRows:
    ({startIndex, stopIndex, filterObj, orderBy}) =>
    async (dispatch, getState) => {
      const loading = selectors.selectLoading(getState());
      if (loading) {
        return;
      }
      const limit = stopIndex - startIndex;
      // console.log('%c⧭ loadMoreRows limit', 'color: #eeff00', {
      //   limit,
      //   filterObj,
      //   stopIndex,
      //   startIndex,
      // });
      if (limit <= 0) return;
      const offset = startIndex;
      const filter = selectors.selectFilter(getState());
      const rawFilter = selectors.selectRawFilter(getState());
      let lastUpdateDateInRows = selectors.selectLastUpdateDateInRows(getState());
      filterObj = Object.keys(filterObj).reduce((acc, key) => {
        if (filterObj[key] == null || filterObj[key] === '' || filterObj[key] === undefined) {
          acc[key] = 'null';
        } else {
          acc[key] = filterObj[key];
        }
        return acc;
      }, {});
      // console.log('%c⧭ filterObj', 'color: #cc0088', filterObj);
      try {
        dispatch({
          type: pendienteListActions.FETCH_STARTED,
          payload: {
            startIndex,
            stopIndex,
            filterObj,
            filter: {
              ...filter,
              updatedAt: lastUpdateDateInRows,
            },
          },
          rawFilter,
        });
        const response = await TareaService.list(
          {
            ...filter,
            ...filterObj,
            pendientes: 1,
          },
          orderBy,
          limit,
          offset,
        );

        if (response.rows?.length > 0) {
          for (let row of response.rows) {
            // console.log('%c⧭ row.updatedAt > lastUpdateDateInRows', 'color: #7f2200', {
            //   updatedAt: row.updatedAt,
            //   lastUpdateDateInRows,
            //   bool: !lastUpdateDateInRows || row.updatedAt < lastUpdateDateInRows,
            // });
            if (!lastUpdateDateInRows || row.updatedAt < lastUpdateDateInRows) {
              // console.log(
              //   '%c⧭',
              //   'color: #d0bfff',
              //   'row has lower updatedAt than lastUpdateDateInRows',
              //   row.updatedAt,
              //   '<',
              //   lastUpdateDateInRows,
              // );
              lastUpdateDateInRows = row.updatedAt;
            }
          }
        }
        dispatch({
          type: pendienteListActions.FETCH_SUCCESS,
          payload: {
            startIndex,
            stopIndex,
            rows: response.rows,
            count: response.count,
          },
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: pendienteListActions.FETCH_ERROR,
        });
      }
    },

  doFetchCurrentFilter: () => async (dispatch, getState) => {
    const filter = selectors.selectFilter(getState());
    const rawFilter = selectors.selectRawFilter(getState());
    dispatch(pendienteListActions.doFetch(filter, rawFilter, true));
  },

  doFetch:
    (filter?, rawFilter?, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: pendienteListActions.FETCH_STARTED,
          payload: {filter, rawFilter, keepPagination},
        });

        const response = await PendienteService.list(
          filter,
          selectors.selectOrderBy(getState()),
          selectors.selectLimit(getState()),
          selectors.selectOffset(getState()),
        );

        dispatch({
          type: pendienteListActions.FETCH_SUCCESS,
          payload: {
            rows: response.rows,
            count: response.count,
          },
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: pendienteListActions.FETCH_ERROR,
        });
      }
    },
  doFetchGroups: (filter?, rawFilter?) => async (dispatch, getState) => {
    try {
      dispatch({
        type: pendienteListActions.FETCH_STARTED,
        payload: {filter, rawFilter, groups: []},
      });

      const groupByFields = selectors.selectGroupByFields(getState());
      const groupByLimit = 0; // initial limit
      //console.log('%c⧭ doFetchGroups', 'color: #1f741f', {filter, groupByFields, groupByLimit});

      const response = await TareaService.listGroup({
        filter,
        groupByFields,
        groupByLimit,
      });

      //console.log('%c⧭ api response', 'color: #1f741f', response);

      let tasks: any = [];
      for (let group of response) {
        tasks = uniqBy([...tasks, ...group.tareas], '_id');
      }
      // console.log('%c⧭ tasks', 'color: #00736b', tasks);
      dispatch({
        type: pendienteListActions.FETCH_SUCCESS,
        payload: {
          groups: response,
          rows: tasks,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: pendienteListActions.FETCH_ERROR,
      });
    }
  },
};

export default pendienteListActions;
