import React from 'react';
import Spinner from 'src/view/shared/Spinner';
import PerfilEmpleadoFormPage from '../form/PerfilEmpleadoFormPage';

function PerfilEmpleadoView(props) {
  const renderView = () => {
    const { record, drawer } = props;
    return <PerfilEmpleadoFormPage record={record} disabled={true} drawer={drawer} contractorsView={props.contractorsView} />;
  };

  const { record, loading } = props;

  if (loading || !record) {
    return <Spinner />;
  }

  return renderView();
}

export default PerfilEmpleadoView;
