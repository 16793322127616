import listActions from 'src/modules/gestorDocumental/list/gestorDocumentalListActions';
import GestorDocumentalService from 'src/modules/gestorDocumental/gestorDocumentalService';
import Errors from 'src/modules/shared/error/errors';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'GESTORDOCUMENTAL_DESTROY';

const gestorDocumentalDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy: (id) => async (dispatch) => {
    try {
      dispatch({
        type: gestorDocumentalDestroyActions.DESTROY_STARTED,
      });

      await GestorDocumentalService.destroyAll([id]);

      dispatch({
        type: gestorDocumentalDestroyActions.DESTROY_SUCCESS,
      });

      Message.success(
        i18n('entities.gestorDocumental.destroy.success'),
      );

      dispatch(listActions.doFetchCurrentFilter());

      // getHistory().push('/gestor-documental');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: gestorDocumentalDestroyActions.DESTROY_ERROR,
      });
    }
  },

  doDestroyAll: (ids) => async (dispatch) => {
    try {
      dispatch({
        type: gestorDocumentalDestroyActions.DESTROY_ALL_STARTED,
      });

      await GestorDocumentalService.destroyAll(ids);

      dispatch({
        type: gestorDocumentalDestroyActions.DESTROY_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doClearAllSelected());
        dispatch(listActions.doFetchCurrentFilter());
      }

      Message.success(
        i18n(
          'entities.gestorDocumental.destroyAll.success',
        ),
      );

      // getHistory().push('/gestor-documental');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: gestorDocumentalDestroyActions.DESTROY_ALL_ERROR,
      });
    }
  },
};

export default gestorDocumentalDestroyActions;
