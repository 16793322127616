const incidentEnumerators = {
  //incidentType: ['Injury', 'Illness', 'NearMiss', 'PropertyDamage', 'Environmental', 'Vehicle', 'Other'],
  initialClassification: ['WorkAccident', 'CommutingAccident', 'OccupationalDisease',],// 'NotCoveredByLaw', 'NotApplicable'],
  finalClassification: ['WorkAccident', 'CommutingAccident', 'OccupationalDisease',],// 'NotCoveredByLaw', 'NotApplicable'],
  severity: ['Mild', 'Severe', 'Fatal'],
  incidentStatus: ['Open', 'Closed'],
  withLostDaysSelector: ['yes', 'no'],
  contractType: ['Unspecified', 'Indefinite', 'FixedTerm', 'Other'],
  entryType: ['ProcessContinuity', 'NewEntry', 'ReEntry'],
  origin: [
    'Undetermined',
    'Attrition',
    'SameLevelFall',
    'FallFromDifferentLevel',
    'ContactWithElectricalEnergy',
    'ContactWithCuttingMaterial',
    'ContactWithChemicalSubstance',
    'StruckBy',
    'StruckAgainst',
    'InjuryCausedByAnimals',
    'RepetitiveMotion',
    'Overexertion',
  ],
};

export default incidentEnumerators;
