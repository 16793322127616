import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.modeloTarea.fields.id'),
  },
  {
    name: 'titulo',
    label: i18n('entities.modeloTarea.fields.titulo'),
  },
  {
    name: 'automatizada',
    label: i18n('entities.modeloTarea.fields.automatizada'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'tipoVisualizacion',
    label: i18n('entities.modeloTarea.fields.tipoVisualizacion'),
  },
  {
    name: 'subTareasRelacionadas',
    label: i18n('entities.modeloTarea.fields.subTareasRelacionadas'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'productosRelacionados',
    label: i18n('entities.modeloTarea.fields.productosRelacionados'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'objetivo',
    label: i18n('entities.modeloTarea.fields.objetivo'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'sucursales',
    label: i18n('entities.modeloTarea.fields.sucursales'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'categoria',
    label: i18n('entities.modeloTarea.fields.categoria'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'documentosAdjuntos',
    label: i18n('entities.modeloTarea.fields.documentosAdjuntos'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'fotosAdjuntas',
    label: i18n('entities.modeloTarea.fields.fotosAdjuntas'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'esEvaluada',
    label: i18n('entities.modeloTarea.fields.esEvaluada'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'responsables',
    label: i18n('entities.modeloTarea.fields.responsables'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'asignarFunciones',
    label: i18n('entities.modeloTarea.fields.asignarFunciones'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'asignadorFunciones',
    label: i18n('entities.modeloTarea.fields.asignadorFunciones'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'incluirAsignacionEconomica',
    label: i18n('entities.modeloTarea.fields.incluirAsignacionEconomica'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'asignacionEconomica',
    label: i18n('entities.modeloTarea.fields.asignacionEconomica'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.modeloTarea.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.modeloTarea.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
