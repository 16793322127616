import list from 'src/modules/inventario/list/inventarioListReducers';
import form from 'src/modules/inventario/form/inventarioFormReducers';
import view from 'src/modules/inventario/view/inventarioViewReducers';
import destroy from 'src/modules/inventario/destroy/inventarioDestroyReducers';
import importerReducer from 'src/modules/inventario/importer/inventarioImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
