import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.respuesta.fields.id'),
  },
  {
    name: 'nombre',
    label: i18n('entities.respuesta.fields.nombre'),
  },
  {
    name: 'tienePuntaje',
    label: i18n('entities.respuesta.fields.tienePuntaje'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'puntaje',
    label: i18n('entities.respuesta.fields.puntaje'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.respuesta.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.respuesta.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
