import {i18n} from 'src/i18n';
import fields from 'src/modules/planificadorTareaCustom/importer/planificadorTareaCustomImporterFields';
import selectors from 'src/modules/planificadorTareaCustom/importer/planificadorTareaCustomImporterSelectors';
import PlanificadorTareaCustomService from 'src/modules/planificadorTareaCustom/planificadorTareaCustomService';
import importerActions from 'src/modules/shared/importer/importerActions';

const planificadorTareaCustomImporterActions = importerActions(
  'PLANIFICADORTAREACUSTOM_IMPORTER',
  selectors,
  PlanificadorTareaCustomService.customImport,
  fields,
  i18n('entities.planificadorTareaCustom.importer.fileName'),
);

export default planificadorTareaCustomImporterActions;
