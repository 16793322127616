import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/modeloFormulario/importer/modeloFormularioImporterSelectors';
import ModeloFormularioService from 'src/modules/modeloFormulario/modeloFormularioService';
import fields from 'src/modules/modeloFormulario/importer/modeloFormularioImporterFields';
import { i18n } from 'src/i18n';

const modeloFormularioImporterActions = importerActions(
  'MODELOFORMULARIO_IMPORTER',
  selectors,
  ModeloFormularioService.import,
  fields,
  i18n('entities.modeloFormulario.importer.fileName'),
);

export default modeloFormularioImporterActions;