import PropTypes from 'prop-types';
import React from 'react';

import {useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import selectors from 'src/modules/registro/registroSelectors';
import DrawerButton from 'src/view/shared/view/DrawerButton';
import RegistroView from '../view/RegistroView';

function RegistroListItem(props) {
  const hasPermissionToRead = useSelector(selectors.selectPermissionToRead);

  const valueAsArray = () => {
    const {value} = props;

    if (!value) {
      return [];
    }

    if (Array.isArray(value)) {
      return value;
    }

    return [value];
  };

  // const displayableRecord = (record) => {
  //   if (hasPermissionToRead) {
  //     return (
  //       <div key={record.id}>
  //         <MaterialLink
  //           component={Link}
  //           to={`/registro/${record.id}`}
  //         >
  //           {record.descripcion}
  //         </MaterialLink>
  //       </div>
  //     );
  //   }

  //   return <div key={record.id}>{record.descripcion}</div>;
  // };

  const displayableRecord = (record) => {
    if (!record) return;
    if (hasPermissionToRead) {
      return (
        <div key={record.id}>
          <DrawerButton
            {...props}
            drawerId={'DrawerRegistroViewPage'}
            type={props.type || 'chip'}
            entity={'registro'}
            tooltipTitle={i18n(record.descripcion)}
            buttonTitle={i18n(record.descripcion)}
            width={window.innerWidth * 0.65}
            record={record}
            component={<RegistroView record={record} drawer={true} />}
          />
        </div>
      );
    }
    return <div key={record.id}>{record.descripcion}</div>;
  };

  if (!valueAsArray().length) {
    return null;
  }

  return <>{valueAsArray().map((value) => displayableRecord(value))}</>;
}

RegistroListItem.propTypes = {
  value: PropTypes.any,
};

export default RegistroListItem;
