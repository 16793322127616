import React, {useEffect, useState} from 'react';
import DescRegistroService from 'src/modules/descRegistro/descRegistroService';
import DescRegistroFormModal from 'src/view/descRegistro/form/DescRegistroFormModal';
import AutocompleteInMemoryFormItem from 'src/view/shared/form/items/AutocompleteInMemoryFormItem';
import {useFormContext} from 'react-hook-form';
import {useSelector} from 'react-redux';
import selectors from 'src/modules/registro/registroSelectors';

function DescRegistroAutocompleteFormItem(props) {
  const {categoriaSeleccionada, tipo} = props;

  const {setValue, getValues} = useFormContext();

  const [modalVisible, setModalVisible] = useState(false);

  const hasPermissionToCreate = useSelector(selectors.selectPermissionToCreate);

  const doCloseModal = () => {
    setModalVisible(false);
  };

  const doOpenModal = () => {
    setModalVisible(true);
  };

  const doCreateSuccess = record => {
    const {name, mode} = props;

    if (mode && mode === 'multiple') {
      setValue(name, [...(getValues()[name] || []), record], {shouldValidate: true, shouldDirty: true});
    } else {
      setValue(name, record, {
        shouldValidate: true,
        shouldDirty: true,
      });
      if (props.onChange) props.onChange(record);
    }

    doCloseModal();
  };

  const fetchFn = (value, limit) => {
    return DescRegistroService.listAutocomplete(value, {categoria: categoriaSeleccionada, tipo}, limit);
  };

  //console.log('%c⧭ Descripción Autocomplete: categoriaSeleccionada', 'color: #cc7033', categoriaSeleccionada);
  // useEffect(() => {
  //   console.log('%c⧭ categoriaSeleccionada', 'color: #cc7033', categoriaSeleccionada);
  //   fetchFn(null, 50);
  // }, [categoriaSeleccionada]); // no sirve, hay que recargarlo a dentro del autocompleteInMemory

  const mapper = {
    toAutocomplete(originalValue) {
      if (!originalValue) {
        return null;
      }

      const value = originalValue.id;
      let label = originalValue.label;

      if (originalValue.descripcion) {
        label = originalValue.descripcion;
      }

      return {
        ...originalValue,
        key: value,
        value,
        label,
      };
    },

    toValue(originalValue) {
      if (!originalValue) {
        return null;
      }

      return {
        ...originalValue,
        id: originalValue.value,
        label: originalValue.label,
      };
    },
  };

  return (
    <>
      <AutocompleteInMemoryFormItem
        noLink={props.noLink || true}
        key={categoriaSeleccionada}
        {...props}
        entity="descRegistro"
        fetchFn={fetchFn}
        mapper={mapper}
        onOpenModal={doOpenModal}
        hasPermissionToCreate={hasPermissionToCreate}
        menuPortalTarget={props.menuPortalTarget}
        minWidth={props.minWidth}
      />

      {modalVisible && <DescRegistroFormModal categoriaSeleccionada={categoriaSeleccionada} onClose={doCloseModal} onSuccess={doCreateSuccess} />}
    </>
  );
}

export default DescRegistroAutocompleteFormItem;
