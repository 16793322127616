import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import modeloTareaEnumerators from 'src/modules/modeloTarea/modeloTareaEnumerators';

export default [
  {
    name: 'titulo',
    label: i18n('entities.modeloTarea.fields.titulo'),
    schema: schemas.string(
      i18n('entities.modeloTarea.fields.titulo'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'automatizada',
    label: i18n('entities.modeloTarea.fields.automatizada'),
    schema: schemas.boolean(
      i18n('entities.modeloTarea.fields.automatizada'),
      {},
    ),
  },
  {
    name: 'tipoVisualizacion',
    label: i18n('entities.modeloTarea.fields.tipoVisualizacion'),
    schema: schemas.enumerator(
      i18n('entities.modeloTarea.fields.tipoVisualizacion'),
      {
        "required": true,
        "options": modeloTareaEnumerators.tipoVisualizacion
      },
    ),
  },
  {
    name: 'subTareasRelacionadas',
    label: i18n('entities.modeloTarea.fields.subTareasRelacionadas'),
    schema: schemas.relationToMany(
      i18n('entities.modeloTarea.fields.subTareasRelacionadas'),
      {},
    ),
  },
  {
    name: 'productosRelacionados',
    label: i18n('entities.modeloTarea.fields.productosRelacionados'),
    schema: schemas.relationToMany(
      i18n('entities.modeloTarea.fields.productosRelacionados'),
      {},
    ),
  },
  {
    name: 'objetivo',
    label: i18n('entities.modeloTarea.fields.objetivo'),
    schema: schemas.relationToOne(
      i18n('entities.modeloTarea.fields.objetivo'),
      {},
    ),
  },
  {
    name: 'sucursales',
    label: i18n('entities.modeloTarea.fields.sucursales'),
    schema: schemas.relationToMany(
      i18n('entities.modeloTarea.fields.sucursales'),
      {},
    ),
  },
  {
    name: 'categoria',
    label: i18n('entities.modeloTarea.fields.categoria'),
    schema: schemas.relationToOne(
      i18n('entities.modeloTarea.fields.categoria'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'documentosAdjuntos',
    label: i18n('entities.modeloTarea.fields.documentosAdjuntos'),
    schema: schemas.files(
      i18n('entities.modeloTarea.fields.documentosAdjuntos'),
      {},
    ),
  },
  {
    name: 'fotosAdjuntas',
    label: i18n('entities.modeloTarea.fields.fotosAdjuntas'),
    schema: schemas.images(
      i18n('entities.modeloTarea.fields.fotosAdjuntas'),
      {},
    ),
  },
  {
    name: 'esEvaluada',
    label: i18n('entities.modeloTarea.fields.esEvaluada'),
    schema: schemas.boolean(
      i18n('entities.modeloTarea.fields.esEvaluada'),
      {},
    ),
  },
  {
    name: 'responsables',
    label: i18n('entities.modeloTarea.fields.responsables'),
    schema: schemas.relationToMany(
      i18n('entities.modeloTarea.fields.responsables'),
      {},
    ),
  },
  {
    name: 'asignarFunciones',
    label: i18n('entities.modeloTarea.fields.asignarFunciones'),
    schema: schemas.boolean(
      i18n('entities.modeloTarea.fields.asignarFunciones'),
      {},
    ),
  },
  {
    name: 'asignadorFunciones',
    label: i18n('entities.modeloTarea.fields.asignadorFunciones'),
    schema: schemas.relationToMany(
      i18n('entities.modeloTarea.fields.asignadorFunciones'),
      {},
    ),
  },
  {
    name: 'incluirAsignacionEconomica',
    label: i18n('entities.modeloTarea.fields.incluirAsignacionEconomica'),
    schema: schemas.boolean(
      i18n('entities.modeloTarea.fields.incluirAsignacionEconomica'),
      {},
    ),
  },
  {
    name: 'asignacionEconomica',
    label: i18n('entities.modeloTarea.fields.asignacionEconomica'),
    schema: schemas.string(
      i18n('entities.modeloTarea.fields.asignacionEconomica'),
      {},
    ),
  },
];