import list from 'src/modules/presupuesto/list/presupuestoListReducers';
import form from 'src/modules/presupuesto/form/presupuestoFormReducers';
import view from 'src/modules/presupuesto/view/presupuestoViewReducers';
import destroy from 'src/modules/presupuesto/destroy/presupuestoDestroyReducers';
import importerReducer from 'src/modules/presupuesto/importer/presupuestoImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
