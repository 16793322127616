import { createSelector } from 'reselect';

const selectRaw = (state) => state.gestorDocumental.destroy;

const selectLoading = createSelector([selectRaw], (raw) =>
  Boolean(raw.loading),
);

const gestorDocumentalDestroySelectors = {
  selectLoading,
};

export default gestorDocumentalDestroySelectors;
