import {combineReducers} from 'redux';
import destroy from 'src/modules/planificadorTareaCustom/destroy/planificadorTareaCustomDestroyReducers';
import form from 'src/modules/planificadorTareaCustom/form/planificadorTareaCustomFormReducers';
import importerReducer from 'src/modules/planificadorTareaCustom/importer/planificadorTareaCustomImporterReducers';
import list from 'src/modules/planificadorTareaCustom/list/planificadorTareaCustomListReducers';
import view from 'src/modules/planificadorTareaCustom/view/planificadorTareaCustomViewReducers';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
