import {loadMessages, locale} from 'devextreme/localization';
import esMessages from 'devextreme/localization/messages/es.json';
import React from 'react';
import ReactDOM from 'react-dom';
import {i18n, init as i18nInit} from 'src/i18n';
import {AuthToken} from './modules/auth/authToken';
import TenantService from './modules/tenant/tenantService';

(async function () {
  loadMessages(esMessages);
  locale(navigator.language);

  AuthToken.applyFromLocationUrlIfExists();
  await TenantService.fetchAndApply();
  await i18nInit();

  const App = require('./App').default;
  document.title = i18n('app.title');

  // Sentry.init({
  //   dsn: 'https://f91cfd0435b6415f8a801f6cefc74310@o1335223.ingest.sentry.io/6602843',
  //   integrations: [new BrowserTracing()],

  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  //   tracesSampleRate: 1.0,
  // });

  ReactDOM.render(<App />, document.getElementById('root'));
})();
