import list from 'src/modules/respuesta/list/respuestaListReducers';
import form from 'src/modules/respuesta/form/respuestaFormReducers';
import view from 'src/modules/respuesta/view/respuestaViewReducers';
import destroy from 'src/modules/respuesta/destroy/respuestaDestroyReducers';
import importerReducer from 'src/modules/respuesta/importer/respuestaImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
