import {i18n} from 'src/i18n';
import AbsenceService from 'src/modules/absence/absenceService';
import fields from 'src/modules/absence/importer/absenceImporterFields';
import selectors from 'src/modules/absence/importer/absenceImporterSelectors';
import importerActions from 'src/modules/shared/importer/importerActions';

const absenceImporterActions = importerActions(
  'ABSENCE_IMPORTER',
  selectors,
  AbsenceService.importBatch,
  fields,
  i18n('entities.absence.importer.fileName'),
  20,
  {
    batchUpload: true,
  },
);

export default absenceImporterActions;
