import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';
import {i18n} from 'src/i18n';
import authSelectors from 'src/modules/auth/authSelectors';
import actions from 'src/modules/firma/form/firmaFormActions';
import selectors from 'src/modules/firma/form/firmaFormSelectors';
import {getHistory} from 'src/modules/store';
import FirmaForm from 'src/view/firma/form/FirmaForm';
import ContentWrapper from 'src/view/layout/styles/ContentWrapper';
import Breadcrumb from 'src/view/shared/Breadcrumb';
import Spinner from 'src/view/shared/Spinner';
import PageTitle from 'src/view/shared/styles/PageTitle';
import ClasificacionTagView from 'src/view/shared/view/ClasificacionTagView';

function FirmaFormPage(props) {
  const [dispatched, setDispatched] = useState(false);
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const initLoading = useSelector(selectors.selectInitLoading);
  const saveLoading = useSelector(selectors.selectSaveLoading);
  const record = useSelector(selectors.selectRecord);

  const isEditing = Boolean(props.record?.id || match.params.id);

  const currentUser = useSelector(authSelectors.selectCurrentUser);

  const title = isEditing ? i18n('entities.firma.edit.title') : i18n('entities.firma.new.title');

  useEffect(() => {
    dispatch(actions.doInit(props.record?.id || match.params.id));
    setDispatched(true);
  }, [dispatch, match.params.id]);

  const doSubmit = (id, data) => {
    if (isEditing) {
      dispatch(actions.doUpdate(id, data));
    } else {
      dispatch(actions.doCreate(data));
    }
  };

  return (
    <>
      {!props.drawer && !props.disabled && (
        <Breadcrumb items={[[i18n('dashboard.menu'), '/'], [i18n('entities.firma.menu'), '/firma'], [title]]} />
      )}

      <ContentWrapper>
        {!props.disabled && <PageTitle>{title}</PageTitle>}

        <div style={{marginBottom: 20}}>
          <ClasificacionTagView value={record?.estado} entity="firma" fieldName="estado" />
        </div>

        {initLoading && <Spinner />}

        {dispatched && !initLoading && (
          <FirmaForm
            disabled={
              currentUser.id === record.responsable?.id
                ? record.estado === 'Pendiente' && record.firmada === true
                  ? false
                  : props.disabled
                : true
            }
            drawer={props.drawer}
            saveLoading={saveLoading}
            initLoading={initLoading}
            record={record}
            isEditing={isEditing}
            onSubmit={doSubmit}
            onCancel={() => getHistory().push('/firma')}
          />
        )}
      </ContentWrapper>
    </>
  );
}

export default FirmaFormPage;
