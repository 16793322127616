import { Box, Grid, Switch, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isArray } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import Select from 'react-select';
import { i18n } from 'src/i18n';
import FormErrors from 'src/view/shared/form/formErrors';
import {
  components as materialUiComponents,
  styles as materialUiStyles,
} from 'src/view/shared/form/items/shared/reactSelectMaterialUi';
import TextAreaFormItem from './TextAreaFormItem';
import { DisabledItem, LabelTypography } from './shared/formItemWrappers';

const useStyles = makeStyles(materialUiStyles as any);

function SelectFormItem(props) {
  const {
    label,
    name,
    hint,
    options,
    required,
    mode,
    placeholder,
    isClearable,
    externalErrorMessage,
    defaultValue,
    disabled,
    nameOthers,
    allowOthersOption,
    selectAllButton,
    shrink = true,
  } = props;

  const {
    register,
    formState: { touchedFields, isSubmitted, errors },
    setValue,
    control,
  } = useFormContext();

  const errorMessage = FormErrors.errorMessage(name, errors, touchedFields, isSubmitted, externalErrorMessage);

  const originalValue: any = useWatch({ name, control, defaultValue });
  const othersValue = useWatch({ name: nameOthers, control });
  // console.log('%c⧭', 'color: #aa00ff', {name, originalValue});

  useEffect(() => {
    register(name);
    setValue(name, originalValue);
  }, []);

  const value = () => {
    const { mode } = props;
    if (mode === 'multiple') {
      return valueMultiple();
    } else {
      return valueOne();
    }
  };

  const valueMultiple = () => {
    if (originalValue && isArray(originalValue)) {
      return originalValue?.map((value) => options.find((option) => option.value === value));
    }

    return [];
  };

  const valueOne = () => {
    const { options } = props;

    if (originalValue) {
      return options.find((option) => option.value === originalValue);
    }

    return null;
  };

  const handleSelect = (data) => {
    const { mode } = props;
    if (mode === 'multiple') {
      return handleSelectMultiple(data);
    } else {
      return handleSelectOne(data);
    }
  };

  const handleSelectMultiple = (values) => {
    if (!values?.length) {
      setValue(name, [], { shouldValidate: true });
      props.onChange && props.onChange([]);
      return;
    }

    const newValue = values
      .map((data) => (data ? data.value : data))
      .filter((value) => value != null)
      .sort((a, b) => {
        if (!props.sort) {
          return 0;
        }
        return props.sort(a, b);
      });
    setValue(name, newValue, { shouldValidate: true });
    console.log('%c⧭ handleSelectMultiple', 'color: #5200cc', newValue);
    props.onChange && props.onChange(newValue);
  };

  const handleSelectOne = (data) => {
    if (!data) {
      setValue(name, null, { shouldValidate: true });
      props.onChange && props.onChange(null);
      return;
    }

    setValue(name, data.value, { shouldValidate: true });
    props.onChange && props.onChange(data.value);
  };

  const classes = useStyles();

  const marginOptions = {
    normal: { marginTop: '16px', marginBottom: '8px' },
    none: { marginTop: '0px', marginBottom: '0px' },
  };
  const controlStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%',
      ...marginOptions[props.margin || 'none'],
      ...props.containerStyle,
    }),
    control: (provided) => ({
      ...provided,
      borderColor: errorMessage ? 'red' : undefined,
      ...props.controlStyle,
    }),
    /** El menu portal solo afecta cuando el props menuPortalTarget={document.body} está activo */
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
      //zoom: props.menuZoom || 0.8,
    }),
  };

  const onChecked = (checked) => {
    if (checked && mode === 'multiple') {
      setValue(
        name,
        options.map((option) => option.value),
        { shouldValidate: true },
      );
    } else {
      setValue(name, [], { shouldValidate: true });
    }
  };

  const computedValues = value();
  // console.log('%c⧭ computedValues', 'color: #00258c', computedValues);

  if (disabled) {
    return (
      <DisabledItem
        label={label}
        show={(mode === 'multiple' && computedValues?.length > 0) || (mode !== 'multiple' && computedValues)}
        item={
          <>
            {mode === 'multiple' ? (
              computedValues.map((item) => <LabelTypography>{item.label}</LabelTypography>)
            ) : (
              <LabelTypography>{computedValues?.label}</LabelTypography>
            )}
            {allowOthersOption && originalValue === 'others' && (
              <Box
                sx={
                  disabled
                    ? {
                      p: 1,
                      mb: 1,
                      border: '1px solid lightgray',
                      borderRadius: '5px',
                    }
                    : undefined
                }>
                <Typography
                  component="pre"
                  variant="subtitle2"
                  sx={{
                    fontWeight: 'inherit',
                    fontSize: 'inherit',
                    fontFamily: 'inherit',
                    whiteSpace: 'pre-wrap',
                  }}>
                  {othersValue}
                </Typography>
              </Box>
            )}
          </>
        }
      />
    );
  }

  return (
    <>
      {selectAllButton && mode === 'multiple' && (
        <>
          <Grid lg={12} style={{ ...marginOptions[props.margin || 'normal'] }}>
            <label>Seleccionar Todos</label>
            <Switch onChange={(e) => onChecked(e.target.checked)} />
          </Grid>
        </>
      )}

      <div style={{ display: 'flex', alignItems: 'start' }}
        onClick={(event) => {
          props.onClick && props.onClick(event);
        }}>
        <Select
          menuPortalTarget={props.menuPortalTarget}
          styles={controlStyles}
          classes={classes}
          value={computedValues}
          onChange={handleSelect}
          inputId={name}
          TextFieldProps={{
            label,
            required,
            variant: 'outlined',
            fullWidth: true,
            size: 'small',
            error: Boolean(errorMessage),
            helperText: errorMessage || hint,
            InputLabelProps: {
              shrink: shrink,
            },
          }}
          components={materialUiComponents}
          onBlur={(event) => {
            props.onBlur && props.onBlur(event);
          }}
          onFocus={(event) => {
            props.onFocus && props.onFocus(event);
          }}
          options={options}
          isMulti={mode === 'multiple'}
          placeholder={placeholder || ''}
          isClearable={isClearable}
          loadingMessage={() => i18n('autocomplete.loading')}
          noOptionsMessage={() => i18n('autocomplete.noOptions')}
        />
        {props.endButtonMargin && ( // && (
          <div
            style={{
              width: props.endButtonMargin,
            }}
          />
        )}
      </div>

      {allowOthersOption && originalValue === 'others' && (
        <>
          <label
            style={{
              color: 'gray',
              fontWeight: 600,
              fontSize: 14,
              marginTop: 15,
            }}>
            {label}
          </label>
          <TextAreaFormItem
            rows={3}
            disabled={false}
            name={nameOthers}
            placeholder="Si tu respuesta es otros, por favor especifica"
          />
        </>
      )}
    </>
  );
}

SelectFormItem.defaultProps = {
  required: false,
  isClearable: true,
  selectAllButton: true,
};

SelectFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  required: PropTypes.bool,
  externalErrorMessage: PropTypes.string,
  mode: PropTypes.string,
  isClearable: PropTypes.bool,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  margin: PropTypes.string,
  nameOthers: PropTypes.string,
  allowOthersOption: PropTypes.bool,
  containerStyle: PropTypes.object,
  controlStyle: PropTypes.object,
  selectAllButton: PropTypes.bool,
  endButtonMargin: PropTypes.string,
  shrink: PropTypes.bool,
  menuPortalTarget: PropTypes.object,
  sort: PropTypes.func,
  onFocus: PropTypes.func,
  onClick: PropTypes.func,
};

export default SelectFormItem;
