import list from 'src/modules/recurso/list/recursoListReducers';
import form from 'src/modules/recurso/form/recursoFormReducers';
import view from 'src/modules/recurso/view/recursoViewReducers';
import destroy from 'src/modules/recurso/destroy/recursoDestroyReducers';
import importerReducer from 'src/modules/recurso/importer/recursoImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
