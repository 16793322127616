import list from 'src/modules/perfilEmpleado/list/perfilEmpleadoListReducers';
import form from 'src/modules/perfilEmpleado/form/perfilEmpleadoFormReducers';
import view from 'src/modules/perfilEmpleado/view/perfilEmpleadoViewReducers';
import destroy from 'src/modules/perfilEmpleado/destroy/perfilEmpleadoDestroyReducers';
import importerReducer from 'src/modules/perfilEmpleado/importer/perfilEmpleadoImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
