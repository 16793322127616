import PlanificacionService from 'src/modules/planificacion/planificacionService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'PLANIFICACION_VIEW';

const planificacionViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: planificacionViewActions.FIND_STARTED,
      });

      const record = await PlanificacionService.find(id);

      dispatch({
        type: planificacionViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: planificacionViewActions.FIND_ERROR,
      });

      getHistory().push('/planificacion');
    }
  },
};

export default planificacionViewActions;
