import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.subTarea.fields.id'),
  },
  {
    name: 'nombre',
    label: i18n('entities.subTarea.fields.nombre'),
  },
  {
    name: 'posicion',
    label: i18n('entities.subTarea.fields.posicion'),
  },
  {
    name: 'responsables',
    label: i18n('entities.subTarea.fields.responsables'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'asignarFunciones',
    label: i18n('entities.subTarea.fields.asignarFunciones'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'asignadorFunciones',
    label: i18n('entities.subTarea.fields.asignadorFunciones'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'incluirAsignacionEconomica',
    label: i18n('entities.subTarea.fields.incluirAsignacionEconomica'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'asignacionEconomica',
    label: i18n('entities.subTarea.fields.asignacionEconomica'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.subTarea.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.subTarea.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
