import { RutFormat, formatRut, validateRut } from '@fdograph/rut-utilities';
import moment from 'moment-timezone';
import { i18n } from 'src/i18n';
import * as yup from 'yup';

const yupFormSchemas = {
  rut(label, config?) {
    config = config || {};
    let yupChain = yup
      .string()
      .transform((cv, ov) => {
        return ov === '' ? null : cv;
      })
      .nullable(true)
      .label(label)
      .test('is-rut', i18n('validation.string.rut'), (value) => {
        if (!value) {
          return true;
        }
        return validateRut(value);
      })
      .transform((value) => {
        if (!value) {
          return null;
        }
        return formatRut(value, RutFormat.DOTS_DASH);
      });

    if (config.required) {
      yupChain = yupChain.required();
    }

    return yupChain;
  },
  generic(label) {
    return yup.mixed().label(label);
  },
  confirmPassword(label?, config = { passwordName: 'password' }) {
    if (!label) label = i18n('customValidation.confirmPassword.label');
    const { passwordName } = config;
    return yup
      .string()
      .transform((cv, ov) => {
        return ov === '' ? null : cv;
      })
      .nullable(true)
      .trim()
      .label(label)
      .required()
      .oneOf([yup.ref(passwordName), null], i18n('customValidation.confirmPassword.match'));
  },
  password(label, config?) {
    config = config || {};

    let yupChain = yup
      .string()
      .transform((cv, ov) => {
        return ov === '' ? null : cv;
      })
      .nullable(true)
      .trim()
      .label(label);

    if (config.required) {
      yupChain = yupChain.required();
    }
    if (config.min) {
      yupChain = yupChain.min(config.min, i18n('customValidation.password.min'));
    }
    if (config.max) {
      yupChain = yupChain.max(config.max, i18n('customValidation.password.max'));
    }
    if (config.requireLowercase) {
      yupChain = yupChain.matches(/[a-z]/, i18n('customValidation.password.requireLowercase'));
    }
    if (config.requireUppercase) {
      yupChain = yupChain.matches(/[A-Z]/, i18n('customValidation.password.requireUppercase'));
    }
    if (config.requireNumbers) {
      yupChain = yupChain.matches(/\d/, i18n('customValidation.password.requireNumbers'));
    }
    if (config.requireSpecialCharacters) {
      yupChain = yupChain.matches(/[@#$!%*?&.]/, i18n('customValidation.password.requireSpecialCharacters') + '\n@#$!%*?&.');
    }
    return yupChain;
  },
  string(label, config?) {
    config = config || {};

    let yupChain = yup
      .string()
      .transform((cv, ov) => {
        return ov === '' ? null : cv;
      })
      .nullable(true)
      .trim()
      .label(label);

    if (config.required) {
      yupChain = yupChain.required();
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    if (config.matches) {
      yupChain = yupChain.matches(config.matches);
    }

    return yupChain;
  },
  boolean(label, config?) {
    return yup.bool().default(false).label(label);
  },
  relationToOne(label, config?) {
    config = config || {};

    let yupChain = yup
      .mixed()
      .nullable(true)
      .label(label)
      .transform((value, originalValue) => {
        //console.log('%c⧭ relationToOne originalValue', 'color: #eeff00', originalValue);
        if (!originalValue) {
          return null;
        }

        return originalValue.id || originalValue.value || originalValue;
      });

    if (config.required) {
      yupChain = yupChain.required();
    }

    return yupChain;
  },
  stringArray(label, config?) {
    config = config || {};

    let yupChain = yup
      .array()
      .compact()
      .ensure()
      .of(
        yup
          .string()
          .transform((cv, ov) => {
            return ov === '' ? null : cv;
          })
          .trim(),
      )
      .label(label);

    if (config.required) {
      yupChain = yupChain.required();
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    return yupChain;
  },
  relationToMany(label, config?) {
    config = config || {};

    let yupChain = yup
      .array()
      .nullable(true)
      .label(label)
      .transform((value, originalValue) => {
        if (!originalValue || !originalValue.length) {
          return [];
        }

        return originalValue?.filter(Boolean).map((item) => item.id);
      });

    if (config.required) {
      yupChain = yupChain.required();
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    return yupChain;
  },
  integer(label, config?) {
    config = config || {};

    let yupChain = yup
      .number()
      .transform((cv, ov) => {
        return ov === '' ? null : cv;
      })
      .integer()
      .nullable(true)
      .label(label);

    if (config.required) {
      yupChain = yupChain.required();
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    return yupChain;
  },
  images(label, config?) {
    config = config || {};

    let yupChain = yup.array().nullable(true).label(label);

    if (config.required || config.min) {
      yupChain = yupChain.required();
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    return yupChain;
  },
  files(label, config?) {
    config = config || {};

    let yupChain = yup.array().compact().ensure().nullable(true).label(label);

    if (config.required || config.min) {
      yupChain = yupChain.required();
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    return yupChain;
  },
  enumerator(label, config?) {
    config = config || {};

    let yupChain = yup
      .string()
      .transform((cv, ov) => {
        return ov === '' ? null : cv;
      })
      .label(label)
      .nullable(true)
      .oneOf([undefined, null, ...(config.options || [])]);

    if (config.required) {
      yupChain = yupChain.required(i18n('validation.string.selected'));
    }

    return yupChain;
  },
  email(label, config?) {
    config = config || {};

    let yupChain = yup
      .string()
      .transform((cv, ov) => {
        return ov === '' ? null : cv;
      })
      .nullable(true)
      .trim()
      .label(label)
      .email();

    if (config.required) {
      yupChain = yupChain.required();
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    if (config.matches) {
      yupChain = yupChain.matches(config.matches);
    }

    return yupChain;
  },
  decimal(label, config?) {
    config = config || {};
    let yupChain = yup
      .number()
      .transform((cv, ov) => {
        return ov === '' ? null : cv;
      })
      .nullable(true)
      .label(label);

    if (config.required) {
      yupChain = yupChain.required();
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    return yupChain;
  },
  datetime(label, config?) {
    config = config || {};
    let yupChain = yup
      .mixed()
      .nullable(true)
      .label(label)
      .transform((value, originalValue) => (value ? moment(value).toISOString() : null));

    if (config.required) {
      yupChain = yupChain.required();
    }

    return yupChain;
  },
  date(label, config?) {
    config = config || {};
    let yupChain = yup
      .mixed()
      .nullable(true)
      .label(label)
      .test('is-date', i18n('validation.mixed.default'), (value) => {
        if (!value) {
          return true;
        }

        return moment(value, 'YYYY-MM-DD').isValid();
      })
      .transform((value) => (value ? moment(value).startOf('day').toISOString() : null));

    if (config.required) {
      yupChain = yupChain.required();
    }

    return yupChain;
  },
};

export default yupFormSchemas;
