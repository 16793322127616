import sharedActions from "./sharedActions";

const initialData: any = {};

export default (state = initialData, { type, payload }) => {
  if (type === sharedActions.UPDATE_SHARED) {
    Object.keys(payload).forEach(key => {
      if (state[key]) {
        payload[key] = { ...state[key], ...payload[key] };
      }
    });
    return {
      ...state,
      ...payload
    };
  }
  return state;
};