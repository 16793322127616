import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.notificacion.fields.id'),
  },
  {
    name: 'tipoDestino',
    label: i18n('entities.notificacion.fields.tipoDestino'),
  },
  {
    name: 'foto',
    label: i18n('entities.notificacion.fields.foto'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'relacionadoA',
    label: i18n(
      'entities.notificacion.fields.relacionadoA',
    ),
  },
  {
    name: 'pendientesRelacionados',
    label: i18n(
      'entities.notificacion.fields.pendientesRelacionados',
    ),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'eventosRelacionados',
    label: i18n(
      'entities.notificacion.fields.eventosRelacionados',
    ),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'mensajesRelacionados',
    label: i18n(
      'entities.notificacion.fields.mensajesRelacionados',
    ),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'empleadosRelacionados',
    label: i18n(
      'entities.notificacion.fields.empleadosRelacionados',
    ),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'titulo',
    label: i18n('entities.notificacion.fields.titulo'),
  },
  {
    name: 'asunto',
    label: i18n('entities.notificacion.fields.asunto'),
  },
  {
    name: 'estado',
    label: i18n('entities.notificacion.fields.estado'),
  },
  {
    name: 'sucursales',
    label: i18n('entities.notificacion.fields.sucursales'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'puestos',
    label: i18n('entities.notificacion.fields.puestos'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'usuariosDestino',
    label: i18n(
      'entities.notificacion.fields.usuariosDestino',
    ),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'comunicacionesInternas',
    label: i18n(
      'entities.notificacion.fields.comunicacionesInternas',
    ),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'tarea',
    label: i18n('entities.notificacion.fields.tarea'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'formulariosRelacionados',
    label: i18n(
      'entities.notificacion.fields.formulariosRelacionados',
    ),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.notificacion.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.notificacion.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
