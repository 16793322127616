import ModeloFormularioService from 'src/modules/modeloFormulario/modeloFormularioService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import {getHistory} from 'src/modules/store';
import {i18n} from 'src/i18n';
import layoutActions from 'src/modules/layout/layoutActions';
import moment from 'moment-timezone';

const prefix = 'MODELOFORMULARIO_FORM';

const modeloFormularioFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: id => async dispatch => {
    try {
      dispatch({
        type: modeloFormularioFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await ModeloFormularioService.find(id);
      }

      dispatch({
        type: modeloFormularioFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: modeloFormularioFormActions.INIT_ERROR,
      });

      getHistory().push('/modelo-formulario');
    }
  },

  doCreate: values => async dispatch => {
    try {
      dispatch({
        type: modeloFormularioFormActions.CREATE_STARTED,
      });

      await ModeloFormularioService.create(values);

      dispatch({
        type: modeloFormularioFormActions.CREATE_SUCCESS,
      });

      Message.success(i18n('entities.modeloFormulario.create.success'));

      dispatch(
        layoutActions.doVisibleDrawer({
          drawerId: 'DrawerModeloFormularioFormPage',
          drawerVisible: false,
          drawerChanged: moment().unix(),
          drawerUpdateComponent: 'ModeloFormularioListFilter',
        }),
      );

      getHistory().push('/modelo-formulario');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: modeloFormularioFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: modeloFormularioFormActions.UPDATE_STARTED,
      });

      await ModeloFormularioService.update(id, values);

      dispatch({
        type: modeloFormularioFormActions.UPDATE_SUCCESS,
      });

      Message.success(i18n('entities.modeloFormulario.update.success'));

      getHistory().push('/modelo-formulario');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: modeloFormularioFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default modeloFormularioFormActions;
