import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'nombre',
    label: i18n('entities.subTarea.fields.nombre'),
    schema: schemas.string(
      i18n('entities.subTarea.fields.nombre'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'posicion',
    label: i18n('entities.subTarea.fields.posicion'),
    schema: schemas.integer(
      i18n('entities.subTarea.fields.posicion'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'responsables',
    label: i18n('entities.subTarea.fields.responsables'),
    schema: schemas.relationToMany(
      i18n('entities.subTarea.fields.responsables'),
      {},
    ),
  },
  {
    name: 'asignarFunciones',
    label: i18n('entities.subTarea.fields.asignarFunciones'),
    schema: schemas.boolean(
      i18n('entities.subTarea.fields.asignarFunciones'),
      {},
    ),
  },
  {
    name: 'asignadorFunciones',
    label: i18n('entities.subTarea.fields.asignadorFunciones'),
    schema: schemas.relationToMany(
      i18n('entities.subTarea.fields.asignadorFunciones'),
      {},
    ),
  },
  {
    name: 'incluirAsignacionEconomica',
    label: i18n('entities.subTarea.fields.incluirAsignacionEconomica'),
    schema: schemas.boolean(
      i18n('entities.subTarea.fields.incluirAsignacionEconomica'),
      {},
    ),
  },
  {
    name: 'asignacionEconomica',
    label: i18n('entities.subTarea.fields.asignacionEconomica'),
    schema: schemas.integer(
      i18n('entities.subTarea.fields.asignacionEconomica'),
      {},
    ),
  },
];