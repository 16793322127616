import list from 'src/modules/nivelPuesto/list/nivelPuestoListReducers';
import form from 'src/modules/nivelPuesto/form/nivelPuestoFormReducers';
import view from 'src/modules/nivelPuesto/view/nivelPuestoViewReducers';
import destroy from 'src/modules/nivelPuesto/destroy/nivelPuestoDestroyReducers';
import importerReducer from 'src/modules/nivelPuesto/importer/nivelPuestoImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
