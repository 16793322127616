import {i18n} from 'src/i18n';
import DataService from 'src/modules/data/dataService';
import listActions from 'src/modules/data/list/dataListActions';
import Errors from 'src/modules/shared/error/errors';
import {getHistory} from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'DATA_DESTROY';

const dataDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy: (entity, data) => async (dispatch) => {
    try {
      dispatch({
        type: dataDestroyActions.DESTROY_STARTED,
      });

      await DataService.destroyAll(entity, {data: [data]});

      dispatch({
        type: dataDestroyActions.DESTROY_SUCCESS,
      });

      Message.success(i18n(`entities.data.${entity}.destroy-success`));

      dispatch(listActions.doFetchCurrentFilter());

      getHistory().push('/data');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: dataDestroyActions.DESTROY_ERROR,
      });
    }
  },

  doDestroyAll: (entity, data) => async (dispatch) => {
    try {
      dispatch({
        type: dataDestroyActions.DESTROY_ALL_STARTED,
      });

      await DataService.destroyAll(entity, {data});

      dispatch({
        type: dataDestroyActions.DESTROY_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doClearAllSelected());
        dispatch(listActions.doFetchCurrentFilter());
      }

      Message.success(i18n(`entities.data.${entity}.destroyAll-success`));

      getHistory().push('/data');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: dataDestroyActions.DESTROY_ALL_ERROR,
      });
    }
  },
};

export default dataDestroyActions;
