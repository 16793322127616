import list from 'src/modules/categoriaFormulario/list/categoriaFormularioListReducers';
import form from 'src/modules/categoriaFormulario/form/categoriaFormularioFormReducers';
import view from 'src/modules/categoriaFormulario/view/categoriaFormularioViewReducers';
import destroy from 'src/modules/categoriaFormulario/destroy/categoriaFormularioDestroyReducers';
import importerReducer from 'src/modules/categoriaFormulario/importer/categoriaFormularioImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
