import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'nombre',
    label: i18n('entities.puesto.fields.nombre'),
    schema: schemas.string(
      i18n('entities.puesto.fields.nombre'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'descripcion',
    label: i18n('entities.puesto.fields.descripcion'),
    schema: schemas.string(
      i18n('entities.puesto.fields.descripcion'),
      {},
    ),
  },
  {
    name: 'modeloEvaluacion',
    label: i18n('entities.puesto.fields.modeloEvaluacion'),
    schema: schemas.relationToOne(
      i18n('entities.puesto.fields.modeloEvaluacion'),
      {},
    ),
  },
  {
    name: 'departamento',
    label: i18n('entities.puesto.fields.departamento'),
    schema: schemas.relationToOne(
      i18n('entities.puesto.fields.departamento'),
      {},
    ),
  },
  {
    name: 'nivelPuesto',
    label: i18n('entities.puesto.fields.nivelPuesto'),
    schema: schemas.relationToOne(
      i18n('entities.puesto.fields.nivelPuesto'),
      {
        "required": true
      },
    ),
  },
];