import {Button, FormControl, FormLabel, Grid} from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import React, {useEffect} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import FormErrors from 'src/view/shared/form/formErrors';
import {DisabledItem} from './shared/formItemWrappers';

function InputMultiSelectorFormItem(props) {
  const {name, disabled, options, label, required, externalErrorMessage, onChange} = props;

  const {
    register,
    control,
    setValue,

    formState: {touchedFields, isSubmitted, errors},
  } = useFormContext();

  const errorMessage = FormErrors.errorMessage(name, errors, touchedFields, isSubmitted, externalErrorMessage);

  useEffect(() => {
    register(name);
  }, []);

  const watchName: any = useWatch({name, control});

  if (disabled) {
    return (
      <DisabledItem
        item={
          <Grid item>
            <ButtonGroup size={window.innerWidth > 768 ? 'large' : 'small'} aria-label="Inspection Buttons">
              {options.map((option, index) => (
                <Button
                  style={{
                    backgroundColor: watchName === option.value ? option.color : 'white',
                    color: watchName === option.value ? 'white' : 'gray',
                    border: watchName === option.value ? `1px solid ${option.color}` : '1px solid gray',
                  }}
                  key={index}>
                  {option.label}
                </Button>
              ))}
            </ButtonGroup>
          </Grid>
        }
        label={label}
        show={true}
      />
    );
  }

  const marginOptions = {
    normal: {marginTop: '6px', marginBottom: '12px'},
    none: {marginTop: '0px', marginBottom: '0px'},
  };
  return (
    <FormControl
      fullWidth
      required={required}
      error={Boolean(errorMessage)}
      component="fieldset"
      size="small"
      style={{...marginOptions[props.margin || 'normal']}}>
      {label && (
        <FormLabel style={{marginBottom: 4}} component="legend">
          {label}
        </FormLabel>
      )}

      <ButtonGroup size={window.innerWidth > 768 ? 'large' : 'small'} aria-label="Inspection Buttons">
        {options.map((option, index) => (
          <Button
            onClick={() => {
              if (!disabled) {
                setValue(name, option.value);
                onChange && onChange(option.value);
              }
            }}
            style={{
              backgroundColor: watchName === option.value ? option.color : 'white',
              color: watchName === option.value ? 'white' : 'gray',
              border: watchName === option.value ? `1px solid ${option.color}` : '1px solid gray',
            }}
            key={option.value}>
            {option.label}
          </Button>
        ))}
      </ButtonGroup>
    </FormControl>
  );
}
export default InputMultiSelectorFormItem;
