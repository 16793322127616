import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/registro/importer/registroImporterSelectors';
import RegistroService from 'src/modules/registro/registroService';
import fields from 'src/modules/registro/importer/registroImporterFields';
import { i18n } from 'src/i18n';

const registroImporterActions = importerActions(
  'REGISTRO_IMPORTER',
  selectors,
  RegistroService.import,
  fields,
  i18n('entities.registro.importer.fileName'),
);

export default registroImporterActions;