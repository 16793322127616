import moment from 'moment-timezone';
import {i18n} from 'src/i18n';
import FirmaService from 'src/modules/firma/firmaService';
import layoutActions from 'src/modules/layout/layoutActions';
import Errors from 'src/modules/shared/error/errors';
import {getHistory} from 'src/modules/store';
import Message from 'src/view/shared/message';
import firmaListActions from '../list/firmaListActions';

const prefix = 'FIRMA_FORM';

const firmaFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: firmaFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await FirmaService.find(id);
      }

      dispatch({
        type: firmaFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: firmaFormActions.INIT_ERROR,
      });

      getHistory().push('/firma');
    }
  },

  doCreate: (values) => async (dispatch) => {
    try {
      dispatch({
        type: firmaFormActions.CREATE_STARTED,
      });

      await FirmaService.create(values);

      dispatch({
        type: firmaFormActions.CREATE_SUCCESS,
      });

      Message.success(i18n('entities.firma.create.success'));

      getHistory().push('/firma');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: firmaFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: firmaFormActions.UPDATE_STARTED,
      });

      await FirmaService.update(id, values);

      dispatch({
        type: firmaFormActions.UPDATE_SUCCESS,
      });

      Message.success(i18n('entities.firma.update.success'));

      dispatch({
        type: firmaListActions.PATCH_ONE,
        payload: {...values, id},
      });

      const firstTarea = values?.tareasVinculadas?.[0];
      console.log('%c⧭ firstTarea', 'color: #00bf00', firstTarea);

      dispatch(
        layoutActions.doVisibleDrawer({
          drawerId: 'DrawerFirmaFormPage',
          drawerVisible: false,
          drawerChanged: moment().unix(),
          drawerUpdateComponent: 'TareaFormPage',
          recordId: firstTarea,
        }),
      );
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: firmaFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default firmaFormActions;
