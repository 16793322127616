import { Add, BuildCircle, Delete, SendOutlined, Sms } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { i18n } from 'src/i18n';
import authSelectors from 'src/modules/auth/authSelectors';
import Storage from 'src/security/storage';
import FilesFormItem from 'src/view/shared/form/items/FilesFormItem';
import ImagesFormItem from 'src/view/shared/form/items/ImagesFormItem';
import TextAreaFormItem from 'src/view/shared/form/items/TextAreaFormItem';
import Message from 'src/view/shared/message';
import ConfirmModal from 'src/view/shared/modals/ConfirmModal';
import FormWrapper from 'src/view/shared/styles/FormWrapper';
import FilesViewItem from 'src/view/shared/view/FilesViewItem';
import ImagesViewItem from 'src/view/shared/view/ImagesViewItem';
import CheckListTareaForm from 'src/view/tarea/form/CheckListTareaForm';
import PasosTareaForm from 'src/view/tarea/form/PasosTareaForm';

import { SaveOutlined } from '@mui/icons-material';
import actions from 'src/modules/tarea/form/tareaFormActions';
import { customButtonStyle } from 'src/theme/schemes/CustomTheme';
import ActivoListItem from 'src/view/activo/list/ActivoListItem';
import DescRegistroAutocompleteFormItem from 'src/view/descRegistro/autocomplete/DescRegistroAutocompleteFormItem';
import FirmaListItem from 'src/view/firma/list/FirmaListItem';
import PrincipalFormularioForm from 'src/view/modeloFormulario/form/PrincipalFormularioForm';
import DatePickerFormItem from 'src/view/shared/form/items/DatePickerFormItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import InputNumberFormItem from 'src/view/shared/form/items/InputNumberFormItem';
import TareaReportProgressPage from './TareaReportProgressPage';

import { Assignment, Description, Edit, Gesture, Send } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import FirmaFormPage from 'src/view/firma/form/FirmaFormPage';

import DrawerButton from 'src/view/shared/view/DrawerButton';

import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import firmaSelectors from 'src/modules/firma/firmaSelectors';
import FirmaService from 'src/modules/firma/firmaService';
import layoutActions from 'src/modules/layout/layoutActions';
import Errors from 'src/modules/shared/error/errors';
import tareaFormActions from 'src/modules/tarea/form/tareaFormActions';
import tareaListActions from 'src/modules/tarea/list/tareaListActions';
import tareaPdf from 'src/modules/tarea/tareaPdf';
import tareaSelectors from 'src/modules/tarea/tareaSelectors';
import TareaService from 'src/modules/tarea/tareaService';
import ComunicacionInternaFormPage from 'src/view/comunicacionInterna/form/ComunicacionInternaFormPage';
import ComunicacionInternaListItem from 'src/view/comunicacionInterna/list/ComunicacionInternaListItem';
import ContentWrapper from 'src/view/layout/styles/ContentWrapper';
import Spinner from 'src/view/shared/Spinner';
import PageTitle from 'src/view/shared/styles/PageTitle';
import ClasificacionTagView from 'src/view/shared/view/ClasificacionTagView';
import TareaReassignTasksModal from '../list/TareaReassignTasksModal';
import TareaPrePdfModal from './TareaPrePdfModal';
import TareaSendFormPage from './TareaSendFormPage';

const CardWrapper = styled(Card)(
  ({ theme }) => `
  position: relative;
  overflow: visible;
  //border: 2px solid ${theme.palette.divider};
  box-shadow: 0px 2px 2px -1px rgba(0,0,0,0.07),
              0px 1px 1px 1px rgba(0,0,0,0.09),
              0px 1px 3px 1px rgba(0,0,0,0.12);
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: inherit;
    z-index: 1;
    transition: ${theme.transitions.create(['box-shadow'])};
  }
  &.Mui-selected::after {
    box-shadow: 0 0 0 3px ${theme['colors'].primary.main};
  }
  `,
);
function TareaForm(props) {
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [confirm, setConfirm] = useState(false);
  //const [expanded, setExpanded] = useState(false);

  const dispatch = useDispatch();

  const currentUser = useSelector(authSelectors.selectCurrentUser);
  const roles: any = useSelector(authSelectors.selectRoles);

  const title = props.title;
  const isTaskEditing = props.isTaskEditing;
  const setIsTaskEditing = props.setIsTaskEditing;

  const progressEditor = !props.hideEditProgress && roles.includes('editorProgreso');

  const rolesThatEditProgress = ['editorProgreso', 'ejecutorProgreso'];
  const canEditProgress = !props.hideEditProgress && roles.some((role) => rolesThatEditProgress.includes(role));
  const recordHasProgress = props.record?.taskProgressList?.length > 0;

  const controlInicioActividad = roles.includes('controlInicioActividad');
  const record = props.record;
  // console.log('%c⧭ record', 'color: #00e600', {record});
  const [blankFormList, setBlankFormList] = useState<any>([]);
  const [initialValues] = useState(() => {
    const record = props.record || {};

    if (record.tipoVisualizacion === 'Procedimiento') {
      const procedimientoInterfaz = record.procedimientoInterfaz || record.modeloTarea?.procedimiento?.formato;
      procedimientoInterfaz?.forEach((seccion) => {
        blankFormList.push({
          tipo: 'seccion',
          categoria: seccion.categoria || (seccion.isDynamic ? i18n('common.section') : ''),
          isDynamic: seccion.isDynamic,
          listIndex: blankFormList.length,
          listLength: seccion.subseccions?.length || 0,
        });
        seccion.subseccions?.forEach((subseccion) => {
          const subsectionIndex = blankFormList.length;
          blankFormList.push({
            tipo: 'subseccion',
            categoria: subseccion.categoria || (subseccion.isDynamic ? i18n('common.subsection') : ''),
            isDynamic: subseccion.isDynamic,
            listIndex: subsectionIndex,
            listLength: subseccion.preguntas?.length || 0,
          });
          subseccion.preguntas?.forEach((pregunta) => {
            if (pregunta.tipoRespuesta === 'inspectionCheck') {
              pregunta.inspectionCheck = 'Aprobado';
              blankFormList.push({
                tipo: 'pregunta',
                ...pregunta,
                listIndex: blankFormList.length,
                subsectionIndex
              });
            } else {
              pregunta.inspectionCheck = undefined;
              blankFormList.push({
                tipo: 'pregunta',
                ...pregunta,
                listIndex: blankFormList.length,
                subsectionIndex
              });
            }
          });
        });
      });
      setBlankFormList(blankFormList);
    }

    const inicioReal = controlInicioActividad ? null : moment();

    const tarea = {
      tipoVisualizacion: record.tipoVisualizacion,
      clasificacion: record.clasificacion,
      modeloTarea: record.modeloTarea,
      listaProcedimiento: record.listaProcedimiento?.length > 0 ? record.listaProcedimiento : blankFormList || [],
      blankFormList: record.blankFormList?.length > 0 ? record.blankFormList : blankFormList || [],
      respuestaInterfaz: record.respuestaInterfaz,
      horometro: record.horometro || record.activo?.horometro,
      fechaHorometro: record.fechaHorometro || record.activo?.fechaHorometro,
      detenciones: record.detenciones,
      kilometraje: record.kilometraje || record.activo?.kilometraje,
      comentarioEnviado: record.comentarioEnviado,
      fotosEnviadas: record.fotosEnviadas,
      documentosEnviados: record.documentosEnviados,
      pasoActual: record.pasoActual,
      ultimaUbicacion: record.ultimaUbicacion,
      comentarioEvaluador: record.comentarioEvaluador,
      estado: record.estado,
      comienzo: record.comienzo ? moment(record.comienzo) : inicioReal,
      fin: record.fin ? moment(record.fin) : null,
      estadoActivacion: record.estadoActivacion,
      fechaActivacion: record.fechaActivacion ? moment(record.fechaActivacion) : null,
      enviada: record.enviada,
      esEvaluada: record.esEvaluada,
      evaluadores: record.evaluadores || [],
      createdBy: record.createdBy,
      gasto: record.gasto,
      encargados: record.encargados || [],
      inicio: record.inicio ? moment(record.inicio) : inicioReal,
      documentosAdjuntos: record.documentosAdjuntos || [],
      fotosAdjuntas: record.fotosAdjuntas || [],
      nota: record.nota,
      activosUtilizados: record.activosUtilizados || [],
      serviciosUtilizados: record.serviciosUtilizados || [],
      ubicacion: record.ubicacion,
      ubicacionGoogle: record.ubicacionGoogle,
      latitud: record.latitud,
      longitud: record.longitud,
      formularioPersonalizado: record.formularioPersonalizado || [],
      etiqueta: record.etiqueta,
    };
    //console.log('%c⧭ TareaForm [tarea]', 'color: #016b48', tarea);
    return tarea;
  });

  useEffect(() => {
    if (!watchComienzo && !controlInicioActividad) {
      form.register('comienzo');
      form.setValue('comienzo', moment());
    }

    // if (watchEjecutor?.id !== currentUser.id) {
    //   if (roles.includes('administrador') || roles.includes('admin')) {
    //     return;
    //   }

    //   if (
    //     watchAceptadores?.filter((user) => user?.id === currentUser.id)?.length > 0 ||
    //     watchValidadores?.filter((user) => user?.id === currentUser.id)?.length > 0
    //   ) {
    //     return;
    //   }

    //   //getHistory().goBack();
    //   // ya no se redirige al usuario a la vista anterior
    // }

    // Mejor no hacer esto en lo absoluto por ahora, pero la idea es que:
    // Si la tarea está abierta y el usuario actual es el ejecutor, y la fecha límite es menor a la fecha actual, entonces se cambia el estado a "Fuera de plazo"
    // if (watchEjecutor?.id === currentUser.id && (watchEstado === 'Abierta')) {
    //   if (roles.includes('administrador') || roles.includes('admin')) return;
    //   if (moment(watchFechaLimite).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
    //     form.setValue('estado', 'FueraPlazo');
    //     return;
    //   }
    // }
  }, []);

  const form = useForm({
    mode: 'all',
    defaultValues: initialValues as any,
    shouldUnregister: false,
  });

  const formState = form.formState;
  const { isDirty, isValid, errors } = formState; // read all formState values to subscribe to changes

  // console.log('%c⧭ formState', 'color: #00b300', {
  //   isDirty,
  //   isValid,
  //   errors,
  // });

  const trigger = form.trigger;
  const control = form.control;
  const watchTipoVisualizacion = record?.tipoVisualizacion;
  //const watchProcedimientoInterfaz: any = useWatch({name: 'procedimientoInterfaz', control, defaultValue: initialValues.procedimientoInterfaz});
  const watchListaProcedimiento: any = initialValues.listaProcedimiento || []; //useWatch({name: 'listaProcedimiento', control, defaultValue: initialValues.listaProcedimiento});
  //console.log('%c⧭', 'color: #e5de73', {watchTipoVisualizacion, watchListaProcedimiento, watchProcedimientoInterfaz});

  const watchComienzo: any = useWatch({ name: 'comienzo', control });
  const watchEstado: any = useWatch({ name: 'estado', control });

  const puedeResponder =
    (['Abierta', 'Rechazada', 'FueraPlazo'].includes(watchEstado) && currentUser.id === record?.ejecutor?.id) || isTaskEditing;

  const showStartButton = puedeResponder && watchComienzo === null && controlInicioActividad;

  const watchClasificacion: any = record?.clasificacion;
  const watchActivo: any = record?.activo;
  const watchModeloTarea: any = record?.modeloTarea;
  const watchHorometroAnterior: any = record?.horometroAnterior;
  const watchFechaHorometroAnterior: any = record?.fechaHorometroAnterior;
  const watchHorometro: any = useWatch({ name: 'horometro', control });
  const watchFechaHorometro: any = useWatch({ name: 'fechaHorometro', control });
  const watchKilometraje: any = useWatch({ name: 'kilometraje', control });
  const watchFotosAdjuntas: any = useWatch({ name: 'fotosAdjuntas', control });
  const watchDocumentosAdjuntos: any = useWatch({ name: 'documentosAdjuntos', control });
  const watchComentarioEnviado: any = form.getValues('comentarioEnviado'); //useWatch({ name: 'comentarioEnviado', control });
  const watchDocumentosEnviados: any = useWatch({ name: 'documentosEnviados', control });
  const watchFotosEnviadas: any = useWatch({ name: 'fotosEnviadas', control });

  const onFinishProcedure = (values) => {
    //console.log('%c⧭ onFinishProcedure', 'color: #00ff88', values);
    let data = values;

    const result = customValidationOnSave();
    if (!result) return false;

    data.fin = moment().toISOString();
    data.enviada = true;
    data.estado = 'PendienteRevision';

    onSubmit(data);
  };

  const onTaskStart = async () => {
    const now = moment();
    const data = {
      comienzo: now,
      updating: true,
    };
    const success = await onSubmit(data);
    if (!success) return;
    form.setValue('comienzo', now);
  };

  const onSubmit = (values) => {
    //console.log('%c⧭ onSubmit', 'color: #0088cc', values);
    setConfirm(false);
    return props.onSubmit(props.record?.id, values);
    //return false;
  };

  const onFinishTasks = (values) => {
    setConfirm(false);

    const result = customValidationOnFinish();
    if (!result) return false;

    values.fin = moment().toISOString();
    values.enviada = true;
    values.estado = 'PendienteRevision';

    onSubmit(values);
  };

  const onSave = (values) => {
    //console.log('%c⧭ onSave', 'color: #997326', values);
    setConfirm(false);

    if (!isTaskEditing) {
      values.estado = 'Abierta';
      values.enviada = false;
      values.updating = true;
    }

    const result = customValidationOnSave();
    if (!result) return false;

    onSubmit(values);
  };

  const validarMantenimiento = () => {
    if (watchClasificacion === 'mantenimiento') {
      if (watchActivo?.tipoMedicionMantenimiento === 'Horas') {
        // validate that detenciones FieldArray values are set // hora, descRegistro, horas
        let detenciones = form.getValues('detenciones');
        let sumHoras = 0;
        if (detenciones?.length > 0) {
          let detencionesValidas = true;
          detenciones.forEach((detencion) => {
            //console.log('%c⧭', 'color: #007300', {detencion, hora: moment(detencion.hora).format('YYYY-MM-DD HH:mm:ss')});
            if (!detencion?.hora || !detencion?.descRegistro || !detencion?.horas) {
              detencionesValidas = false;
            }
            // ensure that its a number
            detencion.horas = Number(detencion.horas);
            sumHoras += detencion.horas;
          });
          if (!detencionesValidas) {
            Message.error('Debe completar los campos de las detenciones agregadas');
            return false;
          }
        }
        //console.log('%c⧭', 'color: #006dcc', {sumHoras, justificarHoras});
        if (sumHoras < justificarHoras) {
          Message.error(
            `Debe justificar al menos ${justificarHoras} hora${justificarHoras > 1 ? 's' : ''} ${i18n('common.ofDetentions')}`,
          );
          return false;
        }
        if (sumHoras > justificarHoras) {
          Message.error(
            `No puede justificar más de ${justificarHoras} hora${justificarHoras > 1 ? 's' : ''} ${i18n('common.ofDetentions')}`,
          );
          return false;
        }
        if (watchHorometro < watchHorometroAnterior) {
          Message.error('El horometro es menor al ingresado anteriormente');
          return false;
        }
      }
      if (watchActivo?.tipoMedicionMantenimiento === 'Kilometraje') {
        if (watchKilometraje < watchActivo.kilometraje) {
          Message.error('El kilometraje es menor al ingresado anteriormente');
          return false;
        }
      }
    }
    return true;
  };

  const customValidationOnFinish = () => {
    return validarMantenimiento();
  };

  const customValidationOnSave = () => {
    return validarMantenimiento();
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  const doConfirm = async () => {
    const isValid = await trigger(); // Validar todos los campos antes de enviar
    console.log('%c⧭ doConfirm', 'color: #ffa640', { isValid, errors });
    let notMissingValue = true;
    const listaProcedimiento = form.getValues('listaProcedimiento');
    listaProcedimiento.forEach((pregunta) => {
      if (pregunta.tipo !== 'pregunta' || !pregunta.isRequired) return;
      const tipoRespuesta = pregunta.tipoRespuesta;
      const preguntaLabel = pregunta.pregunta?.label;
      if (tipoRespuesta === 'files') {
        if (!pregunta?.files?.length && !pregunta?.images?.length) {
          notMissingValue = false;
          Message.error(`La pregunta [${preguntaLabel}] es obligatoria`);
        }
      } else if (tipoRespuesta === 'textFromImage') {
        if (!pregunta?.image) {
          notMissingValue = false;
          Message.error(`La pregunta [${preguntaLabel}] es obligatoria`);
        }
      } else if (tipoRespuesta === 'multiCheckbox') {
        const anySelected = pregunta[tipoRespuesta]?.some((item: any) => item.value);
        if (!anySelected) {
          notMissingValue = false;
          Message.error(`La pregunta [${preguntaLabel}] es obligatoria`);
        }
      } else if (!pregunta[tipoRespuesta]) {
        notMissingValue = false;
        Message.error(`La pregunta [${preguntaLabel}] es obligatoria`);
      }
    });

    if (notMissingValue) {
      console.log('Todas las preguntas requeridas tienen respuesta.');
      //Message.success('Todas las preguntas requeridas tienen respuesta.');
      setConfirm(true);
      if (!isValid) {
        console.log('errors', errors.listaProcedimiento);
        Message.error(JSON.stringify(errors.listaProcedimiento?.filter(Boolean), null, 2));
      }
    }
  };

  const doCloseConfirm = () => {
    setConfirm(false);
  };

  const { saveLoading, modal } = props;

  const {
    fields: detenciones,
    append: appendDetencion,
    remove: removeDetencion,
  } = useFieldArray({
    control,
    name: 'detenciones',
  }) as any;

  const updateDetencion = (index, value, fechaHorometro) => {
    if (fechaHorometro) {
      //console.log('%c⧭ updateDetencion', 'color: #d90000', {value});
      value = moment(value);
      const fechaHorometroWithHora = moment(fechaHorometro).set({
        hour: value?.get('hour'),
        minute: value?.get('minute'),
        second: value?.get('second'),
      });
      //console.log('%c⧭', 'color: #aa00ff', {fechaHorometroWithHora});
      form.setValue(`detenciones[${index}].hora`, fechaHorometroWithHora);
    }
  };

  const updateDetenciones = (fechaHorometro) => {
    let detenciones = form.getValues('detenciones');
    detenciones.forEach((detencion, index) => {
      updateDetencion(index, detencion.hora, fechaHorometro);
    });
  };

  const defaultFechaHorometro =
    (moment().isBefore(watchActivo?.fechaHorometro) ? watchActivo?.fechaHorometro : moment()) || moment();

  const cantidadHorasDisponibilidadDia = watchActivo?.cantidadHorasDisponibilidadDia || 11;
  const horasTrabajadas = watchHorometro - watchHorometroAnterior;
  const justificarHoras = cantidadHorasDisponibilidadDia - horasTrabajadas;
  const sumDetenciones = form
    .getValues('detenciones')
    ?.map((d) => d.horas || 0)
    .reduce((a, b) => {
      a = Number(a);
      b = Number(b);
      return a + b;
    }, 0);
  const horasPorJustificar = sumDetenciones >= justificarHoras ? 0 : justificarHoras - sumDetenciones;

  const taskPreview = [{ ...props.record, descripcion: watchModeloTarea?.titulo }];

  if (props.record.parentTask) {
    taskPreview.unshift({ ...props.record.parentTask, isParentTask: true });
  }
  const [prePdfModalVisible, setPrePdfModalVisible] = useState(false);

  const userRoles = useSelector(authSelectors.selectRoles);
  const hasPermissionToCreate = useSelector(tareaSelectors.selectPermissionToCreate);
  const hasPermissionToSign = useSelector(firmaSelectors.selectPermissionToEdit);
  const hasPermissionToReadFirmas = useSelector(firmaSelectors.selectPermissionToRead);

  const checkFirma = (firma) => {
    //return true;
    return firma && !firma.firmada && firma.responsable?.id === currentUser.id;
  };

  const firma = checkFirma(props.firma) ? props.firma : record?.firmas?.find((firma) => checkFirma(firma));
  const firmasListas = record?.firmas?.filter((firma) => firma.firmada);
  const [expanded, setExpanded] = useState(true);

  const hasAccessToIA = userRoles.includes('accesoIA');

  const [loadingPdf, setLoadingPdf] = useState(false);
  const doExportPdf = async () => {
    setLoadingPdf(true);
    try {
      await tareaPdf.exportDataAsPdf(record);
      // const {base64, filename} = await TareaService.downloadPdf(task.id);
      // const downloadLink = document.createElement('a');
      // downloadLink.href = base64;
      // downloadLink.download = filename;
      // downloadLink.click();
    } catch (error) {
      console.log({ error: error?.message || error });
      //throw error;
    }
    setLoadingPdf(false);
  };
  const [reassignModalVisible, setReassignModalVisible] = useState(false);

  const hasPermissionToReassign = useSelector(tareaSelectors.selectPermissionToReassign);
  const hasPermissionToEditClosed = useSelector(tareaSelectors.selectPermissionToEditClosed);

  const doOpenReassignModal = () => {
    setReassignModalVisible(true);
  };

  const doCloseReassignModal = () => {
    setReassignModalVisible(false);
  };

  const renderReassignButton = () => {
    if (!canReassign) {
      return null;
    }

    const notAbierta = record.estado !== 'Abierta';

    if (notAbierta) {
      return null;
      // (
      //   <Tooltip followCursor title={'Debe seleccionar tareas en estado "Abierta"'}>
      //     <span>{notAbierta}</span>
      //   </Tooltip>
      // );
    }

    //const disabled = notAbierta// || loading;

    const button = (
      <Grid item>
        <Tooltip enterDelay={700} leaveDelay={150} enterNextDelay={400} followCursor title={i18n('common.reassignTask')}>
          <Button
            style={{ height: 36 }}
            type="button"
            disabled={false}
            onClick={doOpenReassignModal}
            startIcon={<Assignment />}
            size="small">
            {i18n('common.reassign')}
          </Button>
        </Tooltip>
      </Grid>
    );

    return button;
  };

  const [loadingReOpen, setLoadingReOpen] = useState(false);
  const doReOpenTask = async () => {
    try {
      dispatch({
        type: tareaFormActions.UPDATE_STARTED,
      });
      setLoadingReOpen(true);

      const id = record.id;
      const values = { estado: 'Abierta' };
      await TareaService.update(id, values);

      setLoadingReOpen(false);
      dispatch({
        type: tareaFormActions.UPDATE_SUCCESS,
      });
      dispatch({
        type: tareaListActions.PATCH_RECORD,
        payload: { id, values },
      });
      dispatch({
        type: layoutActions.DRAWER_VISIBLE,
        payload: {
          drawerId: 'TareaFormPage',
          drawerVisible: true,
          drawerChanged: moment().unix(),
          drawerUpdateComponent: 'TareaFormPage',
          recordId: id,
        },
      });
    } catch (error) {
      Errors.handle(error);
    }
  };
  const doEditTask = async () => {
    setIsTaskEditing(true);
  };
  const stopEditTask = async () => {
    setIsTaskEditing(false);
  };

  const tareasBaseFilter = {
    esPlanificada: undefined,
    withChildren: undefined,
    fechaProgramacionRange: undefined, //fechaRange,
    usuariosSeleccionados: undefined, //usuariosSeleccionados?.map((row) => row.id || row),
    activosSeleccionados: undefined, //activosSeleccionados?.map((row) => row.id || row),
    planificacionesSeleccionadas: undefined, //planificacionesSeleccionadas?.map((row) => row.id || row),
  };

  const canViewSignature = !isTaskEditing && hasPermissionToReadFirmas && firmasListas?.length > 0;
  const canSign = !isTaskEditing && hasPermissionToSign && firma;
  //console.log('%c⧭ canSign', 'color: #e5de73', canSign, firma);
  const canReassign = !isTaskEditing && hasPermissionToReassign;
  const canEditClosedTask = record?.id && ['Cerrada'].includes(record.estado) && hasPermissionToEditClosed;
  const canReopenTask =
    record?.id &&
    !isTaskEditing &&
    ['Cerrada'].includes(record.estado) &&
    ['admin', 'administrador', 'administradorOperaciones'].some((role) => userRoles.includes(role));
  const canSendNewTask = !isTaskEditing && hasPermissionToCreate;
  const canExportPdf = record?.id && !isTaskEditing && ['Cerrada', 'EnRevision', 'PendienteRevision'].includes(record.estado);
  const hasPanelAcciones =
    canViewSignature || canSign || canReassign || canEditClosedTask || canReopenTask || canSendNewTask || canExportPdf;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [modalNota, setModalNota] = useState<any>(null);
  const [modalNotaLoading, setModalNotaLoading] = useState<any>(false);

  const renderSignatureMessages = () => {
    if (!canViewSignature || !firmasListas?.length) return;
    const generarMensaje = (firma) => {
      const signatureId = firma.id;
      if (firma.responsable?.id !== currentUser.id) return;

      const guardarNota = async () => {
        // guardar la nota
        try {
          setModalNotaLoading(true);
          // Obtener el contenido de la nota
          const notaContent = document?.getElementById('nota-content-' + signatureId) as any;
          console.log('%c⧭ notaContent', 'color: #007300', notaContent?.value);
          await FirmaService.update(signatureId, { nota: notaContent?.value });
          dispatch(
            layoutActions.doVisibleDrawer({
              drawerId: 'DrawerFirmaFormPage',
              drawerVisible: false,
              drawerChanged: moment().unix(),
              drawerUpdateComponent: 'TareaFormPage',
              recordId: record?.id,
            }),
          );
        } catch (error) {
          // Manejar el error en caso de que ocurra
          console.error('Error al guardar la nota:', error);
        }
        setModalNotaLoading(false);
        setModalNota(null);
      };

      // abrir un modal para escribir una nota
      return (
        <>
          <Button style={{ height: 36 }} type="button" onClick={() => setModalNota(signatureId)} startIcon={<Edit />} size="small">
            {i18n('common.addNote')}
          </Button>

          <Dialog
            key={signatureId}
            open={modalNota === signatureId}
            onClose={() => setModalNota(null)}
            maxWidth="xs"
            fullWidth={true}>
            <DialogTitle>
              <span
                style={{
                  fontSize: '1.3rem',
                }}>
                {i18n('common.addNote')}
              </span>
            </DialogTitle>

            <DialogContent>
              <textarea
                id={'nota-content-' + signatureId}
                style={{
                  width: '100%',
                  height: '100px',
                  padding: '5px',
                  fontSize: '1rem',
                }}
                placeholder={i18n('common.writeNote')}
              />
            </DialogContent>

            <DialogActions>
              <Button
                disabled={modalNotaLoading}
                onClick={() => setModalNota(null)}
                color="primary"
                style={{
                  fontSize: '0.9rem',
                }}>
                {i18n('common.cancel')}
              </Button>
              <Button
                disabled={modalNotaLoading}
                onClick={() => guardarNota()}
                endIcon={modalNotaLoading && <CircularProgress size={20} />}
                color="primary"
                style={{
                  fontSize: '0.9rem',
                }}>
                {i18n('common.accept')}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    };

    return (
      <div className="tabla-mensajes-container">
        <table className="tabla-mensajes">
          <thead>
            <tr>
              <th>{i18n('common.date')}</th>
              <th>{i18n('common.user')}</th>
              <th>{i18n('common.message')}</th>
            </tr>
          </thead>
          <tbody>
            {firmasListas.map((firma) => (
              <tr key={firma.id}>
                <td>{moment(firma.updatedAt).format('DD-MM-YYYY HH:mm')}</td>
                <td>{firma.responsable?.fullName || i18n('common.unregisteredName')}</td>
                <td>{firma.nota || generarMensaje(firma)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const onSuccessPrePdfModal = async () => {
    props.onSubmit(record.id, { ...record, updating: true, silent: true });
    await doExportPdf();
    doClosePrePdfModal();
  };

  const onCloseReadyPrePdfModal = () => {
    doClosePrePdfModal();
    doExportPdf();
  };

  const onAbortPrePdfModal = () => {
    doClosePrePdfModal();
  };

  const doClosePrePdfModal = () => {
    setPrePdfModalVisible(false);
  };

  const doOpenPrePdfModal = () => {
    setPrePdfModalVisible(true);
  };

  const onChangePrePdfModal = (updatedTask) => {
    props.setRecord(updatedTask);
  };

  // const puedeResponder =
  //   (['Abierta', 'Rechazada', 'FueraPlazo'].includes(record?.estado) && currentUser.id === record?.ejecutor?.id) || isTaskEditing;

  return (
    <>
      {record?.planificacion?.nombre && (
        <Box ml="5px">
          <PageTitle caption>{record?.planificacion?.nombre}</PageTitle>
        </Box>
      )}
      {!record?.planificacion?.nombre && (
        <Box ml="5px">
          {puedeResponder && <PageTitle caption>{title}</PageTitle>}
          {!puedeResponder && <PageTitle caption>{i18n('entities.tarea.view.title')}</PageTitle>}
        </Box>
      )}
      <Box style={{ padding: '0px 20px 5px 5px' }}>
        <Box display="flex" flexDirection="row">
          <PageTitle>
            <Box display="flex" gap={2}>
              <Box>{record?.titulo}</Box>
              <Box>{record?.tituloTarea}</Box>
            </Box>
          </PageTitle>
          {!isDownSm && (
            <Box ml="auto" pr={3}>
              <ClasificacionTagView value={record?.estado} entity="tarea" fieldName="estado" />
            </Box>
          )}
        </Box>
        <Box display="flex" flexDirection="row">
          {record?.subtitulo && !isDownSm && (
            <Tooltip followCursor arrow title={record?.subtitulo}>
              <span>
                <Typography variant="subtitle1">{record?.subtitulo}</Typography>
              </span>
            </Tooltip>
          )}
          <Box ml="auto" pr={3}>
            <Grid container direction="row" gap={1} alignItems="center" wrap="nowrap">
              {isDownSm && (
                <Grid item>
                  <Box
                    sx={{
                      transform: 'scale(0.9)',
                    }}>
                    <Tooltip
                      followCursor
                      arrow
                      title={
                        i18n('entities.tarea.fields.prioridad') +
                        ': ' +
                        i18n(`entities.tarea.enumerators.prioridad.${record?.prioridad}`)
                      }>
                      <Box ml="auto">
                        <ClasificacionTagView value={record?.estado} entity="tarea" fieldName="estado" />
                      </Box>
                    </Tooltip>
                  </Box>
                </Grid>
              )}
              <Grid item>
                <Box
                  sx={{
                    transform: 'scale(0.9)',
                  }}>
                  <Tooltip
                    followCursor
                    arrow
                    title={
                      i18n('entities.tarea.fields.prioridad') +
                      ': ' +
                      i18n(`entities.tarea.enumerators.prioridad.${record?.prioridad}`)
                    }>
                    <Box>
                      <ClasificacionTagView value={record?.prioridad} entity="tarea" fieldName="prioridad" />
                    </Box>
                  </Tooltip>
                </Box>
              </Grid>
              <Grid item>
                <Box display="flex" gap={0.5} alignItems="center" justifyContent="end">
                  {record?.activo?.id && <BuildCircle />}
                  <strong>
                    {record?.activo?.id &&
                      (record?.activo.codigo && record?.activo.nombre && record?.activo.nombre !== record?.activo.codigo
                        ? `${record?.activo.nombre} - ${record?.activo.codigo}`
                        : record?.activo.codigo
                          ? record?.activo.codigo
                          : record?.activo.nombre)}
                  </strong>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {record?.subtitulo && isDownSm && (
          <Tooltip followCursor arrow title={record?.subtitulo}>
            <span>
              <Typography variant="subtitle1">{record?.subtitulo}</Typography>
            </span>
          </Tooltip>
        )}
      </Box>
      {record?.id && hasPanelAcciones && (
        <Grid container direction="column" sx={{ pl: 0.2, pr: 2 }}>
          <Grid item>
            {expanded && (
              <CardWrapper sx={{ p: 0.3, mb: 0.5 }} raised={false}>
                <Box
                  style={{
                    position: 'relative',
                    zIndex: 2,
                  }}>
                  <Grid container spacing={2} alignItems="center">
                    {/* <QRCode
                        value={`${config.frontendUrl.protocol}://${config.frontendUrl.host}/tarea/${props.record?.id}`}
                        size={140}
                      /> */}
                    {/* Firmar tarea */}
                    {canSign && (
                      <Grid item>
                        <DrawerButton
                          style={{ height: 36 }}
                          drawerId={'DrawerFirmaFormPage'}
                          type={'button'}
                          tooltipTitle={i18n('common.signTask')}
                          buttonTitle={i18n('common.sign')}
                          buttonIcon={<Gesture />}
                          variant={'text'}
                          color={'default'}
                          width={window.innerWidth * 0.55}
                          component={<FirmaFormPage record={firma} disabled={false} drawer={true} />}
                        />
                      </Grid>
                    )}
                    {/* Reasignar tarea */}
                    {canReassign && (
                      <>
                        {renderReassignButton()}
                        {reassignModalVisible && (
                          <TareaReassignTasksModal onClose={doCloseReassignModal} ids={[record.id]} drawerId={'TareaFormPage'} />
                        )}
                      </>
                    )}
                    {/* Editar tarea cerrada */}
                    {!isTaskEditing && canEditClosedTask && (
                      <Grid item>
                        <Tooltip
                          enterDelay={700}
                          leaveDelay={150}
                          enterNextDelay={400}
                          followCursor
                          title={i18n('common.editTask')}>
                          <Button
                            style={{ height: 36 }}
                            type="button"
                            disabled={false}
                            onClick={($event) => {
                              $event.stopPropagation();
                              doEditTask();
                            }}
                            startIcon={<Edit />}
                            size="small">
                            {i18n('common.editTask')}
                          </Button>
                        </Tooltip>
                      </Grid>
                    )}
                    {isTaskEditing && canEditClosedTask && (
                      <Grid item>
                        <Tooltip
                          enterDelay={700}
                          leaveDelay={150}
                          enterNextDelay={400}
                          followCursor
                          title={i18n('common.abortEditionWithoutSaving')}>
                          <Button
                            style={{ height: 36 }}
                            type="button"
                            disabled={false}
                            onClick={($event) => {
                              $event.stopPropagation();
                              stopEditTask();
                            }}
                            startIcon={<Close />}
                            size="small">
                            {i18n('common.abortTaskEdition')}
                          </Button>
                        </Tooltip>
                      </Grid>
                    )}
                    {/* Reabrir tarea */}
                    {canReopenTask && (
                      <Grid item>
                        {loadingReOpen && <CircularProgress size={20} />}
                        {!loadingReOpen && (
                          <Tooltip
                            enterDelay={700}
                            leaveDelay={150}
                            enterNextDelay={400}
                            followCursor
                            title={i18n('common.reopenTask')}>
                            <Button
                              style={{ height: 36 }}
                              type="button"
                              disabled={false}
                              onClick={($event) => {
                                $event.stopPropagation();
                                doReOpenTask();
                              }}
                              startIcon={<PlayCircleOutlineIcon />}
                              size="small">
                              {i18n('common.reopenTask')}
                            </Button>
                          </Tooltip>
                        )}
                      </Grid>
                    )}
                    {/* Enviar tarea asociada */}
                    {canSendNewTask && (
                      <Grid item>
                        <DrawerButton
                          style={{ height: 36 }}
                          drawerId={'DrawerTareaSendFormPage'}
                          width={window.innerWidth * 0.55}
                          type={'button'}
                          tooltipTitle={i18n('common.sendAssociatedTask')}
                          buttonTitle={i18n('common.associatedTask')}
                          buttonIcon={<Send />}
                          variant={'text'}
                          color={'default'}
                          component={
                            <TareaSendFormPage
                              silent={props.sendFormSilent}
                              drawerId={'DrawerTareaSendFormPage'}
                              drawer={true}
                              title={i18n('common.sendAssociatedTaskTo') + record.titulo}
                              parentTask={record}
                            />
                          }
                        />
                      </Grid>
                    )}
                    {/* Seguimiento */}
                    {/* <Grid item>
                          <DrawerButton
                            style={{height: 36}}
                            drawerId={'DrawerTareaListPage'}
                            width={window.innerWidth * 0.55}
                            type={'button'}
                            tooltipTitle={'Seguimiento de Actividades'}
                            buttonTitle={'Seguimiento'}
                            buttonIcon={<AccountTree />}
                            variant={'text'}
                            color={'default'}
                            component={<TareaListPage filter={tareasBaseFilter} />}
                          />
                        </Grid> */}
                    {/* Exportar pdf */}
                    {canExportPdf && (
                      <Grid item>
                        {loadingPdf && <CircularProgress size={20} />}
                        {!loadingPdf && (
                          <Tooltip
                            enterDelay={700}
                            leaveDelay={150}
                            enterNextDelay={400}
                            followCursor
                            title={i18n('common.exportPdf')}>
                            <Button
                              style={{ height: 36 }}
                              type="button"
                              disabled={false}
                              onClick={($event) => {
                                $event.stopPropagation();
                                if (hasAccessToIA) {
                                  doOpenPrePdfModal();
                                } else {
                                  doExportPdf();
                                }
                              }}
                              startIcon={<Description />}
                              size="small">
                              {i18n('common.exportPdf')}
                            </Button>
                          </Tooltip>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </CardWrapper>
            )}
          </Grid>
        </Grid>
      )}
      <ContentWrapper
        style={{
          overflowY: 'auto',
          marginBottom: '10px',
        }}>
        <Box width={'calc(100%)'} pl={1} pr={2} pt={1}>
          <Grid
            container
            direction="row"
            columnGap={1}
            alignItems="center"
            wrap={!isDownSm ? 'nowrap' : undefined}
            sx={{ mt: -1, pl: 1 }}>
            <Grid item xs={7} sm={4} md={3}>
              <Box display="flex" flexDirection="row" columnGap={3} flexWrap={isDownSm ? 'wrap' : undefined}>
                {record?.inicioEstimado && (
                  <>
                    <Box display="flex" flexDirection="row" gap={1} justifyContent="flex-end">
                      <Typography variant="subtitle1" color="text.primary">
                        {i18n('common.start')}
                      </Typography>
                      <Typography variant="subtitle1" color="text.primary">
                        <b>{moment(record?.inicioEstimado).format('DD/MM/YYYY HH:mm')}</b>
                      </Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" gap={1} justifyContent="flex-end">
                      <Typography variant="subtitle1" color="text.primary">
                        {i18n('common.end')}
                      </Typography>
                      <Typography variant="subtitle1" color="text.primary">
                        <b>{moment(record?.finEstimado).format('DD/MM/YYYY HH:mm')}</b>
                      </Typography>
                    </Box>
                  </>
                )}
                {!record?.inicioEstimado && (
                  <>
                    <Box display="flex" flexDirection="row" gap={1} justifyContent="flex-end">
                      <Typography variant="subtitle1" color="text.primary">
                        {i18n('common.scheduled')}
                      </Typography>
                      <Typography variant="subtitle1" color="text.primary">
                        <b>{moment(record?.fechaProgramacion).format('DD/MM/YYYY')}</b>
                      </Typography>
                    </Box>
                  </>
                )}
                {record?.fechaLimite && (
                  <>
                    <Box display="flex" flexDirection="row" gap={1} justifyContent="flex-end">
                      <Typography variant="subtitle1" color="text.primary">
                        {i18n('common.deadline')}
                      </Typography>
                      <Typography variant="subtitle1" color="text.primary">
                        <b>{record?.fechaLimite ? moment(record?.fechaLimite).format('DD/MM/YYYY') : ''}</b>
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={5} sm={8} md={9} justifyContent={!isDownSm ? 'flex-end' : undefined}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent={!isDownSm ? 'flex-end' : undefined}
                gap={0.5}>
                <Tooltip followCursor arrow placement="top" title={record?.ejecutor?.fullName || record?.ejecutor?.email}>
                  <Avatar
                    sx={{
                      width: 20,
                      height: 20,
                    }}
                    src={record?.ejecutor?.avatars?.length > 0 ? record?.ejecutor?.avatars[0]?.downloadUrl : ''}
                  />
                </Tooltip>
                {record?.ejecutor?.fullName || record?.ejecutor?.email}
                <Box onClick={($event) => $event.stopPropagation()}>
                  {!record?.comunicacionInterna ? (
                    !props.hideComunicacionInterna && (
                      <DrawerButton
                        style={{ width: '40px' }}
                        drawerId={'DrawerComunicacionInternaFormPage'}
                        type={'icon'}
                        width={window.innerWidth * 0.55}
                        tooltipTitle={i18n('common.new')}
                        buttonTitle={i18n('common.new')}
                        buttonIcon={<Sms />}
                        component={
                          <ComunicacionInternaFormPage
                            passProps={{
                              tipo: 'Tarea',
                              id: record?.id,
                            }}
                            drawer={true}
                          />
                        }
                      />
                    )
                  ) : (
                    <ComunicacionInternaListItem value={record?.comunicacionInterna} hideButtonTitle style={{ maxWidth: '40px' }} />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
          {/* <CardWrapper sx={{p: 1, pr: 2}} raised={false}>
            <Grid container direction="row" gap={1} alignItems="center" wrap="nowrap">
              <Grid item xs={12}>
                <Box flexDirection="row" display="flex" alignItems="center">
                  {!props.hideProgressbar && (
                    <>
                      <LinearProgress
                        sx={{
                          height: '6px',
                          width: '100%',
                        }}
                        value={record?.progreso?.toFixed(0) || 0}
                        color="primary"
                        variant="determinate"
                      />
                      <Box
                        sx={{
                          marginLeft: '5px',
                          width: '30px',
                          textAlign: 'left',
                          transform: 'scale(0.9)',
                        }}>
                        <Typography variant="subtitle1">{record?.progreso?.toFixed(0) || 0}%</Typography>
                      </Box>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </CardWrapper> */}
        </Box>
        {/* {record && record.comunicacionInterna && (
          <div style={{marginTop: 0}}>
            <ComunicacionInternaListItem value={record.comunicacionInterna} />
          </div>
        )} */}
        {renderSignatureMessages()}
        {props.initLoading && <Spinner />}
        <Box style={{ width: '100%' }} pl={1} pr={2} pt={1}>
          <FormWrapper
            style={{
              flexShrink: 0,
            }}>
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                {puedeResponder && (
                  <React.Fragment>
                    <Grid container direction="column">
                      {!props.hidePendienteView && (
                        <Grid item>
                          {/* <Box pl={1} pr={2}>
                            <PendienteView
                              drawer={props.drawer}
                              record={taskPreview}
                              dontOpenRecord={true}
                              hideComunicacionInterna={props.hideComunicacionInterna}
                              hideProgressbar={props.hideProgressbar}
                              hideExportButton={true}
                            />
                          </Box> */}

                          {watchModeloTarea?.descripcion && (
                            <Box p={1}>
                              <Box pl={0}>
                                <Typography variant={'h5'} color="textSecondary">
                                  {i18n('common.description')}
                                </Typography>
                              </Box>
                              <Box pl={0}>
                                <Typography variant={'body2'} color="textSecondary">
                                  {watchModeloTarea?.descripcion}
                                </Typography>
                              </Box>
                            </Box>
                          )}
                        </Grid>
                      )}

                      <React.Fragment>
                        {canEditProgress && !showStartButton && (
                          <Box sx={{ my: 1, px: 1, width: '100%' }}>
                            <TareaReportProgressPage
                              record={props.record}
                              updating
                              expandable
                              noBreadcrumb
                              onSaveSuccess={(id, values) => {
                                dispatch(actions.setRecord({ ...props.record, ...values }));
                              }}
                            />
                          </Box>
                        )}

                        {showStartButton && (
                          <Box p={3} style={{ width: '100%' }}>
                            <Button
                              style={{ width: '100%', ...customButtonStyle }}
                              variant="contained"
                              color="primary"
                              disabled={saveLoading}
                              type="button"
                              onClick={() => onTaskStart()}
                              startIcon={<SendOutlined />}
                              size="large">
                              {i18n('common.startTask')}
                            </Button>
                          </Box>
                        )}

                        {watchEstado === 'Rechazada' && (
                          <Grid sx={{ p: 1 }} item lg={12}>
                            <Alert severity="error">
                              <AlertTitle style={{ marginBottom: 10 }}>
                                {i18n('common.taskHasBeenRejected')}
                                {record.firmas?.length > 0 ? i18n('common.dueToFollowingReasons') : ''}
                              </AlertTitle>

                              {record.firmas
                                ?.filter((firma) => firma.nota)
                                ?.map((firma, index) => (
                                  <Grid key={index} style={{ marginTop: 5 }} container>
                                    {firma.nota}
                                  </Grid>
                                ))}
                            </Alert>
                          </Grid>
                        )}

                        <Grid container sx={{ p: 1 }}>
                          <Grid item lg={props.drawer ? 12 : 3} md={8} sm={12} xs={12}>
                            <ImagesViewItem label={i18n('entities.tarea.fields.fotosAdjuntas')} value={watchFotosAdjuntas} />
                          </Grid>
                          <Grid item lg={props.drawer ? 12 : 3} md={8} sm={12} xs={12}>
                            <FilesViewItem
                              label={i18n('entities.tarea.fields.documentosAdjuntos')}
                              value={watchDocumentosAdjuntos}
                            />
                          </Grid>
                        </Grid>

                        <Grid spacing={3} container sx={{ p: 1 }}>
                          {/* <Grid item lg={12}>
                        <h2>Respuesta</h2>
                      </Grid> */}

                          {watchClasificacion === 'mantenimiento' && (
                            <Grid item lg={12}>
                              <h2>
                                {i18n('common.answerTheFollowingQuestionsAssociatedTo')} <ActivoListItem value={watchActivo} />
                              </h2>
                            </Grid>
                          )}

                          {watchClasificacion === 'mantenimiento' && (
                            <React.Fragment>
                              {watchActivo?.tipoMedicionMantenimiento === 'Horas' && (
                                <Grid item lg={12} xs={12}>
                                  <Grid item sm={6} xs={12}>
                                    <label
                                      style={{
                                        fontWeight: 'bold',
                                        fontSize: 20,
                                      }}>
                                      {i18n('common.dailyHoursAvailability')}: {cantidadHorasDisponibilidadDia}
                                    </label>
                                    <br />
                                    <label
                                      style={{
                                        fontWeight: 'bold',
                                        fontSize: 20,
                                      }}>
                                      {i18n('common.workedHours')}: {horasTrabajadas}
                                    </label>
                                    <br />
                                    <br />
                                    <label
                                      style={{
                                        fontWeight: 'bold',
                                        fontSize: 20,
                                      }}>
                                      {i18n('pastHourmeter')}:{' '}
                                      {new Intl.NumberFormat('es-CL', {
                                        currency: 'CLP',
                                        style: 'decimal',
                                      }).format(watchHorometroAnterior || 0)}
                                    </label>
                                    <br />
                                    <label
                                      style={{
                                        fontWeight: 'bold',
                                        fontSize: 20,
                                      }}>
                                      {i18n('common.hourmeter')}:{' '}
                                      {new Intl.NumberFormat('es-CL', {
                                        currency: 'CLP',
                                        style: 'decimal',
                                      }).format(watchHorometro || 0)}
                                    </label>

                                    <InputNumberFormItem
                                      size={'large'}
                                      name="horometro"
                                      label={i18n('entities.formulario.fields.horometro')}
                                      placeholder={i18n('common.enterHourmeter')}
                                      required={false}
                                      disableFormat
                                      defaultValue={watchActivo?.horometro || 0}
                                      min={watchActivo?.horometro}
                                    />
                                  </Grid>
                                  {/* fecha horometro */}
                                  <Grid item sm={6} xs={12}>
                                    <label
                                      style={{
                                        fontWeight: 'bold',
                                        fontSize: 20,
                                      }}>
                                      {i18n('common.pastHourmeterDate')}:{' '}
                                      {moment(watchFechaHorometroAnterior).format('DD/MM/YYYY')}
                                    </label>
                                    <br />
                                    <label
                                      style={{
                                        fontWeight: 'bold',
                                        fontSize: 20,
                                      }}>
                                      {i18n('common.hourmeterDate')}:{' '}
                                      <DatePickerFormItem
                                        name="fechaHorometro"
                                        label={i18n('entities.formulario.fields.fechaHorometro')}
                                        required={false}
                                        defaultValue={defaultFechaHorometro}
                                        onChange={(fechaHorometro) => {
                                          updateDetenciones(fechaHorometro);
                                        }}
                                        minDate={watchActivo?.fechaHorometro}
                                      />
                                    </label>
                                  </Grid>
                                </Grid>
                              )}

                              {watchActivo?.tipoMedicionMantenimiento === 'Kilometros' && (
                                <Grid item lg={12} xs={12}>
                                  <Grid item lg={props.drawer ? 12 : 5} md={8} sm={12} xs={12}>
                                    <label
                                      style={{
                                        fontWeight: 'bold',
                                        fontSize: 20,
                                      }}>
                                      {i18n('common.addedMileage')}:{' '}
                                      {new Intl.NumberFormat('es-CL', {
                                        currency: 'CLP',
                                        style: 'decimal',
                                      }).format(watchKilometraje || 0)}
                                    </label>

                                    <InputNumberFormItem
                                      size={'large'}
                                      name="kilometraje"
                                      label={i18n('entities.formulario.fields.kilometraje')}
                                      placeholder={i18n('common.enterMileage')}
                                      required={false}
                                    />
                                  </Grid>
                                </Grid>
                              )}
                            </React.Fragment>
                          )}

                          {
                            /* {window.innerWidth <= 768 && (
                          <Grid
                            item
                            lg={props.drawer ? 12 : 6}
                          >
                            <h2
                              style={{
                                marginBottom: 20,
                              }}
                            >
                              {' '}
                              Detalles Adicionales <hr></hr>
                            </h2>
                            <TextAreaFormItem
                              name="comentarioEnviado"
                              label={i18n(
                                'entities.tarea.fields.comentarioEnviado',
                              )}
                              required={false}
                            />

                            <FilesFormItem
                              name="documentosEnviados"
                              label={i18n(
                                'entities.tarea.fields.documentosEnviados',
                              )}
                              required={false}
                              storage={
                                Storage.values
                                  .tareaDocumentosEnviados
                              }
                              max={undefined}
                              formats={undefined}
                            />

                            <ImagesFormItem
                              name="fotosEnviadas"
                              label={i18n(
                                'entities.tarea.fields.fotosEnviadas',
                              )}
                              required={false}
                              storage={
                                Storage.values
                                  .tareaFotosEnviadas
                              }
                              max={3}
                            />
                          </Grid>
                        )} */
                            ''
                          }

                          {watchTipoVisualizacion === 'Procedimiento' && (
                            <Grid item lg={props.drawer ? 12 : 6} xs={12}>
                              {watchListaProcedimiento.length > 0 && (
                                <PrincipalFormularioForm
                                  record={record}
                                  blankFormList={blankFormList}
                                  onSave={onSave}
                                  editar={!showStartButton}
                                  esRespuesta={true}
                                  name="listaProcedimiento"
                                  customButton={props.principalFormButton}
                                />
                              )}
                            </Grid>
                          )}

                          {watchTipoVisualizacion === 'Pasos' && (
                            <Grid item lg={props.drawer ? 12 : 6}>
                              <PasosTareaForm record={record} readOnly={showStartButton} name="respuestaInterfaz" />
                            </Grid>
                          )}

                          {watchTipoVisualizacion === 'Checklist' && (
                            <Grid item lg={props.drawer ? 12 : 6}>
                              <CheckListTareaForm record={record} readOnly={showStartButton} name="respuestaInterfaz" />
                            </Grid>
                          )}

                          {watchTipoVisualizacion === 'Estandar' && !showStartButton && (
                            <Grid item lg={props.drawer ? 12 : 6}>
                              <h2
                                style={{
                                  marginBottom: 20,
                                }}>
                                {' '}
                                Detalles Generales <hr></hr>
                              </h2>
                              <TextAreaFormItem
                                name="comentarioEnviado"
                                label={i18n('entities.tarea.fields.comentarioEnviado')}
                                required={false}
                              />

                              <FilesFormItem
                                name="documentosEnviados"
                                label={i18n('entities.tarea.fields.documentosEnviados')}
                                required={false}
                                storage={Storage.values.tareaDocumentosEnviados}
                                max={undefined}
                                formats={undefined}
                              />

                              <ImagesFormItem
                                name="fotosEnviadas"
                                label={i18n('entities.tarea.fields.fotosEnviadas')}
                                required={false}
                                storage={Storage.values.tareaFotosEnviadas}
                              //max={3}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </React.Fragment>
                    </Grid>

                    {confirm && (
                      <ConfirmModal
                        title={i18n('common.areYouSure')}
                        onConfirm={
                          watchTipoVisualizacion !== 'Procedimiento'
                            ? form.handleSubmit(onFinishTasks)
                            : form.handleSubmit(onFinishProcedure)
                        }
                        onClose={() => doCloseConfirm()}
                        okText={i18n('common.yes')}
                        cancelText={i18n('common.no')}
                      />
                    )}
                  </React.Fragment>
                )}

                {!isTaskEditing &&
                  ['Abierta', 'Rechazada', 'FueraPlazo'].includes(watchEstado) &&
                  currentUser.id !== record?.ejecutor?.id && (
                    <React.Fragment>
                      <Grid container direction="column">
                        {!props.hidePendienteView && (
                          <Grid item>
                            {/* <Box pl={1} pr={2}>
                              <PendienteView
                                drawer={props.drawer}
                                record={taskPreview}
                                dontOpenRecord={true}
                                hideComunicacionInterna={props.hideComunicacionInterna}
                                hideProgressbar={props.hideProgressbar}
                                hideExportButton={true}
                              />
                            </Box> */}

                            {watchModeloTarea?.descripcion && (
                              <Box p={1}>
                                <Box pl={0}>
                                  <Typography variant={'h5'} color="textSecondary">
                                    {i18n('common.description')}
                                  </Typography>
                                </Box>
                                <Box pl={0}>
                                  <Typography variant={'body2'} color="textSecondary">
                                    {watchModeloTarea?.descripcion}
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                          </Grid>
                        )}

                        {/* <ListItemButton
                    onClick={() => setExpanded(!expanded)}
                    style={{ padding: 20 }}
                  >
                    <ListItemIcon>
                      <h2>{form.watch('titulo')}</h2>
                    </ListItemIcon>
                    <ListItemText />
                    {expanded ? (
                      <ExpandMore />
                    ) : (
                      <ExpandLess />
                    )}
                  </ListItemButton> */}

                        {/* <Grid
                    item
                    lg={props.drawer ? 12 : 7}
                    md={8}
                    sm={12}
                    xs={12}
                  >
                    <div style={{ marginBottom: 15 }}>
                      <QRCode
                        value={`${config.frontendUrl.protocol}://${config.frontendUrl.host}/tarea/${props.record?.id}`}
                        size={140}
                      />
                    </div>
                  </Grid> */}

                        <React.Fragment>
                          {(progressEditor || recordHasProgress) && (
                            <Box sx={{ my: 1, px: 1, width: '100%' }}>
                              <TareaReportProgressPage
                                record={props.record}
                                updating
                                expandable
                                noBreadcrumb
                                readOnly={!progressEditor}
                                onSaveSuccess={(id, values) => {
                                  dispatch(actions.setRecord({ ...props.record, ...values }));
                                }}
                              />
                            </Box>
                          )}
                          {watchEstado === 'Rechazada' && (
                            <Grid sx={{ p: 1 }} item lg={12}>
                              <Alert severity="error">
                                <AlertTitle style={{ marginBottom: 10 }}>
                                  {i18n('common.taskHasBeenRejected')}
                                  {record.firmas?.length > 0 ? i18n('common.dueToFollowingReasons') : ''}
                                </AlertTitle>

                                {record.firmas
                                  ?.filter((firma) => firma.nota)
                                  ?.map((firma, index) => (
                                    <Grid key={index} style={{ marginTop: 5 }} container>
                                      {firma.nota}
                                    </Grid>
                                  ))}
                              </Alert>
                            </Grid>
                          )}

                          <Grid container sx={{ p: 1 }}>
                            <Grid item lg={props.drawer ? 12 : 3} md={8} sm={12} xs={12}>
                              <ImagesViewItem label={i18n('entities.tarea.fields.fotosAdjuntas')} value={watchFotosAdjuntas} />
                            </Grid>
                            <Grid item lg={props.drawer ? 12 : 3} md={8} sm={12} xs={12}>
                              <FilesViewItem
                                label={i18n('entities.tarea.fields.documentosAdjuntos')}
                                value={watchDocumentosAdjuntos}
                              />
                            </Grid>
                          </Grid>

                          <Grid spacing={3} container sx={{ p: 1 }}>
                            {/* <Grid item lg={12}>
                        <h2>Respuesta</h2>
                      </Grid> */}
                            {watchClasificacion === 'mantenimiento' && (
                              <Grid item lg={12}>
                                <ActivoListItem value={watchActivo} />
                              </Grid>
                            )}

                            {watchClasificacion === 'mantenimiento' && (
                              <React.Fragment>
                                {watchActivo?.tipoMedicionMantenimiento === 'Horas' && (
                                  <Grid item lg={12} xs={12}>
                                    <Grid item sm={6} xs={12}>
                                      <label
                                        style={{
                                          fontWeight: 'bold',
                                          fontSize: 20,
                                        }}>
                                        {i18n('common.addedHourmeter')}:{' '}
                                        {new Intl.NumberFormat('es-CL', {
                                          currency: 'CLP',
                                          style: 'decimal',
                                        }).format(watchHorometro || 0)}
                                      </label>
                                    </Grid>
                                    {/* fecha horometro */}
                                    <Grid item sm={6} xs={12}>
                                      <label
                                        style={{
                                          fontWeight: 'bold',
                                          fontSize: 20,
                                        }}>
                                        {i18n('common.hourmeterDate')}:{' '}
                                        {watchFechaHorometro ? moment(watchFechaHorometro).format('DD-MM-YYYY') : ''}
                                      </label>
                                    </Grid>
                                  </Grid>
                                )}

                                {watchActivo?.tipoMedicionMantenimiento === 'Kilometros' && (
                                  <Grid item lg={12} xs={12}>
                                    <Grid item lg={props.drawer ? 12 : 5} md={8} sm={12} xs={12}>
                                      <label
                                        style={{
                                          fontWeight: 'bold',
                                          fontSize: 20,
                                        }}>
                                        {i18n('common.addedMileage')}:{' '}
                                        {new Intl.NumberFormat('es-CL', {
                                          currency: 'CLP',
                                          style: 'decimal',
                                        }).format(watchKilometraje || 0)}
                                      </label>
                                    </Grid>
                                  </Grid>
                                )}
                              </React.Fragment>
                            )}

                            {watchTipoVisualizacion === 'Procedimiento' && (
                              <Grid item lg={props.drawer ? 12 : 6} xs={12}>
                                {watchListaProcedimiento.length > 0 && (
                                  <PrincipalFormularioForm
                                    record={record}
                                    blankFormList={blankFormList}
                                    onSave={onSave}
                                    editar={false}
                                    esRespuesta={true}
                                    name="listaProcedimiento"
                                    customButton={props.principalFormButton}
                                  />
                                )}
                              </Grid>
                            )}

                            {watchTipoVisualizacion === 'Pasos' && (
                              <Grid item lg={props.drawer ? 12 : 6}>
                                <PasosTareaForm record={record} readOnly={true} name="respuestaInterfaz" />
                              </Grid>
                            )}

                            {watchTipoVisualizacion === 'Checklist' && (
                              <Grid item lg={props.drawer ? 12 : 6}>
                                <CheckListTareaForm record={record} readOnly={true} name="respuestaInterfaz" />
                              </Grid>
                            )}

                            {watchTipoVisualizacion === 'Estandar' && (
                              <Grid item lg={props.drawer ? 12 : 6} mt={2}>
                                <Grid container direction="column" gap={2}>
                                  <Box>
                                    <Typography variant="h4" pb={1}>
                                      Comentario Enviado
                                    </Typography>
                                    <Typography variant="subtitle1" component="pre" whiteSpace="pre-line">
                                      {watchComentarioEnviado}
                                    </Typography>
                                    {!watchComentarioEnviado && (
                                      <Alert severity="info">
                                        <AlertTitle>Sin Información</AlertTitle>
                                        No se encontraron datos
                                      </Alert>
                                    )}
                                  </Box>
                                  <Box>
                                    <Typography variant="h4" pb={1}>
                                      Documentos Enviados
                                    </Typography>
                                    {watchDocumentosEnviados?.length === 0 && (
                                      <Alert severity="info">
                                        <AlertTitle>Sin Información</AlertTitle>
                                        No se encontraron datos
                                      </Alert>
                                    )}
                                    <FilesViewItem
                                      label={i18n('entities.tarea.fields.documentosEnviados')}
                                      value={watchDocumentosEnviados}
                                    />
                                  </Box>
                                  <Box>
                                    <Typography variant="h4" pb={1}>
                                      Fotos Enviadas
                                    </Typography>
                                    {watchFotosEnviadas?.length === 0 && (
                                      <Alert severity="info">
                                        <AlertTitle>Sin Información</AlertTitle>
                                        No se encontraron datos
                                      </Alert>
                                    )}
                                    <ImagesViewItem
                                      label={i18n('entities.tarea.fields.fotosEnviadas')}
                                      value={watchFotosEnviadas}
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </React.Fragment>
                      </Grid>
                    </React.Fragment>
                  )}

                {!isTaskEditing && ['Cerrada', 'PendienteRevision', 'EnRevision'].includes(watchEstado) && (
                  <React.Fragment>
                    <Grid container direction="column">
                      {!props.hidePendienteView && (
                        <Grid item>
                          {/* <Box pl={1} pr={2}>
                            <PendienteView
                              drawer={props.drawer}
                              record={taskPreview}
                              dontOpenRecord={true}
                              hideComunicacionInterna={props.hideComunicacionInterna}
                              hideProgressbar={props.hideProgressbar}
                              hideExportButton={true}
                            />
                          </Box> */}
                          {watchModeloTarea?.descripcion && (
                            <Box p={1}>
                              <Box pl={0}>
                                <Typography variant={'h5'} color="textSecondary">
                                  {i18n('common.description')}
                                </Typography>
                              </Box>
                              <Box pl={0}>
                                <Typography variant={'body2'} color="textSecondary">
                                  {watchModeloTarea?.descripcion}
                                </Typography>
                              </Box>
                            </Box>
                          )}
                        </Grid>
                      )}

                      <React.Fragment>
                        {(progressEditor || recordHasProgress) && (
                          <Box sx={{ my: 1, px: 1, width: '100%' }}>
                            <TareaReportProgressPage
                              record={props.record}
                              updating
                              expandable
                              noBreadcrumb
                              readOnly={true}
                              onSaveSuccess={(id, values) => {
                                dispatch(actions.setRecord({ ...props.record, ...values }));
                              }}
                            />
                          </Box>
                        )}
                        {watchEstado === 'Rechazada' && (
                          <Grid sx={{ p: 1 }} item lg={12}>
                            <Alert severity="error">
                              <AlertTitle style={{ marginBottom: 10 }}>
                                {i18n('common.taskHasBeenRejected')}
                                {record.firmas?.length > 0 ? i18n('common.dueToFollowingReasons') : ''}
                              </AlertTitle>

                              {record.firmas
                                ?.filter((firma) => firma.nota)
                                ?.map((firma, index) => (
                                  <Grid key={index} style={{ marginTop: 5 }} container>
                                    {firma.nota}
                                  </Grid>
                                ))}
                            </Alert>
                          </Grid>
                        )}

                        {!!(watchFotosAdjuntas?.length || watchDocumentosAdjuntos?.length) && (
                          <Grid container sx={{ p: 1 }}>
                            <Grid item lg={props.drawer ? 12 : 3} md={8} sm={12} xs={12}>
                              <ImagesViewItem label={i18n('entities.tarea.fields.fotosAdjuntas')} value={watchFotosAdjuntas} />
                            </Grid>
                            <Grid item lg={props.drawer ? 12 : 3} md={8} sm={12} xs={12}>
                              <FilesViewItem
                                label={i18n('entities.tarea.fields.documentosAdjuntos')}
                                value={watchDocumentosAdjuntos}
                              />
                            </Grid>
                          </Grid>
                        )}

                        <Grid spacing={3} container sx={{ p: 1 }}>
                          {watchClasificacion === 'mantenimiento' && (
                            <Grid item lg={12}>
                              <ActivoListItem value={watchActivo} />
                            </Grid>
                          )}
                          {watchClasificacion === 'mantenimiento' && (
                            <React.Fragment>
                              {watchActivo?.tipoMedicionMantenimiento === 'Horas' && (
                                <Grid item lg={12} xs={12}>
                                  <Grid item sm={6} xs={12}>
                                    <label
                                      style={{
                                        fontWeight: 'bold',
                                        fontSize: 20,
                                      }}>
                                      {i18n('common.addedHourmeter')}:{' '}
                                      {new Intl.NumberFormat('es-CL', {
                                        currency: 'CLP',
                                        style: 'decimal',
                                      }).format(watchHorometro || 0)}
                                    </label>
                                  </Grid>
                                  {/* fecha horometro */}
                                  <Grid item sm={6} xs={12}>
                                    <label
                                      style={{
                                        fontWeight: 'bold',
                                        fontSize: 20,
                                      }}>
                                      {i18n('common.hourmeterDate')}:{' '}
                                      {watchFechaHorometro ? moment(watchFechaHorometro).format('DD-MM-YYYY') : ''}
                                    </label>
                                  </Grid>
                                </Grid>
                              )}

                              {watchActivo?.tipoMedicionMantenimiento === 'Kilometros' && (
                                <Grid item lg={12} xs={12}>
                                  <Grid item lg={props.drawer ? 12 : 5} md={8} sm={12} xs={12}>
                                    <label
                                      style={{
                                        fontWeight: 'bold',
                                        fontSize: 20,
                                      }}>
                                      {i18n('common.addedMileage')}:{' '}
                                      {new Intl.NumberFormat('es-CL', {
                                        currency: 'CLP',
                                        style: 'decimal',
                                      }).format(watchKilometraje || 0)}
                                    </label>
                                  </Grid>
                                </Grid>
                              )}
                            </React.Fragment>
                          )}

                          {watchTipoVisualizacion === 'Procedimiento' && (
                            <Grid item lg={props.drawer ? 12 : 6} xs={12}>
                              {watchListaProcedimiento.length > 0 && (
                                <PrincipalFormularioForm
                                  record={record}
                                  blankFormList={blankFormList}
                                  onSave={onSave}
                                  editar={false}
                                  esRespuesta={true}
                                  name="listaProcedimiento"
                                  customButton={props.principalFormButton}
                                />
                              )}
                            </Grid>
                          )}

                          {watchTipoVisualizacion === 'Pasos' && (
                            <Grid item lg={props.drawer ? 12 : 6}>
                              <PasosTareaForm record={record} readOnly={true} name="respuestaInterfaz" />
                            </Grid>
                          )}

                          {watchTipoVisualizacion === 'Checklist' && (
                            <Grid item lg={props.drawer ? 12 : 6}>
                              <CheckListTareaForm record={record} readOnly={true} name="respuestaInterfaz" />
                            </Grid>
                          )}

                          {watchTipoVisualizacion === 'Estandar' && (
                            <Grid item lg={props.drawer ? 12 : 6} mt={2}>
                              <Grid container direction="column" gap={2}>
                                <Box>
                                  <Typography variant="h4" pb={1}>
                                    {i18n('common.sentComment')}
                                  </Typography>
                                  <Typography variant="subtitle1" component="pre" whiteSpace="pre-line">
                                    {watchComentarioEnviado}
                                  </Typography>
                                  {!watchComentarioEnviado && (
                                    <Alert severity="info">
                                      <AlertTitle>{i18n('common.noInformation')}</AlertTitle>
                                      {i18n('common.noDataFound')}
                                    </Alert>
                                  )}
                                </Box>
                                <Box>
                                  <Typography variant="h4" pb={1}>
                                    {i18n('common.sentDocuments')}
                                  </Typography>
                                  {watchDocumentosEnviados?.length === 0 && (
                                    <Alert severity="info">
                                      <AlertTitle>{i18n('common.noInformation')}</AlertTitle>
                                      {i18n('common.noDataFound')}
                                    </Alert>
                                  )}
                                  <FilesViewItem
                                    label={i18n('entities.tarea.fields.documentosEnviados')}
                                    value={watchDocumentosEnviados}
                                  />
                                </Box>
                                <Box>
                                  <Typography variant="h4" pb={1}>
                                    {i18n('common.sentPhotos')}
                                  </Typography>
                                  {watchFotosEnviadas?.length === 0 && (
                                    <Alert severity="info">
                                      <AlertTitle>{i18n('common.noInformation')}</AlertTitle>
                                      {i18n('common.noDataFound')}
                                    </Alert>
                                  )}
                                  <ImagesViewItem
                                    label={i18n('entities.tarea.fields.fotosEnviadas')}
                                    value={watchFotosEnviadas}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          )}

                          <Grid style={{ marginBottom: 30 }} item lg={props.drawer ? 12 : 6}>
                            {record?.firmas
                              ?.filter((raw) => raw.responsable === currentUser.id)
                              ?.map((firma) => (
                                <Grid style={{ marginTop: 5 }} container>
                                  <Grid xs={12} lg={12}>
                                    <h2>
                                      {i18n('common.signs')} {currentUser.fullName}
                                    </h2>
                                    <hr />
                                  </Grid>
                                  <Grid style={{ marginTop: 15 }}>
                                    <FirmaListItem value={firma} />
                                  </Grid>
                                </Grid>
                              ))}
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    </Grid>
                  </React.Fragment>
                )}

                {/* tabla de detenciones */}
                {watchClasificacion === 'mantenimiento' &&
                  watchActivo?.tipoMedicionMantenimiento === 'Horas' &&
                  justificarHoras > 0 && (
                    <Grid item lg={props.drawer ? 12 : 6} style={{ paddingBottom: 20 }}>
                      <h2
                        style={{
                          marginBottom: 20,
                        }}>
                        {' '}
                        {i18n('common.detentions')}{' '}
                        {puedeResponder && (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{
                              marginLeft: 20,
                            }}
                            onClick={() => {
                              appendDetencion({
                                horas: null,
                              });
                            }}
                            startIcon={<Add />}>
                            {i18n('common.add')}
                          </Button>
                        )}
                        <hr></hr>
                      </h2>
                      {!detenciones.length && !puedeResponder && (
                        <Alert severity="info">
                          <AlertTitle>{i18n('common.noInformation')}</AlertTitle>
                          {i18n('common.noDataFound')}
                        </Alert>
                      )}
                      {detenciones.length > 0 && (
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table" id="detencionesTable">
                            <TableHead></TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell colSpan={4}>
                                  <label
                                    style={{
                                      fontWeight: 'bold',
                                      fontSize: 20,
                                    }}>
                                    {i18n('common.hoursPendingOfExplanation')}:{' '}
                                    <span
                                      style={{
                                        color: horasPorJustificar > 0 ? 'red' : 'green',
                                      }}>
                                      {horasPorJustificar}
                                    </span>
                                  </label>
                                  {/* {!horasPorJustificar && <CheckIcon style={{marginTop:-5, color:'green'}}/>} */}
                                </TableCell>
                              </TableRow>
                              {detenciones?.map((row, index) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    <DatePickerFormItem
                                      disabled={!puedeResponder}
                                      timeOnly
                                      name={`detenciones[${index}].hora`}
                                      label={i18n('common.detentionTime')}
                                      required={false}
                                      showTime={false}
                                      autoFocus
                                      defaultValue={row.hora || defaultFechaHorometro}
                                      onChange={(value) => {
                                        // combine fechaHorometro with hora
                                        const fechaHorometro = form.getValues('fechaHorometro');
                                        if (fechaHorometro) {
                                          //console.log('%c⧭ onChange hora', 'color: #d90000', {value});
                                          value = moment(value);
                                          const fechaHorometroWithHora = moment(fechaHorometro).set({
                                            hour: value?.get('hour'),
                                            minute: value?.get('minute'),
                                            second: value?.get('second'),
                                          });
                                          // console.log('%c⧭', 'color: #ffa640', {
                                          //   fechaHorometroWithHora: fechaHorometroWithHora.format('YYYY-MM-DD HH:mm:ss'),
                                          // });
                                          form.setValue(`detenciones[${index}].hora`, fechaHorometroWithHora);
                                        }
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <DescRegistroAutocompleteFormItem
                                      disabled={!puedeResponder}
                                      name={`detenciones[${index}].descRegistro`}
                                      label={i18n('common.cause')}
                                      tipo="Detención"
                                      required={false}
                                      showCreate={false}
                                      minWidth={200}
                                      onChange={(value) => {
                                        form.setValue(`detenciones[${index}].descripcion`, value?.descripcion);
                                        if (value?.tipo) form.setValue(`detenciones[${index}].tipo`, value.tipo);
                                        if (value?.prioridad) form.setValue(`detenciones[${index}].prioridad`, value.prioridad);
                                        if (value?.afectaDisponibilidad)
                                          form.setValue(`detenciones[${index}].afectaDisponibilidad`, value.afectaDisponibilidad);
                                      }}
                                      menuPortalTarget={document.body}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <InputNumberFormItem
                                      disabled={!puedeResponder}
                                      name={`detenciones[${index}].horas`}
                                      label={i18n('common.detentionTime')}
                                      required={false}
                                      disableFormat={true}
                                      max={watchActivo?.cantidadHorasDisponibilidadDia || 11}
                                      min={0.25}
                                      step={0.25}
                                      defaultValue={null}
                                      style={{ minWidth: '115px' }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Tooltip followCursor title={form.watch(`detenciones[${index}].comentario`, '')}>
                                      <div>
                                        <InputFormItem
                                          disabled={!puedeResponder}
                                          name={`detenciones[${index}].comentario`}
                                          label={i18n('common.comment')}
                                          required={false}
                                          maxLength={200}
                                        />
                                      </div>
                                    </Tooltip>
                                  </TableCell>
                                  {puedeResponder && (
                                    <TableCell>
                                      <IconButton
                                        onClick={() => {
                                          removeDetencion(index);
                                        }}>
                                        <Delete />
                                      </IconButton>
                                    </TableCell>
                                  )}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </Grid>
                  )}
              </form>
            </FormProvider>
          </FormWrapper>
        </Box>
        {firmasListas?.length > 0 && (
          <Box style={{ width: '100%' }} pl={2} pr={3} pb={4} mt={-6}>
            <Box sx={{ mx: 3, mb: 5 }}>
              <Typography variant="h3" sx={{ textAlign: 'center', p: 1, borderBottom: '1px solid lightgray' }}>
                {i18n('common.validationSignatures')}
              </Typography>
            </Box>
            <Grid container direction="row" gap={4} justifyContent="center">
              {firmasListas?.map((firma) => (
                <Grid item textAlign="center">
                  <Box sx={{ borderBottom: '1px solid gray', width: 'min-content', mb: 1 }}>
                    {firma.firma && (
                      <img
                        alt={'signature'}
                        style={{
                          width: 'auto',
                          height: 100,
                        }}
                        src={firma.firma}
                      />
                    )}
                  </Box>
                  <Typography variant="body2" align="center">
                    {firma.responsable?.fullName || 'Nombre no registrado'}
                  </Typography>
                  <Typography variant="caption" align="center">
                    {firma.responsable?.puesto?.nombre || ''}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
        {prePdfModalVisible && (
          <TareaPrePdfModal
            task={record}
            onChange={onChangePrePdfModal}
            onSuccess={onSuccessPrePdfModal}
            onCloseReady={onCloseReadyPrePdfModal}
            onCancel={onAbortPrePdfModal}
          />
        )}
      </ContentWrapper>
      {/* botones */}
      <Grid
        container
        spacing={3}
        style={{
          bottom: '5px',
        }}>
        {!isTaskEditing && ((puedeResponder && showStartButton) || !puedeResponder) && (
          <Grid item lg={6} xs={6}>
            {/* necesito el espacio que ocupa esto */}
          </Grid>
        )}
        {isTaskEditing && !showStartButton && (
          <Grid item lg={6} xs={6}>
            <Button
              style={{ width: '100%', ...customButtonStyle }}
              variant="contained"
              color="secondary"
              disabled={saveLoading}
              type="button"
              onClick={() => {
                onSave({ ...form.getValues(), updating: true });
              }}
              startIcon={<SaveOutlined />}
              size="large">
              {i18n('entities.tarea.editClosed.title')}
            </Button>
          </Grid>
        )}
        {!isTaskEditing && puedeResponder && !showStartButton && (
          <>
            <Grid item lg={6} xs={6}>
              <Button
                style={{ width: '100%', ...customButtonStyle }}
                variant="contained"
                color="primary"
                disabled={saveLoading}
                type="button"
                onClick={() => doConfirm()}
                startIcon={<SendOutlined />}
                size="large">
                {props.sendTareaText || i18n('common.sendTarea')}
              </Button>
            </Grid>
            {!props.hideGuardarBorrador && (
              <Grid item lg={6} xs={6}>
                <Button
                  style={{ width: '100%', ...customButtonStyle }}
                  variant="contained"
                  color="secondary"
                  disabled={saveLoading}
                  type="button"
                  onClick={() => {
                    onSave({ ...form.getValues(), updating: true });
                  }}
                  startIcon={<SaveOutlined />}
                  size="large">
                  {i18n('common.draft')}
                </Button>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  );
}

export default TareaForm;
