import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.presupuesto.fields.id'),
  },
  {
    name: 'descripcion',
    label: i18n('entities.presupuesto.fields.descripcion'),
  },
  {
    name: 'tipoPresupuesto',
    label: i18n('entities.presupuesto.fields.tipoPresupuesto'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'montoPresupuesto',
    label: i18n('entities.presupuesto.fields.montoPresupuesto'),
  },
  {
    name: 'cuentasContables',
    label: i18n('entities.presupuesto.fields.cuentasContables'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'fechainicio',
    label: i18n('entities.presupuesto.fields.fechainicio'),
  },
  {
    name: 'fechaFin',
    label: i18n('entities.presupuesto.fields.fechaFin'),
  },
  {
    name: 'habilitado',
    label: i18n('entities.presupuesto.fields.habilitado'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'centrosCostosVinculados',
    label: i18n('entities.presupuesto.fields.centrosCostosVinculados'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.presupuesto.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.presupuesto.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
