const codificacionISOEnumerators = {
  reporte: [
    'OTs',
    'Catálogo de Activos',
    'Orden de Compra',
    'Plan de Tareas',
  ],
};

export default codificacionISOEnumerators;
