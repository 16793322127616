import list from 'src/modules/servicio/list/servicioListReducers';
import form from 'src/modules/servicio/form/servicioFormReducers';
import view from 'src/modules/servicio/view/servicioViewReducers';
import destroy from 'src/modules/servicio/destroy/servicioDestroyReducers';
import importerReducer from 'src/modules/servicio/importer/servicioImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
