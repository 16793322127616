import listActions from 'src/modules/tipoCentroCosto/list/tipoCentroCostoListActions';
import TipoCentroCostoService from 'src/modules/tipoCentroCosto/tipoCentroCostoService';
import Errors from 'src/modules/shared/error/errors';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'TIPOCENTROCOSTO_DESTROY';

const tipoCentroCostoDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy: (id) => async (dispatch) => {
    try {
      dispatch({
        type: tipoCentroCostoDestroyActions.DESTROY_STARTED,
      });

      await TipoCentroCostoService.destroyAll([id]);

      dispatch({
        type: tipoCentroCostoDestroyActions.DESTROY_SUCCESS,
      });

      Message.success(
        i18n('entities.tipoCentroCosto.destroy.success'),
      );

      dispatch(listActions.doFetchCurrentFilter());

      getHistory().push('/tipo-centro-costo');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: tipoCentroCostoDestroyActions.DESTROY_ERROR,
      });
    }
  },

  doDestroyAll: (ids) => async (dispatch) => {
    try {
      dispatch({
        type: tipoCentroCostoDestroyActions.DESTROY_ALL_STARTED,
      });

      await TipoCentroCostoService.destroyAll(ids);

      dispatch({
        type: tipoCentroCostoDestroyActions.DESTROY_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doClearAllSelected());
        dispatch(listActions.doFetchCurrentFilter());
      }

      Message.success(
        i18n('entities.tipoCentroCosto.destroyAll.success'),
      );

      getHistory().push('/tipo-centro-costo');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: tipoCentroCostoDestroyActions.DESTROY_ALL_ERROR,
      });
    }
  },
};

export default tipoCentroCostoDestroyActions;
