import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/tercero/importer/terceroImporterSelectors';
import TerceroService from 'src/modules/tercero/terceroService';
import fields from 'src/modules/tercero/importer/terceroImporterFields';
import { i18n } from 'src/i18n';

const terceroImporterActions = importerActions(
  'TERCERO_IMPORTER',
  selectors,
  TerceroService.import,
  fields,
  i18n('entities.tercero.importer.fileName'),
);

export default terceroImporterActions;