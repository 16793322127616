import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/producto/importer/productoImporterSelectors';
import ProductoService from 'src/modules/producto/productoService';
import fields from 'src/modules/producto/importer/productoImporterFields';
import { i18n } from 'src/i18n';

const productoImporterActions = importerActions(
  'PRODUCTO_IMPORTER',
  selectors,
  ProductoService.import,
  fields,
  i18n('entities.producto.importer.fileName'),
);

export default productoImporterActions;