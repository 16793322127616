import {TextField} from '@mui/material';
import {isInteger} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import FormErrors from 'src/view/shared/form/formErrors';
import {DisabledText} from './shared/formItemWrappers';

export function InputNumberFormItem(props) {
  const {
    label,
    name,
    hint,
    type,
    min,
    max,
    step,
    placeholder,
    autoFocus,
    autoComplete,
    required,
    externalErrorMessage,
    defaultValue,
    size,
    disabled,
    disableFormat,
    onChange,
    onBlur,
    style,
  } = props;

  const {
    register,
    formState: {touchedFields, isSubmitted, errors},
    control,
  } = useFormContext();

  const errorMessage = FormErrors.errorMessage(name, errors, touchedFields, isSubmitted, externalErrorMessage);
  const watchName: any = useWatch({name, control});

  if (disabled) {
    return (
      <DisabledText
        label={label}
        value={
          isInteger(watchName)
            ? new Intl.NumberFormat('es-CL', {
                currency: 'CLP',
                style: 'decimal',
              }).format(watchName || 0)
            : watchName
        }
      />
    );
  }

  const inputRef: any = register(name, {onChange: (event) => onChange && onChange(event.target.value), onBlur});

  return (
    <TextField
      {...inputRef}
      inputRef={inputRef.ref}
      inputProps={{
        inputMode: 'numeric',
        pattern: '[0-9]*',
      }}
      style={style}
      disabled={disabled}
      defaultValue={defaultValue}
      id={name}
      name={name}
      type={type}
      label={label}
      required={required}
      margin={props.margin || 'normal'}
      fullWidth
      variant="outlined"
      size={'small' || size}
      placeholder={placeholder || undefined}
      autoFocus={autoFocus || undefined}
      autoComplete={autoComplete || undefined}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        inputProps: {
          min,
          max,
          step,
        },
      }}
      error={Boolean(errorMessage)}
      helperText={
        //|| !disableFormat
        errorMessage || hint
          ? new Intl.NumberFormat('es-CL', {
              currency: 'CLP',
              style: 'decimal',
            }).format(watchName || 0)
          : null
      }
      onWheel={(e: any) => {
        if (type === 'number') e.target.blur(); // o prevent default para impedir que scrolee
      }}
    />
  );
}

InputNumberFormItem.defaultProps = {
  type: 'number',
  required: false,
};

InputNumberFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  label: PropTypes.string,
  size: PropTypes.string,
  hint: PropTypes.string,
  autoFocus: PropTypes.bool,
  prefix: PropTypes.string,
  placeholder: PropTypes.string,
  externalErrorMessage: PropTypes.string,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  disableFormat: PropTypes.bool,
  style: PropTypes.object,
};

export default InputNumberFormItem;
