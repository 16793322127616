import {yupResolver} from '@hookform/resolvers/yup';
import {SaveOutlined} from '@mui/icons-material';
import {Button, Grid, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import QRCode from 'react-qr-code';
import config from 'src/config';
import {i18n} from 'src/i18n';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import ubicacionEnumerators from 'src/modules/ubicacion/ubicacionEnumerators';
import Storage from 'src/security/storage';
import {customButtonStyle, magicFlexBox} from 'src/theme/schemes/CustomTheme';
import EmpresaAutocompleteFormItem from 'src/view/empresa/autocomplete/EmpresaAutocompleteFormItem';
import PresupuestoAutocompleteFormItem from 'src/view/presupuesto/autocomplete/PresupuestoAutocompleteFormItem';
import FilesFormItem from 'src/view/shared/form/items/FilesFormItem';
import GoogleAutocompleteFormItem from 'src/view/shared/form/items/GoogleAutocompleteFormItem';
import ImagesFormItem from 'src/view/shared/form/items/ImagesFormItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import InputNumberFormItem from 'src/view/shared/form/items/InputNumberFormItem';
import SelectFormItem from 'src/view/shared/form/items/SelectFormItem';
import SwitchFormItem from 'src/view/shared/form/items/SwitchFormItem';
import FormWrapper, {FormButtons} from 'src/view/shared/styles/FormWrapper';
import UbicacionAutocompleteFormItem from 'src/view/ubicacion/autocomplete/UbicacionAutocompleteFormItem';
import UserAutocompleteFormItem from 'src/view/user/autocomplete/UserAutocompleteFormItem';
import * as yup from 'yup';

const schema = yup.object().shape({
  presupuesto: yupFormSchemas.relationToOne(i18n('entities.activo.fields.presupuesto'), {}),
  foto: yupFormSchemas.images(i18n('entities.ubicacion.fields.foto'), {
    max: 3,
    required: false,
  }),
  nombre: yupFormSchemas.string(i18n('entities.ubicacion.fields.nombre'), {
    required: true,
  }),
  clasificacion: yupFormSchemas.enumerator(i18n('entities.ubicacion.fields.clasificacion'), {
    required: true,
    options: ubicacionEnumerators.clasificacion,
  }),
  ubicacionAsociada: yupFormSchemas.relationToOne(i18n('entities.ubicacion.fields.ubicacionAsociada'), {}),
  ubicacionGoogle: yupFormSchemas.string(i18n('entities.ubicacion.fields.ubicacionGoogle'), {
    required: false,
  }),
  latitud: yupFormSchemas.decimal(i18n('entities.ubicacion.fields.latitud'), {
    required: false,
  }),
  longitud: yupFormSchemas.decimal(i18n('entities.ubicacion.fields.longitud'), {
    required: false,
  }),
  responsables: yupFormSchemas.relationToMany(i18n('entities.ubicacion.fields.responsables'), {
    required: true,
  }),
  capacidad: yupFormSchemas.decimal(i18n('entities.ubicacion.fields.capacidad'), {}),
  documentos: yupFormSchemas.files(i18n('entities.ubicacion.fields.documentos'), {}),
  estado: yupFormSchemas.enumerator(i18n('entities.ubicacion.fields.estado'), {
    options: ubicacionEnumerators.estado,
  }),
  correoContacto: yupFormSchemas.string(i18n('entities.ubicacion.fields.correoContacto'), {}),
  telefonoContacto: yupFormSchemas.integer(i18n('entities.ubicacion.fields.telefonoContacto'), {}),
  propiedad: yupFormSchemas.boolean(i18n('entities.ubicacion.fields.propiedad'), {}),
  empresaExterna: yupFormSchemas.relationToOne(i18n('entities.ubicacion.fields.empresaExterna'), {}),
  visibilidad: yupFormSchemas.enumerator(i18n('entities.ubicacion.fields.visibilidad'), {
    options: ubicacionEnumerators.visibilidad,
  }),
  parentLabel: yupFormSchemas.string(i18n('entities.ubicacion.fields.parentLabel'), {}),
  label: yupFormSchemas.string(i18n('entities.ubicacion.fields.label'), {}),
});

function UbicacionForm(props) {
  const [initialValues] = useState(() => {
    const record = props.record || {};

    return {
      presupuesto: record.presupuesto,
      foto: record.foto || [],
      nombre: record.nombre,
      clasificacion: record.clasificacion,
      ubicacionAsociada: record.ubicacionAsociada,
      ubicacionGoogle: record.ubicacionGoogle,
      latitud: record.latitud,
      longitud: record.longitud,
      responsables: record.responsables,
      capacidad: record.capacidad,
      documentos: record.documentos || [],
      estado: record.estado,
      correoContacto: record.correoContacto,
      telefonoContacto: record.telefonoContacto,
      propiedad: record.propiedad,
      empresaExterna: record.empresaExterna,
      visibilidad: record.visibilidad,
      parentLabel: record.parentLabel,
      label: record.label,
    };
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
  });

  const onSubmit = (values) => {
    props.onSubmit(props.record?.id, values);
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  const nombre = form.watch('nombre');
  const parentLabel = form.watch('parentLabel');
  useEffect(() => {
    // nombre changed
    let newLabel = nombre;
    if (parentLabel && !nombre.startsWith(parentLabel)) {
      // if nombre does not start with parentLabel
      newLabel = parentLabel + '\\ ' + nombre;
    }
    form.setValue('label', newLabel);
    console.log('newLabel', newLabel);
  }, [nombre, parentLabel]);

  const {saveLoading, modal} = props;

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          style={
            {
              ...magicFlexBox,
              paddingBottom: 18,
              paddingRight: 18,
              overflow: 'auto',
            } as any
          }>
          <Grid spacing={2} container>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <Typography variant="h1" sx={{mb: 2}}>
                {nombre}
              </Typography>

              <div style={{marginBottom: 15}}>
                <QRCode
                  value={`${config.frontendUrl.protocol}://${config.frontendUrl.host}/ubicacion/${props.record?.id}`}
                  size={140}
                />
              </div>
            </Grid>

            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <ImagesFormItem
                name="foto"
                disabled={props.disabled}
                label={i18n('entities.ubicacion.fields.foto')}
                required={false}
                storage={Storage.values.ubicacionFoto}
                max={3}
              />
            </Grid>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <InputFormItem
                name="nombre"
                disabled={props.disabled}
                label={i18n('entities.ubicacion.fields.nombre')}
                required={true}
              />
            </Grid>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <SelectFormItem
                name="clasificacion"
                disabled={props.disabled}
                label={i18n('entities.ubicacion.fields.clasificacion')}
                options={ubicacionEnumerators.clasificacion.map((value) => ({
                  value,
                  label: i18n(`entities.ubicacion.enumerators.clasificacion.${value}`),
                }))}
                required={true}
              />
            </Grid>

            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <PresupuestoAutocompleteFormItem
                name="presupuesto"
                disabled={props.disabled}
                label={i18n('entities.ubicacion.fields.presupuesto')}
                required={false}
                showCreate={!props.modal}
              />
            </Grid>

            {form.watch('clasificacion') === 'Area' && (
              <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                <UbicacionAutocompleteFormItem
                  name="ubicacionAsociada"
                  disabled={props.disabled}
                  label={
                    i18n('entities.ubicacion.fields.ubicacionAsociada') +
                    ' a ' +
                    i18n(`entities.ubicacion.enumerators.clasificacion.${form.watch('clasificacion')}`)
                  }
                  onChange={(newUbicacionAsociada) => {
                    console.log('value changed ubicacionAsociada', newUbicacionAsociada);
                    form.setValue('parentLabel', newUbicacionAsociada?.label);
                  }}
                  required={false}
                  showCreate={!props.modal}
                />
              </Grid>
            )}

            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <GoogleAutocompleteFormItem
                name="ubicacionGoogle"
                disabled={props.disabled}
                label={i18n('entities.ubicacion.fields.ubicacionGoogle')}
                required={false}
                defaultCenter={{
                  lat: -36.8269882,
                  lng: -73.0497665,
                }}
                center={{
                  lat: form.watch('latitud'),
                  lng: form.watch('longitud'),
                }}
                zoom={14}
                enableMap={true}
              />
            </Grid>

            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <UserAutocompleteFormItem
                name="responsables"
                disabled={props.disabled}
                label={i18n('entities.ubicacion.fields.responsables')}
                required={true}
                mode="multiple"
                showCreate={!props.modal}
              />
            </Grid>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <InputFormItem
                name="capacidad"
                disabled={props.disabled}
                label={i18n('entities.ubicacion.fields.capacidad')}
                required={false}
              />
            </Grid>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <FilesFormItem
                name="documentos"
                disabled={props.disabled}
                label={i18n('entities.ubicacion.fields.documentos')}
                required={false}
                storage={Storage.values.ubicacionDocumentos}
                max={undefined}
                formats={undefined}
              />
            </Grid>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <SelectFormItem
                name="estado"
                disabled={props.disabled}
                label={i18n('entities.ubicacion.fields.estado')}
                options={ubicacionEnumerators.estado.map((value) => ({
                  value,
                  label: i18n(`entities.ubicacion.enumerators.estado.${value}`),
                }))}
                required={false}
              />
            </Grid>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <InputFormItem
                name="correoContacto"
                disabled={props.disabled}
                label={i18n('entities.ubicacion.fields.correoContacto')}
                required={false}
              />
            </Grid>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <InputNumberFormItem
                name="telefonoContacto"
                disabled={props.disabled}
                label={i18n('entities.ubicacion.fields.telefonoContacto')}
                required={false}
              />
            </Grid>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <SwitchFormItem name="propiedad" disabled={props.disabled} label={i18n('entities.ubicacion.fields.propiedad')} />
            </Grid>

            {form.watch('propiedad') && (
              <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                <EmpresaAutocompleteFormItem
                  name="empresaExterna"
                  disabled={props.disabled}
                  label={i18n('entities.ubicacion.fields.empresaExterna')}
                  required={false}
                  showCreate={!props.modal}
                />
              </Grid>
            )}

            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <SelectFormItem
                name="visibilidad"
                disabled={props.disabled}
                label={i18n('entities.ubicacion.fields.visibilidad')}
                options={ubicacionEnumerators.visibilidad.map((value) => ({
                  value,
                  label: i18n(`entities.ubicacion.enumerators.visibilidad.${value}`),
                }))}
                required={false}
              />
            </Grid>
          </Grid>
        </form>
        {!props.disabled && (
          <FormButtons
            style={{
              flexDirection: modal ? 'row-reverse' : undefined,
            }}>
            <Button
              style={{
                width: props.drawer ? '100%' : 300,
                ...customButtonStyle,
                marginTop: '12px',
              }}
              variant="contained"
              color="secondary"
              disabled={saveLoading}
              type="button"
              onClick={form.handleSubmit(onSubmit)}
              startIcon={<SaveOutlined />}
              size="large">
              {i18n('common.save')}
            </Button>

            {/* <Button
              disabled={saveLoading}
              onClick={onReset}
              type="button"
              startIcon={<UndoIcon />}
              size="small"
            >
              {i18n('common.reset')}
            </Button>

            {props.onCancel ? (
              <Button
                disabled={saveLoading}
                onClick={() => props.onCancel()}
                type="button"
                startIcon={<CloseIcon />}
                size="small"
              >
                {i18n('common.cancel')}
              </Button>
            ) : null} */}
          </FormButtons>
        )}
      </FormProvider>
    </FormWrapper>
  );
}

export default UbicacionForm;
