import {yupResolver} from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import {Button, Grid} from '@mui/material';
import React, {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {i18n} from 'src/i18n';
import empresaEnumerators from 'src/modules/empresa/empresaEnumerators';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import Storage from 'src/security/storage';
import MaterialAutocompleteFormItem from 'src/view/material/autocomplete/MaterialAutocompleteFormItem';
import RubroAutocompleteFormItem from 'src/view/rubro/autocomplete/RubroAutocompleteFormItem';
import FilesFormItem from 'src/view/shared/form/items/FilesFormItem';
import GoogleAutocompleteFormItem from 'src/view/shared/form/items/GoogleAutocompleteFormItem';
import ImagesFormItem from 'src/view/shared/form/items/ImagesFormItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import InputNumberFormItem from 'src/view/shared/form/items/InputNumberFormItem';
import RadioFormItem from 'src/view/shared/form/items/RadioFormItem';
import SelectFormItem from 'src/view/shared/form/items/SelectFormItem';
import FormWrapper, {FormButtons} from 'src/view/shared/styles/FormWrapper';
import UserAutocompleteFormItem from 'src/view/user/autocomplete/UserAutocompleteFormItem';
import * as yup from 'yup';

const schema = yup.object().shape({
  tipo: yupFormSchemas.enumerator(i18n('entities.empresa.fields.tipo'), {
    options: empresaEnumerators.tipo,
  }),
  foto: yupFormSchemas.images(i18n('entities.empresa.fields.foto'), {
    max: 3,
    required: false,
  }),
  rut: yupFormSchemas.string(i18n('entities.empresa.fields.rut'), {
    required: true,
  }),
  razonSocial: yupFormSchemas.string(i18n('entities.empresa.fields.razonSocial'), {}),
  nombreFantasia: yupFormSchemas.string(i18n('entities.empresa.fields.nombreFantasia'), {
    required: true,
  }),
  casaMatriz: yupFormSchemas.string(i18n('entities.empresa.fields.casaMatriz'), {}),
  telefono: yupFormSchemas.integer(i18n('entities.empresa.fields.telefono'), {}),
  correo: yupFormSchemas.string(i18n('entities.empresa.fields.correo'), {
    required: true,
  }),
  ubicacion: yupFormSchemas.string(i18n('entities.empresa.fields.ubicacion'), {}),
  latitud: yupFormSchemas.decimal(i18n('entities.empresa.fields.latitud'), {}),
  longitud: yupFormSchemas.decimal(i18n('entities.empresa.fields.longitud'), {}),
  responsables: yupFormSchemas.relationToMany(i18n('entities.empresa.fields.responsables'), {}),
  materiales: yupFormSchemas.relationToMany(i18n('entities.empresa.fields.materiales'), {}),
  documentos: yupFormSchemas.files(i18n('entities.empresa.fields.documentos'), {}),
  estado: yupFormSchemas.enumerator(i18n('entities.empresa.fields.estado'), {
    options: empresaEnumerators.estado,
  }),
  rubro: yupFormSchemas.relationToOne(i18n('entities.empresa.fields.rubro'), {
    required: true,
  }),
});

function EmpresaForm(props) {
  const [initialValues] = useState(() => {
    const record = props.record || {};

    return {
      tipo: record.tipo,
      foto: record.foto || [],
      rut: record.rut,
      razonSocial: record.razonSocial,
      nombreFantasia: record.nombreFantasia,
      casaMatriz: record.casaMatriz,
      telefono: record.telefono,
      correo: record.correo,
      ubicacion: record.ubicacion,
      latitud: record.latitud,
      longitud: record.longitud,
      responsables: record.responsables || [],
      materiales: record.materiales || [],
      documentos: record.documentos || [],
      estado: record.estado,
      rubro: record.rubro,
    };
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
  });

  const onSubmit = (values) => {
    props.onSubmit(props.record?.id, values);
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  const {saveLoading, modal} = props;

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Grid spacing={2} container>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <SelectFormItem
                name="tipo"
                label={i18n('entities.empresa.fields.tipo')}
                options={empresaEnumerators.tipo.map((value) => ({
                  value,
                  label: i18n(`entities.empresa.enumerators.tipo.${value}`),
                }))}
                required={false}
              />
            </Grid>
            {form.watch('tipo') === 'GestorReciclaje' && (
              <Grid item lg={7} md={8} sm={12} xs={12}>
                <MaterialAutocompleteFormItem
                  name="materiales"
                  label={i18n('entities.empresa.fields.materiales')}
                  required={false}
                  showCreate={!props.modal}
                  mode="multiple"
                />
              </Grid>
            )}

            <Grid item lg={7} md={8} sm={12} xs={12}>
              <ImagesFormItem
                name="foto"
                label={i18n('entities.empresa.fields.foto')}
                required={false}
                storage={Storage.values.empresaFoto}
                max={3}
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputFormItem name="rut" label={i18n('entities.empresa.fields.rut')} required={true} />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputFormItem name="razonSocial" label={i18n('entities.empresa.fields.razonSocial')} required={false} />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputFormItem name="nombreFantasia" label={i18n('entities.empresa.fields.nombreFantasia')} required={true} />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputFormItem name="casaMatriz" label={i18n('entities.empresa.fields.casaMatriz')} required={false} />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputNumberFormItem name="telefono" label={i18n('entities.empresa.fields.telefono')} required={false} />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputFormItem name="correo" label={i18n('entities.empresa.fields.correo')} required={false} />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <GoogleAutocompleteFormItem
                name="ubicacion"
                label={i18n('entities.empresa.fields.ubicacion')}
                required={false}
                defaultCenter={{lat: -36.8269882, lng: -73.0497665}}
                center={{lat: form.watch('latitud'), lng: form.watch('longitud')}}
                zoom={14}
                enableMap={true}
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <UserAutocompleteFormItem
                name="responsables"
                label={i18n('entities.empresa.fields.responsables')}
                required={false}
                showCreate={!props.modal}
                mode="multiple"
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <FilesFormItem
                name="documentos"
                label={i18n('entities.empresa.fields.documentos')}
                required={false}
                storage={Storage.values.empresaDocumentos}
                max={undefined}
                formats={undefined}
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <RadioFormItem
                name="estado"
                label={i18n('entities.empresa.fields.estado')}
                options={empresaEnumerators.estado.map((value) => ({
                  value,
                  label: i18n(`entities.empresa.enumerators.estado.${value}`),
                }))}
                required={false}
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <RubroAutocompleteFormItem
                name="rubro"
                label={i18n('entities.empresa.fields.rubro')}
                required={true}
                showCreate={!props.modal}
              />
            </Grid>
          </Grid>
          <FormButtons
            style={{
              flexDirection: modal ? 'row-reverse' : undefined,
            }}>
            <Button
              variant="contained"
              color="primary"
              disabled={saveLoading}
              type="button"
              onClick={form.handleSubmit(onSubmit)}
              startIcon={<SaveIcon />}
              size="small">
              {i18n('common.save')}
            </Button>

            <Button disabled={saveLoading} onClick={onReset} type="button" startIcon={<UndoIcon />} size="small">
              {i18n('common.reset')}
            </Button>

            {props.onCancel ? (
              <Button
                disabled={saveLoading}
                onClick={() => props.onCancel()}
                type="button"
                startIcon={<CloseIcon />}
                size="small">
                {i18n('common.cancel')}
              </Button>
            ) : null}
          </FormButtons>
        </form>
      </FormProvider>
    </FormWrapper>
  );
}

export default EmpresaForm;
