import {Box} from '@mui/material';
import {styled} from '@mui/material/styles';
import * as React from 'react';

const TableWrapperComponent = styled('div')({
  width: '100%',
});

export default function TableWrapper(props) {
  return (
    <Box
      id={props.id}
      sx={{
        display: 'flex',
        width: '100%',
        minHeight: '300px',
        overflowX: 'auto',
        flexGrow: 1,
        borderRadius: '5px',
        border: '1px solid rgb(224, 224, 224)',
        ...props.sx,
      }}>
      <TableWrapperComponent>{props.children}</TableWrapperComponent>
    </Box>
  );
}
