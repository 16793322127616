import config from 'src/config';
import Permissions from 'src/security/permissions';
const permissions = Permissions.values;

const privateRoutes = [
  {
    path: '/business-intelligence/dashboard-0',
    loader: () => import('src/view/dashboard/DashboardPageAnalytics'),
    permissionRequired: permissions.dashboardRead,
    exact: true,
  },
  {
    path: '/business-intelligence/dashboard',
    loader: () => import('src/view/dashboard/IFrameDashboard'),
    permissionRequired: permissions.dashboardRead,
    exact: true,
  },
  // {
  //   path: '/gantt',
  //   loader: () => import('src/view/tarea/gantt/TareaGanttPage'),
  //   permissionRequired: permissions.ganttRead,
  //   exact: true,
  // },
  {
    path: '/kanban',
    loader: () => import('src/view/tarea/kanban/TareaKanbanPage'),
    permissionRequired: permissions.kanbanRead,
    exact: true,
  },
  {
    path: '/scheduler',
    loader: () => import('src/view/tarea/scheduler/TareaSchedulerPage'),
    permissionRequired: permissions.schedulerRead,
    exact: true,
  },
  {
    path: '/',
    loader: () => import('src/view/dashboard/DashboardPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/calendario',
    loader: () => import('src/view/dashboard/DashboardCalendario'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/profile',
    loader: () => import('src/view/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/password-change',
    loader: () => import('src/view/auth/PasswordChangeFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/tenant',
    loader: () => import('src/view/tenant/list/TenantListPage'),
    permissionRequired: permissions.tenantRead,
    exact: true,
  },
  {
    path: '/tenant/new',
    loader: () => import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: permissions.tenantCreate,
    exact: true,
  },
  {
    path: '/tenant/:id/edit',
    loader: () => import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: permissions.tenantEdit,
    exact: true,
  },

  config.isPlanEnabled && {
    path: '/plan',
    loader: () => import('src/view/plan/PlanPage'),
    permissionRequired: permissions.planRead,
    exact: true,
  },

  {
    path: '/user',
    loader: () => import('src/view/user/list/UserPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/user/new',
    loader: () => import('src/view/user/new/UserNewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  {
    path: '/user/importer',
    loader: () => import('src/view/user/importer/UserImporterPage'),
    permissionRequired: permissions.userImport,
    exact: true,
  },
  {
    path: '/user/:id/edit',
    loader: () => import('src/view/user/edit/UserEditPage'),
    permissionRequired: permissions.userEdit,
    exact: true,
  },
  {
    path: '/user/:id',
    loader: () => import('src/view/user/view/UserViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/audit-logs',
    loader: () => import('src/view/auditLog/AuditLogPage'),
    permissionRequired: permissions.auditLogRead,
  },

  {
    path: '/settings',
    loader: () => import('src/view/settings/SettingsFormPage'),
    permissionRequired: permissions.settingsEdit,
  },
  {
    path: '/producto',
    loader: () => import('src/view/producto/list/ProductoListPage'),
    permissionRequired: permissions.productoRead,
    exact: true,
  },
  {
    path: '/producto/new',
    loader: () => import('src/view/producto/form/ProductoFormPage'),
    permissionRequired: permissions.productoCreate,
    exact: true,
  },
  {
    path: '/producto/importer',
    loader: () => import('src/view/producto/importer/ProductoImporterPage'),
    permissionRequired: permissions.productoImport,
    exact: true,
  },
  {
    path: '/producto/:id/edit',
    loader: () => import('src/view/producto/form/ProductoFormPage'),
    permissionRequired: permissions.productoEdit,
    exact: true,
  },
  {
    path: '/producto/:id',
    loader: () => import('src/view/producto/view/ProductoViewPage'),
    permissionRequired: permissions.productoRead,
    exact: true,
  },

  // {
  //   path: '/sucursal',
  //   loader: () =>
  //     import('src/view/sucursal/list/SucursalListPage'),
  //   permissionRequired: permissions.sucursalRead,
  //   exact: true,
  // },
  // {
  //   path: '/sucursal/new',
  //   loader: () =>
  //     import('src/view/sucursal/form/SucursalFormPage'),
  //   permissionRequired: permissions.sucursalCreate,
  //   exact: true,
  // },
  // {
  //   path: '/sucursal/importer',
  //   loader: () =>
  //     import(
  //       'src/view/sucursal/importer/SucursalImporterPage'
  //     ),
  //   permissionRequired: permissions.sucursalImport,
  //   exact: true,
  // },
  // {
  //   path: '/sucursal/:id/edit',
  //   loader: () =>
  //     import('src/view/sucursal/form/SucursalFormPage'),
  //   permissionRequired: permissions.sucursalEdit,
  //   exact: true,
  // },
  // {
  //   path: '/sucursal/:id',
  //   loader: () =>
  //     import('src/view/sucursal/view/SucursalViewPage'),
  //   permissionRequired: permissions.sucursalRead,
  //   exact: true,
  // },

  // {
  //   path: '/formulario',
  //   loader: () =>
  //     import('src/view/formulario/list/FormularioListPage'),
  //   permissionRequired: permissions.formularioRead,
  //   exact: true,
  // },
  // {
  //   path: '/formulario/new',
  //   loader: () =>
  //     import('src/view/formulario/form/FormularioFormPage'),
  //   permissionRequired: permissions.formularioCreate,
  //   exact: true,
  // },
  // {
  //   path: '/formulario/importer',
  //   loader: () =>
  //     import(
  //       'src/view/formulario/importer/FormularioImporterPage'
  //     ),
  //   permissionRequired: permissions.formularioImport,
  //   exact: true,
  // },
  // {
  //   path: '/formulario/:id/edit',
  //   loader: () =>
  //     import('src/view/formulario/form/FormularioFormPage'),
  //   permissionRequired: permissions.formularioEdit,
  //   exact: true,
  // },
  // {
  //   path: '/formulario/:id',
  //   loader: () =>
  //     import('src/view/formulario/view/FormularioViewPage'),
  //   permissionRequired: permissions.formularioRead,
  //   exact: true,
  // },

  {
    path: '/pregunta',
    loader: () => import('src/view/pregunta/list/PreguntaListPage'),
    permissionRequired: permissions.preguntaRead,
    exact: true,
  },
  {
    path: '/pregunta/new',
    loader: () => import('src/view/pregunta/form/PreguntaFormPage'),
    permissionRequired: permissions.preguntaCreate,
    exact: true,
  },
  {
    path: '/pregunta/importer',
    loader: () => import('src/view/pregunta/importer/PreguntaImporterPage'),
    permissionRequired: permissions.preguntaImport,
    exact: true,
  },
  {
    path: '/pregunta/:id/edit',
    loader: () => import('src/view/pregunta/form/PreguntaFormPage'),
    permissionRequired: permissions.preguntaEdit,
    exact: true,
  },
  {
    path: '/pregunta/:id',
    loader: () => import('src/view/pregunta/view/PreguntaViewPage'),
    permissionRequired: permissions.preguntaRead,
    exact: true,
  },

  {
    path: '/respuesta',
    loader: () => import('src/view/respuesta/list/RespuestaListPage'),
    permissionRequired: permissions.respuestaRead,
    exact: true,
  },
  {
    path: '/respuesta/new',
    loader: () => import('src/view/respuesta/form/RespuestaFormPage'),
    permissionRequired: permissions.respuestaCreate,
    exact: true,
  },
  {
    path: '/respuesta/importer',
    loader: () => import('src/view/respuesta/importer/RespuestaImporterPage'),
    permissionRequired: permissions.respuestaImport,
    exact: true,
  },
  {
    path: '/respuesta/:id/edit',
    loader: () => import('src/view/respuesta/form/RespuestaFormPage'),
    permissionRequired: permissions.respuestaEdit,
    exact: true,
  },
  {
    path: '/respuesta/:id',
    loader: () => import('src/view/respuesta/view/RespuestaViewPage'),
    permissionRequired: permissions.respuestaRead,
    exact: true,
  },

  {
    path: '/modelo-formulario',
    loader: () => import('src/view/modeloFormulario/list/ModeloFormularioListPage'),
    permissionRequired: permissions.modeloFormularioRead,
    exact: true,
  },
  {
    path: '/modelo-formulario/new',
    loader: () => import('src/view/modeloFormulario/form/ModeloFormularioFormPage'),
    permissionRequired: permissions.modeloFormularioCreate,
    exact: true,
  },
  {
    path: '/modelo-formulario/importer',
    loader: () => import('src/view/modeloFormulario/importer/ModeloFormularioImporterPage'),
    permissionRequired: permissions.modeloFormularioImport,
    exact: true,
  },
  {
    path: '/modelo-formulario/:id/edit',
    loader: () => import('src/view/modeloFormulario/form/ModeloFormularioFormPage'),
    permissionRequired: permissions.modeloFormularioEdit,
    exact: true,
  },
  {
    path: '/modelo-formulario/:id',
    loader: () => import('src/view/modeloFormulario/view/ModeloFormularioViewPage'),
    permissionRequired: permissions.modeloFormularioRead,
    exact: true,
  },

  // {
  //   path: '/rubro',
  //   loader: () =>
  //     import('src/view/rubro/list/RubroListPage'),
  //   permissionRequired: permissions.rubroRead,
  //   exact: true,
  // },
  // {
  //   path: '/rubro/new',
  //   loader: () =>
  //     import('src/view/rubro/form/RubroFormPage'),
  //   permissionRequired: permissions.rubroCreate,
  //   exact: true,
  // },
  // {
  //   path: '/rubro/importer',
  //   loader: () =>
  //     import('src/view/rubro/importer/RubroImporterPage'),
  //   permissionRequired: permissions.rubroImport,
  //   exact: true,
  // },
  // {
  //   path: '/rubro/:id/edit',
  //   loader: () =>
  //     import('src/view/rubro/form/RubroFormPage'),
  //   permissionRequired: permissions.rubroEdit,
  //   exact: true,
  // },
  // {
  //   path: '/rubro/:id',
  //   loader: () =>
  //     import('src/view/rubro/view/RubroViewPage'),
  //   permissionRequired: permissions.rubroRead,
  //   exact: true,
  // },

  // {
  //   path: '/factura',
  //   loader: () =>
  //     import('src/view/factura/list/FacturaListPage'),
  //   permissionRequired: permissions.facturaRead,
  //   exact: true,
  // },
  // {
  //   path: '/factura/new',
  //   loader: () =>
  //     import('src/view/factura/form/FacturaFormPage'),
  //   permissionRequired: permissions.facturaCreate,
  //   exact: true,
  // },
  // {
  //   path: '/factura/importer',
  //   loader: () =>
  //     import(
  //       'src/view/factura/importer/FacturaImporterPage'
  //     ),
  //   permissionRequired: permissions.facturaImport,
  //   exact: true,
  // },
  // {
  //   path: '/factura/:id/edit',
  //   loader: () =>
  //     import('src/view/factura/form/FacturaFormPage'),
  //   permissionRequired: permissions.facturaEdit,
  //   exact: true,
  // },
  // {
  //   path: '/factura/:id',
  //   loader: () =>
  //     import('src/view/factura/view/FacturaViewPage'),
  //   permissionRequired: permissions.facturaRead,
  //   exact: true,
  // },

  // {
  //   path: '/segmentacion',
  //   loader: () =>
  //     import(
  //       'src/view/segmentacion/list/SegmentacionListPage'
  //     ),
  //   permissionRequired: permissions.segmentacionRead,
  //   exact: true,
  // },
  // {
  //   path: '/segmentacion/new',
  //   loader: () =>
  //     import(
  //       'src/view/segmentacion/form/SegmentacionFormPage'
  //     ),
  //   permissionRequired: permissions.segmentacionCreate,
  //   exact: true,
  // },
  // {
  //   path: '/segmentacion/importer',
  //   loader: () =>
  //     import(
  //       'src/view/segmentacion/importer/SegmentacionImporterPage'
  //     ),
  //   permissionRequired: permissions.segmentacionImport,
  //   exact: true,
  // },
  // {
  //   path: '/segmentacion/:id/edit',
  //   loader: () =>
  //     import(
  //       'src/view/segmentacion/form/SegmentacionFormPage'
  //     ),
  //   permissionRequired: permissions.segmentacionEdit,
  //   exact: true,
  // },
  // {
  //   path: '/segmentacion/:id',
  //   loader: () =>
  //     import(
  //       'src/view/segmentacion/view/SegmentacionViewPage'
  //     ),
  //   permissionRequired: permissions.segmentacionRead,
  //   exact: true,
  // },

  // {
  //   path: '/division',
  //   loader: () =>
  //     import('src/view/division/list/DivisionListPage'),
  //   permissionRequired: permissions.divisionRead,
  //   exact: true,
  // },
  // {
  //   path: '/division/new',
  //   loader: () =>
  //     import('src/view/division/form/DivisionFormPage'),
  //   permissionRequired: permissions.divisionCreate,
  //   exact: true,
  // },
  // {
  //   path: '/division/importer',
  //   loader: () =>
  //     import(
  //       'src/view/division/importer/DivisionImporterPage'
  //     ),
  //   permissionRequired: permissions.divisionImport,
  //   exact: true,
  // },
  // {
  //   path: '/division/:id/edit',
  //   loader: () =>
  //     import('src/view/division/form/DivisionFormPage'),
  //   permissionRequired: permissions.divisionEdit,
  //   exact: true,
  // },
  // {
  //   path: '/division/:id',
  //   loader: () =>
  //     import('src/view/division/view/DivisionViewPage'),
  //   permissionRequired: permissions.divisionRead,
  //   exact: true,
  // },

  // {
  //   path: '/pais',
  //   loader: () => import('src/view/pais/list/PaisListPage'),
  //   permissionRequired: permissions.paisRead,
  //   exact: true,
  // },
  // {
  //   path: '/pais/new',
  //   loader: () => import('src/view/pais/form/PaisFormPage'),
  //   permissionRequired: permissions.paisCreate,
  //   exact: true,
  // },
  // {
  //   path: '/pais/importer',
  //   loader: () =>
  //     import('src/view/pais/importer/PaisImporterPage'),
  //   permissionRequired: permissions.paisImport,
  //   exact: true,
  // },
  // {
  //   path: '/pais/:id/edit',
  //   loader: () => import('src/view/pais/form/PaisFormPage'),
  //   permissionRequired: permissions.paisEdit,
  //   exact: true,
  // },
  // {
  //   path: '/pais/:id',
  //   loader: () => import('src/view/pais/view/PaisViewPage'),
  //   permissionRequired: permissions.paisRead,
  //   exact: true,
  // },

  {
    path: '/puesto',
    loader: () => import('src/view/puesto/list/PuestoListPage'),
    permissionRequired: permissions.puestoRead,
    exact: true,
  },
  {
    path: '/puesto/new',
    loader: () => import('src/view/puesto/form/PuestoFormPage'),
    permissionRequired: permissions.puestoCreate,
    exact: true,
  },
  {
    path: '/puesto/importer',
    loader: () => import('src/view/puesto/importer/PuestoImporterPage'),
    permissionRequired: permissions.puestoImport,
    exact: true,
  },
  {
    path: '/puesto/:id/edit',
    loader: () => import('src/view/puesto/form/PuestoFormPage'),
    permissionRequired: permissions.puestoEdit,
    exact: true,
  },
  {
    path: '/puesto/:id',
    loader: () => import('src/view/puesto/view/PuestoViewPage'),
    permissionRequired: permissions.puestoRead,
    exact: true,
  },

  {
    path: '/departamento',
    loader: () => import('src/view/departamento/list/DepartamentoListPage'),
    permissionRequired: permissions.departamentoRead,
    exact: true,
  },
  {
    path: '/departamento/new',
    loader: () => import('src/view/departamento/form/DepartamentoFormPage'),
    permissionRequired: permissions.departamentoCreate,
    exact: true,
  },
  {
    path: '/departamento/importer',
    loader: () => import('src/view/departamento/importer/DepartamentoImporterPage'),
    permissionRequired: permissions.departamentoImport,
    exact: true,
  },
  {
    path: '/departamento/:id/edit',
    loader: () => import('src/view/departamento/form/DepartamentoFormPage'),
    permissionRequired: permissions.departamentoEdit,
    exact: true,
  },
  {
    path: '/departamento/:id',
    loader: () => import('src/view/departamento/view/DepartamentoViewPage'),
    permissionRequired: permissions.departamentoRead,
    exact: true,
  },

  {
    path: '/nivel-puesto',
    loader: () => import('src/view/nivelPuesto/list/NivelPuestoListPage'),
    permissionRequired: permissions.nivelPuestoRead,
    exact: true,
  },
  {
    path: '/nivel-puesto/new',
    loader: () => import('src/view/nivelPuesto/form/NivelPuestoFormPage'),
    permissionRequired: permissions.nivelPuestoCreate,
    exact: true,
  },
  {
    path: '/nivel-puesto/importer',
    loader: () => import('src/view/nivelPuesto/importer/NivelPuestoImporterPage'),
    permissionRequired: permissions.nivelPuestoImport,
    exact: true,
  },
  {
    path: '/nivel-puesto/:id/edit',
    loader: () => import('src/view/nivelPuesto/form/NivelPuestoFormPage'),
    permissionRequired: permissions.nivelPuestoEdit,
    exact: true,
  },
  {
    path: '/nivel-puesto/:id',
    loader: () => import('src/view/nivelPuesto/view/NivelPuestoViewPage'),
    permissionRequired: permissions.nivelPuestoRead,
    exact: true,
  },

  {
    path: '/planificador-formulario',
    loader: () => import('src/view/planificadorFormulario/list/PlanificadorFormularioListPage'),
    permissionRequired: permissions.planificadorFormularioRead,
    exact: true,
  },
  {
    path: '/planificador-formulario/new',
    loader: () => import('src/view/planificadorFormulario/form/PlanificadorFormularioFormPage'),
    permissionRequired: permissions.planificadorFormularioCreate,
    exact: true,
  },
  {
    path: '/planificador-formulario/importer',
    loader: () => import('src/view/planificadorFormulario/importer/PlanificadorFormularioImporterPage'),
    permissionRequired: permissions.planificadorFormularioImport,
    exact: true,
  },
  {
    path: '/planificador-formulario/:id/edit',
    loader: () => import('src/view/planificadorFormulario/form/PlanificadorFormularioFormPage'),
    permissionRequired: permissions.planificadorFormularioEdit,
    exact: true,
  },
  {
    path: '/planificador-formulario/:id',
    loader: () => import('src/view/planificadorFormulario/view/PlanificadorFormularioViewPage'),
    permissionRequired: permissions.planificadorFormularioRead,
    exact: true,
  },

  // {
  //   path: '/configuracion-notificacion',
  //   loader: () => import('src/view/configuracionNotificacion/list/ConfiguracionNotificacionListPage'),
  //   permissionRequired: permissions.configuracionNotificacionRead,
  //   exact: true,
  // },
  // {
  //   path: '/configuracion-notificacion/new',
  //   loader: () => import('src/view/configuracionNotificacion/form/ConfiguracionNotificacionFormPage'),
  //   permissionRequired: permissions.configuracionNotificacionCreate,
  //   exact: true,
  // },
  // {
  //   path: '/configuracion-notificacion/importer',
  //   loader: () => import('src/view/configuracionNotificacion/importer/ConfiguracionNotificacionImporterPage'),
  //   permissionRequired: permissions.configuracionNotificacionImport,
  //   exact: true,
  // },
  // {
  //   path: '/configuracion-notificacion/:id/edit',
  //   loader: () => import('src/view/configuracionNotificacion/form/ConfiguracionNotificacionFormPage'),
  //   permissionRequired: permissions.configuracionNotificacionEdit,
  //   exact: true,
  // },
  // {
  //   path: '/configuracion-notificacion/:id',
  //   loader: () => import('src/view/configuracionNotificacion/view/ConfiguracionNotificacionViewPage'),
  //   permissionRequired: permissions.configuracionNotificacionRead,
  //   exact: true,
  // },

  {
    path: '/objetivo',
    loader: () => import('src/view/objetivo/list/ObjetivoListPage'),
    permissionRequired: permissions.objetivoRead,
    exact: true,
  },
  {
    path: '/objetivo/new',
    loader: () => import('src/view/objetivo/form/ObjetivoFormPage'),
    permissionRequired: permissions.objetivoCreate,
    exact: true,
  },
  {
    path: '/objetivo/importer',
    loader: () => import('src/view/objetivo/importer/ObjetivoImporterPage'),
    permissionRequired: permissions.objetivoImport,
    exact: true,
  },
  {
    path: '/objetivo/:id/edit',
    loader: () => import('src/view/objetivo/form/ObjetivoFormPage'),
    permissionRequired: permissions.objetivoEdit,
    exact: true,
  },
  {
    path: '/objetivo/:id',
    loader: () => import('src/view/objetivo/view/ObjetivoViewPage'),
    permissionRequired: permissions.objetivoRead,
    exact: true,
  },

  {
    path: '/modelo-tarea',
    loader: () => import('src/view/modeloTarea/list/ModeloTareaListPage'),
    permissionRequired: permissions.modeloTareaRead,
    exact: true,
  },
  {
    path: '/modelo-tarea/new',
    loader: () => import('src/view/modeloTarea/form/ModeloTareaFormPage'),
    permissionRequired: permissions.modeloTareaCreate,
    exact: true,
  },
  {
    path: '/modelo-tarea/importer',
    loader: () => import('src/view/modeloTarea/importer/ModeloTareaImporterPage'),
    permissionRequired: permissions.modeloTareaImport,
    exact: true,
  },
  {
    path: '/modelo-tarea/:id/edit',
    loader: () => import('src/view/modeloTarea/form/ModeloTareaFormPage'),
    permissionRequired: permissions.modeloTareaEdit,
    exact: true,
  },
  {
    path: '/modelo-tarea/:id',
    loader: () => import('src/view/modeloTarea/view/ModeloTareaViewPage'),
    permissionRequired: permissions.modeloTareaRead,
    exact: true,
  },

  {
    path: '/sub-tarea',
    loader: () => import('src/view/subTarea/list/SubTareaListPage'),
    permissionRequired: permissions.subTareaRead,
    exact: true,
  },
  {
    path: '/sub-tarea/new',
    loader: () => import('src/view/subTarea/form/SubTareaFormPage'),
    permissionRequired: permissions.subTareaCreate,
    exact: true,
  },
  {
    path: '/sub-tarea/importer',
    loader: () => import('src/view/subTarea/importer/SubTareaImporterPage'),
    permissionRequired: permissions.subTareaImport,
    exact: true,
  },
  {
    path: '/sub-tarea/:id/edit',
    loader: () => import('src/view/subTarea/form/SubTareaFormPage'),
    permissionRequired: permissions.subTareaEdit,
    exact: true,
  },
  {
    path: '/sub-tarea/:id',
    loader: () => import('src/view/subTarea/view/SubTareaViewPage'),
    permissionRequired: permissions.subTareaRead,
    exact: true,
  },

  {
    path: '/planificador-tarea',
    loader: () => import('src/view/planificadorTarea/list/PlanificadorTareaListPage'),
    permissionRequired: permissions.planificadorTareaRead,
    exact: true,
  },
  {
    path: '/planificador-tarea/new',
    loader: () => import('src/view/planificadorTarea/form/PlanificadorTareaFormPage'),
    permissionRequired: permissions.planificadorTareaCreate,
    exact: true,
  },
  {
    path: '/planificador-tarea/importer',
    loader: () => import('src/view/planificadorTarea/importer/PlanificadorTareaCustomImporterPage'),
    permissionRequired: permissions.planificadorTareaImport,
    exact: true,
  },
  {
    path: '/planificador-tarea/:id/edit',
    loader: () => import('src/view/planificadorTarea/form/PlanificadorTareaFormPage'),
    permissionRequired: permissions.planificadorTareaEdit,
    exact: true,
  },
  {
    path: '/planificador-tarea/:id',
    loader: () => import('src/view/planificadorTarea/view/PlanificadorTareaViewPage'),
    permissionRequired: permissions.planificadorTareaRead,
    exact: true,
  },

  {
    path: '/categoria',
    loader: () => import('src/view/categoria/list/CategoriaListPage'),
    permissionRequired: permissions.categoriaRead,
    exact: true,
  },
  {
    path: '/categoria/new',
    loader: () => import('src/view/categoria/form/CategoriaFormPage'),
    permissionRequired: permissions.categoriaCreate,
    exact: true,
  },
  {
    path: '/categoria/importer',
    loader: () => import('src/view/categoria/importer/CategoriaImporterPage'),
    permissionRequired: permissions.categoriaImport,
    exact: true,
  },
  {
    path: '/categoria/:id/edit',
    loader: () => import('src/view/categoria/form/CategoriaFormPage'),
    permissionRequired: permissions.categoriaEdit,
    exact: true,
  },
  {
    path: '/categoria/:id',
    loader: () => import('src/view/categoria/view/CategoriaViewPage'),
    permissionRequired: permissions.categoriaRead,
    exact: true,
  },

  {
    path: '/tarea',
    loader: () => import('src/view/tarea/list/TareaListPage'),
    permissionRequired: permissions.tareaRead,
    exact: true,
  },
  {
    path: '/tarea/new',
    loader: () => import('src/view/tarea/form/TareaFormPage'),
    permissionRequired: permissions.tareaCreate,
    exact: true,
  },
  {
    path: '/tarea/importer',
    loader: () => import('src/view/tarea/importer/TareaImporterPage'),
    permissionRequired: permissions.tareaImport,
    exact: true,
  },
  {
    path: '/tarea/:id/edit',
    loader: () => import('src/view/tarea/form/TareaFormPage'),
    permissionRequired: permissions.tareaEdit,
    exact: true,
  },
  {
    path: '/tarea/:id',
    loader: () => import('src/view/tarea/view/TareaViewPage'),
    permissionRequired: permissions.tareaRead,
    exact: true,
  },

  {
    path: '/pendiente',
    loader: () => import('src/view/pendiente/list/PendienteListPage'),
    permissionRequired: permissions.pendienteRead,
    exact: true,
  },
  {
    path: '/pendiente/c/:id',
    loader: () => import('src/view/pendiente/list/PendienteListPage'),
    permissionRequired: permissions.pendienteRead,
    exact: true,
  },
  /*
  {
    path: '/pendiente/new',
    loader: () =>
      import('src/view/pendiente/form/PendienteFormPage'),
    permissionRequired: permissions.pendienteCreate,
    exact: true,
  },
  {
    path: '/pendiente/importer',
    loader: () =>
      import(
        'src/view/pendiente/importer/PendienteImporterPage'
      ),
    permissionRequired: permissions.pendienteImport,
    exact: true,
  },
  {
    path: '/pendiente/:id/edit',
    loader: () =>
      import('src/view/pendiente/form/PendienteFormPage'),
    permissionRequired: permissions.pendienteEdit,
    exact: true,
  },
  {
    path: '/pendiente/:id',
    loader: () =>
      import('src/view/pendiente/view/PendienteViewPage'),
    permissionRequired: permissions.pendienteRead,
    exact: true,
  },
  */
  {
    path: '/comunicacion-interna',
    loader: () => import('src/view/comunicacionInterna/list/ComunicacionInternaListPage'),
    permissionRequired: permissions.comunicacionInternaRead,
    exact: true,
  },
  {
    path: '/comunicacion-interna/new',
    loader: () => import('src/view/comunicacionInterna/form/ComunicacionInternaFormPage'),
    permissionRequired: permissions.comunicacionInternaCreate,
    exact: true,
  },
  {
    path: '/comunicacion-interna/vista-360-mobile/:idComunicacion',
    loader: () => import('src/view/comunicacionInterna/messenger/'),
    permissionRequired: permissions.mensajeInternoCreate,
    exact: true,
  },
  {
    path: '/comunicacion-interna/vista-360-desktop/:idComunicacion',
    loader: () => import('src/view/comunicacionInterna/list/ComunicacionInternaListPage'),
    permissionRequired: permissions.mensajeInternoCreate,
    exact: true,
  },
  {
    path: '/comunicacion-interna/importer',
    loader: () => import('src/view/comunicacionInterna/importer/ComunicacionInternaImporterPage'),
    permissionRequired: permissions.comunicacionInternaImport,
    exact: true,
  },
  {
    path: '/comunicacion-interna/:id/edit',
    loader: () => import('src/view/comunicacionInterna/form/ComunicacionInternaFormPage'),
    permissionRequired: permissions.comunicacionInternaEdit,
    exact: true,
  },
  {
    path: '/comunicacion-interna/:id',
    loader: () => import('src/view/comunicacionInterna/view/ComunicacionInternaViewPage'),
    permissionRequired: permissions.comunicacionInternaRead,
    exact: true,
  },

  {
    path: '/tema-comunicacion',
    loader: () => import('src/view/temaComunicacion/list/TemaComunicacionListPage'),
    permissionRequired: permissions.temaComunicacionRead,
    exact: true,
  },
  {
    path: '/tema-comunicacion/new',
    loader: () => import('src/view/temaComunicacion/form/TemaComunicacionFormPage'),
    permissionRequired: permissions.temaComunicacionCreate,
    exact: true,
  },
  {
    path: '/tema-comunicacion/importer',
    loader: () => import('src/view/temaComunicacion/importer/TemaComunicacionImporterPage'),
    permissionRequired: permissions.temaComunicacionImport,
    exact: true,
  },
  {
    path: '/tema-comunicacion/:id/edit',
    loader: () => import('src/view/temaComunicacion/form/TemaComunicacionFormPage'),
    permissionRequired: permissions.temaComunicacionEdit,
    exact: true,
  },
  {
    path: '/tema-comunicacion/:id',
    loader: () => import('src/view/temaComunicacion/view/TemaComunicacionViewPage'),
    permissionRequired: permissions.temaComunicacionRead,
    exact: true,
  },

  {
    path: '/nivel-prioridad',
    loader: () => import('src/view/nivelPrioridad/list/NivelPrioridadListPage'),
    permissionRequired: permissions.nivelPrioridadRead,
    exact: true,
  },
  {
    path: '/nivel-prioridad/new',
    loader: () => import('src/view/nivelPrioridad/form/NivelPrioridadFormPage'),
    permissionRequired: permissions.nivelPrioridadCreate,
    exact: true,
  },
  {
    path: '/nivel-prioridad/importer',
    loader: () => import('src/view/nivelPrioridad/importer/NivelPrioridadImporterPage'),
    permissionRequired: permissions.nivelPrioridadImport,
    exact: true,
  },
  {
    path: '/nivel-prioridad/:id/edit',
    loader: () => import('src/view/nivelPrioridad/form/NivelPrioridadFormPage'),
    permissionRequired: permissions.nivelPrioridadEdit,
    exact: true,
  },
  {
    path: '/nivel-prioridad/:id',
    loader: () => import('src/view/nivelPrioridad/view/NivelPrioridadViewPage'),
    permissionRequired: permissions.nivelPrioridadRead,
    exact: true,
  },
  /*
  {
    path: '/mensaje-interno',
    loader: () =>
      import('src/view/mensajeInterno/list/MensajeInternoListPage'),
    permissionRequired: permissions.mensajeInternoRead,
    exact: true,
  },
  */
  {
    path: '/mensaje-interno/new',
    loader: () => import('src/view/mensajeInterno/form/MensajeInternoFormPage'),
    permissionRequired: permissions.mensajeInternoCreate,
    exact: true,
  },
  {
    path: '/mensaje-interno/importer',
    loader: () => import('src/view/mensajeInterno/importer/MensajeInternoImporterPage'),
    permissionRequired: permissions.mensajeInternoImport,
    exact: true,
  },
  {
    path: '/mensaje-interno/:id/edit',
    loader: () => import('src/view/mensajeInterno/form/MensajeInternoFormPage'),
    permissionRequired: permissions.mensajeInternoEdit,
    exact: true,
  },
  /*
  {
    path: '/mensaje-interno/:id',
    loader: () =>
      import('src/view/mensajeInterno/view/MensajeInternoViewPage'),
    permissionRequired: permissions.mensajeInternoRead,
    exact: true,
  },
  */
  // {
  //   path: '/evento',
  //   loader: () => import('src/view/evento/list/EventoListPage'),
  //   permissionRequired: permissions.eventoRead,
  //   exact: true,
  // },
  // {
  //   path: '/evento/new',
  //   loader: () => import('src/view/evento/form/EventoFormPage'),
  //   permissionRequired: permissions.eventoCreate,
  //   exact: true,
  // },
  // {
  //   path: '/evento/importer',
  //   loader: () => import('src/view/evento/importer/EventoImporterPage'),
  //   permissionRequired: permissions.eventoImport,
  //   exact: true,
  // },
  // {
  //   path: '/evento/:id/edit',
  //   loader: () => import('src/view/evento/form/EventoFormPage'),
  //   permissionRequired: permissions.eventoEdit,
  //   exact: true,
  // },
  // {
  //   path: '/evento/:id',
  //   loader: () => import('src/view/evento/view/EventoViewPage'),
  //   permissionRequired: permissions.eventoRead,
  //   exact: true,
  // },

  // {
  //   path: '/recordatorio',
  //   loader: () => import('src/view/recordatorio/list/RecordatorioListPage'),
  //   permissionRequired: permissions.recordatorioRead,
  //   exact: true,
  // },
  // {
  //   path: '/recordatorio/new',
  //   loader: () => import('src/view/recordatorio/form/RecordatorioFormPage'),
  //   permissionRequired: permissions.recordatorioCreate,
  //   exact: true,
  // },
  // {
  //   path: '/recordatorio/importer',
  //   loader: () => import('src/view/recordatorio/importer/RecordatorioImporterPage'),
  //   permissionRequired: permissions.recordatorioImport,
  //   exact: true,
  // },
  // {
  //   path: '/recordatorio/:id/edit',
  //   loader: () => import('src/view/recordatorio/form/RecordatorioFormPage'),
  //   permissionRequired: permissions.recordatorioEdit,
  //   exact: true,
  // },
  // {
  //   path: '/recordatorio/:id',
  //   loader: () => import('src/view/recordatorio/view/RecordatorioViewPage'),
  //   permissionRequired: permissions.recordatorioRead,
  //   exact: true,
  // },

  {
    path: '/notificacion',
    loader: () => import('src/view/notificacion/list/NotificacionListPage'),
    permissionRequired: permissions.notificacionRead,
    exact: true,
  },
  {
    path: '/notificacion/new',
    loader: () => import('src/view/notificacion/form/NotificacionFormPage'),
    permissionRequired: permissions.notificacionCreate,
    exact: true,
  },
  {
    path: '/notificacion/importer',
    loader: () => import('src/view/notificacion/importer/NotificacionImporterPage'),
    permissionRequired: permissions.notificacionImport,
    exact: true,
  },
  {
    path: '/notificacion/:id/edit',
    loader: () => import('src/view/notificacion/form/NotificacionFormPage'),
    permissionRequired: permissions.notificacionEdit,
    exact: true,
  },
  {
    path: '/notificacion/:id',
    loader: () => import('src/view/notificacion/view/NotificacionViewPage'),
    permissionRequired: permissions.notificacionRead,
    exact: true,
  },

  {
    path: '/categoria-producto',
    loader: () => import('src/view/categoriaProducto/list/CategoriaProductoListPage'),
    permissionRequired: permissions.categoriaProductoRead,
    exact: true,
  },
  {
    path: '/categoria-producto/new',
    loader: () => import('src/view/categoriaProducto/form/CategoriaProductoFormPage'),
    permissionRequired: permissions.categoriaProductoCreate,
    exact: true,
  },
  {
    path: '/categoria-producto/importer',
    loader: () => import('src/view/categoriaProducto/importer/CategoriaProductoImporterPage'),
    permissionRequired: permissions.categoriaProductoImport,
    exact: true,
  },
  {
    path: '/categoria-producto/:id/edit',
    loader: () => import('src/view/categoriaProducto/form/CategoriaProductoFormPage'),
    permissionRequired: permissions.categoriaProductoEdit,
    exact: true,
  },
  {
    path: '/categoria-producto/:id',
    loader: () => import('src/view/categoriaProducto/view/CategoriaProductoViewPage'),
    permissionRequired: permissions.categoriaProductoRead,
    exact: true,
  },

  {
    path: '/asignador-funcion',
    loader: () => import('src/view/asignadorFuncion/list/AsignadorFuncionListPage'),
    permissionRequired: permissions.asignadorFuncionRead,
    exact: true,
  },
  {
    path: '/asignador-funcion/new',
    loader: () => import('src/view/asignadorFuncion/form/AsignadorFuncionFormPage'),
    permissionRequired: permissions.asignadorFuncionCreate,
    exact: true,
  },
  {
    path: '/asignador-funcion/importer',
    loader: () => import('src/view/asignadorFuncion/importer/AsignadorFuncionImporterPage'),
    permissionRequired: permissions.asignadorFuncionImport,
    exact: true,
  },
  {
    path: '/asignador-funcion/:id/edit',
    loader: () => import('src/view/asignadorFuncion/form/AsignadorFuncionFormPage'),
    permissionRequired: permissions.asignadorFuncionEdit,
    exact: true,
  },
  {
    path: '/asignador-funcion/:id',
    loader: () => import('src/view/asignadorFuncion/view/AsignadorFuncionViewPage'),
    permissionRequired: permissions.asignadorFuncionRead,
    exact: true,
  },

  {
    path: '/funcion',
    loader: () => import('src/view/funcion/list/FuncionListPage'),
    permissionRequired: permissions.funcionRead,
    exact: true,
  },
  {
    path: '/funcion/new',
    loader: () => import('src/view/funcion/form/FuncionFormPage'),
    permissionRequired: permissions.funcionRead,
    exact: true,
  },
  {
    path: '/funcion/importer',
    loader: () => import('src/view/funcion/importer/FuncionImporterPage'),
    permissionRequired: permissions.funcionImport,
    exact: true,
  },
  {
    path: '/funcion/:id/edit',
    loader: () => import('src/view/funcion/form/FuncionFormPage'),
    permissionRequired: permissions.funcionEdit,
    exact: true,
  },
  {
    path: '/funcion/:id',
    loader: () => import('src/view/funcion/view/FuncionViewPage'),
    permissionRequired: permissions.funcionRead,
    exact: true,
  },

  /*
  {
    path: '/categoria-formulario',
    loader: () =>
      import('src/view/categoriaFormulario/list/CategoriaFormularioListPage'),
    permissionRequired: permissions.categoriaFormularioRead,
    exact: true,
  },
  {
    path: '/categoria-formulario/new',
    loader: () =>
      import('src/view/categoriaFormulario/form/CategoriaFormularioFormPage'),
    permissionRequired: permissions.categoriaFormularioCreate,
    exact: true,
  },
  {
    path: '/categoria-formulario/importer',
    loader: () =>
      import(
        'src/view/categoriaFormulario/importer/CategoriaFormularioImporterPage'
      ),
    permissionRequired: permissions.categoriaFormularioImport,
    exact: true,
  },
  {
    path: '/categoria-formulario/:id/edit',
    loader: () =>
      import('src/view/categoriaFormulario/form/CategoriaFormularioFormPage'),
    permissionRequired: permissions.categoriaFormularioEdit,
    exact: true,
  },
  {
    path: '/categoria-formulario/:id',
    loader: () =>
      import('src/view/categoriaFormulario/view/CategoriaFormularioViewPage'),
    permissionRequired: permissions.categoriaFormularioRead,
    exact: true,
  },
  */
  {
    path: '/habilidad',
    loader: () => import('src/view/habilidad/list/HabilidadListPage'),
    permissionRequired: permissions.habilidadRead,
    exact: true,
  },
  {
    path: '/habilidad/new',
    loader: () => import('src/view/habilidad/form/HabilidadFormPage'),
    permissionRequired: permissions.habilidadCreate,
    exact: true,
  },
  {
    path: '/habilidad/importer',
    loader: () => import('src/view/habilidad/importer/HabilidadImporterPage'),
    permissionRequired: permissions.habilidadImport,
    exact: true,
  },
  {
    path: '/habilidad/:id/edit',
    loader: () => import('src/view/habilidad/form/HabilidadFormPage'),
    permissionRequired: permissions.habilidadEdit,
    exact: true,
  },
  {
    path: '/habilidad/:id',
    loader: () => import('src/view/habilidad/view/HabilidadViewPage'),
    permissionRequired: permissions.habilidadRead,
    exact: true,
  },

  //Stem
  // {
  //   path: '/empresa',
  //   loader: () =>
  //     import('src/view/empresa/list/EmpresaListPage'),
  //   permissionRequired: permissions.empresaRead,
  //   exact: true,
  // },
  // {
  //   path: '/empresa/new',
  //   loader: () =>
  //     import('src/view/empresa/form/EmpresaFormPage'),
  //   permissionRequired: permissions.empresaCreate,
  //   exact: true,
  // },
  // {
  //   path: '/empresa/importer',
  //   loader: () =>
  //     import(
  //       'src/view/empresa/importer/EmpresaImporterPage'
  //     ),
  //   permissionRequired: permissions.empresaImport,
  //   exact: true,
  // },
  // {
  //   path: '/empresa/:id/edit',
  //   loader: () =>
  //     import('src/view/empresa/form/EmpresaFormPage'),
  //   permissionRequired: permissions.empresaEdit,
  //   exact: true,
  // },
  // {
  //   path: '/empresa/:id',
  //   loader: () =>
  //     import('src/view/empresa/view/EmpresaViewPage'),
  //   permissionRequired: permissions.empresaRead,
  //   exact: true,
  // },

  {
    path: '/ubicacion',
    loader: () => import('src/view/ubicacion/list/UbicacionListPage'),
    permissionRequired: permissions.ubicacionRead,
    exact: true,
  },
  {
    path: '/ubicacion/new',
    loader: () => import('src/view/ubicacion/form/UbicacionFormPage'),
    permissionRequired: permissions.ubicacionCreate,
    exact: true,
  },
  {
    path: '/ubicacion/importer',
    loader: () => import('src/view/ubicacion/importer/UbicacionImporterPage'),
    permissionRequired: permissions.ubicacionImport,
    exact: true,
  },
  {
    path: '/ubicacion/:id/edit',
    loader: () => import('src/view/ubicacion/form/UbicacionFormPage'),
    permissionRequired: permissions.ubicacionEdit,
    exact: true,
  },
  {
    path: '/ubicacion/:id',
    loader: () => import('src/view/ubicacion/view/UbicacionViewPage'),
    permissionRequired: permissions.ubicacionRead,
    exact: true,
  },

  {
    path: '/activo',
    loader: () => import('src/view/activo/list/ActivoListPage'),
    permissionRequired: permissions.activoRead,
    exact: true,
  },
  {
    path: '/activo/new',
    loader: () => import('src/view/activo/form/ActivoFormPage'),
    permissionRequired: permissions.activoCreate,
    exact: true,
  },
  {
    path: '/activo/importer',
    loader: () => import('src/view/activo/importer/ActivoImporterPage'),
    permissionRequired: permissions.activoImport,
    exact: true,
  },
  {
    path: '/activo/:id/edit',
    loader: () => import('src/view/activo/form/ActivoFormPage'),
    permissionRequired: permissions.activoEdit,
    exact: true,
  },
  {
    path: '/activo/:id',
    loader: () => import('src/view/activo/view/ActivoViewPage'),
    permissionRequired: permissions.activoRead,
    exact: true,
  },

  // {
  //   path: '/marca',
  //   loader: () =>
  //     import('src/view/marca/list/MarcaListPage'),
  //   permissionRequired: permissions.marcaRead,
  //   exact: true,
  // },
  // {
  //   path: '/marca/new',
  //   loader: () =>
  //     import('src/view/marca/form/MarcaFormPage'),
  //   permissionRequired: permissions.marcaCreate,
  //   exact: true,
  // },
  // {
  //   path: '/marca/importer',
  //   loader: () =>
  //     import('src/view/marca/importer/MarcaImporterPage'),
  //   permissionRequired: permissions.marcaImport,
  //   exact: true,
  // },
  // {
  //   path: '/marca/:id/edit',
  //   loader: () =>
  //     import('src/view/marca/form/MarcaFormPage'),
  //   permissionRequired: permissions.marcaEdit,
  //   exact: true,
  // },
  // {
  //   path: '/marca/:id',
  //   loader: () =>
  //     import('src/view/marca/view/MarcaViewPage'),
  //   permissionRequired: permissions.marcaRead,
  //   exact: true,
  // },

  // {
  //   path: '/modelo',
  //   loader: () =>
  //     import('src/view/modelo/list/ModeloListPage'),
  //   permissionRequired: permissions.modeloRead,
  //   exact: true,
  // },
  // {
  //   path: '/modelo/new',
  //   loader: () =>
  //     import('src/view/modelo/form/ModeloFormPage'),
  //   permissionRequired: permissions.modeloCreate,
  //   exact: true,
  // },
  // {
  //   path: '/modelo/importer',
  //   loader: () =>
  //     import('src/view/modelo/importer/ModeloImporterPage'),
  //   permissionRequired: permissions.modeloImport,
  //   exact: true,
  // },
  // {
  //   path: '/modelo/:id/edit',
  //   loader: () =>
  //     import('src/view/modelo/form/ModeloFormPage'),
  //   permissionRequired: permissions.modeloEdit,
  //   exact: true,
  // },
  // {
  //   path: '/modelo/:id',
  //   loader: () =>
  //     import('src/view/modelo/view/ModeloViewPage'),
  //   permissionRequired: permissions.modeloRead,
  //   exact: true,
  // },

  {
    path: '/desc-registro',
    loader: () => import('src/view/descRegistro/list/DescRegistroListPage'),
    permissionRequired: permissions.descRegistroRead,
    exact: true,
  },
  {
    path: '/desc-registro/new',
    loader: () => import('src/view/descRegistro/form/DescRegistroFormPage'),
    permissionRequired: permissions.descRegistroCreate,
    exact: true,
  },
  {
    path: '/desc-registro/importer',
    loader: () => import('src/view/descRegistro/importer/DescRegistroImporterPage'),
    permissionRequired: permissions.descRegistroImport,
    exact: true,
  },
  {
    path: '/desc-registro/:id/edit',
    loader: () => import('src/view/descRegistro/form/DescRegistroFormPage'),
    permissionRequired: permissions.descRegistroEdit,
    exact: true,
  },
  {
    path: '/desc-registro/:id',
    loader: () => import('src/view/descRegistro/view/DescRegistroViewPage'),
    permissionRequired: permissions.descRegistroRead,
    exact: true,
  },

  {
    path: '/tipo-activo',
    loader: () => import('src/view/tipoActivo/list/TipoActivoListPage'),
    permissionRequired: permissions.tipoActivoRead,
    exact: true,
  },
  {
    path: '/tipo-activo/new',
    loader: () => import('src/view/tipoActivo/form/TipoActivoFormPage'),
    permissionRequired: permissions.tipoActivoCreate,
    exact: true,
  },
  {
    path: '/tipo-activo/importer',
    loader: () => import('src/view/tipoActivo/importer/TipoActivoImporterPage'),
    permissionRequired: permissions.tipoActivoImport,
    exact: true,
  },
  {
    path: '/tipo-activo/:id/edit',
    loader: () => import('src/view/tipoActivo/form/TipoActivoFormPage'),
    permissionRequired: permissions.tipoActivoEdit,
    exact: true,
  },
  {
    path: '/tipo-activo/:id',
    loader: () => import('src/view/tipoActivo/view/TipoActivoViewPage'),
    permissionRequired: permissions.tipoActivoRead,
    exact: true,
  },

  // {
  //   path: '/tipo-energia',
  //   loader: () =>
  //     import(
  //       'src/view/tipoEnergia/list/TipoEnergiaListPage'
  //     ),
  //   permissionRequired: permissions.tipoEnergiaRead,
  //   exact: true,
  // },
  // {
  //   path: '/tipo-energia/new',
  //   loader: () =>
  //     import(
  //       'src/view/tipoEnergia/form/TipoEnergiaFormPage'
  //     ),
  //   permissionRequired: permissions.tipoEnergiaCreate,
  //   exact: true,
  // },
  // {
  //   path: '/tipo-energia/importer',
  //   loader: () =>
  //     import(
  //       'src/view/tipoEnergia/importer/TipoEnergiaImporterPage'
  //     ),
  //   permissionRequired: permissions.tipoEnergiaImport,
  //   exact: true,
  // },
  // {
  //   path: '/tipo-energia/:id/edit',
  //   loader: () =>
  //     import(
  //       'src/view/tipoEnergia/form/TipoEnergiaFormPage'
  //     ),
  //   permissionRequired: permissions.tipoEnergiaEdit,
  //   exact: true,
  // },
  // {
  //   path: '/tipo-energia/:id',
  //   loader: () =>
  //     import(
  //       'src/view/tipoEnergia/view/TipoEnergiaViewPage'
  //     ),
  //   permissionRequired: permissions.tipoEnergiaRead,
  //   exact: true,
  // },

  // {
  //   path: '/tabla-costo-energia',
  //   loader: () =>
  //     import(
  //       'src/view/tablaCostoEnergia/list/TablaCostoEnergiaListPage'
  //     ),
  //   permissionRequired: permissions.tablaCostoEnergiaRead,
  //   exact: true,
  // },
  // {
  //   path: '/tabla-costo-energia/new',
  //   loader: () =>
  //     import(
  //       'src/view/tablaCostoEnergia/form/TablaCostoEnergiaFormPage'
  //     ),
  //   permissionRequired: permissions.tablaCostoEnergiaCreate,
  //   exact: true,
  // },
  // {
  //   path: '/tabla-costo-energia/importer',
  //   loader: () =>
  //     import(
  //       'src/view/tablaCostoEnergia/importer/TablaCostoEnergiaImporterPage'
  //     ),
  //   permissionRequired: permissions.tablaCostoEnergiaImport,
  //   exact: true,
  // },
  // {
  //   path: '/tabla-costo-energia/:id/edit',
  //   loader: () =>
  //     import(
  //       'src/view/tablaCostoEnergia/form/TablaCostoEnergiaFormPage'
  //     ),
  //   permissionRequired: permissions.tablaCostoEnergiaEdit,
  //   exact: true,
  // },
  // {
  //   path: '/tabla-costo-energia/:id',
  //   loader: () =>
  //     import(
  //       'src/view/tablaCostoEnergia/view/TablaCostoEnergiaViewPage'
  //     ),
  //   permissionRequired: permissions.tablaCostoEnergiaRead,
  //   exact: true,
  // },

  // {
  //   path: '/trazabilidad',
  //   loader: () => import('src/view/trazabilidad/list/TrazabilidadListPage'),
  //   permissionRequired: permissions.trazabilidadRead,
  //   exact: true,
  // },
  // {
  //   path: '/trazabilidad/new',
  //   loader: () => import('src/view/trazabilidad/form/TrazabilidadFormPage'),
  //   permissionRequired: permissions.trazabilidadCreate,
  //   exact: true,
  // },
  // {
  //   path: '/trazabilidad/importer',
  //   loader: () => import('src/view/trazabilidad/importer/TrazabilidadImporterPage'),
  //   permissionRequired: permissions.trazabilidadImport,
  //   exact: true,
  // },
  // {
  //   path: '/trazabilidad/:id/edit',
  //   loader: () => import('src/view/trazabilidad/form/TrazabilidadFormPage'),
  //   permissionRequired: permissions.trazabilidadEdit,
  //   exact: true,
  // },
  // {
  //   path: '/trazabilidad/:id',
  //   loader: () => import('src/view/trazabilidad/view/TrazabilidadViewPage'),
  //   permissionRequired: permissions.trazabilidadRead,
  //   exact: true,
  // },

  // {
  //   path: '/unidad-medida',
  //   loader: () => import('src/view/unidadMedida/list/UnidadMedidaListPage'),
  //   permissionRequired: permissions.unidadMedidaRead,
  //   exact: true,
  // },
  // {
  //   path: '/unidad-medida/new',
  //   loader: () => import('src/view/unidadMedida/form/UnidadMedidaFormPage'),
  //   permissionRequired: permissions.unidadMedidaCreate,
  //   exact: true,
  // },
  // {
  //   path: '/unidad-medida/importer',
  //   loader: () => import('src/view/unidadMedida/importer/UnidadMedidaImporterPage'),
  //   permissionRequired: permissions.unidadMedidaImport,
  //   exact: true,
  // },
  // {
  //   path: '/unidad-medida/:id/edit',
  //   loader: () => import('src/view/unidadMedida/form/UnidadMedidaFormPage'),
  //   permissionRequired: permissions.unidadMedidaEdit,
  //   exact: true,
  // },
  // {
  //   path: '/unidad-medida/:id',
  //   loader: () => import('src/view/unidadMedida/view/UnidadMedidaViewPage'),
  //   permissionRequired: permissions.unidadMedidaRead,
  //   exact: true,
  // },

  {
    path: '/planning',
    loader: () => import('src/view/planning/list/PlanningListPage'),
    permissionRequired: permissions.superadmin,
    exact: true,
  },
  {
    path: '/planning/new',
    loader: () => import('src/view/planning/form/PlanningFormPage'),
    permissionRequired: permissions.superadmin,
    exact: true,
  },
  {
    path: '/task-planning/importer',
    loader: () => import('src/view/planificadorTarea/importer/PlanificadorTareaCustomImporterPage'),
    permissionRequired: permissions.superadmin,
    exact: true,
  },
  {
    path: '/planning/importer',
    loader: () => import('src/view/planificadorTarea/importer/PlanificadorTareaCustomImporterPage'),
    //loader: () => import('src/view/planning/importer/PlanningImporterPage'),
    permissionRequired: permissions.superadmin,
    exact: true,
  },
  {
    path: '/planning/:id/edit',
    loader: () => import('src/view/planning/form/PlanningFormPage'),
    permissionRequired: permissions.superadmin,
    exact: true,
  },
  {
    path: '/planning/:id',
    loader: () => import('src/view/planning/view/PlanningViewPage'),
    permissionRequired: permissions.superadmin,
    exact: true,
  },

  {
    path: '/planificacion',
    loader: () => import('src/view/planificacion/list/PlanificacionListPage'),
    permissionRequired: permissions.planificacionRead,
    exact: true,
  },
  {
    path: '/planificacion/new',
    loader: () => import('src/view/planificacion/form/PlanificacionFormPage'),
    permissionRequired: permissions.planificacionCreate,
    exact: true,
  },
  {
    path: '/planificador-tarea/importer',
    loader: () => import('src/view/planificadorTarea/importer/PlanificadorTareaCustomImporterPage'),
    permissionRequired: permissions.planificadorTareaImport || permissions.planificacionImport,
    exact: true,
  },
  {
    path: '/planificacion/importer',
    loader: () => import('src/view/planificadorTarea/importer/PlanificadorTareaCustomImporterPage'),
    //loader: () => import('src/view/planificacion/importer/PlanificacionImporterPage'),
    permissionRequired: permissions.planificadorTareaImport || permissions.planificacionImport,
    exact: true,
  },
  {
    path: '/planificacion/:id/edit',
    loader: () => import('src/view/planificacion/form/PlanificacionFormPage'),
    permissionRequired: permissions.planificacionEdit,
    exact: true,
  },
  {
    path: '/planificacion/:id',
    loader: () => import('src/view/planificacion/view/PlanificacionViewPage'),
    permissionRequired: permissions.planificacionRead,
    exact: true,
  },

  {
    path: '/servicio-proveedor',
    loader: () => import('src/view/servicioProveedor/list/ServicioProveedorListPage'),
    permissionRequired: permissions.servicioProveedorRead,
    exact: true,
  },
  {
    path: '/servicio-proveedor/new',
    loader: () => import('src/view/servicioProveedor/form/ServicioProveedorFormPage'),
    permissionRequired: permissions.servicioProveedorCreate,
    exact: true,
  },
  {
    path: '/servicio-proveedor/importer',
    loader: () => import('src/view/servicioProveedor/importer/ServicioProveedorImporterPage'),
    permissionRequired: permissions.servicioProveedorImport,
    exact: true,
  },
  {
    path: '/servicio-proveedor/:id/edit',
    loader: () => import('src/view/servicioProveedor/form/ServicioProveedorFormPage'),
    permissionRequired: permissions.servicioProveedorEdit,
    exact: true,
  },
  {
    path: '/servicio-proveedor/:id',
    loader: () => import('src/view/servicioProveedor/view/ServicioProveedorViewPage'),
    permissionRequired: permissions.servicioProveedorRead,
    exact: true,
  },

  // {
  //   path: '/campo-personalizado',
  //   loader: () =>
  //     import(
  //       'src/view/campoPersonalizado/list/CampoPersonalizadoListPage'
  //     ),
  //   permissionRequired: permissions.campoPersonalizadoRead,
  //   exact: true,
  // },
  // {
  //   path: '/campo-personalizado/new',
  //   loader: () =>
  //     import(
  //       'src/view/campoPersonalizado/form/CampoPersonalizadoFormPage'
  //     ),
  //   permissionRequired:
  //     permissions.campoPersonalizadoCreate,
  //   exact: true,
  // },
  // {
  //   path: '/campo-personalizado/importer',
  //   loader: () =>
  //     import(
  //       'src/view/campoPersonalizado/importer/CampoPersonalizadoImporterPage'
  //     ),
  //   permissionRequired:
  //     permissions.campoPersonalizadoImport,
  //   exact: true,
  // },
  // {
  //   path: '/campo-personalizado/:id/edit',
  //   loader: () =>
  //     import(
  //       'src/view/campoPersonalizado/form/CampoPersonalizadoFormPage'
  //     ),
  //   permissionRequired: permissions.campoPersonalizadoEdit,
  //   exact: true,
  // },
  // {
  //   path: '/campo-personalizado/:id',
  //   loader: () =>
  //     import(
  //       'src/view/campoPersonalizado/view/CampoPersonalizadoViewPage'
  //     ),
  //   permissionRequired: permissions.campoPersonalizadoRead,
  //   exact: true,
  // },

  // {
  //   path: '/material',
  //   loader: () => import('src/view/material/list/MaterialListPage'),
  //   permissionRequired: permissions.materialRead,
  //   exact: true,
  // },
  // {
  //   path: '/material/new',
  //   loader: () => import('src/view/material/form/MaterialFormPage'),
  //   permissionRequired: permissions.materialCreate,
  //   exact: true,
  // },
  // {
  //   path: '/material/importer',
  //   loader: () => import('src/view/material/importer/MaterialImporterPage'),
  //   permissionRequired: permissions.materialImport,
  //   exact: true,
  // },
  // {
  //   path: '/material/:id/edit',
  //   loader: () => import('src/view/material/form/MaterialFormPage'),
  //   permissionRequired: permissions.materialEdit,
  //   exact: true,
  // },
  // {
  //   path: '/material/:id',
  //   loader: () => import('src/view/material/view/MaterialViewPage'),
  //   permissionRequired: permissions.materialRead,
  //   exact: true,
  // },

  // {
  //   path: '/estado-activos',
  //   loader: () =>
  //     import(
  //       'src/view/estadoActivos/list/EstadoActivosListPage'
  //     ),
  //   permissionRequired: permissions.estadoActivosRead,
  //   exact: true,
  // },
  // {
  //   path: '/estado-activos/new',
  //   loader: () =>
  //     import(
  //       'src/view/estadoActivos/form/EstadoActivosFormPage'
  //     ),
  //   permissionRequired: permissions.estadoActivosCreate,
  //   exact: true,
  // },
  // {
  //   path: '/estado-activos/importer',
  //   loader: () =>
  //     import(
  //       'src/view/estadoActivos/importer/EstadoActivosImporterPage'
  //     ),
  //   permissionRequired: permissions.estadoActivosImport,
  //   exact: true,
  // },
  // {
  //   path: '/estado-activos/:id/edit',
  //   loader: () =>
  //     import(
  //       'src/view/estadoActivos/form/EstadoActivosFormPage'
  //     ),
  //   permissionRequired: permissions.estadoActivosEdit,
  //   exact: true,
  // },
  // {
  //   path: '/estado-activos/:id',
  //   loader: () =>
  //     import(
  //       'src/view/estadoActivos/view/EstadoActivosViewPage'
  //     ),
  //   permissionRequired: permissions.estadoActivosRead,
  //   exact: true,
  // },

  // {
  //   path: '/cantidad-material-activo',
  //   loader: () =>
  //     import(
  //       'src/view/cantidadMaterialActivo/list/CantidadMaterialActivoListPage'
  //     ),
  //   permissionRequired:
  //     permissions.cantidadMaterialActivoRead,
  //   exact: true,
  // },
  // {
  //   path: '/cantidad-material-activo/new',
  //   loader: () =>
  //     import(
  //       'src/view/cantidadMaterialActivo/form/CantidadMaterialActivoFormPage'
  //     ),
  //   permissionRequired:
  //     permissions.cantidadMaterialActivoCreate,
  //   exact: true,
  // },
  // {
  //   path: '/cantidad-material-activo/importer',
  //   loader: () =>
  //     import(
  //       'src/view/cantidadMaterialActivo/importer/CantidadMaterialActivoImporterPage'
  //     ),
  //   permissionRequired:
  //     permissions.cantidadMaterialActivoImport,
  //   exact: true,
  // },
  // {
  //   path: '/cantidad-material-activo/:id/edit',
  //   loader: () =>
  //     import(
  //       'src/view/cantidadMaterialActivo/form/CantidadMaterialActivoFormPage'
  //     ),
  //   permissionRequired:
  //     permissions.cantidadMaterialActivoEdit,
  //   exact: true,
  // },
  // {
  //   path: '/cantidad-material-activo/:id',
  //   loader: () =>
  //     import(
  //       'src/view/cantidadMaterialActivo/view/CantidadMaterialActivoViewPage'
  //     ),
  //   permissionRequired:
  //     permissions.cantidadMaterialActivoRead,
  //   exact: true,
  // },

  // {
  //   path: '/solicitud-reciclaje',
  //   loader: () => import('src/view/solicitudReciclaje/list/SolicitudReciclajeListPage'),
  //   permissionRequired: permissions.solicitudReciclajeRead,
  //   exact: true,
  // },
  // {
  //   path: '/solicitud-reciclaje/new',
  //   loader: () => import('src/view/solicitudReciclaje/form/SolicitudReciclajeFormPage'),
  //   permissionRequired: permissions.solicitudReciclajeCreate,
  //   exact: true,
  // },
  // {
  //   path: '/solicitud-reciclaje/importer',
  //   loader: () => import('src/view/solicitudReciclaje/importer/SolicitudReciclajeImporterPage'),
  //   permissionRequired: permissions.solicitudReciclajeImport,
  //   exact: true,
  // },
  // {
  //   path: '/solicitud-reciclaje/:id/edit',
  //   loader: () => import('src/view/solicitudReciclaje/form/SolicitudReciclajeFormPage'),
  //   permissionRequired: permissions.solicitudReciclajeEdit,
  //   exact: true,
  // },
  // {
  //   path: '/solicitud-reciclaje/:id',
  //   loader: () => import('src/view/solicitudReciclaje/view/SolicitudReciclajeViewPage'),
  //   permissionRequired: permissions.solicitudReciclajeRead,
  //   exact: true,
  // },

  // {
  //   path: '/etiqueta',
  //   loader: () => import('src/view/etiqueta/list/EtiquetaListPage'),
  //   permissionRequired: permissions.etiquetaRead,
  //   exact: true,
  // },
  // {
  //   path: '/etiqueta/new',
  //   loader: () => import('src/view/etiqueta/form/EtiquetaFormPage'),
  //   permissionRequired: permissions.etiquetaCreate,
  //   exact: true,
  // },
  // {
  //   path: '/etiqueta/importer',
  //   loader: () => import('src/view/etiqueta/importer/EtiquetaImporterPage'),
  //   permissionRequired: permissions.etiquetaImport,
  //   exact: true,
  // },
  // {
  //   path: '/etiqueta/:id/edit',
  //   loader: () => import('src/view/etiqueta/form/EtiquetaFormPage'),
  //   permissionRequired: permissions.etiquetaEdit,
  //   exact: true,
  // },
  // {
  //   path: '/etiqueta/:id',
  //   loader: () => import('src/view/etiqueta/view/EtiquetaViewPage'),
  //   permissionRequired: permissions.etiquetaRead,
  //   exact: true,
  // },

  //v2.0.7
  {
    path: '/perfil-empleado',
    loader: () => import('src/view/perfilEmpleado/list/PerfilEmpleadoListPage'),
    permissionRequired: permissions.perfilEmpleadoRead,
    exact: true,
  },
  {
    path: '/perfil-empleado/new',
    loader: () => import('src/view/perfilEmpleado/form/PerfilEmpleadoFormPage'),
    permissionRequired: permissions.perfilEmpleadoCreate,
    exact: true,
  },
  {
    path: '/perfil-empleado/importer',
    loader: () => import('src/view/perfilEmpleado/importer/PerfilEmpleadoImporterPage'),
    permissionRequired: permissions.perfilEmpleadoImport,
    exact: true,
  },
  {
    path: '/perfil-empleado/:id/edit',
    loader: () => import('src/view/perfilEmpleado/form/PerfilEmpleadoFormPage'),
    permissionRequired: permissions.perfilEmpleadoEdit,
    exact: true,
  },
  {
    path: '/perfil-empleado/:id',
    loader: () => import('src/view/perfilEmpleado/view/PerfilEmpleadoViewPage'),
    permissionRequired: permissions.perfilEmpleadoRead,
    exact: true,
  },

  {
    path: '/turno-laboral',
    loader: () => import('src/view/turnoLaboral/list/TurnoLaboralListPage'),
    permissionRequired: permissions.turnoLaboralRead,
    exact: true,
  },
  {
    path: '/turno-laboral/new',
    loader: () => import('src/view/turnoLaboral/form/TurnoLaboralFormPage'),
    permissionRequired: permissions.turnoLaboralCreate,
    exact: true,
  },
  {
    path: '/turno-laboral/importer',
    loader: () => import('src/view/turnoLaboral/importer/TurnoLaboralImporterPage'),
    permissionRequired: permissions.turnoLaboralImport,
    exact: true,
  },
  {
    path: '/turno-laboral/:id/edit',
    loader: () => import('src/view/turnoLaboral/form/TurnoLaboralFormPage'),
    permissionRequired: permissions.turnoLaboralEdit,
    exact: true,
  },
  {
    path: '/turno-laboral/:id',
    loader: () => import('src/view/turnoLaboral/view/TurnoLaboralViewPage'),
    permissionRequired: permissions.turnoLaboralRead,
    exact: true,
  },

  {
    path: '/gestor-documental',
    loader: () => import('src/view/gestorDocumental/list/GestorDocumentalListPage'),
    permissionRequired: permissions.gestorDocumentalRead,
    exact: true,
  },
  {
    path: '/gestor-documental/new',
    loader: () => import('src/view/gestorDocumental/form/GestorDocumentalFormPage'),
    permissionRequired: permissions.gestorDocumentalCreate,
    exact: true,
  },
  {
    path: '/gestor-documental/importer',
    loader: () => import('src/view/gestorDocumental/importer/GestorDocumentalImporterPage'),
    permissionRequired: permissions.gestorDocumentalImport,
    exact: true,
  },
  {
    path: '/gestor-documental/:id/edit',
    loader: () => import('src/view/gestorDocumental/form/GestorDocumentalFormPage'),
    permissionRequired: permissions.gestorDocumentalEdit,
    exact: true,
  },
  {
    path: '/gestor-documental/:id',
    loader: () => import('src/view/gestorDocumental/view/GestorDocumentalViewPage'),
    permissionRequired: permissions.gestorDocumentalRead,
    exact: true,
  },

  // {
  //   path: '/dia-festivo',
  //   loader: () => import('src/view/diaFestivo/list/DiaFestivoListPage'),
  //   permissionRequired: permissions.diaFestivoRead,
  //   exact: true,
  // },
  // {
  //   path: '/dia-festivo/new',
  //   loader: () => import('src/view/diaFestivo/form/DiaFestivoFormPage'),
  //   permissionRequired: permissions.diaFestivoCreate,
  //   exact: true,
  // },
  // {
  //   path: '/dia-festivo/importer',
  //   loader: () => import('src/view/diaFestivo/importer/DiaFestivoImporterPage'),
  //   permissionRequired: permissions.diaFestivoImport,
  //   exact: true,
  // },
  // {
  //   path: '/dia-festivo/:id/edit',
  //   loader: () => import('src/view/diaFestivo/form/DiaFestivoFormPage'),
  //   permissionRequired: permissions.diaFestivoEdit,
  //   exact: true,
  // },
  // {
  //   path: '/dia-festivo/:id',
  //   loader: () => import('src/view/diaFestivo/view/DiaFestivoViewPage'),
  //   permissionRequired: permissions.diaFestivoRead,
  //   exact: true,
  // },

  {
    path: '/centro-costo',
    loader: () => import('src/view/centroCosto/list/CentroCostoListPage'),
    permissionRequired: permissions.centroCostoRead,
    exact: true,
  },
  {
    path: '/centro-costo/new',
    loader: () => import('src/view/centroCosto/form/CentroCostoFormPage'),
    permissionRequired: permissions.centroCostoCreate,
    exact: true,
  },
  {
    path: '/centro-costo/importer',
    loader: () => import('src/view/centroCosto/importer/CentroCostoImporterPage'),
    permissionRequired: permissions.centroCostoImport,
    exact: true,
  },
  {
    path: '/centro-costo/:id/edit',
    loader: () => import('src/view/centroCosto/form/CentroCostoFormPage'),
    permissionRequired: permissions.centroCostoEdit,
    exact: true,
  },
  {
    path: '/centro-costo/:id',
    loader: () => import('src/view/centroCosto/view/CentroCostoViewPage'),
    permissionRequired: permissions.centroCostoRead,
    exact: true,
  },

  {
    path: '/presupuesto',
    loader: () => import('src/view/presupuesto/list/PresupuestoListPage'),
    permissionRequired: permissions.presupuestoRead,
    exact: true,
  },
  {
    path: '/presupuesto/new',
    loader: () => import('src/view/presupuesto/form/PresupuestoFormPage'),
    permissionRequired: permissions.presupuestoCreate,
    exact: true,
  },
  {
    path: '/presupuesto/importer',
    loader: () => import('src/view/presupuesto/importer/PresupuestoImporterPage'),
    permissionRequired: permissions.presupuestoImport,
    exact: true,
  },
  {
    path: '/presupuesto/:id/edit',
    loader: () => import('src/view/presupuesto/form/PresupuestoFormPage'),
    permissionRequired: permissions.presupuestoEdit,
    exact: true,
  },
  {
    path: '/presupuesto/:id',
    loader: () => import('src/view/presupuesto/view/PresupuestoViewPage'),
    permissionRequired: permissions.presupuestoRead,
    exact: true,
  },

  {
    path: '/tercero',
    loader: () => import('src/view/tercero/list/TerceroListPage'),
    permissionRequired: permissions.terceroRead,
    exact: true,
  },
  {
    path: '/tercero/new',
    loader: () => import('src/view/tercero/form/TerceroFormPage'),
    permissionRequired: permissions.terceroCreate,
    exact: true,
  },
  {
    path: '/tercero/importer',
    loader: () => import('src/view/tercero/importer/TerceroImporterPage'),
    permissionRequired: permissions.terceroImport,
    exact: true,
  },
  {
    path: '/tercero/:id/edit',
    loader: () => import('src/view/tercero/form/TerceroFormPage'),
    permissionRequired: permissions.terceroEdit,
    exact: true,
  },
  {
    path: '/tercero/:id',
    loader: () => import('src/view/tercero/view/TerceroViewPage'),
    permissionRequired: permissions.terceroRead,
    exact: true,
  },

  {
    path: '/clasificacion-tercero',
    loader: () => import('src/view/clasificacionTercero/list/ClasificacionTerceroListPage'),
    permissionRequired: permissions.clasificacionTerceroRead,
    exact: true,
  },
  {
    path: '/clasificacion-tercero/new',
    loader: () => import('src/view/clasificacionTercero/form/ClasificacionTerceroFormPage'),
    permissionRequired: permissions.clasificacionTerceroCreate,
    exact: true,
  },
  {
    path: '/clasificacion-tercero/importer',
    loader: () => import('src/view/clasificacionTercero/importer/ClasificacionTerceroImporterPage'),
    permissionRequired: permissions.clasificacionTerceroImport,
    exact: true,
  },
  {
    path: '/clasificacion-tercero/:id/edit',
    loader: () => import('src/view/clasificacionTercero/form/ClasificacionTerceroFormPage'),
    permissionRequired: permissions.clasificacionTerceroEdit,
    exact: true,
  },
  {
    path: '/clasificacion-tercero/:id',
    loader: () => import('src/view/clasificacionTercero/view/ClasificacionTerceroViewPage'),
    permissionRequired: permissions.clasificacionTerceroRead,
    exact: true,
  },

  // {
  //   path: '/contacto',
  //   loader: () => import('src/view/contacto/list/ContactoListPage'),
  //   permissionRequired: permissions.contactoRead,
  //   exact: true,
  // },
  // {
  //   path: '/contacto/new',
  //   loader: () => import('src/view/contacto/form/ContactoFormPage'),
  //   permissionRequired: permissions.contactoCreate,
  //   exact: true,
  // },
  // {
  //   path: '/contacto/importer',
  //   loader: () => import('src/view/contacto/importer/ContactoImporterPage'),
  //   permissionRequired: permissions.contactoImport,
  //   exact: true,
  // },
  // {
  //   path: '/contacto/:id/edit',
  //   loader: () => import('src/view/contacto/form/ContactoFormPage'),
  //   permissionRequired: permissions.contactoEdit,
  //   exact: true,
  // },
  // {
  //   path: '/contacto/:id',
  //   loader: () => import('src/view/contacto/view/ContactoViewPage'),
  //   permissionRequired: permissions.contactoRead,
  //   exact: true,
  // },

  {
    path: '/tipo-servicio',
    loader: () => import('src/view/tipoServicio/list/TipoServicioListPage'),
    permissionRequired: permissions.tipoServicioRead,
    exact: true,
  },
  {
    path: '/tipo-servicio/new',
    loader: () => import('src/view/tipoServicio/form/TipoServicioFormPage'),
    permissionRequired: permissions.tipoServicioCreate,
    exact: true,
  },
  {
    path: '/tipo-servicio/importer',
    loader: () => import('src/view/tipoServicio/importer/TipoServicioImporterPage'),
    permissionRequired: permissions.tipoServicioImport,
    exact: true,
  },
  {
    path: '/tipo-servicio/:id/edit',
    loader: () => import('src/view/tipoServicio/form/TipoServicioFormPage'),
    permissionRequired: permissions.tipoServicioEdit,
    exact: true,
  },
  {
    path: '/tipo-servicio/:id',
    loader: () => import('src/view/tipoServicio/view/TipoServicioViewPage'),
    permissionRequired: permissions.tipoServicioRead,
    exact: true,
  },

  {
    path: '/servicio',
    loader: () => import('src/view/servicio/list/ServicioListPage'),
    permissionRequired: permissions.servicioRead,
    exact: true,
  },
  {
    path: '/servicio/new',
    loader: () => import('src/view/servicio/form/ServicioFormPage'),
    permissionRequired: permissions.servicioCreate,
    exact: true,
  },
  {
    path: '/servicio/importer',
    loader: () => import('src/view/servicio/importer/ServicioImporterPage'),
    permissionRequired: permissions.servicioImport,
    exact: true,
  },
  {
    path: '/servicio/:id/edit',
    loader: () => import('src/view/servicio/form/ServicioFormPage'),
    permissionRequired: permissions.servicioEdit,
    exact: true,
  },
  {
    path: '/servicio/:id',
    loader: () => import('src/view/servicio/view/ServicioViewPage'),
    permissionRequired: permissions.servicioRead,
    exact: true,
  },

  // {
  //   path: '/tipo-unidad',
  //   loader: () => import('src/view/tipoUnidad/list/TipoUnidadListPage'),
  //   permissionRequired: permissions.tipoUnidadRead,
  //   exact: true,
  // },
  // {
  //   path: '/tipo-unidad/new',
  //   loader: () => import('src/view/tipoUnidad/form/TipoUnidadFormPage'),
  //   permissionRequired: permissions.tipoUnidadCreate,
  //   exact: true,
  // },
  // {
  //   path: '/tipo-unidad/importer',
  //   loader: () => import('src/view/tipoUnidad/importer/TipoUnidadImporterPage'),
  //   permissionRequired: permissions.tipoUnidadImport,
  //   exact: true,
  // },
  // {
  //   path: '/tipo-unidad/:id/edit',
  //   loader: () => import('src/view/tipoUnidad/form/TipoUnidadFormPage'),
  //   permissionRequired: permissions.tipoUnidadEdit,
  //   exact: true,
  // },
  // {
  //   path: '/tipo-unidad/:id',
  //   loader: () => import('src/view/tipoUnidad/view/TipoUnidadViewPage'),
  //   permissionRequired: permissions.tipoUnidadRead,
  //   exact: true,
  // },

  {
    path: '/inventario',
    loader: () => import('src/view/inventario/list/InventarioListPage'),
    permissionRequired: permissions.inventarioRead,
    exact: true,
  },
  {
    path: '/inventario/new',
    loader: () => import('src/view/inventario/form/InventarioFormPage'),
    permissionRequired: permissions.inventarioCreate,
    exact: true,
  },
  {
    path: '/inventario/importer',
    loader: () => import('src/view/inventario/importer/InventarioImporterPage'),
    permissionRequired: permissions.inventarioImport,
    exact: true,
  },
  {
    path: '/inventario/:id/edit',
    loader: () => import('src/view/inventario/form/InventarioFormPage'),
    permissionRequired: permissions.inventarioEdit,
    exact: true,
  },
  {
    path: '/inventario/:id',
    loader: () => import('src/view/inventario/view/InventarioViewPage'),
    permissionRequired: permissions.inventarioRead,
    exact: true,
  },

  {
    path: '/ausencia',
    loader: () => import('src/view/absence/list/AbsenceListPage'),
    permissionRequired: permissions.absenceRead,
    exact: true,
  },
  {
    path: '/ausencia/new',
    loader: () => import('src/view/absence/form/AbsenceFormPage'),
    permissionRequired: permissions.absenceCreate,
    exact: true,
  },
  {
    path: '/ausencia/importer',
    loader: () => import('src/view/absence/importer/AbsenceImporterPage'),
    permissionRequired: permissions.absenceImport,
    exact: true,
  },
  {
    path: '/ausencia/:id/edit',
    loader: () => import('src/view/absence/form/AbsenceFormPage'),
    permissionRequired: permissions.absenceEdit,
    exact: true,
  },
  {
    path: '/ausencia/:id',
    loader: () => import('src/view/absence/view/AbsenceViewPage'),
    permissionRequired: permissions.absenceRead,
    exact: true,
  },

  {
    path: '/contractors',
    loader: () => import('src/view/incident/contractors/ContractorsPage'),
    permissionRequired: permissions.incidentRead,
    exact: true,
  },

  {
    path: '/incidente',
    loader: () => import('src/view/incident/list/IncidentListPage'),
    permissionRequired: permissions.incidentRead,
    exact: true,
  },
  {
    path: '/incidente/new',
    loader: () => import('src/view/incident/form/IncidentFormPage'),
    permissionRequired: permissions.incidentCreate,
    exact: true,
  },
  {
    path: '/incidente/importer',
    loader: () => import('src/view/incident/importer/IncidentImporterPage'),
    permissionRequired: permissions.incidentImport,
    exact: true,
  },
  {
    path: '/incidente/:id/edit',
    loader: () => import('src/view/incident/form/IncidentFormPage'),
    permissionRequired: permissions.incidentEdit,
    exact: true,
  },
  {
    path: '/incidente/:id',
    loader: () => import('src/view/incident/view/IncidentViewPage'),
    permissionRequired: permissions.incidentRead,
    exact: true,
  },

  {
    path: '/registro',
    loader: () => import('src/view/registro/list/RegistroListPage'),
    permissionRequired: permissions.registroRead,
    exact: true,
  },
  {
    path: '/registro/new',
    loader: () => import('src/view/registro/form/RegistroFormPage'),
    permissionRequired: permissions.registroCreate,
    exact: true,
  },
  {
    path: '/registro/importer',
    loader: () => import('src/view/registro/importer/RegistroImporterPage'),
    permissionRequired: permissions.registroImport,
    exact: true,
  },
  {
    path: '/registro/:id/edit',
    loader: () => import('src/view/registro/form/RegistroFormPage'),
    permissionRequired: permissions.registroEdit,
    exact: true,
  },
  {
    path: '/registro/:id',
    loader: () => import('src/view/registro/view/RegistroViewPage'),
    permissionRequired: permissions.registroRead,
    exact: true,
  },

  {
    path: '/historial-inventario',
    loader: () => import('src/view/historialInventario/list/HistorialInventarioListPage'),
    permissionRequired: permissions.historialInventarioRead,
    exact: true,
  },
  {
    path: '/historial-inventario/new',
    loader: () => import('src/view/historialInventario/form/HistorialInventarioFormPage'),
    permissionRequired: permissions.historialInventarioCreate,
    exact: true,
  },
  {
    path: '/historial-inventario/importer',
    loader: () => import('src/view/historialInventario/importer/HistorialInventarioImporterPage'),
    permissionRequired: permissions.historialInventarioImport,
    exact: true,
  },
  {
    path: '/historial-inventario/:id/edit',
    loader: () => import('src/view/historialInventario/form/HistorialInventarioFormPage'),
    permissionRequired: permissions.historialInventarioEdit,
    exact: true,
  },
  {
    path: '/historial-inventario/:id',
    loader: () => import('src/view/historialInventario/view/HistorialInventarioViewPage'),
    permissionRequired: permissions.historialInventarioRead,
    exact: true,
  },

  {
    path: '/firma',
    loader: () => import('src/view/firma/list/FirmaListPage'),
    permissionRequired: permissions.firmaRead,
    exact: true,
  },
  {
    path: '/firma/new',
    loader: () => import('src/view/firma/form/FirmaFormPage'),
    permissionRequired: permissions.firmaCreate,
    exact: true,
  },
  {
    path: '/firma/importer',
    loader: () => import('src/view/firma/importer/FirmaImporterPage'),
    permissionRequired: permissions.firmaImport,
    exact: true,
  },
  {
    path: '/firma/:id/edit',
    loader: () => import('src/view/firma/form/FirmaFormPage'),
    permissionRequired: permissions.firmaEdit,
    exact: true,
  },
  {
    path: '/firma/:id',
    loader: () => import('src/view/firma/view/FirmaViewPage'),
    permissionRequired: permissions.firmaRead,
    exact: true,
  },

  // {
  //   path: '/solicitud',
  //   loader: () => import('src/view/solicitud/list/SolicitudListPage'),
  //   permissionRequired: permissions.solicitudRead,
  //   exact: true,
  // },
  // {
  //   path: '/solicitud/new',
  //   loader: () => import('src/view/solicitud/form/SolicitudFormPage'),
  //   permissionRequired: permissions.solicitudCreate,
  //   exact: true,
  // },
  // {
  //   path: '/solicitud/importer',
  //   loader: () => import('src/view/solicitud/importer/SolicitudImporterPage'),
  //   permissionRequired: permissions.solicitudImport,
  //   exact: true,
  // },
  // {
  //   path: '/solicitud/:id/edit',
  //   loader: () => import('src/view/solicitud/form/SolicitudFormPage'),
  //   permissionRequired: permissions.solicitudEdit,
  //   exact: true,
  // },
  // {
  //   path: '/solicitud/:id',
  //   loader: () => import('src/view/solicitud/view/SolicitudViewPage'),
  //   permissionRequired: permissions.solicitudRead,
  //   exact: true,
  // },

  // {
  //   path: '/solicitud-activo',
  //   loader: () => import('src/view/solicitudActivo/list/SolicitudActivoListPage'),
  //   permissionRequired: permissions.solicitudActivoRead,
  //   exact: true,
  // },
  // {
  //   path: '/solicitud-activo/new',
  //   loader: () => import('src/view/solicitudActivo/form/SolicitudActivoFormPage'),
  //   permissionRequired: permissions.solicitudActivoCreate,
  //   exact: true,
  // },
  // {
  //   path: '/solicitud-activo/importer',
  //   loader: () => import('src/view/solicitudActivo/importer/SolicitudActivoImporterPage'),
  //   permissionRequired: permissions.solicitudActivoImport,
  //   exact: true,
  // },
  // {
  //   path: '/solicitud-activo/:id/edit',
  //   loader: () => import('src/view/solicitudActivo/form/SolicitudActivoFormPage'),
  //   permissionRequired: permissions.solicitudActivoEdit,
  //   exact: true,
  // },
  // {
  //   path: '/solicitud-activo/:id',
  //   loader: () => import('src/view/solicitudActivo/view/SolicitudActivoViewPage'),
  //   permissionRequired: permissions.solicitudActivoRead,
  //   exact: true,
  // },

  // {
  //   path: '/codificacion-i-s-o',
  //   loader: () => import('src/view/codificacionISO/list/CodificacionISOListPage'),
  //   permissionRequired: permissions.codificacionISORead,
  //   exact: true,
  // },
  // {
  //   path: '/codificacion-i-s-o/new',
  //   loader: () => import('src/view/codificacionISO/form/CodificacionISOFormPage'),
  //   permissionRequired: permissions.codificacionISOCreate,
  //   exact: true,
  // },
  // {
  //   path: '/codificacion-i-s-o/importer',
  //   loader: () => import('src/view/codificacionISO/importer/CodificacionISOImporterPage'),
  //   permissionRequired: permissions.codificacionISOImport,
  //   exact: true,
  // },
  // {
  //   path: '/codificacion-i-s-o/:id/edit',
  //   loader: () => import('src/view/codificacionISO/form/CodificacionISOFormPage'),
  //   permissionRequired: permissions.codificacionISOEdit,
  //   exact: true,
  // },
  // {
  //   path: '/codificacion-i-s-o/:id',
  //   loader: () => import('src/view/codificacionISO/view/CodificacionISOViewPage'),
  //   permissionRequired: permissions.codificacionISORead,
  //   exact: true,
  // },

  {
    path: '/recurso',
    loader: () => import('src/view/recurso/list/RecursoListPage'),
    permissionRequired: permissions.recursoRead,
    exact: true,
  },
  {
    path: '/recurso/new',
    loader: () => import('src/view/recurso/form/RecursoFormPage'),
    permissionRequired: permissions.recursoCreate,
    exact: true,
  },
  {
    path: '/recurso/importer',
    loader: () => import('src/view/recurso/importer/RecursoImporterPage'),
    permissionRequired: permissions.recursoImport,
    exact: true,
  },
  {
    path: '/recurso/:id/edit',
    loader: () => import('src/view/recurso/form/RecursoFormPage'),
    permissionRequired: permissions.recursoEdit,
    exact: true,
  },
  {
    path: '/recurso/:id',
    loader: () => import('src/view/recurso/view/RecursoViewPage'),
    permissionRequired: permissions.recursoRead,
    exact: true,
  },

  // {
  //   path: '/cuenta-contable',
  //   loader: () => import('src/view/cuentaContable/list/CuentaContableListPage'),
  //   permissionRequired: permissions.cuentaContableRead,
  //   exact: true,
  // },
  // {
  //   path: '/cuenta-contable/new',
  //   loader: () => import('src/view/cuentaContable/form/CuentaContableFormPage'),
  //   permissionRequired: permissions.cuentaContableCreate,
  //   exact: true,
  // },
  // {
  //   path: '/cuenta-contable/importer',
  //   loader: () => import('src/view/cuentaContable/importer/CuentaContableImporterPage'),
  //   permissionRequired: permissions.cuentaContableImport,
  //   exact: true,
  // },
  // {
  //   path: '/cuenta-contable/:id/edit',
  //   loader: () => import('src/view/cuentaContable/form/CuentaContableFormPage'),
  //   permissionRequired: permissions.cuentaContableEdit,
  //   exact: true,
  // },
  // {
  //   path: '/cuenta-contable/:id',
  //   loader: () => import('src/view/cuentaContable/view/CuentaContableViewPage'),
  //   permissionRequired: permissions.cuentaContableRead,
  //   exact: true,
  // },

  {
    path: '/tipo-centro-costo',
    loader: () => import('src/view/tipoCentroCosto/list/TipoCentroCostoListPage'),
    permissionRequired: permissions.tipoCentroCostoRead,
    exact: true,
  },
  {
    path: '/tipo-centro-costo/new',
    loader: () => import('src/view/tipoCentroCosto/form/TipoCentroCostoFormPage'),
    permissionRequired: permissions.tipoCentroCostoCreate,
    exact: true,
  },
  {
    path: '/tipo-centro-costo/importer',
    loader: () => import('src/view/tipoCentroCosto/importer/TipoCentroCostoImporterPage'),
    permissionRequired: permissions.tipoCentroCostoImport,
    exact: true,
  },
  {
    path: '/tipo-centro-costo/:id/edit',
    loader: () => import('src/view/tipoCentroCosto/form/TipoCentroCostoFormPage'),
    permissionRequired: permissions.tipoCentroCostoEdit,
    exact: true,
  },
  {
    path: '/tipo-centro-costo/:id',
    loader: () => import('src/view/tipoCentroCosto/view/TipoCentroCostoViewPage'),
    permissionRequired: permissions.tipoCentroCostoRead,
    exact: true,
  },

  {
    path: '/tipo-presupuesto',
    loader: () => import('src/view/tipoPresupuesto/list/TipoPresupuestoListPage'),
    permissionRequired: permissions.tipoPresupuestoRead,
    exact: true,
  },
  {
    path: '/tipo-presupuesto/new',
    loader: () => import('src/view/tipoPresupuesto/form/TipoPresupuestoFormPage'),
    permissionRequired: permissions.tipoPresupuestoCreate,
    exact: true,
  },
  {
    path: '/tipo-presupuesto/importer',
    loader: () => import('src/view/tipoPresupuesto/importer/TipoPresupuestoImporterPage'),
    permissionRequired: permissions.tipoPresupuestoImport,
    exact: true,
  },
  {
    path: '/tipo-presupuesto/:id/edit',
    loader: () => import('src/view/tipoPresupuesto/form/TipoPresupuestoFormPage'),
    permissionRequired: permissions.tipoPresupuestoEdit,
    exact: true,
  },
  {
    path: '/tipo-presupuesto/:id',
    loader: () => import('src/view/tipoPresupuesto/view/TipoPresupuestoViewPage'),
    permissionRequired: permissions.tipoPresupuestoRead,
    exact: true,
  },
  // {
  //   path: '/tipo-cuenta-contable',
  //   loader: () => import('src/view/tipoCuentaContable/list/TipoCuentaContableListPage'),
  //   permissionRequired: permissions.tipoCuentaContableRead,
  //   exact: true,
  // },
  // {
  //   path: '/tipo-cuenta-contable/new',
  //   loader: () => import('src/view/tipoCuentaContable/form/TipoCuentaContableFormPage'),
  //   permissionRequired: permissions.tipoCuentaContableCreate,
  //   exact: true,
  // },
  // {
  //   path: '/tipo-cuenta-contable/importer',
  //   loader: () => import('src/view/tipoCuentaContable/importer/TipoCuentaContableImporterPage'),
  //   permissionRequired: permissions.tipoCuentaContableImport,
  //   exact: true,
  // },
  // {
  //   path: '/tipo-cuenta-contable/:id/edit',
  //   loader: () => import('src/view/tipoCuentaContable/form/TipoCuentaContableFormPage'),
  //   permissionRequired: permissions.tipoCuentaContableEdit,
  //   exact: true,
  // },
  // {
  //   path: '/tipo-cuenta-contable/:id',
  //   loader: () => import('src/view/tipoCuentaContable/view/TipoCuentaContableViewPage'),
  //   permissionRequired: permissions.tipoCuentaContableRead,
  //   exact: true,
  // },
  {
    path: '/data-lake',
    loader: () => import('src/view/dataLake/list/DataLakeListPage'),
    permissionRequired: permissions.dataLakeRead,
    exact: true,
  },
  {
    path: '/data-lake/new',
    loader: () => import('src/view/dataLake/form/DataLakeFormPage'),
    permissionRequired: permissions.dataLakeCreate,
    exact: true,
  },
  {
    path: '/data-lake/importer',
    loader: () => import('src/view/dataLake/importer/DataLakeImporterPage'),
    permissionRequired: permissions.dataLakeImport,
    exact: true,
  },
  {
    path: '/data-lake/:id/edit',
    loader: () => import('src/view/dataLake/form/DataLakeFormPage'),
    permissionRequired: permissions.dataLakeEdit,
    exact: true,
  },
  {
    path: '/data-lake/:id',
    loader: () => import('src/view/dataLake/view/DataLakeViewPage'),
    permissionRequired: permissions.dataLakeRead,
    exact: true,
  },
  {
    path: '/data',
    loader: () => import('src/view/data/list/DataTabPage'),
    permissionRequired: permissions.dataRead,
    exact: true,
  },
  {
    path: '/data/new',
    loader: () => import('src/view/data/form/DataFormPage'),
    permissionRequired: permissions.dataCreate,
    exact: true,
  },
  {
    path: '/data/importer',
    loader: () => import('src/view/data/importer/DataImporterPage'),
    permissionRequired: permissions.dataImport,
    exact: true,
  },
  {
    path: '/data/:id/edit',
    loader: () => import('src/view/data/form/DataFormPage'),
    permissionRequired: permissions.dataEdit,
    exact: true,
  },
  {
    path: '/data/:id',
    loader: () => import('src/view/data/view/DataViewPage'),
    permissionRequired: permissions.dataRead,
    exact: true,
  },
].filter(Boolean);

const publicRoutes = [
  {
    path: '/auth/signin',
    loader: () => import('src/view/auth/SigninPage'),
  },
  {
    path: '/auth/signup',
    loader: () => import('src/view/auth/SignupPage'),
  },
  {
    path: '/auth/forgot-password',
    loader: () => import('src/view/auth/ForgotPasswordPage'),
  },
].filter(Boolean);

const emptyTenantRoutes = [
  {
    path: '/auth/tenant',
    loader: () => import('src/view/auth/TenantPage'),
  },
].filter(Boolean);

const emptyPermissionsRoutes = [
  {
    path: '/auth/empty-permissions',
    loader: () => import('src/view/auth/EmptyPermissionsPage'),
  },
].filter(Boolean);

const emailUnverifiedRoutes = [
  {
    path: '/auth/email-unverified',
    loader: () => import('src/view/auth/EmailUnverifiedPage'),
  },
].filter(Boolean);

const simpleRoutes = [
  {
    path: '/tarea/pdf/:tareaId/:tenantId',
    loader: () => import('src/view/tarea/pdf/TareaPdfViewPage'),
  },
  {
    path: '/gantt/:id',
    loader: () => import('src/view/planificacion/gantt/GanttPublicViewPage'),
  },
  {
    path: '/auth/password-reset',
    loader: () => import('src/view/auth/PasswordResetPage'),
  },
  {
    path: '/auth/invitation',
    loader: () => import('src/view/auth/InvitationPage'),
  },
  {
    path: '/auth/verify-email',
    loader: () => import('src/view/auth/VerifyEmailPage'),
  },
  {
    path: '/403',
    loader: () => import('src/view/shared/errors/Error403Page'),
  },
  {
    path: '/500',
    loader: () => import('src/view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () => import('src/view/shared/errors/Error404Page'),
  },
].filter(Boolean);

export default {
  privateRoutes,
  publicRoutes,
  emptyTenantRoutes,
  emptyPermissionsRoutes,
  emailUnverifiedRoutes,
  simpleRoutes,
};
