const absenceEnumerators = {
  //absenceType: ['Injury', 'Illness', 'NearMiss', 'PropertyDamage', 'Environmental', 'Vehicle', 'Other'],
  classification: ['WorkAccident', 'CommutingAccident', 'OccupationalDisease', 'NotCoveredByLaw', 'NotApplicable'],
  initialClassification: ['WorkAccident', 'CommutingAccident', 'OccupationalDisease', 'NotCoveredByLaw', 'NotApplicable'],
  finalClassification: ['WorkAccident', 'CommutingAccident', 'OccupationalDisease', 'NotCoveredByLaw', 'NotApplicable'],
  severity: ['Mild', 'Severe', 'Fatal'],
  incidentStatus: ['Open', 'Closed'],
  withLostDaysSelector: ['yes', 'no'],
  contractType: ['Unspecified', 'Indefinite', 'FixedTerm', 'Other'],
};

export default absenceEnumerators;
