const descRegistroEnumerators = {
  categoria: [
    'General',
    'Activo',
    'Tarea',
    'Usuario',
  ],
  tipo: [
    'Detención',
    'Aviso',
    'Prevención',
    'Preparación',
    'Alarma',
    'Afectación',
  ],
  prioridad: [
    'Muy Alta',
    'Alta',
    'Media',
    'Baja',
    'Muy Baja',
  ],
};

export default descRegistroEnumerators;
