const servicioEnumerators = {
  frecuencia: [
    'Horas',
    'Días',
    'Semanas',
    'Meses',
    'Año',
  ],
};

export default servicioEnumerators;
