import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import registroEnumerators from 'src/modules/registro/registroEnumerators';

export default [
  {
    name: 'descripcion',
    label: i18n('entities.registro.fields.descripcion'),
    schema: schemas.string(
      i18n('entities.registro.fields.descripcion'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'tipo',
    label: i18n('entities.registro.fields.tipo'),
    schema: schemas.enumerator(
      i18n('entities.registro.fields.tipo'),
      {
        "options": registroEnumerators.tipo
      },
    ),
  },
  {
    name: 'prioridad',
    label: i18n('entities.registro.fields.prioridad'),
    schema: schemas.enumerator(
      i18n('entities.registro.fields.prioridad'),
      {
        "options": registroEnumerators.prioridad
      },
    ),
  },
  {
    name: 'cuentasContables',
    label: i18n('entities.registro.fields.cuentasContables'),
    schema: schemas.relationToMany(
      i18n('entities.registro.fields.cuentasContables'),
      {},
    ),
  },
  {
    name: 'nota',
    label: i18n('entities.registro.fields.nota'),
    schema: schemas.string(
      i18n('entities.registro.fields.nota'),
      {},
    ),
  },
  {
    name: 'ubicacionGoogle',
    label: i18n('entities.registro.fields.ubicacionGoogle'),
    schema: schemas.string(
      i18n('entities.registro.fields.ubicacionGoogle'),
      {},
    ),
  },
  {
    name: 'latitud',
    label: i18n('entities.registro.fields.latitud'),
    schema: schemas.decimal(
      i18n('entities.registro.fields.latitud'),
      {},
    ),
  },
  {
    name: 'longitud',
    label: i18n('entities.registro.fields.longitud'),
    schema: schemas.decimal(
      i18n('entities.registro.fields.longitud'),
      {},
    ),
  },
  {
    name: 'trazabilidadesVinculadas',
    label: i18n('entities.registro.fields.trazabilidadesVinculadas'),
    schema: schemas.relationToMany(
      i18n('entities.registro.fields.trazabilidadesVinculadas'),
      {},
    ),
  },
  {
    name: 'activosVinculados',
    label: i18n('entities.registro.fields.activosVinculados'),
    schema: schemas.relationToMany(
      i18n('entities.registro.fields.activosVinculados'),
      {},
    ),
  },
  {
    name: 'documentosVinculados',
    label: i18n('entities.registro.fields.documentosVinculados'),
    schema: schemas.relationToMany(
      i18n('entities.registro.fields.documentosVinculados'),
      {},
    ),
  },
  {
    name: 'inventariosVinculados',
    label: i18n('entities.registro.fields.inventariosVinculados'),
    schema: schemas.relationToMany(
      i18n('entities.registro.fields.inventariosVinculados'),
      {},
    ),
  },
];