import moment from 'moment';
import {i18n} from 'src/i18n';
import layoutActions from 'src/modules/layout/layoutActions';
import PlanificacionListActions from 'src/modules/planificacion/list/planificacionListActions';
import PlanificacionService from 'src/modules/planificacion/planificacionService';
import Errors from 'src/modules/shared/error/errors';
import {getHistory} from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'PLANIFICACION_FORM';

const planificacionFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: planificacionFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await PlanificacionService.find(id);
      }

      dispatch({
        type: planificacionFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: planificacionFormActions.INIT_ERROR,
      });

      getHistory().push('/planificacion');
    }
  },

  doCreateFromTemplates: (data, created) => async (dispatch) => {
    try {
      dispatch({
        type: planificacionFormActions.CREATE_STARTED,
      });

      const creadas = await PlanificacionService.createFromTemplates(data);

      if (creadas?.length > 0) {
        for (let i = 0; i < creadas.length; i++) {
          const newPlan = creadas[i];
          if (created) {
            created(newPlan, 'planificacion');
          }
        }
      }

      dispatch({
        type: planificacionFormActions.CREATE_SUCCESS,
      });

      Message.success(i18n('entities.planificacion.create.success'));

      dispatch(
        layoutActions.doVisibleDrawer({
          drawerId: 'DrawerPlanificacionFormPage',
          drawerVisible: false,
          drawerChanged: moment().unix(),
        }),
      );

      dispatch(PlanificacionListActions.doFetchCurrentFilter());
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: planificacionFormActions.CREATE_ERROR,
      });
    }
  },
  doCreate:
    (values, created: any = null) =>
    async (dispatch) => {
      try {
        dispatch({
          type: planificacionFormActions.CREATE_STARTED,
        });

        const newPlan = await PlanificacionService.create(values);

        if (created) {
          created(newPlan, 'planificacion');
        }

        dispatch({
          type: planificacionFormActions.CREATE_SUCCESS,
        });

        Message.success(i18n('entities.planificacion.create.success'));

        dispatch(
          layoutActions.doVisibleDrawer({
            drawerId: 'DrawerPlanificacionFormPage',
            drawerVisible: false,
            drawerChanged: moment().unix(),
          }),
        );

        dispatch(PlanificacionListActions.doFetchCurrentFilter());
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: planificacionFormActions.CREATE_ERROR,
        });
      }
    },

  doUpdate:
    (id, values, edited: any = null) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: planificacionFormActions.UPDATE_STARTED,
        });

        const editedPlan = await PlanificacionService.update(id, values);

        if (edited) {
          edited(id, editedPlan);
        }

        dispatch({
          type: planificacionFormActions.UPDATE_SUCCESS,
        });

        Message.success(i18n('entities.planificacion.update.success'));

        dispatch(
          layoutActions.doVisibleDrawer({
            drawerId: 'DrawerPlanificacionFormPage',
            drawerVisible: false,
            drawerChanged: moment().unix(),
          }),
        );

        dispatch(PlanificacionListActions.doFetchCurrentFilter());
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: planificacionFormActions.UPDATE_ERROR,
        });
      }
    },
};

export default planificacionFormActions;
