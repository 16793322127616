import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { i18n } from 'src/i18n';
import selectors from 'src/modules/ubicacion/ubicacionSelectors';
import DrawerButton from 'src/view/shared/view/DrawerButton';
import UbicacionView from 'src/view/ubicacion/view/UbicacionView';

function UbicacionListItem(props) {
  const hasPermissionToRead = useSelector(selectors.selectPermissionToRead);

  const valueAsArray = () => {
    const { value } = props;
    //console.log('%c⧭ valueAsArray', 'color: #1d5673', value);

    if (!value) {
      return [];
    }

    if (Array.isArray(value)) {
      return value.filter(Boolean);
    }

    return [value].filter(Boolean);
  };

  const displayableRecord = (record) => {
    if (!record) return null;
    return (
      <div key={record.id}>
        <DrawerButton
          wrapText={true}
          drawerId={'DrawerUbicacionViewPage'}
          type={'chip'}
          entity={'ubicacion'}
          tooltipTitle={i18n(record.nombre)}
          buttonTitle={i18n(record.nombre)}
          width={window.innerWidth * 0.55}
          record={record}
          contractorsView={props.contractorsView}
          component={<UbicacionView record={record} drawer={true} contractorsView={props.contractorsView} />}
        />
      </div>
    );
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  if (!(valueAsArray()?.length > 0)) {
    return null;
  }

  const displayedValues = isExpanded ? valueAsArray() : valueAsArray().slice(0, 2);
  return (
    <>
      {displayedValues.map((value) => displayableRecord(value))}
      {!isExpanded && valueAsArray().length > 2 && (
        <Typography
          style={{
            cursor: 'pointer',
            color: '#3f51b5',
          }}
          variant="caption"
          onClick={handleExpandClick}>
          Ver más
        </Typography>
      )}
      {isExpanded && valueAsArray().length > 2 && (
        <Typography
          style={{
            cursor: 'pointer',
            color: '#3f51b5',
          }}
          variant="caption"
          onClick={handleExpandClick}>
          Ver menos
        </Typography>
      )}
    </>
  );
}

UbicacionListItem.propTypes = {
  value: PropTypes.any,
  contractorsView: PropTypes.bool,
};

export default UbicacionListItem;
