import FileSaver from 'file-saver';
import XLSX from 'xlsx';

export const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const EXCEL_TYPE_WITH_CHARSET = `${EXCEL_TYPE};charset=UTF-8`;
export const EXCEL_EXTENSION = '.xlsx';

export class Excel {
  static exportAsExcelFile(json, header, fileName, options: any) {
    if (options) {
      //console.log('%c⧭ exportAsExcelFile options', 'color: #b81c1c', fileName, options);
    }

    let worksheet = XLSX.utils.json_to_sheet(json, {
      header,
      skipHeader: false,
    });

    let workbook = {
      Sheets: {data: worksheet},
      SheetNames: ['data'],
    };

    if (options?.appendSheets) {
      options.appendSheets.forEach((sheet) => {
        let {name, json, header, skipHeader = false} = sheet;
        let newSheet = XLSX.utils.json_to_sheet(json, {
          header,
          skipHeader,
        });
        workbook.Sheets[name] = newSheet;
        if (!workbook.SheetNames.includes(name)) {
          workbook.SheetNames.push(name);
        }
      });
    }

    let excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    this.saveAsExcelFile(excelBuffer, fileName);
  }

  static saveAsExcelFile(buffer, fileName) {
    const data = new Blob([buffer], {
      type: EXCEL_TYPE_WITH_CHARSET,
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
}
