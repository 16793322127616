import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'nombre',
    label: i18n('entities.modeloFormulario.fields.nombre'),
    schema: schemas.string(
      i18n('entities.modeloFormulario.fields.nombre'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'sucursales',
    label: i18n('entities.modeloFormulario.fields.sucursales'),
    schema: schemas.relationToMany(
      i18n('entities.modeloFormulario.fields.sucursales'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'formato',
    label: i18n('entities.modeloFormulario.fields.formato'),
    schema: schemas.string(
      i18n('entities.modeloFormulario.fields.formato'),
      {},
    ),
  },
  {
    name: 'categoriaGeneral',
    label: i18n('entities.modeloFormulario.fields.categoriaGeneral'),
    schema: schemas.relationToOne(
      i18n('entities.modeloFormulario.fields.categoriaGeneral'),
      {},
    ),
  },
  {
    name: 'categoriasFormulario',
    label: i18n('entities.modeloFormulario.fields.categoriasFormulario'),
    schema: schemas.relationToMany(
      i18n('entities.modeloFormulario.fields.categoriasFormulario'),
      {},
    ),
  },
];