import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Chip, Drawer, ListItem, ListItemIcon, ListItemText, MenuItem, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import layoutSelectors from 'src/modules/layout/layoutSelectors';
import { magicFlexBox } from 'src/theme/schemes/CustomTheme';
import ChipActivoView from 'src/view/activo/view/ChipActivoView';
import ChipCentroCostoView from 'src/view/centroCosto/view/ChipCentroCostoView';
import ChipFirmaView from 'src/view/firma/view/ChipFirmaView';
import ChipInventarioView from 'src/view/inventario/view/ChipInventarioView';
import ChipModeloTareaView from 'src/view/modeloTarea/view/ChipModeloTareaView';
import ChipPerfilEmpleadoView from 'src/view/perfilEmpleado/view/ChipPerfilEmpleadoView';
import ChipPlanificacionView from 'src/view/planificacion/view/ChipPlanificacionView';
import ChipPlanificadorTareaView from 'src/view/planificadorTarea/view/ChipPlanificadorTareaView';
import ChipPresupuestoView from 'src/view/presupuesto/view/ChipPresupuestoView';
import ChipRecursoView from 'src/view/recurso/view/ChipRecursoView';
import ChipRegistroView from 'src/view/registro/view/ChipRegistroView';
import ChipServicioView from 'src/view/servicio/view/ChipServicioView';
import ChipTerceroView from 'src/view/tercero/view/ChipTerceroView';
import ChipTurnoLaboralView from 'src/view/turnoLaboral/view/ChipTurnoLaboralView';
import ChipUbicacionView from 'src/view/ubicacion/view/ChipUbicacionView';
import ChipUserView from 'src/view/user/view/ChipUserView';

import config from 'src/config';

const useStyles = makeStyles((theme: any) => ({
  toolbarOffset: theme.mixins.toolbar,
}));

export default function DrawerButton(props) {
  let { visible, setVisible } = props;
  if (visible === undefined && setVisible === undefined) {
    [visible, setVisible] = useState(false);
  }
  const [drawerId, setDrawerId] = useState(props.drawerId);

  const layout = useSelector(layoutSelectors.selectRaw);

  const classes = useStyles();

  useEffect(() => {
    // console.log({
    //   stateDrawerId: drawerId,
    //   propsDrawerId: props.drawerId,
    //   ...layout,
    // });
    if (drawerId === layout.drawerId) {
      setVisible(layout.drawerVisible);
    }
  }, [layout.drawerChanged]);

  useEffect(() => {
    setDrawerId(props.drawerId);
  }, [props.drawerId]);

  useEffect(() => {
    if (props.open) {
      setVisible(true);
    }
  }, [props.open]);

  const { style } = props;

  const onClick = ($event) => {
    if (props.onClick) {
      props.onClick($event);
    }
    setVisible(!visible);
  };

  const PropElement = props.element;

  return (
    <React.Fragment>
      {props.type === 'text' && (
        <span style={{ cursor: 'pointer', ...style }} onClick={onClick}>
          {props.element}
        </span>
      )}

      {props.type === 'menuItem' && (
        <MenuItem style={{ ...style }} onClick={onClick}>
          {props.buttonIcon && <ListItemIcon>{props.buttonIcon}</ListItemIcon>}
          <ListItemText primary={props.tooltipTitle} />
        </MenuItem>
      )}

      {props.type === 'listItem' && (
        <ListItem style={{ ...style }} onClick={onClick}>
          {props.buttonIcon && <ListItemIcon>{props.buttonIcon}</ListItemIcon>}
          <ListItemText primary={props.tooltipTitle} />
        </ListItem>
      )}

      {props.type === 'icon' && (
        <Tooltip followCursor title={props.tooltipTitle}>
          <IconButton size={props.size} style={{ ...style }} onClick={onClick}>
            {props.buttonIcon}
          </IconButton>
        </Tooltip>
      )}

      {props.type === 'chip' && !props.entity && (
        <Tooltip followCursor title={props.tooltipTitle}>
          <Chip
            style={{ ...style }}
            onClick={onClick}
            label={props.buttonTitle}
            variant="outlined"
            color="primary"
            icon={props.buttonIcon}
          />
        </Tooltip>
      )}

      {props.entity === 'user' && (
        <div style={{ ...style }} onClick={onClick}>
          <ChipUserView size={props.size || 40} type={props.type} user={props.record} />
        </div>
      )}

      {props.entity === 'activo' && (
        <div style={{ ...style }} onClick={onClick}>
          <ChipActivoView {...props} type={props.type} record={props.record} />
        </div>
      )}

      {props.entity === 'registro' && (
        <div style={{ ...style }} onClick={onClick}>
          <ChipRegistroView {...props} type={props.type} record={props.record} />
        </div>
      )}

      {props.type === 'chip' && props.entity === 'tercero' && (
        <div style={{ ...style }} onClick={onClick}>
          <ChipTerceroView type={'chip'} record={props.record} />
        </div>
      )}

      {props.type === 'chip' && props.entity === 'ubicacion' && (
        <div style={{ ...style }} onClick={onClick}>
          <ChipUbicacionView type={'chip'} record={props.record} contractorsView={props.contractorsView} />
        </div>
      )}

      {props.type === 'chip' && props.entity === 'planificacion' && (
        <div style={{ ...style }} onClick={onClick}>
          <ChipPlanificacionView type={'chip'} record={props.record} />
        </div>
      )}

      {props.type === 'chip' && props.entity === 'planificadorTarea' && (
        <div style={{ ...style }} onClick={onClick}>
          <ChipPlanificadorTareaView type={'chip'} record={props.record} />
        </div>
      )}

      {props.type === 'chip' && props.entity === 'modeloTarea' && (
        <div style={{ ...style }} onClick={onClick}>
          <ChipModeloTareaView type={'chip'} record={props.record} />
        </div>
      )}

      {props.type === 'chip' && props.entity === 'presupuesto' && (
        <div style={{ ...style }} onClick={onClick}>
          <ChipPresupuestoView type={'chip'} record={props.record} />
        </div>
      )}

      {props.type === 'chip' && props.entity === 'perfilEmpleado' && (
        <div style={{ ...style }} onClick={onClick}>
          <ChipPerfilEmpleadoView type={'chip'} record={props.record} contractorsView={props.contractorsView} />
        </div>
      )}

      {props.type === 'chip' && props.entity === 'recurso' && (
        <div style={{ ...style }} onClick={onClick}>
          <ChipRecursoView type={'chip'} record={props.record} />
        </div>
      )}

      {props.type === 'chip' && props.entity === 'firma' && (
        <div style={{ ...style }} onClick={onClick}>
          <ChipFirmaView type={'chip'} record={props.record} />
        </div>
      )}

      {props.entity === 'inventario' && (
        <div style={{ ...style }} onClick={onClick}>
          <ChipInventarioView type={props.type} record={props.record} />
        </div>
      )}

      {props.entity === 'servicio' && (
        <div style={{ ...style }} onClick={onClick}>
          <ChipServicioView type={props.type} record={props.record} />
        </div>
      )}

      {props.type === 'chip' && props.entity === 'centroCosto' && (
        <div style={{ ...style }} onClick={onClick}>
          <ChipCentroCostoView type={'chip'} record={props.record} />
        </div>
      )}

      {props.type === 'chip' && props.entity === 'turnoLaboral' && (
        <div style={{ ...style }} onClick={onClick}>
          <ChipTurnoLaboralView type={'chip'} record={props.record} />
        </div>
      )}

      {props.type === 'button' &&
        (props.tooltipTitle ? (
          <Tooltip enterDelay={700} leaveDelay={150} enterNextDelay={400} followCursor title={props.tooltipTitle}>
            <Button
              style={{ ...style }}
              disabled={props.disabled}
              onClick={onClick}
              variant={props.variant || 'contained'}
              color={props.color || 'primary'}
              startIcon={props.buttonIcon ? { ...props.buttonIcon } : undefined}
              size={props.size || 'small'}>
              {props.buttonTitle}
            </Button>
          </Tooltip>
        ) : (
          <Button
            style={{ ...style }}
            disabled={props.disabled}
            onClick={onClick}
            variant={props.variant || 'contained'}
            color={props.color || 'primary'}
            startIcon={props.buttonIcon ? { ...props.buttonIcon } : undefined}
            size={props.size || 'small'}>
            {props.buttonTitle}
          </Button>
        ))}

      {props.type === 'element' && (
        <div style={{ ...style }} onClick={onClick}>
          {props.element}
        </div>
      )}

      {props.type === 'table' && <PropElement onClick={onClick} />}

      <Drawer
        key={drawerId}
        onClick={($event) => props.stopPropagation && $event.stopPropagation()}
        anchor={window.innerWidth > 768 ? 'right' : 'top'}
        open={visible}
        onClose={(ev, reason) => {
          if (props.promptBeforeExit && reason === 'backdropClick') {
            if (!window.confirm(props.promptBeforeExit)) {
              return;
            }
          } else {
            if (props.preventBackdrop && reason === 'backdropClick') return;
          }
          setVisible(!visible);
          props.onClose && props.onClose();
        }}>
        {props.qrLink && (
          <div style={{ position: 'relative' }}>
            <div
              style={{
                position: 'absolute',
                top: 132,
                left: -(140 + 8 * 2),
                backgroundColor: 'white',
                padding: '12px',
                paddingBottom: '8px',
                borderRadius: '8px',
              }}>
              <QRCode value={`${config.frontendUrl.protocol}://${config.frontendUrl.host}/${props.qrLink}`} size={140} />
            </div>
          </div>
        )}
        <Box
          sx={{
            paddingLeft: 2,
            paddingBottom: '15px',
            height: '100vh',
            maxHeight: '100vh',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            width: window.innerWidth > 768 ? props.width || null : null,
          }}>
          <div
            style={{
              flexShrink: 0,
              display: 'flex',
            }}
            className={classes.toolbarOffset}></div>
          <Box style={{ width: '100%', textAlign: 'end', marginTop: 2, marginBottom: -7, paddingRight: 15 }}>
            <IconButton
              onClick={() => {
                setVisible(!visible);
                props.onClose && props.onClose();
              }}
              size="small"
              aria-label="close"
              style={{
                borderRadius: '50%',
                border: '1px solid black',
              }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box style={{ ...magicFlexBox, paddingRight: 15 } as any}>{props.component}</Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
