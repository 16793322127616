import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/tipoPresupuesto/importer/tipoPresupuestoImporterSelectors';
import TipoPresupuestoService from 'src/modules/tipoPresupuesto/tipoPresupuestoService';
import fields from 'src/modules/tipoPresupuesto/importer/tipoPresupuestoImporterFields';
import { i18n } from 'src/i18n';

const tipoPresupuestoImporterActions = importerActions(
  'TIPOPRESUPUESTO_IMPORTER',
  selectors,
  TipoPresupuestoService.import,
  fields,
  i18n('entities.tipoPresupuesto.importer.fileName'),
);

export default tipoPresupuestoImporterActions;