import styled from 'styled-components';

const FormWrapper = styled('div')({
  paddingTop: 0,
  paddingBottom: 0,
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'hidden',
});

export const FormButtons = styled('div')({
  paddingTop: '8px',
  display: 'flex',

  '& > *': {
    marginRight: '4px',
    marginBottom: '4px',
  },
});

export default FormWrapper;
