import {yupResolver} from '@hookform/resolvers/yup';
import {Remove as RemoveIcon} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import moment from 'moment-timezone';
import React, {useEffect, useState} from 'react';
import {FormProvider, useFieldArray, useForm, useWatch} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import authSelectors from 'src/modules/auth/authSelectors';
import selectors from 'src/modules/tarea/form/tareaFormSelectors';
import DatePickerFormItem from 'src/view/shared/form/items/DatePickerFormItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import ConfirmModal from 'src/view/shared/modals/ConfirmModal';
import * as yup from 'yup';

const schema = yup.object().shape({});

function TareaReportProgressForm(props) {
  const {record, onSubmit} = props;

  const saveLoading = useSelector(selectors.selectSaveLoading);
  const loading = saveLoading;

  const currentRoles: any = useSelector(authSelectors.selectRoles);
  const progressExecutor = currentRoles.includes('ejecutorProgreso');
  const progressEditor = currentRoles.includes('editorProgreso');

  const [initialValues] = useState(() => {
    return {
      taskProgressList: record.taskProgressList || [],
    };
  });

  const [confirmDelete, setConfirmDelete] = useState<any>(null);
  const openConfirmDelete = (modal) => {
    setConfirmDelete(modal);
  };
  const closeConfirmDelete = () => {
    setConfirmDelete(null);
  };

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
    shouldUnregister: false,
  });

  const {
    setValue,
    getValues,
    handleSubmit,
    formState: {errors, touchedFields, isSubmitted},
    control,
    reset,
  } = form;

  const {fields, append, remove} = useFieldArray({
    control,
    name: 'taskProgressList',
  });

  const sortDate = (a, b, label) => {
    if (!a[label] && !b[label]) {
      return 0;
    } else if (!a[label]) {
      return 1;
    } else if (!b[label]) {
      return -1;
    } else {
      return moment(a[label]).diff(moment(b[label]));
    }
  };

  const preSubmit = (values) => {
    setEditing(null);
    let acumulado = 0;
    const taskProgressList = values.taskProgressList
      .filter((item) => item.fecha && (item.progreso || item.progreso === 0))
      .map((item) => {
        if (item.new) {
          item.fechaRegistro = moment().toISOString();
        }
        acumulado += parseInt(item.progreso);
        return {
          fecha: item.fecha,
          progreso: item.progreso,
          fechaRegistro: item.fechaRegistro,
        };
      })
      .sort((a, b) => {
        // Compare by 'fechaRegistro'
        const sortByFechaRegistro = sortDate(a, b, 'fechaRegistro');
        // if (sortByFechaRegistro === 0) {
        //   const sortByFecha = sortDate(a, b, 'fecha');
        //   return sortByFecha;
        // }
        return sortByFechaRegistro;
      });
    //console.log('preSubmit', {taskProgressList});
    let progreso = record.progreso;
    if (taskProgressList.length > 0) {
      progreso = acumulado > 100 ? 100 : acumulado; //parseInt(taskProgressList[taskProgressList.length - 1].progreso);
    }
    setValue('taskProgressList', taskProgressList);
    onSubmit(record.id, {taskProgressList, progreso});
  };

  const handleAddRow = () => {
    const fecha = new Date().toISOString().slice(0, 10);
    append({fecha, progreso: 0, new: true});
    setTimeout(() => {
      setEditing(getValues().taskProgressList[fields.length]);
    }, 0);
  };

  const handleRemoveRow = (index, isEditing) => {
    remove(index);
    if (isEditing) {
      setEditing(null);
    }
    calcProgresoAcumulado();
    setConfirmDelete(null);
  };

  const [editing, setEditing] = useState<any>(null);

  const taskProgressList = useWatch({
    control,
    name: 'taskProgressList',
    defaultValue: record.taskProgressList || [],
  });

  const calcProgresoAcumulado = () => {
    const acumulado = getValues().taskProgressList.reduce((a, b) => {
      if (b === editing) {
        //console.log('%c⧭ if (b === editing)', 'color: #d0bfff', {a, b, editing});
        return a;
      }
      return a + parseInt(b.progreso);
    }, 0);
    //console.log('calcProgresoAcumulado', {editing, taskProgressList, acumulado});
    setProgresoAcumulado(acumulado);
    return acumulado;
  };

  useEffect(() => {
    calcProgresoAcumulado();
  }, [editing]);

  const [progresoAcumulado, setProgresoAcumulado] = useState(100);

  const superaMaximo = progresoAcumulado > 100;
  const superaMinimo = progresoAcumulado < 0;
  const alcanzaMaximo = (editing ? parseInt(editing.progreso) + progresoAcumulado : progresoAcumulado) >= 100;

  const maximoNuevo = 100 - progresoAcumulado;

  const ejecutorSinNuevos = progressExecutor && taskProgressList.every((item) => item.fechaRegistro);
  const guardarDisabled = loading || superaMaximo || superaMinimo || taskProgressList?.length === 0 || ejecutorSinNuevos;

  const readOnly = props.readOnly;
  const hideAddButton = readOnly; //taskProgressList.some((item) => item.progress === 0);
  const hideSaveButton = readOnly;

  //console.log('⧭ taskProgressList', taskProgressList);
  return (
    <>
      {/* {loading && <Spinner />} */}
      <FormProvider {...form}>
        <form style={{width: '100%'}}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {!hideAddButton && (
                <div style={{width: '100%', textAlign: 'right', paddingRight: 20}}>
                  <Button
                    disabled={alcanzaMaximo || superaMaximo}
                    onClick={handleAddRow}
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    size="small">
                    {i18n('common.add')}
                  </Button>
                </div>
              )}
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Registrado el</TableCell>
                      <TableCell>Fecha</TableCell>
                      <TableCell>Progreso</TableCell>
                      <TableCell padding="checkbox"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {taskProgressList &&
                      fields.map((field, index) => {
                        const item = taskProgressList[index];
                        //console.log('⧭ taskProgressList item', item);

                        const isEditing = editing === item;

                        const isRegistered = item?.fechaRegistro;
                        const canEdit = !readOnly && (!isRegistered || progressEditor);

                        return (
                          <TableRow key={field.id}>
                            <TableCell
                              sx={{
                                py: 0,
                              }}>
                              {field['fechaRegistro'] ? (
                                <div>{moment(field['fechaRegistro']).format('DD-MM-YYYY HH:mm')}</div>
                              ) : (
                                ''
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                py: 0,
                              }}>
                              <DatePickerFormItem
                                name={`taskProgressList[${index}].fecha`}
                                label={!isEditing ? undefined : 'Fecha'}
                                required={false}
                                readOnly={!isEditing}
                                defaultValue={new Date().toISOString()}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                py: 0,
                              }}>
                              <InputFormItem
                                name={`taskProgressList[${index}].progreso`}
                                label={!isEditing ? undefined : 'Progreso'}
                                readonly={!isEditing}
                                disabledInput={!isEditing}
                                type="number"
                                endAdornment="%"
                                max={maximoNuevo}
                                min={0}
                                defaultValue="0"
                                onChange={(value) => {
                                  const maximo = 100 - calcProgresoAcumulado();
                                  if (value > maximo) {
                                    value = maximo;
                                  }
                                  if (value < 0) {
                                    value = 0;
                                  }
                                  setValue(`taskProgressList[${index}].progreso`, value);
                                }}
                              />
                            </TableCell>
                            <TableCell padding="checkbox">
                              <div style={{display: 'inline-flex', padding: 10}}>
                                {canEdit && isEditing && (
                                  <IconButton onClick={() => setEditing(null)} size="small">
                                    <CheckIcon style={{color: 'green'}} />
                                  </IconButton>
                                )}
                                {canEdit && !isEditing && (
                                  <IconButton onClick={() => setEditing(item)} color="primary" size="small">
                                    <EditIcon />
                                  </IconButton>
                                )}
                                {canEdit && (
                                  <IconButton
                                    style={{marginLeft: 15, marginRight: 5}}
                                    onClick={() =>
                                      openConfirmDelete(
                                        <ConfirmModal
                                          title={i18n('common.areYouSure')}
                                          onConfirm={() => handleRemoveRow(index, isEditing)}
                                          onClose={() => closeConfirmDelete()}
                                          okText={i18n('common.yes')}
                                          cancelText={i18n('common.no')}
                                        />,
                                      )
                                    }
                                    color="secondary"
                                    size="small">
                                    <RemoveIcon />
                                  </IconButton>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              {confirmDelete}
            </Grid>
          </Grid>
          <Grid style={{marginTop: 35}} container>
            <Grid item xs={12}>
              {!hideSaveButton && (
                <Button
                  style={{
                    width: '100%',
                    borderRadius: 20,
                    padding: 16,
                    fontSize: 16,
                    fontWeight: 800,
                  }}
                  variant="contained"
                  color="primary"
                  disabled={guardarDisabled}
                  type="button"
                  endIcon={
                    loading && (
                      <CircularProgress
                        style={{color: 'white', marginLeft: 10, marginRight: -80, marginTop: -10, marginBottom: -10}}
                      />
                    )
                  }
                  onClick={handleSubmit(preSubmit)}>
                  {i18n('common.saveProgress')}
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
}

export default TareaReportProgressForm;
