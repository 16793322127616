import {PersonPinCircle, ScheduleOutlined} from '@mui/icons-material';
import {Grid} from '@mui/material';
import moment from 'moment-timezone';
import 'moment/locale/es';
import React, {useEffect} from 'react';
import {useFieldArray, useFormContext} from 'react-hook-form';

import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import {makeStyles} from '@mui/styles';
import Geocode from 'react-geocode';
import {useSelector} from 'react-redux';
import {compose, withProps} from 'recompose';
import authSelectors from 'src/modules/auth/authSelectors';
import CheckboxFormItem from '../../shared/form/items/CheckboxFormItem';

const {withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow} = require('react-google-maps');

Geocode.setApiKey('AIzaSyBdnksXevtjy1scvJVYtZ0HlEgkQkOp8Xk');
Geocode.setLanguage('es');
Geocode.setRegion('cl');

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

const GoogleMapComponent = compose(
  withProps({
    loadingElement: <div style={{height: `100%`}} />,
    containerElement: <div style={{height: `250px`}} />,
    mapElement: <div style={{height: `100%`}} />,
  }),
  withGoogleMap,
)((props) => <GoogleMap {...props} />);

function getSteps(modeloTarea) {
  return modeloTarea?.subTareasRelacionadas.map((item) => item);
  //return _.orderBy(modeloTarea.subTareasRelacionadas, ['posicion'], ['asc']).map((item) => item);
}

export default function PasosTareaForm({name, readOnly, record}) {
  const {control, getValues, watch, register, setValue} = useFormContext();
  const classes = useStyles();
  const [checkStep, setcheckStep] = React.useState(0);
  const steps = getSteps(record.modeloTarea);

  const {fields, remove, append, insert} = useFieldArray({
    control,
    name,
  }) as any;

  const currentUser = useSelector(authSelectors.selectCurrentUser);

  useEffect(() => {
    if (['Abierta', 'Rechazada', 'FueraPlazo'].includes(watch('estado')) && watch('ejecutor')?.id === currentUser.id) {
      setValue('pasoActual', checkStep);

      setValue(`${name}[${checkStep}].paso`, checkStep);

      setValue(`${name}[${checkStep}].fecha`, moment().format('YYYY-MM-DD HH:mm:ss'));

      navigator.geolocation.getCurrentPosition((pos) => {
        const coords = pos.coords;

        Geocode.fromLatLng(coords.latitude, coords.longitude).then(
          (response) => {
            const address = response.results[0].formatted_address;

            setValue(`${name}[${checkStep}].ubicacion`, {
              latitud: coords.latitude,
              longitud: coords.longitude,
              address: address,
            });

            setValue('ultimaUbicacion', {
              latitud: coords.latitude,
              longitud: coords.longitude,
              address: address,
            });
          },
          (error) => {
            console.error(error);
          },
        );
      });
    }
  }, [checkStep]);

  useEffect(() => {
    fields.map((item, index) => {
      register('pasoActual');
      register(`${name}[${index}].paso`);
      register(`${name}[${index}].fecha`);
      register(`${name}[${index}].checkbox`);

      setValue(`${name}[${index}].checkbox`, item.checkbox);

      setValue(`${name}[${index}].ubicacion`, item.ubicacion);

      setValue('ultimaUbicacion', watch('ultimaUbicacion'));
    });
  }, []);

  const itemList = watch(name)?.filter(Boolean);

  return (
    <div className={classes.root}>
      {itemList ? (
        <Grid style={{marginBottom: 20}} item lg={12}>
          <Grid container lg={12} alignItems={'center'}>
            <Typography style={{fontWeight: 600, marginBottom: 10}} variant={'h4'}>
              {(
                (itemList.filter((item) => item.checkbox).length * 100) /
                record.modeloTarea?.subTareasRelacionadas?.length
              ).toFixed(1)}{' '}
              %
            </Typography>
          </Grid>
          <LinearProgress
            variant="determinate"
            value={(itemList.filter((item) => item.checkbox).length * 100) / record.modeloTarea?.subTareasRelacionadas?.length}
          />
        </Grid>
      ) : null}

      <Grid item lg={12}>
        {steps?.filter(Boolean).map((item: any, index) => (
          <Grid style={{marginBottom: 40}} container key={item.id}>
            <Grid container direction="row" alignItems="center">
              <Grid item lg={1}>
                <CheckboxFormItem disabled={readOnly} name={`${name}[${index}].checkbox`} size={'large'} />
              </Grid>
              <Grid item lg={11}>
                <Typography variant="h6">{item.nombre}</Typography>
                <Typography variant="subtitle2">{item.descripcion}</Typography>

                {fields[index] ? (
                  <React.Fragment>
                    {fields[index].ubicacion ? (
                      <Typography style={{marginTop: 10}} variant="body1" color="textSecondary">
                        <PersonPinCircle /> {fields[index].ubicacion?.address}
                      </Typography>
                    ) : null}
                    <Typography
                      style={{
                        marginTop: 5,
                        marginBottom: 10,
                      }}
                      variant="body2"
                      color="textSecondary">
                      <ScheduleOutlined /> {moment(fields[index].fecha).format('LLLL')}
                    </Typography>

                    {fields[index].ubicacion ? (
                      <GoogleMapComponent
                        defaultCenter={{
                          lat: fields[index].ubicacion?.latitud,
                          lng: fields[index].ubicacion?.longitud,
                        }}
                        center={{
                          lat: fields[index].ubicacion?.latitud,
                          lng: fields[index].ubicacion?.longitud,
                        }}
                        defaultZoom={12}>
                        <Marker
                          position={{
                            lat: fields[index].ubicacion?.latitud,
                            lng: fields[index].ubicacion?.longitud,
                          }}
                          defaultPosition={{
                            lat: fields[index].ubicacion?.latitud,
                            lng: fields[index].ubicacion?.longitud,
                          }}
                          draggable={true}></Marker>
                      </GoogleMapComponent>
                    ) : null}
                  </React.Fragment>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
