import React from 'react';
import Spinner from 'src/view/shared/Spinner';
import UbicacionFormPage from '../form/UbicacionFormPage';

function UbicacionView(props) {
  const renderView = () => {
    const { record, drawer, contractorsView } = props;
    return <UbicacionFormPage record={record} disabled={true} drawer={drawer} contractorsView={props.contractorsView} />;
  };

  const { record, loading } = props;

  if (loading || !record) {
    return <Spinner />;
  }

  return renderView();
}

export default UbicacionView;
