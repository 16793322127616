import list from 'src/modules/dataLake/list/dataLakeListReducers';
import form from 'src/modules/dataLake/form/dataLakeFormReducers';
import view from 'src/modules/dataLake/view/dataLakeViewReducers';
import destroy from 'src/modules/dataLake/destroy/dataLakeDestroyReducers';
import importerReducer from 'src/modules/dataLake/importer/dataLakeImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
