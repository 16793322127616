import {connectRouter} from 'connected-react-router';
import auditLog from 'src/modules/auditLog/auditLogReducers';
import auth from 'src/modules/auth/authReducers';
import layout from 'src/modules/layout/layoutReducers';
import plan from 'src/modules/plan/planReducers';
import producto from 'src/modules/producto/productoReducers';
import settings from 'src/modules/settings/settingsReducers';
import tenant from 'src/modules/tenant/tenantReducers';
import user from 'src/modules/user/userReducers';
// import sucursal from 'src/modules/sucursal/sucursalReducers';
// import formulario from 'src/modules/formulario/formularioReducers';
import modeloFormulario from 'src/modules/modeloFormulario/modeloFormularioReducers';
import pregunta from 'src/modules/pregunta/preguntaReducers';
import respuesta from 'src/modules/respuesta/respuestaReducers';
// import rubro from 'src/modules/rubro/rubroReducers';
// import factura from 'src/modules/factura/facturaReducers';
// import segmentacion from 'src/modules/segmentacion/segmentacionReducers';
// import division from 'src/modules/division/divisionReducers';
// import pais from 'src/modules/pais/paisReducers';
import categoria from 'src/modules/categoria/categoriaReducers';
import comunicacionInterna from 'src/modules/comunicacionInterna/comunicacionInternaReducers';
//import configuracionNotificacion from 'src/modules/configuracionNotificacion/configuracionNotificacionReducers';
import departamento from 'src/modules/departamento/departamentoReducers';
//import evento from 'src/modules/evento/eventoReducers';
import mensajeInterno from 'src/modules/mensajeInterno/mensajeInternoReducers';
import modeloTarea from 'src/modules/modeloTarea/modeloTareaReducers';
import nivelPrioridad from 'src/modules/nivelPrioridad/nivelPrioridadReducers';
import nivelPuesto from 'src/modules/nivelPuesto/nivelPuestoReducers';
import notificacion from 'src/modules/notificacion/notificacionReducers';
import objetivo from 'src/modules/objetivo/objetivoReducers';
import pendiente from 'src/modules/pendiente/pendienteReducers';
import planificadorFormulario from 'src/modules/planificadorFormulario/planificadorFormularioReducers';
import planificadorTarea from 'src/modules/planificadorTarea/planificadorTareaReducers';
import planificadorTareaCustom from 'src/modules/planificadorTareaCustom/planificadorTareaCustomReducers';
import puesto from 'src/modules/puesto/puestoReducers';
//import recordatorio from 'src/modules/recordatorio/recordatorioReducers';
import absence from 'src/modules/absence/absenceReducers';
import asignadorFuncion from 'src/modules/asignadorFuncion/asignadorFuncionReducers';
import categoriaFormulario from 'src/modules/categoriaFormulario/categoriaFormularioReducers';
import categoriaProducto from 'src/modules/categoriaProducto/categoriaProductoReducers';
import funcion from 'src/modules/funcion/funcionReducers';
import habilidad from 'src/modules/habilidad/habilidadReducers';
import incident from 'src/modules/incident/incidentReducers';
import subTarea from 'src/modules/subTarea/subTareaReducers';
import tarea from 'src/modules/tarea/tareaReducers';
import temaComunicacion from 'src/modules/temaComunicacion/temaComunicacionReducers';

// import empresa from 'src/modules/empresa/empresaReducers';
import activo from 'src/modules/activo/activoReducers';
import ubicacion from 'src/modules/ubicacion/ubicacionReducers';
// import marca from 'src/modules/marca/marcaReducers';
// import modelo from 'src/modules/modelo/modeloReducers';
import tipoActivo from 'src/modules/tipoActivo/tipoActivoReducers';
// import tipoEnergia from 'src/modules/tipoEnergia/tipoEnergiaReducers';
// import tablaCostoEnergia from 'src/modules/tablaCostoEnergia/tablaCostoEnergiaReducers';
import planificacion from 'src/modules/planificacion/planificacionReducers';
//import trazabilidad from 'src/modules/trazabilidad/trazabilidadReducers';
//import unidadMedida from 'src/modules/unidadMedida/unidadMedidaReducers';
// import servicioProveedor from 'src/modules/servicioProveedor/servicioProveedorReducers';
// import campoPersonalizado from 'src/modules/campoPersonalizado/campoPersonalizadoReducers';
//import material from 'src/modules/material/materialReducers';
// import estadoActivos from 'src/modules/estadoActivos/estadoActivosReducers';
// import cantidadMaterialActivo from 'src/modules/cantidadMaterialActivo/cantidadMaterialActivoReducers';
//import etiqueta from 'src/modules/etiqueta/etiquetaReducers';
//import solicitudReciclaje from 'src/modules/solicitudReciclaje/solicitudReciclajeReducers';

import {combineReducers} from 'redux';
import centroCosto from 'src/modules/centroCosto/centroCostoReducers';
import clasificacionTercero from 'src/modules/clasificacionTercero/clasificacionTerceroReducers';
//import codificacionISO from 'src/modules/codificacionISO/codificacionISOReducers';
//import contacto from 'src/modules/contacto/contactoReducers';
//import cuentaContable from 'src/modules/cuentaContable/cuentaContableReducers';
import data from 'src/modules/data/dataReducers';
import dataLake from 'src/modules/dataLake/dataLakeReducers';
import descRegistro from 'src/modules/descRegistro/descRegistroReducers';
//import diaFestivo from 'src/modules/diaFestivo/diaFestivoReducers';
import firma from 'src/modules/firma/firmaReducers';
import gestorDocumental from 'src/modules/gestorDocumental/gestorDocumentalReducers';
import historialInventario from 'src/modules/historialInventario/historialInventarioReducers';
import inventario from 'src/modules/inventario/inventarioReducers';
import perfilEmpleado from 'src/modules/perfilEmpleado/perfilEmpleadoReducers';
import presupuesto from 'src/modules/presupuesto/presupuestoReducers';
import recurso from 'src/modules/recurso/recursoReducers';
import registro from 'src/modules/registro/registroReducers';
import servicio from 'src/modules/servicio/servicioReducers';
import shared from 'src/modules/shared/sharedReducers';
import solicitud from 'src/modules/solicitud/solicitudReducers';
//import solicitudActivo from 'src/modules/solicitudActivo/solicitudActivoReducers';
import tercero from 'src/modules/tercero/terceroReducers';
import tipoCentroCosto from 'src/modules/tipoCentroCosto/tipoCentroCostoReducers';
// import tipoCuentaContable from 'src/modules/tipoCuentaContable/tipoCuentaContableReducers';
import tipoPresupuesto from 'src/modules/tipoPresupuesto/tipoPresupuestoReducers';
import tipoServicio from 'src/modules/tipoServicio/tipoServicioReducers';
// import tipoUnidad from 'src/modules/tipoUnidad/tipoUnidadReducers';
import turnoLaboral from 'src/modules/turnoLaboral/turnoLaboralReducers';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    auth,
    tenant,
    plan,
    user,
    auditLog,
    settings,
    producto,
    // sucursal,
    // formulario,
    pregunta,
    respuesta,
    modeloFormulario,
    // rubro,
    // factura,
    // segmentacion,
    // division,
    // pais,
    puesto,
    departamento,
    nivelPuesto,
    planificadorFormulario,
    //configuracionNotificacion,
    objetivo,
    modeloTarea,
    subTarea,
    planificadorTarea,
    planificadorTareaCustom,
    categoria,
    tarea,
    incident,
    absence,
    pendiente,
    comunicacionInterna,
    temaComunicacion,
    nivelPrioridad,
    mensajeInterno,
    //evento,
    //recordatorio,
    notificacion,
    categoriaProducto,
    asignadorFuncion,
    funcion,
    categoriaFormulario,
    habilidad,

    // empresa,
    ubicacion,
    activo,
    // marca,
    // modelo,
    tipoActivo,
    // tipoEnergia,
    // tablaCostoEnergia,
    //trazabilidad,
    //unidadMedida,
    planificacion,
    // servicioProveedor,
    // campoPersonalizado,
    //material,
    // estadoActivos,
    // cantidadMaterialActivo,
    //solicitudReciclaje,
    //etiqueta,

    perfilEmpleado,
    turnoLaboral,
    gestorDocumental,
    //diaFestivo,
    centroCosto,
    presupuesto,
    tercero,
    clasificacionTercero,
    //contacto,
    tipoServicio,
    servicio,
    //tipoUnidad,
    inventario,
    registro,
    descRegistro,
    historialInventario,
    firma,
    solicitud,
    //solicitudActivo,
    //codificacionISO,
    recurso,
    //cuentaContable,
    tipoCentroCosto,
    tipoPresupuesto,
    //tipoCuentaContable,
    shared,
    dataLake,
    data,
  });
