const ubicacionEnumerators = {
  clasificacion: [
    'Area',
    'Bodega',
    'Tienda',
    'Taller',
    'Cancha',
    'Faena',
    'Vehiculo',
    'Planta',
    'Obra',
    'Embarcacion',
    'Oficina',
  ],
  estado: [
    'Normal',
    'Alerta',
    'Grave',
  ],
  visibilidad: [
    'Visible',
    'Oculta',
  ],
};

export default ubicacionEnumerators;
