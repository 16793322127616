import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/dataLake/importer/dataLakeImporterSelectors';
import DataLakeService from 'src/modules/dataLake/dataLakeService';
import fields from 'src/modules/dataLake/importer/dataLakeImporterFields';
import { i18n } from 'src/i18n';

const dataLakeImporterActions = importerActions(
  'DATALAKE_IMPORTER',
  selectors,
  DataLakeService.import,
  fields,
  i18n('entities.dataLake.importer.fileName'),
);

export default dataLakeImporterActions;