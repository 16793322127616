import MaterialLink from '@mui/material/Link';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';

import {useSelector} from 'react-redux';
import selectors from 'src/modules/puesto/puestoSelectors';

function PuestoListItem(props) {
  const hasPermissionToRead = useSelector(selectors.selectPermissionToRead);

  const valueAsArray = () => {
    const {value} = props;

    if (!value) {
      return [];
    }

    if (Array.isArray(value)) {
      return value;
    }

    return [value];
  };

  const displayableRecord = (record) => {
    if (hasPermissionToRead) {
      return (
        <div key={record.id}>
          <MaterialLink component={Link} to={`/puesto/${record.id}`}>
            {record.nombre}
          </MaterialLink>
        </div>
      );
    }

    return <div key={record.id}>{record.nombre}</div>;
  };

  if (!valueAsArray().length) {
    return null;
  }

  return <>{valueAsArray().map((value) => displayableRecord(value))}</>;
}

PuestoListItem.propTypes = {
  value: PropTypes.any,
};

export default PuestoListItem;
