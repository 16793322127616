import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/firma/importer/firmaImporterSelectors';
import FirmaService from 'src/modules/firma/firmaService';
import fields from 'src/modules/firma/importer/firmaImporterFields';
import { i18n } from 'src/i18n';

const firmaImporterActions = importerActions(
  'FIRMA_IMPORTER',
  selectors,
  FirmaService.import,
  fields,
  i18n('entities.firma.importer.fileName'),
);

export default firmaImporterActions;